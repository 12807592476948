import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from "../../base-module/base-component/base.component";
import { MatDialog,MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EstadoCivilService } from '../services/estado-civil.service';
import { FormControl, Validators} from '@angular/forms';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { ProfissaoService } from 'app/auxiliares/services/profissao.service';
import { ClienteService } from '../services/cliente.service';
import { AbordagemService } from 'app/gestao-patrimonial/services/abordagem.service';

export interface DialogData {
    abordagem: boolean;
}

@Component({
  selector: 'app-cadastro-cliente-simples',
  templateUrl: './cadastro-cliente-simples.component.html',
  styleUrls: ['./cadastro-cliente-simples.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [ AbordagemService ]
})
export class CadastroClienteSimplesComponent extends BaseComponent {

    nome = new FormControl('', [Validators.required]);
    data_nascimento = new FormControl();
    sexo = new FormControl('',[Validators.required]);
    estado_civil_id = new FormControl('');
    profissao_id = new FormControl('');
    assessor_id = new FormControl('',[Validators.required]);
    prospect = new FormControl('',[Validators.required]);
    telefone = new FormControl();

    model:any = {
        nome:"",
    };

    loadingLocal:any;
    listAssessor: any[] = [];
    listProfissao: any[] = [];
    listEstadoCivil: any[] = [];
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerProfissao:boolean = false;
    loadingSpinnerEstadoCivil:boolean = false;

    constructor(public service: ClienteService,
                private _abordagemService: AbordagemService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<CadastroClienteSimplesComponent>,
                private usuarioService:UsuarioService,
                private _profissaoService: ProfissaoService,
                private _estadoCivilService: EstadoCivilService,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        super(dialog, snackBar);

        this.getAssessores();
        this.getProfissao();
        this.getEstadoCivil();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    isInvalidForm() : boolean
    {
        if (!this.nome.valid){
            return true;
        }        

        if (!this.sexo.valid){
            return true;
        }

        if (!this.assessor_id.valid){
            return true;
        }

        if (!this.telefone.valid){
            return true;
        }

        return false;
    }

    salvar() : void{
        this.openLoading();
        this.model.nome = this.nome.value;
        this.model.estado_civil_id = this.estado_civil_id.value;
        if(this.profissao_id.value != ''){
            this.model.profissao_id = this.profissao_id.value;
        }
        this.model.data_nascimento = this.data_nascimento.value;
        this.model.sexo = this.sexo.value;
        this.model.assessor_id = this.assessor_id.value;
        this.model.prospect = this.prospect.value;
        this.model.telefone = this.telefone.value;

        this.service.create(this.model).subscribe(
            data => {
                if(data && this.data.abordagem){
                    this.gerarAbordage(data.data.id);
                }
                this.closeLoading();
                this.dialogRef.close(data);                                                       
            },
            error => {                    
                this.closeLoading();
                this.showError(error.error.message);

            },
            () => {
                this.closeLoading();
            }
        );

    }

    gerarAbordage(cliente_id: Number): void 
    {
        
        const abordagem = {
            cliente_id: cliente_id,
            data_abordagem: new Date(),
            descricao: 'Planejamento Financeiro'
        }

        this._abordagemService.create(abordagem).subscribe(
            data => {
                this.closeLoading();
                this.dialogRef.close(data);                                                       
            },
            error => {
                this.showError(error.error.message);
            },
            () => { }
        );
    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getErrorAssessorMessage() {
        return this.assessor_id.hasError('required') ? 'Assessor é um campo obrigatório' : '';
    }

    getAssessores() {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({tipo_usuario_id: 'todos'})
        .subscribe(
            resquest => {
                this.listAssessor = resquest.data;
            },
            error => {
                this.loadingSpinnerAssessor = false;
            },
            () => {
                this.loadingSpinnerAssessor = false;               
            }
        );
    }

    getProfissao() {
        this.loadingSpinnerProfissao = true;
        this._profissaoService.getLista()
        .subscribe(
            resquest => {
                this.listProfissao = resquest.data;
            },
            error => {
                this.loadingSpinnerProfissao = false;
            },
            () => {
                this.loadingSpinnerProfissao = false;              
            }
        );
    }

    getEstadoCivil() {
        this.loadingSpinnerEstadoCivil = true;
        this._estadoCivilService.getLista()
        .subscribe(
            resquest => {
                this.listEstadoCivil = resquest.data;
            },
            error => {
                this.loadingSpinnerEstadoCivil = false;
            },
            () => {
                this.loadingSpinnerEstadoCivil = false;                
            }
        );
    }

}
