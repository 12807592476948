import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RendaVariavelRoutesModule } from './renda-variavel.routing';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMaskModule } from "ngx-mask";
import { BaseModule } from "../base-module/base.module";

import { CadastroBoletaExecucaoComponent } from './boletas/cadastro-boleta-execucao/cadastro-boleta-execucao.component';
import { FiltraBasketAtivoComponent } from './filtra-basket-ativo/filtra-basket-ativo.component';
import { FiltraBasketComponent } from './filtra-basket/filtra-basket.component';
import { AdicionaAtivoComponent } from './gerador-ordem/adiciona-ativo/adiciona-ativo.component';
import { CadastroClienteAtivoComponent } from './gerador-ordem/cadastro-cliente-ativo/cadastro-cliente-ativo.component';
import { CadastroGeradorOrdemComponent } from './gerador-ordem/cadastro-gerador-ordem/cadastro-gerador-ordem.component';
import { GeradorOrdemListaPendenteComponent } from './gerador-ordem/gerador-ordem-lista-pendente/gerador-ordem-lista-pendente.component';
import { GeradorOrdemListaComponent } from './gerador-ordem/gerador-ordem-lista/gerador-ordem-lista.component';
import { GeradorOrdemStepsComponent } from './gerador-ordem/gerador-ordem-steps/gerador-ordem-steps.component';
import { ListaBasketAtivoComponent } from './lista-basket-ativo/lista-basket-ativo.component';
import { BasketService } from './services/basket.service';
import { BoletaExecucaoService } from './services/boleta-execucao.service';
import { EstrategiaService } from './services/estrategia.service';
import { GeradorOrdemService } from './services/gerador-ordem.service';
import { SwingTradeService } from './services/swing-trade.service';
import { TipoBasketService } from './services/tipo-basket.service';
import { ListaOperacaoClientesComponent } from './swing-trade/lista-operacao-clientes/lista-operacao-clientes.component';
import { ListaOperacaoComponent } from './swing-trade/lista-operacao/lista-operacao.component';
import { ListaPendenciaTradeComponent } from './swing-trade/lista-pendencia-trade/lista-pendencia-trade.component';
import { UploadBasketComponent } from './upload-basket/upload-basket.component';
import { UploadHistoricoOrdensComponent } from './upload-historico-ordens/upload-historico-ordens.component';

import { CadastroBoletaPendenteComponent } from './boletas/cadastro-boleta-pendente/cadastro-boleta-pendente.component';
import { ListaBoletaPendenteComponent } from './boletas/lista-boleta-pendente/lista-boleta-pendente.component';
import { DashRvComercialComponent } from './dash-rv-comercial/dash-rv-comercial.component';
import { AlertDialog, DashboardOperacionalComponent } from './dashboard-operacional/dashboard-operacional.component';
import { FiltraOperacaoEstruturadaComponent } from './filtra-operacao-estruturada/filtra-operacao-estruturada.component';
import { LegendaEstrategiasRvComponent } from './legenda-estrategias-rv/legenda-estrategias-rv.component';
import { ListaOperacaoEstruturadaComponent } from './lista-operacao-estruturada/lista-operacao-estruturada.component';
import { DashboardService } from './services/dashboard.service';
import { UploadOperacaoEstruturadaComponent } from './upload-operacao-estruturada/upload-operacao-estruturada.component';

import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { CadastroTipoBasketComponent } from './cadastro-tipo-basket/cadastro-tipo-basket.component';
import { ListaBasketComponent } from './lista-basket/lista-basket.component';
import { ListaIncosistenciasComponent } from './lista-incosistencias/lista-incosistencias.component';
import { ListaTipoBasketComponent } from './lista-tipo-basket/lista-tipo-basket.component';
import { CadastraItemPainelComponent } from './painel-rv/cadastra-item-painel/cadastra-item-painel.component';
import { ItensPainelComponent } from './painel-rv/itens-painel/itens-painel.component';
import { ListaComponent } from './painel-rv/lista/lista.component';


@NgModule({
    imports: [
        CommonModule,
        RendaVariavelRoutesModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatTabsModule,
        MatRadioModule, 
        MatMenuModule, 
        MatChipsModule, 
        MatExpansionModule, 
        MatFormFieldModule, 
        MatRippleModule, 
        MatSortModule, 
        MatTableModule,
        FormsModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        FuseWidgetModule,
        FlexLayoutModule,
        BaseModule,
        NgxMaskModule.forRoot(),
        CurrencyMaskModule,
        FileUploadModule,
        NgxChartsModule,
        ChartsModule,
        FuseSharedModule,
        NgxShimmerLoadingModule,
        MatBadgeModule
    ],
    declarations: [
        ListaBasketComponent,
        FiltraBasketComponent,
        UploadBasketComponent,
        ListaBasketAtivoComponent,
        FiltraBasketAtivoComponent,
        UploadHistoricoOrdensComponent,
        ListaOperacaoComponent,
        ListaOperacaoClientesComponent,
        ListaPendenciaTradeComponent,
        CadastroBoletaExecucaoComponent,
        GeradorOrdemListaComponent,
        CadastroGeradorOrdemComponent,
        GeradorOrdemStepsComponent,
        AdicionaAtivoComponent,
        GeradorOrdemListaPendenteComponent,
        CadastroClienteAtivoComponent,
        ListaOperacaoEstruturadaComponent,
        FiltraOperacaoEstruturadaComponent,
        UploadOperacaoEstruturadaComponent,
        DashboardOperacionalComponent,
        DashRvComercialComponent,
        ListaBoletaPendenteComponent,
        CadastroBoletaPendenteComponent,
        LegendaEstrategiasRvComponent,
        ListaIncosistenciasComponent,        
        AlertDialog,
        ListaComponent, 
        ItensPainelComponent, 
        CadastraItemPainelComponent, 
        ListaTipoBasketComponent, 
        CadastroTipoBasketComponent
    ],
    entryComponents: [
        UploadBasketComponent,
        FiltraBasketComponent,
        FiltraBasketAtivoComponent,
        UploadHistoricoOrdensComponent,
        CadastroBoletaExecucaoComponent,
        CadastroGeradorOrdemComponent,
        AdicionaAtivoComponent,
        CadastroClienteAtivoComponent,
        FiltraOperacaoEstruturadaComponent,
        UploadOperacaoEstruturadaComponent,
        CadastroBoletaPendenteComponent,
        CadastraItemPainelComponent,
        CadastroTipoBasketComponent
        
    ],
    providers: [
        BasketService,
        TipoBasketService,
        SwingTradeService,      
        BoletaExecucaoService,
        EstrategiaService,
        GeradorOrdemService,
        DashboardService    
    ],
    exports: [
        ListaBoletaPendenteComponent,
        LegendaEstrategiasRvComponent
    ]
})
export class RendaVariavelModule { }
