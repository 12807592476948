import { 
    Component, 
    ViewChild, 
    TemplateRef
} from '@angular/core';

import {
    MatDialog, 
    MatSnackBar, 
    PageEvent
} from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { compareAsc } from 'date-fns'


import { BaseComponent, ActionDialog} from "../../base-module/base-component/base.component";
import { FiltrarRelacionamentoComponent } from '../filtrar-relacionamento/filtrar-relacionamento.component';
import { CadastroRelacionamentoClienteComponent } from '../cadastro-relacionamento-cliente/cadastro-relacionamento-cliente.component';

import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RespostaAtividadeComponent } from 'app/lista-atividades/resposta-atividade/resposta-atividade.component';
import { IItemsPermissionActions } from 'app/interfaces/IItemsPermissionActions';

interface Actions {
    listOportunidade: IItemsPermissionActions;
    listAtividades: IItemsPermissionActions;
    listRelacionamento: IItemsPermissionActions;
    listGestaoRelacionamento: IItemsPermissionActions;
}

@Component({
    selector: 'app-lista-historico-relacionamento',
    templateUrl: './lista-historico-relacionamento.component.html',
    styleUrls: ['./lista-historico-relacionamento.component.scss']
})

export class ListaHistoricoRelacionamentoComponent extends BaseComponent{

    params: any = {};
    user: any;
    data: any;
    loadingIndicator: boolean;
    data_atualizacao: any
    todayDate: Date = new Date(); 
    search = new FormControl("");
    inputSubscription: Subscription;

    _actions: Actions = {
        listOportunidade: {
            permission: 'Listar Oportunidade',
            enabled: false
        },
        listAtividades: {
            permission: 'Listar Atividade da Lista de Atividades',
            enabled: false
        },
        listRelacionamento: {
            permission: 'Listar Histórico Relacionamento do Cliente',
            enabled: false
        },
        listGestaoRelacionamento: {
            permission: 'Listar Gestão de Relacionamento',
            enabled: false
        },
    }; 
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaHistoricoRelacionamentoComponent, { static: false }) table: ListaHistoricoRelacionamentoComponent;

    @ViewChild('table',{ static: false }) table2: DatatableComponent;

    constructor(public _service: ClienteRelacionentoService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);
        this.verifyPermissionForActions();
        this.user = JSON.parse(this._authService.getUser());
        this.getAll();
    }

    verifyPermissionForActions(){

        this._actions.listAtividades.enabled = this._authService
        .isPermissao([this._actions.listAtividades.permission]);

        this._actions.listOportunidade.enabled = this._authService
        .isPermissao([this._actions.listOportunidade.permission]);

        this._actions.listRelacionamento.enabled = this._authService
        .isPermissao([this._actions.listRelacionamento.permission]);

        this._actions.listGestaoRelacionamento.enabled = this._authService
        .isPermissao([this._actions.listGestaoRelacionamento.permission]);

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }  

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator   = true;                
        this.inputSubscription = this._service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }


    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarRelacionamentoComponent, {
            width: '700px',
            data:{
                cliente: true,
                titulo: 'FILTRAR HISTÓRICO DE RELACIONAMENTO'
            }          
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                this.clearFilter();
                if (result.data_inicio != undefined && result.data_inicio != null) {                    
                    this.params.data_inicio = result.data_inicio;                    
                }

                if (result.data_fim != undefined && result.data_fim != null) {
                    this.params.data_fim = result.data_fim;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.acao_id != undefined && result.acao_id != null) {
                    this.params.acao_id = result.acao_id;
                }

                if (result.cliente != undefined && result.cliente != null) {
                    this.params.cliente_id = result.cliente.id;
                }else{
                    this.params.cliente_id = '';
                }

                if (result.assunto_id != undefined && result.assunto_id != null) {
                    this.params.assunto_id = result.assunto_id;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    clearFilter(): void {
        this.params.data_inicio = "";
        this.params.data_fim = "";
        this.params.escritorio_id = "";
        this.params.assessor_id = "";
        this.params.acao_id = "";
        this.params.cliente_id = "";
        this.params.assunto_id = "";
    }
    
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent , {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                cliente_id: this.data,
                cliente: true,
                only_show: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento Cadastrado com Sucesso!");                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o registro");
            }

            this.getAll();
        });
    }

    alterar(id): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent, {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                cliente_id: this.data,
                id: id,
                cliente: true,
                only_show: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento atualizado com sucesso!");              
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registrod de relacionamento com cliente");
            }
        });
    }  

    visualizar(id): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent, {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                cliente_id: this.data,
                id: id,
                cliente: true,
                only_show: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento atualizado com sucesso!");              
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registrod de relacionamento com cliente");
            }
        });
    } 

    /**
     * Exclui registro de histórico
     * @param id
    */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir histórico",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    exportarSaldo(){
        this.openLoading();
        this._service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    verifyIfDateIsValidToEditRow(row){
        if( compareAsc(new Date(this.todayDate.toDateString()), new Date(new Date(row.data_hora_operacao).toDateString()) ) === 1 ){
            return false;
        }else{
            return true;
        }
    }

    onEnter(value: string) {
        this.stopNow();
        this.params.search = value;
        this.search.setValue(value);
        this.getAll();
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    isResponse(row) {
        return (row.atividade_cliente && row.atividade_cliente.data_resposta != null); 
    }

    openResposta(row){
        this.dialog.open(RespostaAtividadeComponent, {
            width: '500px',
            data: { 
                cliente_aceitou: row.cliente_aceitou,
                data_resposta: row.data_resposta,
                observacao: row.observacao,
                valor: row.valor,
                assunto: row.atividade_cliente.atividade.assunto.nome,
                type: 'HR'
            }
        });
    }
}
