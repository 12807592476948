import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatIconModule,
  MatCardModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  MatGridListModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatPaginatorModule,
  MatListModule,
  MatSelectModule,
  MatOptionModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatTabsModule,
  MatRadioModule, 
  MatMenuModule, 
  MatChipsModule, 
  MatExpansionModule, 
  MatFormFieldModule, 
  MatRippleModule, 
  MatSortModule, 
  MatTableModule,
  MatSlideToggleModule,
  MatBadgeModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BaseModule} from "../base-module/base.module";
import {NgxMaskModule} from "ngx-mask";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NotificacaoRoutesModule } from './notificacao.routing';

import { ListaNotificacaoComponent } from './lista-notificacao/lista-notificacao.component';
import { NotificacaoService } from './services/notificacao.service';
import { VerNotificacaoComponent } from './ver-notificacao/ver-notificacao.component';
import { FiltroNotificacaoComponent } from './filtro-notificacao/filtro-notificacao.component';

@NgModule({  
  imports: [
    CommonModule,
    NotificacaoRoutesModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRadioModule, 
    MatMenuModule, 
    MatChipsModule, 
    MatExpansionModule, 
    MatFormFieldModule, 
    MatRippleModule, 
    MatSortModule, 
    MatTableModule,
    FormsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    FlexLayoutModule,
    BaseModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    MatBadgeModule
  ],
  declarations: [
    ListaNotificacaoComponent,
    VerNotificacaoComponent,
    FiltroNotificacaoComponent
  ],
  providers: [
    NotificacaoService
  ],
  entryComponents:[
    VerNotificacaoComponent,
    FiltroNotificacaoComponent
  ]
})
export class NotificacaoModule { }
