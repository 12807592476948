import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation
}  from '@angular/core';

import {
    MatDialog, 
    MatSnackBar
}  from '@angular/material';

import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute} from "@angular/router";
import { FormControl } from "@angular/forms";

import { BaseComponent } from "../../../base-module/base-component/base.component";
import { ClienteService } from "../../services/cliente.service";
import { AuthService } from 'app/base-module/base-service/auth.service';


@Component({
    selector: 'app-posicao-consolidada',
    templateUrl: './posicao-consolidada.component.html',
    styleUrls: ['./posicao-consolidada.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class PosicaoConsolidadaComponent extends BaseComponent {

    cliente: any = {
        nome:'',
        assessor:{
            name:''
        }
    };
    assessor:any;
    codigo_assessor:any;
    perfil:any;

    valor_disponivel:any = 0;
    custodia_fop:any = 0;
    custodia_st:any = 0;
    custodia_fii:any = 0;
    boletado_fop:any = 0;
    boletado_st:any = 0;
    boletado_fii:any = 0;
    
    widget9: any = {};

    usuario:any;
    totalCustodia:any;
    resultado_5:any;
    resultado_5_total:any;
    
    data:any;
    loadingIndicator: boolean;
    habilitaEscritorios: boolean = false;
    public search = new FormControl();

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;

    constructor(public service: ClienteService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,                 
                private _authService:AuthService,
                private _Activatedroute: ActivatedRoute) {
        super(dialog,snackBar);

        this.search.setValue('');

        this.data = this._Activatedroute.snapshot.params['id'];
        
        this.usuario = JSON.parse(this._authService.getUser());
        //this.getCliente();
    }   

    ngOnInit(): void{
        this.getCliente();       
    }    

    getCliente(){
        this.openLoading();
        this.service.getById(this.data).subscribe(
         cliente=> {
            this.cliente = cliente.data;

            if(cliente.data.assessor != undefined && cliente.data.assessor != null){
                this.assessor = cliente.data.assessor.name;
                this.codigo_assessor = cliente.data.assessor.codigo_aai;
            }

            if(cliente.data.perfil != undefined && cliente.data.perfil != null){
                this.perfil = cliente.data.perfil.nome;                
            }

            this.getValoresPainel();

        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    getValoresPainel(){
        this.service.getValoresPainel(this.data).subscribe(
            cliente => {
                this.cliente.sow = cliente.data.sow;
                this.cliente.total_prosperidade = this.getValueZero(cliente.data.total_prosperidade);
                this.cliente.saldo = this.getValueZero(cliente.data.saldo);
            },
            error => {
                //this.closeLoading();
            },
            () => {
                //this.closeLoading();
                console.log("complete");
            });
    }

    getValueZero(value){
        if(value == undefined || value == null || value == ""){
            return 0.00;
        }

        return parseFloat(value);
    }

}
