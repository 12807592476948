import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {BaseComponent, ActionDialog} from '../../base-module/base-component/base.component';
import { CaracteristicaCoeService } from '../services/caracteristica-coe.service';
import { CadastroCaracteristicaCoeComponent } from '../cadastro-caracteristica-coe/cadastro-caracteristica-coe.component';
import { FiltroCaracteristicaCoeComponent } from '../filtro-caracteristica-coe/filtro-caracteristica-coe.component';
import { UploadCaracteristicaComponent } from '../coe/upload-caracteristica/upload-caracteristica.component';
import { AuthService } from 'app/base-module/base-service/auth.service';


@Component({
    selector: 'app-lista-caracteristica-coe',
    templateUrl: './lista-caracteristica-coe.component.html',
    styleUrls: ['./lista-caracteristica-coe.component.scss']
})

export class ListaCaracteristicaCoeComponent extends BaseComponent{

    params: any = {};
    loadingIndicator: boolean;
    user:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaCaracteristicaCoeComponent, { static: false }) table: ListaCaracteristicaCoeComponent;

    constructor(public service: CaracteristicaCoeService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.getAll();

        this.user = JSON.parse(this._authService.getUser());

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.params.page = (event.pageIndex + 1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll(): void {

        this.loadingIndicator = true;
        this.params.historico = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];

            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            data: {
                title: "Excluir Característica do COE",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();
                    
                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.closeLoading();
                            lista.showError(error.message);

                        },
                        () => {
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroCaracteristicaCoeComponent, {
            width: '400px',
            height: '80%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Característica cadastrada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo registro.");
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastroCaracteristicaCoeComponent, {
            width: '400px',
            height: '80%',
            data: { 
                id: id 
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Característica atualizada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltroCaracteristicaCoeComponent, {
            width: '700px',
            data:{
                titulo: 'FILTRAR CARACTÉRISTICA DO COE',
                campos: {
                    data:true,
                    cnpj:false
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {

                if (result.data_inicio != undefined && result.data_inicio != null) {
                    this.params.data_inicio = result.data_inicio;
                }

                if (result.data_fim != undefined && result.data_fim != null) {
                    this.params.data_fim = result.data_fim;
                }

                if (result.cetip != undefined && result.cetip != null) {
                    this.params.codigo_cetip = result.cetip;
                }else{
                    this.params.codigo_cetip = '';
                }

                if (result.nome != undefined && result.nome != null) {
                    this.params.nome = result.nome;
                }else{
                    this.params.nome = '';
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    openUpload() : void {
        let dialogRef = this.dialog.open(UploadCaracteristicaComponent, {
            width: '400px',     
            data: {
                service: this.service,
                title: 'Upload de Caracteristica COE',
                nome_arquivo: 'modelo_upload_coe.xlsx',
                url_upload: 'rendafixa/caracteristica-coe/upload'
            }     
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Característica atualizada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

}