import { Component }  from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent }  from '@angular/material';
import { BaseComponent } from "../../../base-module/base-component/base.component";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SwingTradeService } from 'app/renda-variavel/services/swing-trade.service';

@Component({
    selector: 'app-lista-operacao-clientes',
    templateUrl: './lista-operacao-clientes.component.html',
    styleUrls: ['./lista-operacao-clientes.component.scss']
})

export class ListaOperacaoClientesComponent extends BaseComponent {

    params: any = {};
    user:any;    
    loadingIndicator: boolean;   
    SelectionType = SelectionType;

    selected = [];
    data:any = {};
    titulo:string;    

    constructor(public service: SwingTradeService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                private _authService: AuthService,
                private _Activatedroute: ActivatedRoute) 
    {
        
        super(dialog, snackBar);        
        this.user = JSON.parse(this._authService.getUser());
        this.data = this._Activatedroute.snapshot.params['id'];
        this.getAll();
        this.titulo = 'Lista de Trade';

    }
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {
        this.loadingIndicator = true;
        this.params.trade_id = this.data;
        this.service.getAllSWTClientes(this.params).subscribe(
            data => {
                
                if (data.data != undefined && data.data.length > 0){
                    this.titulo = 'Trade de ' + data.data[0].trade.ativo.sigla;
                    this.length = data.meta.total;
                    this.rows = data.data;
                    this.temp = [...this.rows];
                }
                
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    } 

    back(){
        this.route.navigate(['/renda-variavel/swing-trade']);
    }

    getFinanceiro(tipo: string, row){
        if(tipo == 'I'){
            return parseFloat(row.compra_qtd_executada) * parseFloat(row.compra_preco_executado);
        }else{
            if(row.venda_qtd_executada != null){
                return parseFloat(row.venda_qtd_executada) * parseFloat(row.venda_preco_executado);                
            }else{
                return 0;
            }
        }
    }

    openFiltro(){        
    }

    /**
     * Cor das linhas
     */
    getRowClass = (row) => {
        
        if(row.venda_qtd_executada != null){
            return {'row-color-sucess': true};
        }
    }

}

