import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { BaseComponent } from "../../../base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { CarteiraService } from 'app/clientes/services/carteira.service';
import { ClienteService } from 'app/clientes/services/cliente.service';

@Component({
    selector: 'app-cliente-renda-variavel',
    templateUrl: './cliente-renda-variavel.component.html',
    styleUrls: ['./cliente-renda-variavel.component.scss']
})

export class ClienteRendaVariavelComponent extends BaseComponent implements OnInit{

    params: any = {};
    user: any;    
    loadingIndicator: boolean;
    data_atualizacao: any;

    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 30];
    
    @Input() cliente_id: number;

    constructor(public _service: CarteiraService, 
                private _serviceCliente: ClienteService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.user = JSON.parse(this._authService.getUser());
        this.params.per_page = this.pageSize;
    }

    ngOnInit(){
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }
    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }
  

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator = true;
        this.params.cliente_id = this.cliente_id;
        this.params.migrado = false;

        this._service.getDadosCarteiraCliente(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }    

    getValorFinanceiro(row){
        return parseInt(row.quantidade)  * parseFloat(row.cotacao_atual);
    }

    gerarStvm(){
        this.openLoading();
        this.params.tipo = 'RV';
        this._serviceCliente.gerarStvm(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
                this.showError('Não foi possivel gerar a STVM, por favor verifique o CPF, Endereço e se a(s) custódia(s) já foram migradas');
            },
            () => {

                console.log("complete");
                this.closeLoading();
            }
        );
    }
}
