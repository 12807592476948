import {
    Component, 
    ViewChild, 
    TemplateRef, 
    Inject
} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-filtra-operacao-estruturada',
    templateUrl: './filtra-operacao-estruturada.component.html',
    styleUrls: ['./filtra-operacao-estruturada.component.scss'],
    providers: [ 
        ClienteService, 
        AtivoService,
        EscritorioService
    ]
})

export class FiltraOperacaoEstruturadaComponent extends BaseComponent {
    
    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

    cliente = new FormControl('');
    ativo = new FormControl('');
    data_operacao_inicio = new FormControl(this.primeiroDia);
    data_operacao_fim = new FormControl(this.ultimoDia);
    assessor_id = new FormControl('');    
    escritorio_id = new FormControl('');    

    paramsStatus: any = {
        finalizada: ""
    }

    usuario:any;
    habilitaEscritorios: boolean = false;
    loadingSpinner: boolean;
    loadingSpinnerStatus: boolean;
    loadingSpinnerAssessor: boolean;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerEscritorio: boolean;
    filteredOptions: Observable<string[]>;
    listStatus: Object[] = [];
    listAssessor: Object[] = [];
    listAtivos: Object[] = [];
    listEscritorios: Object[] = [];
    filteredOptionsAtivo: Observable<string[]>;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltraOperacaoEstruturadaComponent, { static: false }) table: FiltraOperacaoEstruturadaComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltraOperacaoEstruturadaComponent>,
        private _clienteService: ClienteService,
        private _utilitesService: UtilitesService,
        private _ativoService: AtivoService,  
        public escritorioService:EscritorioService,
        private usuarioService: UsuarioService,
        private _authService:AuthService,      
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);
        this.usuario = JSON.parse(this._authService.getUser());

        if ( this.usuario.escritorios.length > 1){
            this.habilitaEscritorios = true;
        }

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        );

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;
                    this.params.tipo = 'NORMAL';
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );       
       
        this.getAllEscritorios();
        this.getAssessores();
    }

    displayFn(cliente): void {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente): void{
        this.loadingSpinner  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(): void {


        var inic = null;
        if (this.data_operacao_inicio.value != null){
            inic = this._utilitesService.formatDateToString(new Date(this.data_operacao_inicio.value));
        }

        var fim = null;
        if (this.data_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(new Date(this.data_operacao_fim.value));
        }
        
        this.dialogRef.close({
            'cliente': this.cliente.value,
            'data_operacao_inicio': inic,
            'data_operacao_fim': fim,
            'ativo': this.ativo.value,            
            'assessor_id': this.assessor_id.value,            
            'escritorio_id': this.escritorio_id.value,            
        });
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }


    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    getAtivos(): void {
        this.loadingSpinnerAtivos = true;
        this._ativoService.getAll({ no_paginate:true })
            .subscribe(
                resquest => {
                    this.listAtivos = resquest.data;
                },
                error => {
                    this.loadingSpinnerAtivos = false;
                },
                () => {
                    this.loadingSpinnerAtivos = false;                    
                }
            );
    }

    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;                
            });
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;                    
                }
            );
    }

}
