import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule, 
    MatAutocompleteModule, 
    MatTabsModule, 
    MatRadioModule, 
    MatDatepickerModule, 
    MatMenuModule,
    MatChipsModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import {NgxMaskModule} from "ngx-mask";
import {BaseModule} from "../base-module/base.module";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { ListaBoletaCoeComponent } from './lista-boleta-coe/lista-boleta-coe.component';
import { BoletaCoeService } from './services/boleta-coe.service';
import { BoletaRendaFixaService } from './services/boleta-rendafixa.service';
import { CaracteristicaCoeService } from './services/caracteristica-coe.service';
import { ProdutoRendaFixaService } from './services/produto-rendafixa.service';
import { ListaBoletaRendaFixaComponent } from './lista-boleta-renda-fixa/lista-boleta-renda-fixa.component';
import { CadastroBoletaCoeComponent } from './cadastro-boleta-coe/cadastro-boleta-coe.component';
import { CadastroBoletaRendaFixaComponent } from './cadastro-boleta-renda-fixa/cadastro-boleta-renda-fixa.component';
import { ListaCaracteristicaCoeComponent } from './lista-caracteristica-coe/lista-caracteristica-coe.component';
import { ListaProdutoComponent } from './lista-produto/lista-produto.component';
import { CadastroProdutoRendafixaComponent } from './cadastro-produto-rendafixa/cadastro-produto-rendafixa.component';
import { CadastroCaracteristicaCoeComponent } from './cadastro-caracteristica-coe/cadastro-caracteristica-coe.component';
import { FiltroCaracteristicaCoeComponent } from './filtro-caracteristica-coe/filtro-caracteristica-coe.component';
import { UploadCaracteristicaComponent } from './coe/upload-caracteristica/upload-caracteristica.component';
import { UploadCoeComponent } from './coe/upload-coe/upload-coe.component';
import { UploadRendaFixaComponent } from './renda-fixa/upload-renda-fixa/upload-renda-fixa.component';
import { CadastroBoletaFundoComponent } from './cadastro-boleta-fundo/cadastro-boleta-fundo.component';
import { CadastroFundoComponent } from './cadastro-fundo/cadastro-fundo.component';
import { RendaFixaRoutesModule } from './renda-fixa.routing';
import { MovimentacaoModule } from 'app/movimentacao/movimentacao.module';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { AcaoComercialService } from 'app/acao-comercial/services/acao-comercial.service';
import { DashboardRendaFixaComponent } from './dashboard-renda-fixa/dashboard-renda-fixa.component';
import { DashboardRendaFixaService } from './services/dashboard-renda-fixa.service';
import { DashboardRendaFixaCoeComponent } from './dashboard-renda-fixa-coe/dashboard-renda-fixa-coe.component';
import { DashboardCoeService } from './services/dashboard-coe.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
import { ListaComissionamentoRfComponent } from './renda-fixa/comissionamento/lista-comissionamento-rf/lista-comissionamento-rf.component';
import { ProdutosComponent } from './renda-fixa/produtos/produtos.component';
import { AtualizaStatusBoletaRendaFixaComponent } from './atualiza-status-boleta-renda-fixa/atualiza-status-boleta-renda-fixa.component';

@NgModule({
    imports: [
        CommonModule,
        RendaFixaRoutesModule,
        MovimentacaoModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatAutocompleteModule,
        BaseModule,
        FileUploadModule,
        MatSelectModule,
        MatOptionModule,
        NgxMaskModule,
        MatTabsModule,
        MatRadioModule,
        MatDatepickerModule,
        MatSelectModule,        
        CurrencyMaskModule,
        MatMenuModule,
        MatChipsModule,
        NgxChartsModule,
        ChartsModule,
        FuseWidgetModule,
        NgxShimmerLoadingModule,
        DragDropModule
    ],
    declarations: [ 
        ListaBoletaCoeComponent,
        ListaBoletaRendaFixaComponent,
        CadastroBoletaCoeComponent,
        CadastroBoletaRendaFixaComponent,
        ListaCaracteristicaCoeComponent,
        ListaProdutoComponent,
        CadastroProdutoRendafixaComponent,
        CadastroCaracteristicaCoeComponent,
        FiltroCaracteristicaCoeComponent,
        UploadCaracteristicaComponent,
        UploadCoeComponent,
        UploadRendaFixaComponent,
        CadastroBoletaFundoComponent,        
        CadastroFundoComponent,
        DashboardRendaFixaComponent,
        DashboardRendaFixaCoeComponent,
        ListaComissionamentoRfComponent,
        ProdutosComponent,
        AtualizaStatusBoletaRendaFixaComponent
    ],
    entryComponents: [ 
        CadastroBoletaCoeComponent,
        CadastroBoletaRendaFixaComponent,
        CadastroCaracteristicaCoeComponent,
        CadastroProdutoRendafixaComponent,
        UploadCaracteristicaComponent,
        UploadCoeComponent,
        UploadRendaFixaComponent,
        CadastroBoletaFundoComponent,
        CadastroFundoComponent,
        FiltroCaracteristicaCoeComponent,
        AtualizaStatusBoletaRendaFixaComponent
    ],
    providers: [ 
        BoletaCoeService,
        BoletaRendaFixaService,
        CaracteristicaCoeService,
        ProdutoRendaFixaService,
        ClienteService,
        AcaoComercialService,
        DashboardRendaFixaService,
        DashboardCoeService,
        UtilitesService
    ],
    exports:[
        FiltroCaracteristicaCoeComponent
    ]
})

export class RendaFixaModule { }
