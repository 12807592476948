import { BaseComponent, LoadingTeste} from "../../../base-module/base-component/base.component";
import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { AuthService} from "../../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { BoletaExecucaoService } from 'app/renda-variavel/services/boleta-execucao.service';

export interface DialogData {    
    boleta_id: number;
}

@Component({
    selector: 'cadastro-boleta-execucao',
    templateUrl: './cadastro-boleta-execucao.component.html',
    styleUrls: ['./cadastro-boleta-execucao.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,    
})

export class CadastroBoletaExecucaoComponent extends BaseComponent {

    boletaForm: FormGroup;
    loadingLocal:any;
    novoCadastro:boolean = true;    
    filteredOptionsAtivo: Observable<string[]>;
    loadingSpinnerAtivos: boolean;

    constructor(public service: BoletaExecucaoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroBoletaExecucaoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,                
                private _fb: FormBuilder )
    {
        super(dialog, snackBar);     
        this.createForm();
        this.getByBoleta();
    }

    montaValor(){
        const formulario = this.boletaForm.value;
        let valor = 0;
        for(var ativo of formulario.ativos ){
            valor += parseFloat(ativo.preco_executado) * parseFloat(ativo.qtd);
        }        
        this.boletaForm.controls['valor_financeiro'].setValue(valor);        
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    getByBoleta(){

        this.service.getAll({ boleta_id: this.data.boleta_id }).subscribe(
            list => {                
                if(list.data.length > 0) {
                    this.novoCadastro = false;
                    let total:number = 0;
                    for(var boleta of list.data ){
                        const ativo = {
                            ativo: boleta.ativo.sigla,
                            qtd: boleta.qtd,
                            preco_executado: boleta.preco,
                            tipo: boleta.tipo,
                        };
                        total += boleta.preco * boleta.qtd;
                        this.boletaForm.get('valor_financeiro').setValue(total);
                        this.ativos.push(this.buildBoleta(ativo));
                    }   
                    this.ativos.removeAt(0);                 
                }
            },
            error => { },
            () => { }
        );
    }

    salvar(){
        this.openLoading();

        if (this.novoCadastro){         

            this.service.create(this.boletaForm.value).subscribe(
                data => {
                    this.closeLoading();
                    this.showSuccess('Boleta Cadastra com Sucesso!');
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );

        }else{            

            this.service.update(this.data.boleta_id, this.boletaForm.value).subscribe(
                data => {
                    this.closeLoading();
                    this.showSuccess('Boleta Atualizada com Sucesso!');
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }
    }

    get ativos(): FormArray {
        return <FormArray>this.boletaForm.get('ativos');
    }

    buildBoleta( ativo = null ): FormGroup { 
        
        let ativoInfo;
        if(ativo == null){
            ativoInfo = this._fb.group({
                tipo: 'C',
                ativo: '',
                qtd: 0,
                preco_executado: 0,
            });
        }else{
            ativoInfo = this._fb.group({
                tipo: ativo.tipo,
                ativo: ativo.ativo,
                qtd: ativo.qtd,
                preco_executado: ativo.preco_executado
            });
        }

        return ativoInfo;
    }

    createForm() {
        this.boletaForm = this._fb.group({
            valor_financeiro: 0,
            boleta_id: this.data.boleta_id,
            ativos: this._fb.array([this.buildBoleta()])
        });       
    }

    addAtivo(): void {
        this.ativos.push(this.buildBoleta());
    }

    removeAtivo(index) {        
        if(index > 0){
            this.ativos.removeAt(index);
        }
    }

}

