import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { ListaBoletaCoeComponent } from './lista-boleta-coe/lista-boleta-coe.component';
import { ListaCaracteristicaCoeComponent } from './lista-caracteristica-coe/lista-caracteristica-coe.component';
import { ListaBoletaRendaFixaComponent } from './lista-boleta-renda-fixa/lista-boleta-renda-fixa.component';
import { DashboardRendaFixaComponent } from './dashboard-renda-fixa/dashboard-renda-fixa.component';
import { DashboardRendaFixaCoeComponent } from './dashboard-renda-fixa-coe/dashboard-renda-fixa-coe.component';
import { ListaComissionamentoRfComponent } from './renda-fixa/comissionamento/lista-comissionamento-rf/lista-comissionamento-rf.component';

import { AuthGuardService } from "../base-module/base-service/auth-guard.service";
import { ProdutosComponent } from './renda-fixa/produtos/produtos.component';

const routes: Routes = [{
    path: '',
    children: [
        {
            path: 'dashboard',
            component: DashboardRendaFixaComponent
        },
        {
            path: 'consulta',
            component: ListaBoletaCoeComponent
        },
        {
            path: 'lista-produto-renda-fixa',
            component: ProdutosComponent
        },
        {
            path: 'lista-caracteristica-coe',
            component: ListaCaracteristicaCoeComponent
        },
        {
            path: 'lista-boleta-coe',
            component: ListaBoletaCoeComponent
        },
        {
            path: 'dashboard-coe',
            component: DashboardRendaFixaCoeComponent
        },
        {
            path: 'lista-boleta-renda-fixa',
            component: ListaBoletaRendaFixaComponent
        },
        {
            path: 'comissionamento/renda-fixa',
            component: ListaComissionamentoRfComponent,
            data: { mercado_id: 5 }
        },
        {
            path: 'comissionamento/COE',
            component: ListaComissionamentoRfComponent,
            data: { mercado_id: 8 }
        },
        
    ],
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class RendaFixaRoutesModule {}