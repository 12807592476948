import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { ChatService } from  'app/apps/chat/chat.service';
import { ChatComponent } from 'app/apps/chat/chat.component';
import { ChatStartComponent } from 'app/apps/chat/chat-start/chat-start.component';
import { ChatViewComponent } from 'app/apps/chat/chat-view/chat-view.component';
import { ChatChatsSidenavComponent } from 'app/apps/chat/sidenavs/left/chats/chats.component';
import { ChatUserSidenavComponent } from 'app/apps/chat/sidenavs/left/user/user.component';
import { ChatLeftSidenavComponent } from 'app/apps/chat/sidenavs/left/left.component';
import { ChatRightSidenavComponent } from 'app/apps/chat/sidenavs/right/right.component';
import { ChatContactSidenavComponent } from 'app/apps/chat/sidenavs/right/contact/contact.component';
import { SocketService } from './services/socker.service';
import { ChamadoComponent } from './sidenavs/right/chamado/chamado.component';

const routes: Routes = [
    {
        path: 'chat',
        component: ChatComponent,
        children: [],
        resolve: {
            chat: ChatService
        }
    }
];

@NgModule({
    declarations: [
        ChatComponent,
        ChatViewComponent,
        ChatStartComponent,
        ChatChatsSidenavComponent,
        ChatUserSidenavComponent,
        ChatLeftSidenavComponent,
        ChatRightSidenavComponent,
        ChatContactSidenavComponent,
        ChamadoComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatRadioModule,
        MatSidenavModule,
        MatToolbarModule,
        FuseSharedModule
    ],
    providers   : [
        ChatService,
        SocketService        
    ]
})

export class ChatModule { }
