import {Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

export interface CustodiaRendaVariavel {
    id: number;
    data_compra: Date;
    ativo_id: number;
    ativo: any;
    ativo_tipo: string;
    ativo_tipo_opcao: string;
    ativo_bloqueado: boolean;
    migrado: boolean;
    qtd: number;
    preco_executado: number;
    vlr_financeiro: number;
    cotacao_atual: number;
    volume_financerio: number;
    vol_fin_hoje: number;
    percentual: number;
    estrategia_id: number;
    estrategia: any;
    cor_hexadecimal: string;
}

@Injectable()
export class CarteiraService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Carteira do cliente por ID
     * @param id ID da Carteira do cliente
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/" + id, { headers: this.authService.getAuthHeaders() });
    }

     /**
     * Lista a Custódia da Carteira de um Cliente
     * @param params Parametros para filtrar a lista
     */
    getCarteiraCliente(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/getCarteiraCliente"+env, { headers: this.authService.getAuthHeaders() });
    }  

     /**
     * Lista a Custódia da Carteira de um Cliente
     * @param params Parametros para filtrar a lista
     */
    getDadosCarteiraCliente(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/cliente-carteira"+env, { headers: this.authService.getAuthHeaders() });
    }  

    /**
     * Lista a Custódia da Carteira dos Clientes
     * @param params Parametros para filtrar a lista
     */
    getAllAtivosCliente(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista a Custódia da Carteira dos Clientes
     * @param params Parametros para filtrar a lista
     */
    getAllAtivosSetor(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/setor"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista a Custódia da Carteira dos Clientes
     * @param params Parametros para filtrar a lista
     */
    getTotalFinanceiro(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/total-fin-carteira"+env, { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Lista a Custódia da Carteira dos Clientes
     * @param params Parametros para filtrar a lista
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/all-carteiras"+env, { headers: this.authService.getAuthHeaders() });
    }
    
    
    /**
     * Busca de quantidade por ativo
     * @param params Parametros para busca de quantidade de ativo
     */
    public getQtdAtivo(params){

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/busca-ativo-carteira"+env, { headers: this.authService.getAuthHeaders() }).toPromise();        
    }    

    /**
     * Delete Ativo da Carteira do Cliente
     * @param id ID do Ativo
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "clientes/carteira/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Ativo da Carteira do Cliente
     * @param params Json Carteira do Cliente
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "clientes/carteira/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Adiciona Ativo da Carteira do Cliente
     * @param params Json Carteira do Cliente
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "clientes/carteira/novo", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
    
    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }    

}
