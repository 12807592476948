import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation, 
    OnInit
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    PageEvent, 
    MatPaginator,
    MatStepper
} from '@angular/material';

import { 
    FormBuilder,
    FormControl, 
    Validators 
} from '@angular/forms';

import { ActivatedRoute, Router} from "@angular/router";
import { DatatableComponent } from '@swimlane/ngx-datatable';


/** Components */

import {
    BaseComponent, 
    LoadingTeste, 
    ActionDialog
} from "../../base-module/base-component/base.component";
import { fuseAnimations } from '@fuse/animations';

import { CadastroRelacionamentoComponent } from '../cadastro-relacionamento/cadastro-relacionamento.component';
import { FiltrarRelacionamentoComponent } from '../filtrar-relacionamento/filtrar-relacionamento.component';
import { CadastroAporteRetiradaComponent } from '../cadastro-aporte-retirada/cadastro-aporte-retirada.component';

/** Services */
import { ClienteService } from '../services/cliente.service';
import { EnderecoService } from "../../base-module/base-service/endereco.service";
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { SituacaoTributariaService } from 'app/auxiliares/services/situacao-tributaria.service';
import { PrimeiroContatoService } from 'app/auxiliares/services/primeiro-contato.service';
import { OrigemService } from 'app/auxiliares/services/origem-cliente.service';
import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';
import { ProfissaoService } from 'app/auxiliares/services/profissao.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ClientePerfilService } from '../services/cliente-perfil.service';
import { EstadoCivilService } from '../services/estado-civil.service';
import { ClienteCarteiraConsorcioService } from 'app/consorcio/services/cliente-carteira-consorcio.service';
import { CadastroCarteiraConsorcioComponent } from 'app/consorcio/cadastro-carteira-consorcio/cadastro-carteira-consorcio.component';
import { VisualizarCarteiraConsorcioComponent } from 'app/consorcio/visualizar-carteira-consorcio/visualizar-carteira-consorcio.component';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'dialog-cliente-cadastro-stepper-completo',
    templateUrl: './cadastro-completo-stepper.component.html',
    styleUrls: ['./cadastro-completo-stepper.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class CadastroCompletoStepperComponent extends BaseComponent implements OnInit {

    dadosCadastraisFormGroup = this._formBuilder.group({
        nome: new FormControl('', [Validators.required]),
        razao_social: new FormControl('', []),
        tipo_pessoa: new FormControl('', [Validators.required]),
        numero_xp: new FormControl(),
        cpf: new FormControl('', []),
        cnpj: new FormControl('', []),

        assessor_id: new FormControl('', [Validators.required]),
        tipo_situacao_tributaria: new FormControl('', [Validators.required]),
        
        rg: new FormControl('', []),
        telefone: new FormControl('', [Validators.required]),
        sexo: new FormControl(),
        data_nascimento: new FormControl('', []),

        comunicacao_celular: new FormControl(false, []),
        comunicacao_whatsapp: new FormControl(false, []),
        comunicacao_email: new FormControl(false, []),

        estado_civil_id: new FormControl(),

        email: new FormControl('', [Validators.required]),

        //operador_id: new FormControl('', []), //todo: verificar se está sendo utilizado
        origem_cliente_id: new FormControl('', []),
        primeiro_contato_id: new FormControl('', []),

        profissao_id: new FormControl('', []),
        cliente_prosp: new FormControl(false, []),

        assessor: new FormControl(),
        perfil_id: new FormControl('', []),
        operacao_stop: new FormControl(),

        tipo_endereco: new FormControl('R', [Validators.required]),
        cep: new FormControl('', [Validators.required]),
        endereco: new FormControl('', [Validators.required]),
        bairro: new FormControl('', [Validators.required]),
        estado_id: new FormControl('', [Validators.required]),
        cidade_id: new FormControl('', [Validators.required]),
        numero: new FormControl('', []),
        complemento: new FormControl('', []),

    });
    secondFormGroup = this._formBuilder.group({
    });
    thirdFormGroup = this._formBuilder.group({
    });
    //dados financeiros
    fourthFormGroup = this._formBuilder.group({
        valor_investimento: new FormControl('0', []),
        realizou_call: new FormControl(false, []),
        valor_disponivel: new FormControl('0', []),
        volume_global: new FormControl('0', [Validators.required]),
        investimento_inicial: new FormControl('0', [Validators.required]),
        desconto_corretagem: new FormControl('', []),
        habilitado_bovespa: new FormControl(false, [Validators.required]),
        termo_push: new FormControl(false, [Validators.required]),
        homebroker: new FormControl(false, []),
        estrategia_rv_alavancagem: new FormControl(),
        //todo: verificar dados de boleta
        vlr_boleta_fo: new FormControl('', []),
        vlr_boleta_st: new FormControl('', []),
        vlr_boleta_fii: new FormControl('', []),
        
    });
    fifthFormGroup = this._formBuilder.group({
    });
    sixthFormGroup = this._formBuilder.group({
    });

    public strCidadeCep:any;

    @ViewChild('tableRelacionamento',{ static: false }) table2: DatatableComponent;    

    params:any = {
        per_page:""
    }

    model:any = {
        empresa_id:"",
        nome:"",
        telefone:"",
        cep:"",
        tipo_pessoa:'PF',
        numero_xp:"",
        investimento_inicial:0,
        assessor_id:"",
        operador_id:"",
        volume_global:"",
        tipo_situacao_tributaria:"",
        rg:"",
        origem_cliente_id: '',
        primeiro_contato_id: '',
        habilitado_bovespa: false,
        termo_push: false,
        tipo_endereco: 'R',
        realizou_call: false,
        homebroker: false,
        estado_civil_id: '',
        sexo: '',
        digito_xp: '',
        conta_btg: '',
        digito_btg: '',
        estrategia_rv_alavancagem: false,
        comunicacao_celular: false,
        comunicacao_whatsapp: false,
        comunicacao_email: false,
    };

    data:any = {};
    

    listEstados:any[]=[];
    listCidades:any[]=[];
    listAssessor: any[] = [];
    listOperador: any[] = [];
    listOrigem: any[] = [];
    listProfissao: any[] = [];
    listPrimeiroContato: any[] = [];
    listSituacaoTributaria: any[] = [];    
    listPerfil: any[] = [];    
    listEstadoCivil: any[] = [];
    loadingLocal:any;
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerOperador:boolean = false;
    loadingSpinnerSituacao:boolean = false;
    loadingSpinnerPrimeiro:boolean = false;
    loadingSpinnerOrigem:boolean = false;
    isDisabledVlrInvest:boolean = true;
    loadingIndicator:boolean = false;
    loadingListaAtivo:boolean = false;
    loadingRelacionamento:boolean = false;
    loadingSpinnerProfissao:boolean = false;
    loadingSpinnerPerfil:boolean = false;
    loadingSpinnerEstadoCivil:boolean = false;
    
    exibeValorInicial:boolean = true;
    exibeValorGlobal:boolean = true;
    isConditionIsTrue:boolean = true;

    totalCustodiaFop:any = 0;
    totalCustodiaSt:any = 0;
    totalCustodiaFii:any = 0;

    isDisabledAssessor:boolean = true;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroCompletoStepperComponent, { static: false }) table: CadastroCompletoStepperComponent;
    @ViewChild('paginatorRelacionamento', { static: false }) paginatorRelacionamento: MatPaginator;

    titulo:string;

    isCpf:boolean   = true;
    isCnpj:boolean  = false;
    isRg:boolean    = true;
    existeCliente   = false;

     //variáveis para datatable
    editing = {};
    rowsRelacionamento = [];
    tempRelacionamento = [];  
     
     //paginação
    lengthRelacionamento = 0;
    pageSizeRelacionameto = 100;
    pageSizeOptionsRelacionamento: number[] = [100, 200, 300, 500];
    user:any;

    //tratamento carteira consorcio
    loadingListaCarteiraConsorcio: boolean = false;
    rowsCarteiraConsorcio = [];
    tempCarteiraConsorcio = [];
    paramsCarteiraConsorcio: any = {};

    lengthCarteiraConsorcio = 0;
    pageSizeCarteiraConsorcio = 100;
    pageSizeOptionsCarteiraConsorcio: number[] = [100, 200, 300, 500];

    currentCliente:any;

    isLinear = true;
    constructor(public service: ClienteService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _Activatedroute:ActivatedRoute,
                private route: Router,
                private loading:MatDialog,
                private enderecoService:EnderecoService,
                private usuarioService: UsuarioService,
                private situacaoTributaria: SituacaoTributariaService,
                private _primeiroService: PrimeiroContatoService,
                private _origemService: OrigemService,
                private _clienteRelacService: ClienteRelacionentoService,
                private _profissaoService:ProfissaoService,
                private _authService:AuthService,
                private _perfilService: ClientePerfilService,
                private _estadoCivil: EstadoCivilService,
                private _clienteCarteiraConsorcioService: ClienteCarteiraConsorcioService,
                private _formBuilder: FormBuilder)
    {
        super(dialog, snackBar);
        this.data = this._Activatedroute.snapshot.params['id'];

        this.listEstados=[];
        this.listCidades=[];

        this.user = JSON.parse(this._authService.getUser());

        this.getAllEstados();
        this.getOperadores();
        this.getAssessores();
        this.getSituacaoTributaria();
        this.getOrigem();
        this.getPrimeiroContato();
        this.getProfissao();
        this.getAllPerfil();
        this.getEstadoCivil();
        this.getAllCarteriraConsorcio();
        
        var dataRow = [];

        if(this.params.per_page == undefined || this.params.per_page == "" || this.params.per_page == null){
            this.params.per_page = 100;
        }

        this.rows = dataRow;
        this.temp = [...dataRow];
        
        this.rowsRelacionamento = dataRow;
        this.tempRelacionamento = [...dataRow];
        
        
        if(this.isClienteId()){
            this.getCliente();
            this.titulo = "Alterar Cliente";
            this.isDisabledVlrInvest = true;
            this.exibeValorInicial = false;
            this.exibeValorGlobal = true;
            
            this.existeCliente = true;

        }else{
            this.model.alter_certificado=true;
            this.model.alter_imagem=true;
            this.titulo = "Cadastrar Cliente";
            this.isDisabledVlrInvest = false;
            this.exibeValorInicial = true;
            this.exibeValorGlobal = false;

            this.existeCliente = false;
        }

        if(this.user.role_id === 2 || this.user.role_id === 7 || this.user.role_id === 4 ){
            this.isDisabledAssessor = false;
        }
    }

    ngOnInit(){
        this.isLinear = !this.isClienteId();
        console.log("isLinear", this.isLinear);
    }

    isClienteId(){
        return (this.data != null && this.data != undefined)
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    getAllEstados(){
        this.enderecoService.getEstados().subscribe(
            list=>{
                this.listEstados = list.data;
            },
            error => {},
            () => {}
        );
    }

    

    /**
     * Set value tipo_pessoa
     * @param event
     */
    changeTipoCliente(){
        if(this.model.tipo_pessoa == "PF"){
            this.isCpf  = true;
            this.isRg   = true;
            this.isCnpj = false;
        }else{
            this.isCpf  = false;
            this.isRg   = false;
            this.isCnpj = true;
        }
    }

    getAllCidades(campo){
        var valor = '';
        if(Number.isInteger(campo)){
            valor = campo;
        }else{
            valor = campo.value;
        }
        this.enderecoService.getCidades(valor).subscribe(
            list=>{
                this.listCidades = list.data;

                for(var i=0; i<this.listCidades.length; i++){
                    if(this.strCidadeCep != null && this.strCidadeCep != "" && this.strCidadeCep == this.listCidades[i].nome){
                        this.dadosCadastraisFormGroup.get('cidade_id').setValue(this.listCidades[i].id);
                    }
                }
            },
            error => {},
            () => {}
        );
    }

    salvar(stepper: MatStepper){
        console.log(this.dadosCadastraisFormGroup);
        if(!this.dadosCadastraisFormGroup.valid){
            return;
        }

        this.model = {
            ...this.dadosCadastraisFormGroup.value,
            ...this.fourthFormGroup.value
        }
        this.model.nome = this.dadosCadastraisFormGroup.get('nome').value.toUpperCase();
        this.model.razao_social = this.dadosCadastraisFormGroup.get('razao_social').value;
        if(this.dadosCadastraisFormGroup.get('cpf').value != ''){
            this.model.cnpj_cpf = this.dadosCadastraisFormGroup.get('cpf').value;
        }else{
            this.model.cnpj_cpf = this.dadosCadastraisFormGroup.get('cnpj').value;
        }

        const valorTotal = parseFloat(this.fourthFormGroup.get('vlr_boleta_fii').value) + parseFloat(this.fourthFormGroup.get('vlr_boleta_st').value) + parseFloat(this.fourthFormGroup.get('vlr_boleta_fo').value);

        /*if ( valorTotal > parseFloat(this.fourthFormGroup.get('valor_investimento').value) ){
            this.showError(`O valor total das 3 estratégias ( ${valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) } ) tem que ser menor que o valor de investimento em MRV.`);
        }else{
          */  
            if(this.data == null){
                this.openLoading();
                this.service.create(this.model).subscribe(
                    response => {
                        //this.showSuccess("Cliente cadastrado com sucesso.");
                        console.log(response.data.id);
                        this.data = response.data.id;
                        //this.retornarLista();
                        setTimeout(() => {
                            stepper.next();
                        }, 500)
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
    
                        if(error.error.errors != undefined){
                            
                            var $this = this;
                            Object.entries(error.error.errors).forEach(function(erro){
                                var str = erro[1].toString();
                                $this.showError(str);
                            });
    
                        }else{
                            this.showError(error.error.message);
                        }
    
                    },
                    () => {
                        this.closeLoading();
                    }
                );
    
            }else{
                if(!this.fourthFormGroup.valid){
                    return;
                }
                this.openLoading();
                this.service.update(this.data, this.model).subscribe(
                    data => {
                        //this.showSuccess("Cliente atualizado com sucesso.");
                        setTimeout(() => {
                            stepper.next();
                        }, 500)
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }
        //}       

    }

    getCliente(){
        this.openLoading();
        this.isLinear = false;
        this.service.getById(this.data).subscribe(
         cliente => {
            this.currentCliente = cliente.data;

            if(cliente.data.cnpj_cpf != '' && cliente.data.cnpj_cpf != undefined){
                if(cliente.data.cnpj_cpf.length > 12){
                    this.dadosCadastraisFormGroup.get('cnpj').setValue(cliente.data.cnpj_cpf);
                }else{
                    this.dadosCadastraisFormGroup.get('cpf').setValue(cliente.data.cnpj_cpf);
                }
            }

            this.dadosCadastraisFormGroup.get('nome').setValue(cliente.data.nome);
            this.dadosCadastraisFormGroup.get('razao_social').setValue(cliente.data.razao_social);
            
            this.dadosCadastraisFormGroup.get('endereco').setValue(cliente.data.endereco);
            this.dadosCadastraisFormGroup.get('numero').setValue(cliente.data.numero);
            this.dadosCadastraisFormGroup.get('bairro').setValue(cliente.data.bairro);
            this.dadosCadastraisFormGroup.get('complemento').setValue(cliente.data.complemento);
            this.dadosCadastraisFormGroup.get('estado_id').setValue(cliente.data.estado_id);
            this.dadosCadastraisFormGroup.get('telefone').setValue(cliente.data.telefone);
            this.dadosCadastraisFormGroup.get('cep').setValue(cliente.data.cep);
            this.dadosCadastraisFormGroup.get('email').setValue(cliente.data.email);
            this.dadosCadastraisFormGroup.get('numero_xp').setValue(cliente.data.numero_xp);
            this.dadosCadastraisFormGroup.get('tipo_situacao_tributaria').setValue(cliente.data.tipo_situacao_tributaria);
            this.dadosCadastraisFormGroup.get('assessor_id').setValue(cliente.data.assessor_id);
            this.dadosCadastraisFormGroup.get('comunicacao_celular').setValue(cliente.data.comunicacao_celular);
            this.dadosCadastraisFormGroup.get('comunicacao_whatsapp').setValue(cliente.data.comunicacao_whatsapp);
            this.dadosCadastraisFormGroup.get('comunicacao_email').setValue(cliente.data.comunicacao_email);
            //this.dadosCadastraisFormGroup.get('operador_id').setValue(cliente.data.operador_id);

            this.dadosCadastraisFormGroup.get('origem_cliente_id').setValue(cliente.data.origem_cliente_id);
            this.dadosCadastraisFormGroup.get('primeiro_contato_id').setValue(cliente.data.primeiro_contato_id);
            this.dadosCadastraisFormGroup.get('data_nascimento').setValue(cliente.data.data_nascimento);
            this.dadosCadastraisFormGroup.get('tipo_endereco').setValue(cliente.data.tipo_endereco);
            this.dadosCadastraisFormGroup.get('profissao_id').setValue(cliente.data.profissao_id);
            this.dadosCadastraisFormGroup.get('perfil_id').setValue(cliente.data.perfil_id);
            this.dadosCadastraisFormGroup.get('operacao_stop').setValue(cliente.data.operacao_stop);
            this.dadosCadastraisFormGroup.get('cliente_prosp').setValue(cliente.data.cliente_prosp.toString());

            this.fourthFormGroup.get('investimento_inicial').setValue(cliente.data.investimento_inicial);
            this.fourthFormGroup.get('valor_investimento').setValue(cliente.data.valor_investimento);
            this.fourthFormGroup.get('volume_global').setValue(cliente.data.volume_global);
            this.fourthFormGroup.get('homebroker').setValue(cliente.data.homebroker);
            this.fourthFormGroup.get('desconto_corretagem').setValue(cliente.data.desconto_corretagem);
            this.fourthFormGroup.get('habilitado_bovespa').setValue(cliente.data.habilitado_bovespa);
            this.fourthFormGroup.get('termo_push').setValue(cliente.data.termo_push);
            this.fourthFormGroup.get('realizou_call').setValue(cliente.data.realizou_call);
            this.fourthFormGroup.get('vlr_boleta_fo').setValue(cliente.data.vlr_boleta_fo);
            this.fourthFormGroup.get('vlr_boleta_st').setValue(cliente.data.vlr_boleta_st);
            this.fourthFormGroup.get('vlr_boleta_fii').setValue(cliente.data.vlr_boleta_fii);
            this.fourthFormGroup.get('estrategia_rv_alavancagem').setValue(cliente.data.estrategia_rv_alavancagem);

            this.model.tipo_pessoa = cliente.data.tipo_pessoa;
            this.model.operacao_stop = cliente.data.operacao_stop;
            this.model.estado_civil_id = cliente.data.estado_civil_id;
            this.model.sexo = cliente.data.sexo;
            this.model.cliente_prosp = cliente.data.cliente_prosp.toString();
            
            this.changeTipoCliente();

            if(cliente.data.assessor != undefined && cliente.data.assessor != null){
                this.dadosCadastraisFormGroup.get('assessor').setValue(cliente.data.assessor.name);
            }

            if( cliente.data.custodia_fop != undefined && cliente.data.custodia_fop != null && cliente.data.custodia_fop.length > 0) {
                this.totalCustodiaFop = parseFloat(cliente.data.custodia_fop[0].valor_total);
            }

            if( cliente.data.custodia_st != undefined && cliente.data.custodia_st != null && cliente.data.custodia_st.length > 0) {
                this.totalCustodiaSt  = parseFloat(cliente.data.custodia_st[0].valor_total);
            }

            if( cliente.data.custodia_fii != undefined && cliente.data.custodia_fii != null && cliente.data.custodia_fii.length > 0) {
                this.totalCustodiaFii =parseFloat(cliente.data.custodia_fii[0].valor_total);
            }
            
            if( cliente.data.carteira != undefined && cliente.data.carteira != null && cliente.data.carteira.length > 0) {
                this.fourthFormGroup.get('valor_disponivel').setValue( parseFloat(cliente.data.valor_investimento) - ( parseFloat(cliente.data.valor_bloqueado) + parseFloat(cliente.data.carteira[0].valor_total) ) );
            }else{
                this.fourthFormGroup.get('valor_disponivel').setValue( parseFloat(cliente.data.valor_investimento) - parseFloat(cliente.data.valor_bloqueado));    
            }

            if(cliente.data.estado_id != undefined && cliente.data.estado_id != null) {
                this.getAllCidades(cliente.data.estado_id);
                this.dadosCadastraisFormGroup.get('cidade_id').setValue(cliente.data.cidade_id);
            }
        },
         error => {
            this.closeLoading();
         },
        () => {
            this.closeLoading();            
        });
    }

    isDisabledEstado(){
        if(this.listEstados.length > 0){
            return false;
        }
        return true;
    }

    isDisabledCidade(){
        if(this.listCidades.length > 0){
            return false;
        }
        return true;
    }

    retornarLista(){
        this.route.navigate(['/clientes/consulta']);
    }
    /**
     * Seleciona estado na busca por cep
     * @param {string} str
     */
    selecionaEstado(str:string){

        for(var i=0; i<this.listEstados.length;i++){
            if(this.listEstados[i].sigla == str){
                this.dadosCadastraisFormGroup.get('estado_id').setValue(this.listEstados[i].id);
                this.getAllCidades(this.listEstados[i].id);
            }
        }


    }

    /* consulta de endereco por cep */
    getEnderecoByCep(){

        this.enderecoService.getByCep(this.dadosCadastraisFormGroup.get('cep').value)
            .subscribe(
                address => {

                    this.model.endereco = address.logradouro;
                    this.model.bairro = address.bairro;

                    this.strCidadeCep = address.localidade;
                    this.dadosCadastraisFormGroup.get('endereco').setValue(address.logradouro);
                    this.selecionaEstado(address.uf);
                    this.dadosCadastraisFormGroup.get('bairro').setValue(address.bairro);

                },
                error => {},
                () => {}
            );
    }

    getAllPerfil(){
        this._perfilService.getLista().subscribe(
            list=>{
                this.listPerfil = list.data;
            },
            error => {
            },
            () => {}
        );
    }

    getAssessores() {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({tipo_usuario_id: 'todos'})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;                    
                }
            );
    }

    getOperadores() {
        this.loadingSpinnerOperador = true;
        this.usuarioService.getLista({ tipo_usuario_id: 2 })
            .subscribe(
                resquest => {

                    this.listOperador = resquest.data;
                },
                error => {
                    this.loadingSpinnerOperador = false;
                },
                () => {
                    this.loadingSpinnerOperador = false;                    
                }
            );
    }

    getSituacaoTributaria() {
        this.loadingSpinnerSituacao = true;
        this.situacaoTributaria.getLista().subscribe(
            resquest => {

                this.listSituacaoTributaria = resquest.data;
            },
            error => {

            },
            () => {
                this.loadingSpinnerSituacao = false;                
            }
        );
    }

    getOrigem() {
        this.loadingSpinnerOrigem = true;
        this._origemService.getLista()
            .subscribe(
                resquest => {

                    this.listOrigem = resquest.data;
                },
                error => {
                    this.loadingSpinnerOrigem = false;
                },
                () => {
                    this.loadingSpinnerOrigem = false;                    
                }
            );
    }

    getProfissao() {
        this.loadingSpinnerProfissao = true;
        this._profissaoService.getLista()
            .subscribe(
                resquest => {

                    this.listProfissao = resquest.data;
                },
                error => {
                    this.loadingSpinnerProfissao = false;
                },
                () => {
                    this.loadingSpinnerProfissao = false;                    
                }
            );
    }

    getEstadoCivil() {
        this.loadingSpinnerEstadoCivil = true;
        this._estadoCivil.getLista()
            .subscribe(
                resquest => {
                    this.listEstadoCivil = resquest.data;
                },
                error => {
                    this.loadingSpinnerEstadoCivil = false;
                },
                () => {
                    this.loadingSpinnerEstadoCivil = false;                    
                }
            );
    }

    getPrimeiroContato() {
        this.loadingSpinnerPrimeiro = true;
        this._primeiroService.getLista()
            .subscribe(
                resquest => {

                    this.listPrimeiroContato = resquest.data;
                },
                error => {
                    this.loadingSpinnerPrimeiro = false;
                },
                () => {
                    this.loadingSpinnerPrimeiro = false;                    
                }
            );
    }

    onLinkClick(e){               

        if(e.index == 3){
            this.getAllRelacionamento();
        }
        
    }

    verificaContaCliente(){
        this.service.getByConta(this.dadosCadastraisFormGroup.get('numero_xp').value).subscribe(
            cliente => {
                if(cliente.data){
                    this.showError(`Já existe um cliente: ${cliente.cliente.nome} 
                    cadastrado com esse código de conta: ${cliente.cliente.numero_xp}, 
                    por favor escolha outro código.`);
                    this.dadosCadastraisFormGroup.get('numero_xp').setValue('');
                }
                
            },
            error => {},
            () => { }
        );
    }

    getAllRelacionamento(){
        
        this.loadingRelacionamento  = true;
        this.params.cliente_id      = this.data;

        this._clienteRelacService.getAll(this.params).subscribe(
            data => {
                this.lengthRelacionamento   = data.meta.total;
                this.rowsRelacionamento     = data.data;
                this.tempRelacionamento     = [...this.rows];
            },
            error => {
                this.loadingRelacionamento = false;
            },
            () => {                
                this.loadingRelacionamento = false;
            }
        );
    }   

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEventRelacionamento(event:PageEvent){        

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAllRelacionamento();
    }
   

    /**
     * Modal de edição
     */
    cadastrarRelacionamento(): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoComponent, {
            width: '400px',
            data: { 
                cliente_id: this.data,
                cliente:false
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento Cadastrado com Sucesso!");
                this.getAllRelacionamento();                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o registro");
            }
        });
    }

    /**
     * Modal de edição
     */
    alterarRelacionamento(id): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoComponent, {
            width: '400px',
            data: { 
                cliente_id: this.data,
                id: id,
                cliente:false
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento atualizado com sucesso!");              
                this.getAllRelacionamento();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registrod de relacionamento com cliente");
            }
        });
    }

    filtrarHistoricoRelacionamento():void {
        let dialogRef = this.dialog.open(FiltrarRelacionamentoComponent, {
            width: '700px',
            data: { 
                acao_id: this.params.escritorio_id,
                data_hora_operacao_inicio: this.params.assessor_id,
                data_hora_operacao_fim: this.params.nome,
                cliente: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            
            if (result != "cancel" && result != undefined) {
                this.params.acao_id                     = result.acao_id;
                this.params.data_hora_operacao_inicio   = result.data_inicio;
                this.params.data_hora_operacao_fim      = result.data_fim;
                this.paginatorRelacionamento.firstPage();
                this.getAllRelacionamento();
            }
        });
    }

    setRequired() {
        const valor = this.dadosCadastraisFormGroup.get('cliente_prosp').value;        
        if(valor){
            this.isConditionIsTrue = true;
            this.fourthFormGroup.get('volume_global').setValidators([Validators.required]);
            this.fourthFormGroup.get('investimento_inicial').setValidators([Validators.required]);            
            this.dadosCadastraisFormGroup.get('telefone').setValidators([]);
            this.fourthFormGroup.get('volume_global').updateValueAndValidity();
            this.fourthFormGroup.get('investimento_inicial').updateValueAndValidity();
            this.dadosCadastraisFormGroup.get('telefone').updateValueAndValidity();
        }else{
            this.isConditionIsTrue = false;
            
            this.fourthFormGroup.get('volume_global').setValidators([]);
            this.fourthFormGroup.get('investimento_inicial').setValidators([]);
            this.dadosCadastraisFormGroup.get('telefone').setValidators([Validators.required]);
            this.fourthFormGroup.get('volume_global').updateValueAndValidity();
            this.fourthFormGroup.get('investimento_inicial').updateValueAndValidity();
            this.dadosCadastraisFormGroup.get('telefone').updateValueAndValidity();
        }
    }

    boleta(tipo){   

        let dialogRef = this.dialog.open(CadastroAporteRetiradaComponent, {
            width: '400px',
            data: { 
                tipo: tipo,
                cliente_id: this.data,
                nome_cliente: this.dadosCadastraisFormGroup.get('nome').value,
                valor_disponivel: this.fourthFormGroup.get('valor_disponivel').value,
                total_fop: this.fourthFormGroup.get('vlr_boleta_fo').value, 
                total_st: this.fourthFormGroup.get('vlr_boleta_st').value, 
                total_fii: this.fourthFormGroup.get('vlr_boleta_fii').value, 
                custodia_total_fop: this.totalCustodiaFop, 
                custodia_total_st: this.totalCustodiaSt, 
                custodia_total_fii: this.totalCustodiaFii, 
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Boleta cadastrada com Sucesso!");
                window.location.reload();               
                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o aprote, tente novamente");
            }
        });
    }
    /**
     * Modal de edição
     */
    openAporte(tipo): void {

        let dialogRef = this.dialog.open(CadastroAporteRetiradaComponent, {
            width: '400px',
            data: { 
                tipo: tipo,
                cliente_id: this.data,
                nome_cliente: this.dadosCadastraisFormGroup.get('nome').value,
                valor_disponivel: this.fourthFormGroup.get('valor_disponivel').value
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                if(tipo == 'A'){
                    this.showSuccess("Aporte cadastrado com Sucesso!");                
                }else if(tipo == 'R'){
                    this.showSuccess("Retirada cadastrada com Sucesso!");
                }
                window.location.reload();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o aprote, tente novamente");
            }
        });
    }

    /**
     * Cor das linhas
     */
    getRowClass = (row) => {
        
        if(!row.migrado){
            return {
                'red-900': true
            };
        }
    }

    /**
     * Consulta lista de CarteriraConsorcio
     */
    getAllCarteriraConsorcio() {

        this.loadingListaCarteiraConsorcio = true;
        this.paramsCarteiraConsorcio.cliente_id = this.data;
        this._clienteCarteiraConsorcioService.getAll(this.paramsCarteiraConsorcio).subscribe(
            data => {
                this.lengthCarteiraConsorcio = data.meta.total;
                this.rowsCarteiraConsorcio = data.data;
                this.tempCarteiraConsorcio = [...this.rowsCarteiraConsorcio];
            },
            error => {
                this.loadingListaCarteiraConsorcio = false;
            },
            () => {
                this.loadingListaCarteiraConsorcio = false;
            }
        );
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEventCarteiraConsorcio(event: PageEvent) {

        this.paramsCarteiraConsorcio.page = (event.pageIndex + 1);
        this.paramsCarteiraConsorcio.per_page = event.pageSize;

        this.getAllCarteriraConsorcio();
    }

    /**
     * Modal de cadastro
     */
    openCadastroCarteiraConsorcio(): void {
        let dialogRef = this.dialog.open(CadastroCarteiraConsorcioComponent, {
            width: '800px',
            maxHeight: '99vh',
            data:{
                cliente: this.currentCliente,
                type: 'by_cliente'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Carteira do Crédito/Câmbio/Consórcio cadastrado com sucesso!");
                this.getAllCarteriraConsorcio();
            } else if (result != undefined && result != "cancel") {
                this.showError("Ocorreu um erro ao tentar cadastrar um novo registro.");
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterarCarteiraConsorcio(id): void {
        let dialogRef = this.dialog.open(CadastroCarteiraConsorcioComponent, {
            width: '800px',
            maxHeight: '99vh',
            data: {
                id: id,
                cliente: this.currentCliente,
                type: 'by_cliente'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Carteira do Crédito/Câmbio/Consórcio atualizado com sucesso!");
                this.getAllCarteriraConsorcio();
            } else if (result != undefined && result != "cancel") {
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

    /**
     * Modal de Visualizar Carteira
     */
    openVisualizarCarteiraConsorcio(id): void {
        let dialogRef = this.dialog.open(VisualizarCarteiraConsorcioComponent, {
            width: '800px',
            maxHeight: '99vh',
            data: {
                id: id,
                cliente: this.currentCliente,
                type: 'by_cliente'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

}
