import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import {RolesService} from "../services/roles.service";
import {ActivatedRoute} from "@angular/router";

export interface DialogData {
    id: number;
}

@Component({
    selector: 'dialog-role-cadastrar',
    templateUrl: './role-cadastrar.component.html',
    styleUrls: ['./role-cadastrar.component.scss']
})
export class RoleCadastrarComponent extends BaseComponent {
    
    name = new FormControl('', [Validators.required,Validators.maxLength(255)]);

    model:any = {
        name:"",
    };

    listRoles:any[]=[];
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(RoleCadastrarComponent, { static: false }) table: RoleCadastrarComponent;

    constructor(public service: RolesService, public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<RoleCadastrarComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public roleService: RolesService) {
        super(dialog, snackBar);

        this.listRoles=[];

        if(this.data != null && this.data != undefined){
            this.getItem();
        }

    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    getErrorNameMessage() {
        return this.name.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    isInvalidForm(){
        if (!this.name.valid){
            return true;
        }
        
        return false;
    }

    salvar(){
        this.model.name = this.name.value;

        if(this.data == null){
            this.openLoading();
            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );

        }else{
            this.openLoading();
            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }

    }

    getItem(){
        this.openLoading();

        this.service.getById(this.data.id).subscribe(
         item=>{
             this.name.setValue(item.data.name);
        },
         error => {
             this.closeLoading();
         },
        () => {
            this.closeLoading();
        });
    }
}