import { 
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation
}  from '@angular/core';

import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar }  from '@angular/material';
import { Router } from "@angular/router";
import { SelectionType } from '@swimlane/ngx-datatable';
import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from "../../base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ParametroService } from '../services/parametro.service';

@Component({
    selector: 'app-lista-parametro',
    templateUrl: './lista-parametro.component.html',
    styleUrls: ['./lista-parametro.component.scss'],  
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class ListaParametroComponent extends BaseComponent{

    params: any = {};
    user:any;
    loadingIndicator: boolean;
    SelectionType = SelectionType;

    selected = [];
    data:any = {};
    titulo:string;
    
    model:any = {        
    };

    vlr_min_boleta_sw           = new FormControl();
    vlr_min_primeira_boleta_sw  = new FormControl();
    vlr_min_boleta_fop          = new FormControl();
    vlr_min_primeira_boleta_fop = new FormControl();
    pertual_min_sinte = new FormControl();
    texto_notificacao_operacao = new FormControl();
    texto_notificacao_operacao_informativo = new FormControl();
    percentual_max_custodia = new FormControl();
    percentual_max_custodia_setor = new FormControl();
    percentual_marcao_mercado_rv = new FormControl();
    vlr_minimo_divisao_ativo_rv = new FormControl();

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;        

    constructor(public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                private _authService: AuthService,
                private _service: ParametroService) 
    {
        
        super(dialog, snackBar);        
        this.user = JSON.parse(this._authService.getUser());
        this.getAll();  
    }
    
    /**
     * Consulta lista de Boletas
     */
    getAll() {
        
        this._service.getAll(this.params).subscribe(
            data => {
                for(let dados of data.data){
                    
                    if(dados.codigo == 'VALOR_MIN_BOLETA_SWING'){
                        this.vlr_min_boleta_sw.setValue(parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_PRIMEIRA_BOLETA_SWING'){
                        this.vlr_min_primeira_boleta_sw.setValue(parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_BOLETA_FOP'){
                        this.vlr_min_boleta_fop.setValue(parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_PRIMEIRA_BOLETA_FOP'){
                        this.vlr_min_primeira_boleta_fop.setValue(parseFloat(dados.valor));
                    } 
                    
                    if(dados.codigo == 'PERCENTUAL_MIM_GANHO_SINTETICO'){
                        this.pertual_min_sinte.setValue(parseFloat(dados.valor));
                    } 

                    if(dados.codigo == 'MENSAGEM_NOTIFIC_OPERACAO'){
                        this.texto_notificacao_operacao.setValue(dados.valor);
                    } 

                    if(dados.codigo == 'MENSAGEM_NOTIFIC_OPERACAO_INFORMATIVO'){
                        this.texto_notificacao_operacao_informativo.setValue(dados.valor);
                    }

                    if(dados.codigo == 'PERCENTUAL_MAXIMO_CUSTODIA'){
                        this.percentual_max_custodia.setValue(dados.valor);
                    }

                    if(dados.codigo == 'PERCENTUAL_MAXIMO_CUSTODIA_SETOR'){
                        this.percentual_max_custodia_setor.setValue(dados.valor);
                    }

                    if(dados.codigo == 'RV_VLR_MINIMO_GERADOR_ORDEM_DIVICAO_ATIVO'){
                        this.vlr_minimo_divisao_ativo_rv.setValue(dados.valor);
                    }

                    if(dados.codigo == 'RV_VLR_PERCENTUAL_GERADOR_MARCAO_MERCADO_ATIVO'){
                        this.percentual_marcao_mercado_rv.setValue(dados.valor);
                    }
                }               
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }

    openVisualizar(id){
        this.route.navigate(['/renda-variavel/basket-ativos/' + id]);
    }

    openAlterar(id){
        this.route.navigate(['/renda-variavel/basket-ativos/' + id]);
    }    

    salvar(){
        this.openLoading();
        
        this.model.VALOR_MIN_BOLETA_SWING = this.vlr_min_boleta_sw.value;
        this.model.VALOR_MIN_PRIMEIRA_BOLETA_SWING = this.vlr_min_primeira_boleta_sw.value;
        this.model.VALOR_MIN_BOLETA_FOP = this.vlr_min_boleta_fop.value;
        this.model.VALOR_MIN_PRIMEIRA_BOLETA_FOP = this.vlr_min_primeira_boleta_fop.value;
        this.model.PERCENTUAL_MIM_GANHO_SINTETICO = this.pertual_min_sinte.value;
        this.model.MENSAGEM_NOTIFIC_OPERACAO = this.texto_notificacao_operacao.value;
        this.model.MENSAGEM_NOTIFIC_OPERACAO_INFORMATIVO = this.texto_notificacao_operacao_informativo.value;
        this.model.PERCENTUAL_MAXIMO_CUSTODIA = this.percentual_max_custodia.value;
        this.model.PERCENTUAL_MAXIMO_CUSTODIA_SETOR = this.percentual_max_custodia_setor.value;
        this.model.RV_VLR_PERCENTUAL_GERADOR_MARCAO_MERCADO_ATIVO = this.percentual_marcao_mercado_rv.value;
        this.model.RV_VLR_MINIMO_GERADOR_ORDEM_DIVICAO_ATIVO = this.vlr_minimo_divisao_ativo_rv.value;

        this._service.update(this.model).subscribe(
            data => {
                this.closeLoading();    
                this.showSuccess(data.message);
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {                    
                this.closeLoading();
            }
        );

    }

}
