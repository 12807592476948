import {EventEmitter, Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class EmpresaService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * Empresa por id
     * @param id ID da empresa
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "empresas/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Empresas
     * @param id ID da empresa
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "empresas"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Empresa
     * @param id ID da empresa
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "empresas/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Empresa
     * @param params Json Empresa
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "empresas", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastro Externo
     * @param params Json Empresa
     */
    createAcount(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "cadastro", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Empresa
     * @param params Json Empresa
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "empresas/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Seta empresa na sessão
     * @param empresa
     */
    setEmpresaSession(empresa){
        this.setEmpresaPadrao(empresa);
        window.localStorage.setItem('empresa', JSON.stringify(empresa));
    }

    /**
     * Retorna empresa da sessão
     * @returns {string | null}
     */
    getEmpresaSession(){
        return window.localStorage.getItem('empresa');
    }

    /**
     * Lança evento com a empresa padrão
     * @param empresa
     */
    setEmpresaPadrao(empresa:any){
        this.empresaPadrao.emit(empresa);
    }

	/**
	 * Buscas os Pais
	 */
	getPais():Observable<any>{
		return this._http.get(this.utilitesService.urlPrefix + "pais", { headers: this.authService.getAuthHeaders() });
	}
    /**
     * Buscas os estados
     */
    getEstados():Observable<any>{
        return this._http.get(this.utilitesService.urlPrefix + "estados", { headers: this.authService.getAuthHeaders() });
    }

    getCidades(estado_id):Observable<any>{
        return this._http.get(this.utilitesService.urlPrefix + "estados/"+estado_id+'/cidades', { headers: this.authService.getAuthHeaders() });
    }

	getTipoMoeda():Observable<any>{
		return this._http.get(this.utilitesService.urlPrefix + "empresas/tipo_moeda", { headers: this.authService.getAuthHeaders() });
	}

	getCrt():Observable<any>{
		return this._http.get(this.utilitesService.urlPrefix + "fiscal/crt", { headers: this.authService.getAuthHeaders() });
	}
}
