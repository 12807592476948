import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PainelService {

    constructor(
        private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * Basket por id
     * @param id ID da Basket
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/painel-rv/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
    * Lista de Basket
    * @param id ID da Basket
    */
    getAll(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/painel-rv"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
    * Lista de Basket
    * @param id ID da Basket
    */
     getAllEstrategias(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/painel-rv/estrategias"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete Basket
     * @param id ID do Basket
     */
     delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "renda-variavel/painel-rv/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Basket
     * @param params Json Basket
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "renda-variavel/painel-rv", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }


    /**
     * AtualizaBasket
     * @param params Json Basket
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "renda-variavel/painel-rv/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

}
