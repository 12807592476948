import { Component, Input } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { BaseComponent, ActionDialog } from "../../base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { CustodiaService } from 'app/movimentacao/services/custodia.service';
import { CadastroHistoricoClienteComponent } from '../cadastro-historico-cliente/cadastro-historico-cliente.component';
import { FiltroHistoricoClienteComponent } from '../filtro-historico-cliente/filtro-historico-cliente.component';

@Component({
    selector: 'app-cliente-produtos',
    templateUrl: './cliente-produtos.component.html',
    styleUrls: ['./cliente-produtos.component.scss']
})

export class ClienteProdutosComponent extends BaseComponent{

    params: any = {};    
    user: any;
    data: any;
    loadingIndicator: boolean;
    data_atualizacao: any;

    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 30];


    @Input() cliente_id: any;

    constructor(public _custodiaService: CustodiaService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);
        this.params.per_page = this.pageSize;

        this.user = JSON.parse(this._authService.getUser());

        if( this.cliente_id != undefined){
            this.getAll();
        }
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }
  

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator   = true;
        this.params.cliente_id  = this.data;

        this._custodiaService.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }



    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltroHistoricoClienteComponent, {
            width: '700px',
            data: { 
                titulo: 'Filtrar Saldo de Clientes',
                status_boleta: false,
                status_ordem: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_fim = result.data_operacao_fim;
                }

                if (result.ativo_id != undefined && result.ativo_id != null) {
                    this.params.ativo_id = result.ativo_id;
                }else{
                    this.params.ativo_id = '';
                }

                if (result.tipo_ativo != undefined && result.tipo_ativo != null) {
                    this.params.tipo_ativo = result.tipo_ativo;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }
    
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroHistoricoClienteComponent, {
            width: '500px',
            height: '90%',
            data: { 
                cliente_id: this.data,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Histórico cadastrado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                //this.showError("Ocorreu um erro ao tentar cadastrar a custódia dos clientes.");
            }
        });
    }

    alterar(id): void {
        let dialogRef = this.dialog.open(CadastroHistoricoClienteComponent, {
            width: '400px',
            height: '90%',
            data: { 
                cliente_id: this.data,
                id:id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Histórico atualizado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                //this.showError("Ocorreu um erro ao tentar cadastrar a custódia dos clientes.");
            }
        });
    }

    

    /**
    * Exclui registro de histórico
    * @param id
    */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir histórico",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._custodiaService.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }   

    getPrecoExecutado(row){
        if(row.preco_executado == '' || parseFloat(row.preco_executado) == 0 ){
            return parseFloat(row.vlr_financeiro);
        }else{
            return parseFloat(row.preco_executado) * parseFloat(row.qtd_executado);
        }
    }    

}
