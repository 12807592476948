import { 
    Component, 
    ViewChild, 
    TemplateRef,
    ViewEncapsulation
}  from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent
} from '@angular/material';

import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from "../../base-module/base-component/base.component";
import { FiltraOperacaoEstruturadaComponent } from '../filtra-operacao-estruturada/filtra-operacao-estruturada.component';
import { UploadOperacaoEstruturadaComponent } from '../upload-operacao-estruturada/upload-operacao-estruturada.component';

import { OperacaoEstruturadaService } from '../services/operacao-estruturada.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
    selector: 'app-lista-operacao-estruturada',
    templateUrl: './lista-operacao-estruturada.component.html',
    styleUrls: ['./lista-operacao-estruturada.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,    
    providers: [ OperacaoEstruturadaService ]
    
})

export class ListaOperacaoEstruturadaComponent extends BaseComponent{

    usuario:any;
    loadingIndicator: boolean;
    loadingListaAtivo: boolean;   
    user:any;
    params:any = {
        per_page: 100
    }

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaOperacaoEstruturadaComponent, { static: false }) table: ListaOperacaoEstruturadaComponent;

    constructor(public service: OperacaoEstruturadaService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,
                private _authService: AuthService ) {
        super(dialog, snackBar);
        this.getAll();

        this.user = JSON.parse(this._authService.getUser());
    }

    getPrecoExecutado(row){
        return parseFloat(row.preco_cliente) * parseFloat(row.qtd);
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }
    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    } 
    /**
     * Consulta lista de Boletas
     */
    getAll() {
        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {                
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }  

    openCadastro(): void {
        let dialogRef = this.dialog.open(UploadOperacaoEstruturadaComponent, {
            width: '400px',
            
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("FEE importado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o FEE dos clientes.");
            }
        });
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltraOperacaoEstruturadaComponent, {
            width: '700px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_fim = result.data_operacao_fim;
                }
               
                if (result.ativo != undefined && result.ativo != null) {
                    this.params.ativo_id = result.ativo.id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                } 
                
                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }  
                
                this.getAll();

                this.paginator.firstPage();
            }
        });
    }
    
}
