import {Component, ViewChild, TemplateRef, Inject, ViewEncapsulation} from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import {BaseComponent, LoadingTeste, ActionDialog} from "../../base-module/base-component/base.component";
import {RolesService} from "../services/roles.service";
import {AuthService} from "../../base-module/base-service/auth.service";
import { EscritorioService } from 'app/empreendimento/services/escritorio.service'

export interface DialogData {
    id: number;
}

@Component({
    selector: 'dialog-usuarios-cadastrar',
    templateUrl: './usuarios-cadastrar.component.html',
    styleUrls: ['./usuarios-cadastrar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ EscritorioService ]
})
export class UsuariosCadastrarComponent extends BaseComponent {
    
    name = new FormControl('', [Validators.required, Validators.maxLength(255)]);
    password = new FormControl('', [Validators.required, Validators.maxLength(255)]);
    confirm_password = new FormControl('', [Validators.required, Validators.maxLength(255)]);
    email = new FormControl('', [Validators.required,Validators.email, Validators.maxLength(255)]);
    role_id = new FormControl('', [Validators.required]);
    escritorio_id = new FormControl('', [Validators.required]);
    escritorios = new FormControl('', [Validators.required]);
    codigo_aai = new FormControl('', []);
    telefone = new FormControl('', []);
    cpf = new FormControl('', []);
    data_nascimento = new FormControl('', []);
    tipo_usuario_id = new FormControl('', [Validators.required]);

    model:any = {
        name:"",
        email:"",
        role_id:"",
        escritorio_id: [],
        password:"",
        confirm_password:"",
        codigo_aai:"",
        tipo_usuario_id:"",
    };
    user:any;
    listRoles:any[]=[];
    loadingLocal:any;
    listEscritorios: any[] = [];
    listTipoUsuario: any[] = [];
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(UsuariosCadastrarComponent, { static: false }) table: UsuariosCadastrarComponent;

    constructor(public service: UsuariosService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<UsuariosCadastrarComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, 
                public roleService: RolesService,
                public loading: MatDialog, 
                public authService:AuthService,                 
                public escritorioService:EscritorioService,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.user = JSON.parse(this._authService.getUser());

        this.listRoles=[];
        if(this.data != null && this.data != undefined){
            this.getItem();
        }
        
        this.getAllRoles();
        this.getAllEscritorios();
        this.getAllTipoUsuario();
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    getErrorNameMessage() {
        return this.name.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getErrorCodigoMessage() {
        return this.name.hasError('required') ? 'Código AAI é um campo obrigatório' : '';
    }

    getErrorEmailMessage() {
        return this.email.hasError('required') ? 'E-mail é um campo obrigatório' : '';
    }

    getErrorRoleMessage() {
        return this.email.hasError('required') ? 'Perfil é um campo obrigatório' : '';
    }

    getErrorEscritorioMessage() {
        return this.escritorio_id.hasError('required') ? 'Escritório é um campo obrigatório' : '';
    }

    isInvalidForm(){
        if (!this.name.valid){
            return true;
        }
        if (!this.email.valid) {
            return true;
        }

        if (!this.role_id.valid) {
            return true;
        }

        if (!this.escritorio_id.valid) {
            return true;
        }

        if(this.password.value != this.confirm_password.value){
            return true;
        }
        
        return false;
    }

    salvar(){
        this.model.name = this.name.value;
        this.model.email = this.email.value;
        this.model.codigo_aai = this.codigo_aai.value;
        this.model.escritorio_id = this.escritorio_id.value;
        this.model.escritorios = this.escritorios.value;
        this.model.role_id = this.role_id.value;
        this.model.tipo_usuario_id = this.tipo_usuario_id.value;
        this.model.cpf = this.cpf.value;
        this.model.telefone = this.telefone.value;
        this.model.data_nascimento = this.data_nascimento.value;

        //envia password e confirmação se existir
        if(this.password != null && this.confirm_password != null){
            this.model.password = this.password.value;
            this.model.confirm_password = this.confirm_password.value;
        }

        if(this.data == null){
            this.openLoading();
            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );

        }else{
            this.openLoading();
            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }

    }

    getItem(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {
            this.model = item.data;
            this.name.setValue(item.data.name);
            this.email.setValue(item.data.email);
            this.escritorio_id.setValue(item.data.escritorio_id);
            this.escritorios.setValue(item.data.escritorios);
            this.role_id.setValue(item.data.role_id);
            this.codigo_aai.setValue(item.data.codigo_aai);
            this.tipo_usuario_id.setValue(item.data.tipo_usuario_id);            
            this.cpf.setValue(item.data.cpf);            
            this.data_nascimento.setValue(item.data.data_nascimento);            
            this.telefone.setValue(item.data.telefone);            
        },
         error => {
             this.closeLoading();
         },
        () => {
            this.closeLoading();
        });
    }

    getAllRoles(){
        this.openLoadingLocal();
        this.roleService.getAll({no_paginate:true}).subscribe(
            list=>{
                this.closeLoadingLocal();
                this.listRoles = list.data;
            },
            error => {
                this.closeLoadingLocal();
            },
            () => {
                this.closeLoadingLocal();                
            });
    }

    isDisabledRole(){
        if(this.listRoles.length > 0){
            return false;
        }
        return true;
    }

    getAllEscritorios() {
        
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
        
            },
            () => {
            });
    }

    getAllTipoUsuario() {
        
        this.service.tipoUsuario().subscribe(
            list => {        
                this.listTipoUsuario = list.data;
            },
            error => {
        
            },
            () => {
            });
    }

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir usuário",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    //lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);   
                            const dados = {id}
                            lista.dialogRef.close(dados);
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.message);
                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    

}
