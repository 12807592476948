import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class RolesService {

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * Consulta por ID
     * @param id
     * @returns {Observable<any>}
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "usuarios/perfis/" + id, {headers: this.authService.getAuthHeaders()} );
    }

    /**
     *
     * @param params
     * @returns {Observable<any>}
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/perfis"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     *
     * @param id
     * @returns {Observable<any>}
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "usuarios/perfis/"+id, {headers: this.authService.getAuthHeaders()});
    }

    /**
     *
     * @param params
     * @returns {Observable<Object>}
     */
    create(params){
        return this._http.post(this.utilitesService.urlPrefix + "usuarios/perfis", params,{headers: this.authService.getAuthHeaders()});
    }

    /**
     *
     * @param id
     * @param params
     * @returns {Observable<Object>}
     */
    update(id, params){
        return this._http.put(this.utilitesService.urlPrefix + "usuarios/perfis/"+id, params, {headers: this.authService.getAuthHeaders()});
    }

    /**
     *
     * @param params
     * @returns {Observable<any>}
     */
    getAllPermissoes(id,params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/perfis/"+id+"/permissoes"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Salva as permissões de acesso
     * @param id
     * @param params
     * @returns {Observable<Object>}
     */
    savePermissoes(id,params){
        return this._http.post(this.utilitesService.urlPrefix + "usuarios/perfis/"+id+"/permissoes", params,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Consulta Autocomplete
     * @param {string} query
     * @returns {Observable<any>}
     */
    search(query: string): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(query);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "usuarios/perfis/search" + env, { headers: this.authService.getAuthHeaders() });
    }

}
