import {Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class CustodiaService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Custodia do Cliente por id
     * @param id ID da Aporte do Cliente
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/custodia/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Custodia do Cliente
     * @param id ID da Aporte do Cliente
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/custodia"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista a Custódia da Carteira dos Clientes
     * @param id ID da Aporte do Cliente
     */
    getAllCarteira(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/all-carteiras"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista a Arquivos Custódia
     * @param id ID da Aporte do Cliente
     */
    getAllArquivos(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/custodia/getArquivos"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Busca informações para preencher grafico
     * @param id ID da Aporte do Cliente
     */
    getGraficoCarteria(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/carteira/getGraficoCarteira"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Custodia do Cliente
     * @param id ID da Aporte do Cliente
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "movimentacao/custodia/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Custodia do Cliente
     * @param params Json Aporte do Cliente
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/custodia", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    upload(file):Observable<any>{
        const formData = new FormData();
        formData.append('arquivo', file);
        return this._http.post(this.utilitesService.urlPrefix +'movimentacao/custodia/uploads', formData, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/custodia/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }
}
