import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AtivoService } from 'app/auxiliares/services/ativo-service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-filtrar-inconsistencias-ordens',
    templateUrl: './filtrar-inconsistencias-ordens.component.html',
    styleUrls: ['./filtrar-inconsistencias-ordens.component.scss']
})
export class FiltrarInconsistenciasOrdensComponent extends BaseComponent {
    
    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

    cliente                 = new FormControl('');
    ativo                   = new FormControl('');
    data_operacao_inicio    = new FormControl(this.primeiroDia);
    data_operacao_fim       = new FormControl(this.ultimoDia);
    assessor_id             = new FormControl('');
    arquivo_id              = new FormControl('');

    paramsStatus: any = {
        finalizada: ""
    }
    loadingSpinner: boolean;
    loadingSpinnerStatus: boolean;
    loadingSpinnerAssessor: boolean;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerArquivos: boolean;
    filteredOptions: Observable<string[]>;
    listStatus: Object[] = [];
    listAssessor: Object[] = [];
    listAtivos: Object[] = [];
    listArquivos: Object[] = [];
    filteredOptionsAtivo: Observable<string[]>;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;    

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltrarInconsistenciasOrdensComponent>,
        private _clienteService: ClienteService,
        private _utilitesService: UtilitesService,
        private _ativoService: AtivoService,        
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        );

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;
                    this.params.tipo = 'NORMAL';
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );       
        
    }

    displayFn(cliente): void {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente): void{
        this.loadingSpinner  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(): void {


        var inic = null;
        if (this.data_operacao_inicio.value != null){
            inic = this._utilitesService.formatDateToString(new Date(this.data_operacao_inicio.value));
        }

        var fim = null;
        if (this.data_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(new Date(this.data_operacao_fim.value));
        }
        
        this.dialogRef.close({
            'cliente': this.cliente.value,
            'data_operacao_inicio': inic,
            'data_operacao_fim': fim,
            'ativo': this.ativo.value,            
        });
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }


    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }


    getAtivos(): void {
        this.loadingSpinnerAtivos = true;
        this._ativoService.getAll({ no_paginate:true })
            .subscribe(
                resquest => {
                    this.listAtivos = resquest.data;
                },
                error => {
                    this.loadingSpinnerAtivos = false;
                },
                () => {
                    this.loadingSpinnerAtivos = false;
                    console.log("complete");
                }
            );
    }    

}