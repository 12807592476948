import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class BoletaValoresService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }   
    
    /**
     * Aporte do Cliente por id
     * @param id ID da Aporte do Cliente
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta/valores/get/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Boleta por id
     * @param id ID da Boleta do Cliente
    */
    getValoresById(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }


        return this._http.get(this.utilitesService.urlPrefix + `movimentacao/boleta/valores${env}`, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Boleta por id
     * @param id ID da Boleta do Cliente
    */
    getTotalTrades(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + `movimentacao/boleta/valores/total-trades`, { headers: this.authService.getAuthHeaders() });
    }

     /**
     * Boleta por id
     * @param id ID da Boleta do Cliente
    */
    getTotalBoletas(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + `movimentacao/boleta/valores/total-boletas${env}`, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Boleta do Cliente
     * @param params Json Boleta do Cliente
    */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "movimentacao/boleta/valores/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Boleta do Cliente
     * @param id ID da Boleta do Cliente
    */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "movimentacao/boleta/valores/"+id, { headers: this.authService.getAuthHeaders() });
    }   
	
}
