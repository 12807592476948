
import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import {BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { TipoBasketService } from '../services/tipo-basket.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
export interface DialogData {
    id: number;
}

@Component({
  selector: 'cadastro-tipo-basket',
  templateUrl: './cadastro-tipo-basket.component.html',
  styleUrls: ['./cadastro-tipo-basket.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers:[ EstrategiaService ]
})
export class CadastroTipoBasketComponent extends BaseComponent {
    nome = new FormControl("", [Validators.required]);
    estrategia_id = new FormControl();

    model: any = {
        nome: "",
    };

    loadingLocal: any;
    listEstrategia:any[]=[];
    loadingSpinnerEstrategia: boolean = false;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    
    

    constructor(
        public service: TipoBasketService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public authService: AuthService,
        public dialogRef: MatDialogRef<CadastroTipoBasketComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _serviceEstrategia: EstrategiaService  
        
    ) {
        super(dialog, snackBar);
        this.getAllEstrategias();

        if (this.data != null && this.data != undefined) {
            this.getById();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (!this.nome.valid) {
            return true;
        }

        return false;
    }

    salvar() {
        this.openLoading();
        this.model.nome = this.nome.value;
        this.model.estrategia_id = this.estrategia_id.value;
        if (this.data == null) {
            this.service.create(this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        } else {
            this.service.update(this.data.id, this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getErrorNameMessage() {
        return this.nome.hasError("required")
            ? "Nome é um campo obrigatório"
            : "";
    }

    getById() {
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
            (item) => {
                this.model = item.data;
                this.nome.setValue(item.data.nome);
                this.estrategia_id.setValue(item.data.estrategia_id);
            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getAll({}).subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
            }
        );
    }
}
