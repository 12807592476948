import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { fuseAnimations } from '@fuse/animations';
import { FormControl, Validators} from '@angular/forms';

import { BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { ListaAtividadeAssuntoService } from '../services/lista-atividade-assunto.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'cadastro-assunto',
    templateUrl: './cadastro-assunto.component.html',
    styleUrls: ['./cadastro-assunto.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CadastroAssuntoComponent extends BaseComponent {
    nome = new FormControl("", [Validators.required]);

    model: any = {
        nome: "",
        is_visible: false,
        is_subject_prosperidade: false
    };

    loadingLocal: any;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;

    constructor(
        public service: ListaAtividadeAssuntoService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public authService: AuthService,
        public dialogRef: MatDialogRef<CadastroAssuntoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);
        if (this.data != null && this.data != undefined) {
            this.getById();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (!this.nome.valid) {
            return true;
        }

        return false;
    }

    salvar() {
        this.openLoading();
        this.model.nome = this.nome.value;       
        if (this.data == null) {
            this.service.create(this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        } else {
            this.service.update(this.data.id, this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getErrorNameMessage() {
        return this.nome.hasError("required")
            ? "Nome é um campo obrigatório"
            : "";
    }

    getById() {
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
            (item) => {
                this.model = item.data;
                this.nome.setValue(item.data.nome);
            },
            (error) => {
                this.closeLoading();              
            },
            () => {
                this.closeLoading();                
            }
        );
    }

    changeVisible(event){
        this.model.is_visible = event.checked;
    }
    
    changeIsSubjectProsperidade(event){
        this.model.is_subject_prosperidade = event.checked;
    }
}
