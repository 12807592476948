import { Injectable } from "@angular/core";
import { UtilitesService } from "app/base-module/base-service/utilites.service";
import { AuthService } from  "app/base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: 'root',
})
export class DashboardRendaVariavelService {

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }   
 
    /**
     * Indicadores de clientes
     * @param id ID
     */
    getIndicadoresClientes(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard-renda-variavel/indicadoresCliente"+env, { headers: this.authService.getAuthHeaders() });
    } 

    /**
     * Indicadores de clientes
     * @param id ID
     */
    getCorretagemClientes(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard-renda-variavel/corretagemCliente"+env, { headers: this.authService.getAuthHeaders() });
    } 

    /**
     * Indicadores de clientes
     * @param id ID
     */
    getFeeClientes(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard-renda-variavel/feeCliente"+env, { headers: this.authService.getAuthHeaders() });
    } 

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params, url): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + url  + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }

}