import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { Observable } from 'rxjs';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { StatusOrdemService } from 'app/renda-variavel/services/status-ordem.service';
import { AcaoComercialService } from 'app/acao-comercial/services/acao-comercial.service';
import { PrimeiroContatoService } from 'app/auxiliares/services/primeiro-contato.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
import { TipoConsorcioService } from '../../consorcio/services/tipo-consorcio.service';
import { ProdutoRendaFixaService } from 'app/renda-fixa/services/produto-rendafixa.service';
import { OperadorPlanoSaudeService } from 'app/plano-saude/services/operador-plano-saude.service';
import { PrazoPlanoSaudeService } from 'app/plano-saude/services/prazo-plano-saude.service';
import { TipoOperacaoPlanoSaudeService } from 'app/plano-saude/services/tipo-operacao-plano-saude.service';
import { ParceiroPlanoSaudeService } from 'app/plano-saude/services/parceiro-plano-saude.service';
import { OperadoraCreditoService } from 'app/credito/services/operadora-credito.service';
import { TipoOperacaoCreditoService } from 'app/credito/services/tipo-operacao-credito.service';
import { ParceiroCreditoService } from 'app/credito/services/parceiro-credito.service';
import { TipoLanceCartaConsorcioService } from 'app/consorcio/services/tipo-lance-carta-consorcio.service';

export interface DialogData {
    id: number;
    titulo: string;
    status_ordem: boolean;
    status_boleta: boolean;
    tipo_produto: string;
    tipo: string;
}

@Component({
    selector: "dialog-filtrar-boleta",
    templateUrl: "./filtrar-boleta.component.html",
    styleUrls: ["./filtrar-boleta.component.scss"],
    providers: [
        PrimeiroContatoService, 
        EstrategiaService, 
        EscritorioService,
        ProdutoRendaFixaService,
        OperadorPlanoSaudeService,
        PrazoPlanoSaudeService,
        TipoOperacaoPlanoSaudeService,
        ParceiroPlanoSaudeService,
        OperadoraCreditoService,
        TipoOperacaoCreditoService,
        ParceiroCreditoService,
    ],
})
export class FiltrarBoletaComponent extends BaseComponent {
    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

    cliente = new FormControl();
    statusBoleta = new FormControl("");
    assessor_id = new FormControl("");
    escritorio_id = new FormControl("");
    status_ordem = new FormControl("");
    homebroker = new FormControl("");
    acao_comercial_id = new FormControl("");
    primeiro_contato_id = new FormControl("");
    estrategia_id = new FormControl("");
    ativos = new FormControl("");
    tipo_consorcio = new FormControl("");
    compra_venda = new FormControl("");
    produto_id = new FormControl("");
    data_operacao_inicio = new FormControl(this.primeiroDia);
    data_operacao_fim = new FormControl(this.ultimoDia);
    prazo_id = new FormControl("");
    tipo_operacao_id = new FormControl("");
    operador_id = new FormControl("");
    parceiro_id = new FormControl("");
    data_retorno_inicio = new FormControl(null);
    data_retorno_fim = new FormControl(null);
    prazo_credito = new FormControl("");
    tipo_operacao_credito_id = new FormControl("");
    operadora_credito_id = new FormControl("");
    parceiro_credito_id = new FormControl("");
    tipo_lance_id = new FormControl("");

    paramsStatus: any = {
        finalizada: "",
    };

    loadingSpinner: boolean;
    loadingSpinnerStatus: boolean;
    loadingSpinnerStatusOrdem: boolean;
    loadingSpinnerAssessor: boolean;
    loadingSpinnerEscritorio: boolean;
    loadingSpinnerAcaoComercial: boolean;
    loadingSpinnerClassificacao: boolean = false;
    loadingSpinnerEstrategia: boolean = false;
    loadingSpinnerTipo: boolean = false;
    loadingSpinnerProduto: boolean = false;
    loadingSpinnerTipoLance: boolean = false;
    filteredOptions: Observable<string[]>;
    listStatus: Object[] = [];
    listStatusOrdem: Object[] = [];
    listAssessor: Object[] = [];
    listEscritorios: Object[] = [];
    listAcaoComercial: Object[] = [];
    listClassificacao: Object[] = [];
    listEstrategia: Object[] = [];
    listTipoConsorcio: any[] = [];
    listProduto: any[] = [];
    listTipoLance: any[] = [];
    usuario: any;

    loadingSpinnerOperador: boolean = false;
    listOperador: any[] = [];
    loadingSpinnerTipoOperacao: boolean = false;
    listTipoOperacao: any[] = [];
    loadingSpinnerPrazo: boolean = false;
    listPrazo: any[] = [];
    loadingSpinnerParceiro: boolean = false;
    listParceiro: any[] = [];

    loadingSpinnerOperadoraCredito: boolean = false;
    listOperadoraCredito: any[] = [];
    loadingSpinnerTipoOperacaoCredito: boolean = false;
    listTipoOperacaoCredito: any[] = [];
    loadingSpinnerParceiroCredito: boolean = false;
    listParceiroCredito: any[] = [];


    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltrarBoletaComponent, { static: false })
    table: FiltrarBoletaComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltrarBoletaComponent>,
        private _clienteService: ClienteService,
        private _statusService: StatusMovimentacaoService,
        private _statusOrdemService: StatusOrdemService,
        private _usuarioService: UsuarioService,
        private _utilitesService: UtilitesService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _authService: AuthService,
        public escritorioService: EscritorioService,
        public _primeiroService: PrimeiroContatoService,
        private _serviceAcaoComercial: AcaoComercialService,
        private _serviceEstrategia: EstrategiaService,
        private _tipoService: TipoConsorcioService,
        private _serviceProduto: ProdutoRendaFixaService,
        private _operadorService: OperadorPlanoSaudeService,
        private _prazoService: PrazoPlanoSaudeService,
        private _tipoOperacaoService: TipoOperacaoPlanoSaudeService,
        private _parceiroService: ParceiroPlanoSaudeService,
        private _operadoraCreditoService: OperadoraCreditoService,
        private _tipoOperacaoCreditoService: TipoOperacaoCreditoService,
        private _parceiroCreditoService: ParceiroCreditoService,
        private _tipoLanceCartaService: TipoLanceCartaConsorcioService,
    ) {
        super(dialog, snackBar);

        this.cliente.valueChanges.subscribe((query) => {
            if (typeof query === "string" || typeof query === "number") {
                this.loadingSpinner = true;
                this.params.search = query;
                this.filteredOptions = this._clienteService.search(this.params);
            }
        });

        this.usuario = JSON.parse(this._authService.getUser());
        this.getAllEstrategias();
        this.getAllStatus();
        this.getAllAcaoComercial();
        this.getClassificacao();

        if(this.data.tipo == 'RF'){
            this.getProdutosRendaFixa();
        }

        if (this.data.status_ordem) {
            this.getAllStatusOdem();
        }

        if (this.usuario.role_id != 5) {
            this.getAllEscritorios();
            this.getAssessores();
        }

        if (this.data.tipo == "CS") {
            this.getAllTipoConsorcio();
            this.getAllTipoLance();
        }

        if (this.data.tipo === 'PS'){
            this.data_operacao_inicio = new FormControl('');
            this.data_operacao_fim = new FormControl('');
            this.data_retorno_inicio = new FormControl('');
            this.data_retorno_fim = new FormControl('');
            this.getPrazo();
            this.getOperador();
            this.getTipoOperacao();
            this.getParceiro();
        }

        if (this.data.tipo === 'CR'){
            this.data_operacao_inicio = new FormControl('');
            this.data_operacao_fim = new FormControl('');
            this.getOperadoraCredito();
            this.getTipoOperacaoCredito();
            this.getParceiroCredito();
        }

        if(this.data.tipo === 'PA'){
            this.data_operacao_inicio = new FormControl('');
            this.data_operacao_fim = new FormControl('');
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome;
        }
    }

    getCliente(cliente) {
        this.loadingSpinner = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar() {
        var inic = null;
        if (this.data_operacao_inicio.value != null) {
            inic = this._utilitesService.formatDateToString(
                new Date(this.data_operacao_inicio.value)
            );
        }

        var fim = null;
        if (this.data_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(
                new Date(this.data_operacao_fim.value)
            );
        }

        this.dialogRef.close({
            status_id: this.statusBoleta.value,
            cliente: this.cliente.value,
            data_operacao_inicio: inic,
            data_operacao_fim: fim,
            assessor_id: this.assessor_id.value,
            escritorio_id: this.escritorio_id.value,
            status_ordem_id: this.status_ordem.value,
            homebroker: this.homebroker.value,
            acao_comercial_id: this.acao_comercial_id.value,
            primeiro_contato_id: this.primeiro_contato_id.value,
            estrategia_id: this.estrategia_id.value,
            ativos: this.ativos.value,
            tipo_consorcio_id: this.tipo_consorcio.value,
            compra_venda: this.compra_venda.value,
            produto_id: this.produto_id.value,
            prazo_id: this.prazo_id.value,
            tipo_operacao_id: this.data.tipo=='CR'? this.tipo_operacao_credito_id.value : this.tipo_operacao_id.value,
            operador_id: this.operador_id.value,
            data_retorno_inicio: this.data_retorno_inicio.value? this._utilitesService.formatDateToString(
                new Date(this.data_retorno_inicio.value)
            ) : null,
            data_retorno_fim: this.data_retorno_fim.value? this._utilitesService.formatDateToString(
                new Date(this.data_retorno_fim.value)
            ) : null,
            parceiro_id: this.data.tipo=='CR'? this.parceiro_credito_id.value : this.parceiro_id.value,
            prazo: this.prazo_credito.value,
            operadora_id: this.operadora_credito_id.value,
            tipo_lance_id: this.tipo_lance_id.value
        });
    }

    getAllStatus() {
        this.loadingSpinnerStatus = true;

        this._statusService
            .getLista({ tipo_produto: this.data.tipo })
            .subscribe(
                (list) => {
                    this.listStatus = list.data;
                },
                (error) => {},
                () => {
                    this.loadingSpinnerStatus = false;
                    console.log("complete");
                }
            );
    }

    getAllEstrategias() {
        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getLista().subscribe(
            (list) => {
                this.listEstrategia = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerEstrategia = false;
                console.log("complete");
            }
        );
    }

    getAllAcaoComercial() {
        this.loadingSpinnerAcaoComercial = true;

        this._serviceAcaoComercial.getAll({ no_paginate: true }).subscribe(
            (list) => {
                this.listAcaoComercial = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerAcaoComercial = false;
                console.log("complete");
            }
        );
    }

    getAllStatusOdem() {
        this.loadingSpinnerStatusOrdem = true;
        this._statusOrdemService.getAll({ no_paginate: true }).subscribe(
            (list) => {
                this.listStatusOrdem = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerStatusOrdem = false;
            }
        );
    }

    selectEscritorio(event) {
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({ no_paginate: true }).subscribe(
            (list) => {
                this.listEscritorios = list.data;
            },
            (error) => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                console.log("complete");
            }
        );
    }

    getAssessores(escritorio_id = "") {
        this.loadingSpinnerAssessor = true;
        this._usuarioService
            .getLista({ tipo_usuario: "A", escritorio_id: escritorio_id })
            .subscribe(
                (resquest) => {
                    this.listAssessor = resquest.data;
                },
                (error) => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }

    getClassificacao() {
        this.loadingSpinnerClassificacao = true;
        this._primeiroService.getLista().subscribe(
            (resquest) => {
                this.listClassificacao = resquest.data;
            },
            (error) => {
                this.loadingSpinnerClassificacao = false;
            },
            () => {
                this.loadingSpinnerClassificacao = false;
            }
        );
    }

    getAllTipoConsorcio() {
        this.loadingSpinnerTipo = true;
        this._tipoService.getAll({ no_paginate: true }).subscribe(
            (list) => {
                this.listTipoConsorcio = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerTipo = false;
                console.log("complete");
            }
        );
    }

    getAllTipoLance() {
        this.loadingSpinnerTipoLance = true;
        this._tipoLanceCartaService.getAll({ no_paginate: true }).subscribe(
            (list) => {
                this.listTipoLance = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerTipoLance = false;
            }
        );
    }

    getProdutosRendaFixa(){

        this.loadingSpinnerProduto = true;        
        this._serviceProduto.getAll({inative: false}).subscribe(
            list => {
                if(this.listProduto.length == 0){
                    this.listProduto = list.data;
                }else{
                    this.listProduto.push(list.data)
                }
            },
            error => { },
            () => {
                this.loadingSpinnerProduto = false;                
            }
        );
    }

    getOperador(){
        this.loadingSpinnerOperador = true;
        this._operadorService.getAll(this.params).subscribe(
            resquest => {
                this.listOperador = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerOperador = false;
            },
            () => {             
                this.loadingSpinnerOperador = false;
            }
        );
    }
    
    getParceiro(){
        this.loadingSpinnerParceiro = true;
        this._parceiroService.getAll(this.params).subscribe(
            resquest => {
                this.listParceiro = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerParceiro = false;
            },
            () => {             
                this.loadingSpinnerParceiro = false;
            }
        );
    }

    getPrazo(){
        this.loadingSpinnerPrazo = true;
        this._prazoService.getAll(this.params).subscribe(
            resquest => {
                this.listPrazo = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerPrazo = false;
            },
            () => {
                this.loadingSpinnerPrazo = false;
            }
        );
    }

    getTipoOperacao(){
        this.loadingSpinnerTipoOperacao = true;
        this._tipoOperacaoService.getAll(this.params).subscribe(
            resquest => {
                this.listTipoOperacao = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerTipoOperacao = false;
            },
            () => {
                this.loadingSpinnerTipoOperacao = false;
            }
        );
    }
    
    getTipoOperacaoCredito(){
        this.loadingSpinnerTipoOperacaoCredito = true;
        this._tipoOperacaoCreditoService.getAll(this.params).subscribe(
            resquest => {
                this.listTipoOperacaoCredito = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerTipoOperacaoCredito = false;
            },
            () => {
                this.loadingSpinnerTipoOperacaoCredito = false;
            }
        );
    }
    
    getOperadoraCredito(){
        this.loadingSpinnerOperadoraCredito = true;
        this._operadoraCreditoService.getAll(this.params).subscribe(
            resquest => {
                this.listOperadoraCredito = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerOperadoraCredito = false;
            },
            () => {             
                this.loadingSpinnerOperadoraCredito = false;
            }
        );
    }
    
    getParceiroCredito(){
        this.loadingSpinnerParceiroCredito = true;
        this._parceiroCreditoService.getAll(this.params).subscribe(
            resquest => {
                this.listParceiroCredito = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerParceiroCredito = false;
            },
            () => {             
                this.loadingSpinnerParceiroCredito = false;
            }
        );
    }
}