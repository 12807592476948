import { Component, Input, OnInit } from '@angular/core';
import { TimelineItem } from 'ngx-horizontal-timeline';
import { ModalRelacionamentoComponent } from 'app/clientes/modal-relacionamento/modal-relacionamento.component';
import { MatDialog } from '@angular/material';
import { ClienteRelacionentoService } from 'app/clientes/services/cliente-relacionamento.service';
import { format } from "date-fns";
import { CadastroRelacionamentoClienteComponent } from '../cadastro-relacionamento-cliente/cadastro-relacionamento-cliente.component';

@Component({
    selector: "app-gestao-relacionamento-cliente",
    templateUrl: "./gestao-relacionamento-cliente.component.html",
    styleUrls: ["./gestao-relacionamento-cliente.component.scss"],
})
export class GestaoRelacionamentoClienteComponent implements OnInit {
    items: TimelineItem[];
    isHistoricoRelacionamento: boolean = false;
    paginacaoRelacionamento: any = 1;
    globalCliente: any;

    @Input()
    cliente_id: any;

    private page: number = 1;

    constructor(
        public dialog: MatDialog,
        private _cienteRelacionamentoService: ClienteRelacionentoService
    ) {}

    ngOnInit() {
        this.paginacaoRelacionamento = 0;
        this.getRelacionamentos();
    }

    paginate(p: number) {
        this.paginacaoRelacionamento = this.paginacaoRelacionamento + p;
        this.getRelacionamentos();
    }

    openRelacionamento(item): void {
        let dialogRef = this.dialog.open(ModalRelacionamentoComponent, {
            width: "400px",
            data: {
                dados: item,
                cliente_id: this.cliente_id,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
        });
    }

    setItems(item) {
        const thisGRComponent = this;
        this.items.push({
            label: this.getFormatDate(item.data_hora_operacao),
            icon:
                "material-icons " +
                (item.acao.icon ? item.acao.icon : "icon-history"),
            active: false,
            title: item.acao.nome,
            color: item.acao.cor ? item.acao.cor : "16a085",
            command() {
                thisGRComponent.openRelacionamento(item);
            },
        });
    }

    montItems(list) {
        for (const element of list) {
            this.setItems(element);
        }
    }

    getFormatDate(dateItem) {
        return format(new Date(dateItem), "dd/MM/yyyy HH:mm");
    }

    getRelacionamentos() {
        this._cienteRelacionamentoService
            .getAll({
                cliente_id: this.cliente_id,
                page: this.paginacaoRelacionamento,
            })
            .subscribe(
                (list) => {
                    console.log(list);
                    this.items = [];
                    this.montItems(list.data);
                },
                (error) => {
                    console.log(error);
                    //this.showError(error.error.message);
                }
            );
    }

    paginateTimeline(page) {
        this.paginacaoRelacionamento = this.paginacaoRelacionamento + page;
        console.log(this.paginacaoRelacionamento);
        this.getRelacionamentos();
    }

    openCadastro(){
        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent, {
            width: "1200px",
            maxHeight: '99vh',
            data: {
                cliente_id: this.cliente_id,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {            
            if(result !== "cancel"){
                this.paginacaoRelacionamento = 1;                
            }
            
            this.getRelacionamentos()
        });
    }
}
