import { 
    Component, 
    OnInit    
} from '@angular/core';

import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { NotificacaoService } from 'app/notificacao/services/notificacao.service';
import { VerNotificacaoComponent } from 'app/notificacao/ver-notificacao/ver-notificacao.component';

@Component({
  selector: 'notificacao-component',
  templateUrl: './notificacao-component.component.html',
  styleUrls: ['./notificacao-component.component.scss'],
  providers: [ NotificacaoService ]
})

export class NotificacaoComponentComponent extends BaseComponent implements OnInit {
    
    user:any;
    loadingIndicator:boolean = false;

    constructor(public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService,
        public _route: Router,
        private _notificacaoService: NotificacaoService) { 
            super(dialog, snackBar);
            this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getAll();
    }

    getAll(){
        this.loadingIndicator = true;
        this._notificacaoService.getAll({
            no_paginate:true, 
            assessor_id: this.user.id, 
            hoje: true, 
            unread:false
        }).subscribe(
            data => {
                this.rows = data.data;
                this.temp = [...this.rows];
        },
            error => {
                this.loadingIndicator = false;
            },
        () => {
            this.loadingIndicator = false;
        });    
    }

    handleNotification(){
        this._route.navigate(['/notificacao']);
    }

    onClickNotification(e) {
        if(e.type == 'click'){
            const dialogRef =  this.dialog.open(VerNotificacaoComponent, {
                data: { 
                    id: e.row.id
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.getAll();
                if (this.user != undefined && this.user != "") {
                    this._notificacaoService.getNotificacao();
                }
                
            });
        }        
    }

}
