import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

//Services
import {UtilitesService} from './utilites.service';

//Models
import {HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class AuthService {

  	constructor(private _http:HttpClient, public utilitesService: UtilitesService){
  	}

  	post(data):Observable<any>{
		return this._http.post(this.utilitesService.urlPrefix + "login", JSON.stringify(data), { headers: this.getAuthHeaders() });
  	}

  	setUser(user){
    	window.localStorage.setItem('user', JSON.stringify(user));
  	}


  	getUser(){
    	return window.localStorage.getItem('user');
  	}

  	setToken(token){
    	window.localStorage.setItem('token', token);
  	}

  //return token
  	getToken(){
    	return window.localStorage.getItem('token');
    }
      
  	public isAuth(){
		var user = this.getUser();
		if(user == null || user == undefined || user == 'null' || user == 'undefined'){
		  return false;
		}

		return true;
  	}

  	esqueceuSenha(params): Observable<any> {
		var headers = new Headers();
		headers.append('Content-Type','application/json; charset=utf-8');

		return this._http.post(this.utilitesService.urlPrefix+"password/sendlink",JSON.stringify(params), { headers: this.getAuthHeaders() })
		;
  	}

  	cadastrarUsuario(params): Observable<any> {
		var headers = new Headers();
		headers.append('Content-Type','application/json; charset=utf-8');

		return this._http.post(this.utilitesService.urlPrefix+"customers/create",JSON.stringify(params), { headers: this.getAuthHeaders() })
		;
  	}

  	atualizarUsuario(params): Observable<any> {
		var headers = new Headers();
		var token = this.getToken();

		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');
		headers.append('Authorization','Bearer '+token);

		return this._http.post(this.utilitesService.urlPrefix+"customers/edit",JSON.stringify(params), { headers: this.getAuthHeaders() })
		;
  	}

  	atualizarFoto(params): Observable<any> {
    	var headers = new Headers();
    	var token = this.getToken();

		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');
		headers.append('Authorization','Bearer '+token);

		return this._http.put(this.utilitesService.urlPrefix+"customers/photo",JSON.stringify(params), { headers: this.getAuthHeaders() })
		;
  	}

  	logout(){
    	window.localStorage.removeItem('user');
   	 	window.localStorage.removeItem('token');    	    	
  	}

    isSuperAdmin(){
        var user = JSON.parse(this.getUser());       

        if (user.role_id == 2) {
           return true;
        }

        return false;
    }

    isAdmin(){
        var user = JSON.parse(this.getUser());        
       
		if(user.role_id == 1){
			return true;
		}

        return false;
	}

	getRoleId() {
		var user = JSON.parse(this.getUser());
		return user.role_id;
	}

  	getAuthHeaders(){
		return new HttpHeaders({
			'Content-Type':'application/json; charset=utf-8',
			'Accept':'application/json',
			'Access-Control-Allow-Origin': "*",
			'Authorization':'Bearer '+this.getToken()
		});
  	}

    isAuthenticated(){
        let user:any = this.getUser();
        if(user == null || user == ""){
            return false;
        }
        return true;
    }

    getPermissoes(){
		var user = JSON.parse(this.getUser());
		var permissoes = {
			permissoes:[]
		};
		
		if (user != null && user.role != null && user.role != undefined){
			permissoes = JSON.parse(this.getUser()).role;
		}

		return permissoes;
    }

    isPermissao(permissao){
		var role = this.getPermissoes();
		var permissoes = role.permissoes;
        let p = false;        

		for (var i = 0; i < permissao.length; i++) {
			for (var k = 0; k < permissoes.length; k++){
				if (permissao[i] == permissoes[k].nome){
					p=true;
				}
			}
		}

		return p;
    }

    getAllUsers(params): Observable<any> {
		var headers = new Headers();
		var token = this.getToken();

		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');
		headers.append('Authorization','Bearer '+token);

		var paramHttp = this.utilitesService.formurlencoded(params);
		var env = "";
		if(paramHttp != null && paramHttp != ""){
			env = "?"+paramHttp;
		}

		return this._http.get(this.utilitesService.urlPrefix+"usuarios"+env, { headers: this.getAuthHeaders() })
			;
	}

    possuiPermissao(permissao){
        var role = this.getPermissoes();

		var p=false;
		if (role != null && role.permissoes != null && role.permissoes != undefined){
			for (var i = 0; i < role.permissoes.length; i++) {
				var permis = role.permissoes[i].permissoes;
				for (var k = 0; k < permis.length; k++) {
					if (permissao == permis[k].nome) {
						p = true;
					}
				}
			}
		}
        

        return p;

    }

    possuiPermissaoMenu(permissao){
        var role = this.getPermissoes();
        var p=false;
        for (var i=0; i < role.permissoes.length; i++){
			var permis = role.permissoes[i].permissoes;
            for (var k=0; k < permis.length; k++){
				if (permissao != undefined && permissao.indexOf(permis[k].nome) > -1){
					p = true;
				}
            }
        }

        return p;

    }

    possuiPermissaoPorModulo(modulo_id){
        var role = this.getPermissoes();
        var p=false;
        for (var i=0; i < role.permissoes.length; i++){
			var permis = role.permissoes[i].permissoes;
            for (var k=0; k < permis.length; k++){
                if(modulo_id == permis[k].modulo_id){
                    p=true;
                }
            }
        }

        return p;
	}
}
