import { 
    Component, 
    Input, 
    OnInit, 
    Output, 
    ViewChild,
    EventEmitter
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent
} from '@angular/material';

import { differenceInBusinessDays } from 'date-fns';

import { 
    ActionDialog, 
    BaseComponent 
} from 'app/base-module/base-component/base.component';
import { AlteraCarteiraComponent } from '../../altera-carteira/altera-carteira.component';
import { CadastroAtivoComponent } from 'app/movimentacao/cadastro-ativo/cadastro-ativo.component';

import { CarteiraService } from '../../services/carteira.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
  selector: 'lista-custodia-rv-cliente',
  templateUrl: './lista-custodia-rv-cliente.component.html',
  styleUrls: ['./lista-custodia-rv-cliente.component.scss']
})

export class ListaCustodiaRvClienteComponent extends BaseComponent implements OnInit {

    @Input() cliente_id: number;
    @Input() estrategia_id: number;    
    @Input() listaDerivativos: any;
    @Output() refreshEvent = new EventEmitter<boolean>();

    loadingListaAtivo:boolean = false;
    paramsCarteira: any = {};    
        
    enableSummary = true;
    summaryPosition = 'top';    
    user:any;
    length = 0;
    pageSize = 100;
    pageSizeOptions: number[] = [100, 200, 300, 500];        

    @ViewChild('tableAtivo', { static: false }) table: ListaCustodiaRvClienteComponent;    

    constructor( 
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _carteiraService: CarteiraService,
        private _authService: AuthService) { 
            super(dialog,snackBar);              
            this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {

        if(this.cliente_id != undefined && this.estrategia_id != undefined){
            this.getAll();     
        }else if(this.listaDerivativos != undefined){
            this.rows = this.listaDerivativos
        }
    }

     /**
     * Consulta lista de Boletas
     */
    getAll() {

        this.loadingListaAtivo = true;
        this.paramsCarteira.cliente_id = this.cliente_id;
        this.paramsCarteira.estrategia_id = this.estrategia_id;
        this.paramsCarteira.no_paginate = true;

        this._carteiraService.getCarteiraCliente(this.paramsCarteira).subscribe(
            data => {
                this.length = data.data.length;
                this.rows  = data.data;
                this.temp  = [...this.rows];                
            },
            error => {                
                this.loadingListaAtivo = false;
            },
            () => {
                this.loadingListaAtivo = false;
            }
        );
    }
  

    getQtdDias(row){
        const dataInicio = new Date(row.data_compra);
        const dataFim = new Date();        
        return differenceInBusinessDays( dataFim, dataInicio );
    }

     /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any): void{

        this.paramsCarteira.sortColumn = event.column.prop;
        this.paramsCarteira.sortOrder = event.newValue;

        this.getAll();
    }

        /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.paramsCarteira.page = (event.pageIndex+1);
        this.paramsCarteira.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Modal de edição
    */
    alterarCotacao(ativo_id): void {
        let dialogRef = this.dialog.open(CadastroAtivoComponent, {
            width: '400px',
            data: { id: ativo_id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Cotação Atual do Ativo alterado!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar alterar o valor da cotação atual do ativo");
            }
        });
    }

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id): void {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Ação da Carteira do Cliente",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();
                    
                    lista.loadingListaAtivo = true;
                    lista._carteiraService.delete(id).subscribe(
                        data => {
                            lista.loadingListaAtivo = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                            lista.refreshEvent.emit(true);
                        },
                        error => {
                            lista.closeLoading();                            
                            lista.showError(error.message);

                        },
                        () => {
                            lista.loadingListaAtivo = false;
                        }
                    );
                }
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterarAtivo(carteira_id): void {

        let dialogRef = this.dialog.open(AlteraCarteiraComponent, {
            width: '400px',
            maxHeight: '99vh',
            data: { id: carteira_id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Carteira atualizada com Sucesso!");
                this.getAll();
                this.refreshEvent.emit(true);
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registro");
            }
        });
    }    

    getValorFinanceiro(row){
        if(row.preco_executado > 0){
            return parseFloat(row.qtd) * parseFloat(row.preco_executado);
        }else{
            return row.vlr_financeiro;
        }        
    }

    getCotacaoAtual(row){
        return parseFloat(row.qtd) * parseFloat(row.cotacao_atual);
    }

}
