import {LOCALE_ID, NgModule} from '@angular/core';
import {
    CommonModule, 
    HashLocationStrategy, 
    LocationStrategy, 
    registerLocaleData
} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatGridListModule, 
    MatIconModule,
    MatInputModule, 
    MatListModule, 
    MatMenuModule, 
    MatOptionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule, 
    MatSelectModule, 
    MatSidenavModule, 
    MatSnackBarModule,
    MatToolbarModule, 
    MatTooltipModule,
    MatDatepickerModule
} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";
import {NgxMaskModule} from "ngx-mask";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {BaseModule} from "../base-module/base.module";
import {AuthService} from "../base-module/base-service/auth.service";
import {UsuariosListaComponent} from "./lista/usuarios-lista.component";
import {UsuarioRoutesModule} from "./usuarios.routing";
import {UsuariosService} from "./services/usuarios.service";
import {UsuariosCadastrarComponent} from "./usuarios-cadastrar/usuarios-cadastrar.component";
import {RolesService} from "./services/roles.service";
import {RoleListaComponent} from "./role-lista/role-lista.component";
import {RoleCadastrarComponent} from "./role-cadastrar/role-cadastrar.component";
import localePt from "@angular/common/locales/pt";
import {
    PERFECT_SCROLLBAR_CONFIG, 
    PerfectScrollbarConfigInterface, 
    PerfectScrollbarModule
} from "ngx-perfect-scrollbar";
import {PermissaoComponent} from "./permissoes/permissao.component";
import { FiltrarUsuariosComponent } from './filtrar-usuarios/filtrar-usuarios.component';

registerLocaleData(localePt, 'pt');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true,
};
@NgModule({
    imports: [
        CommonModule,
        UsuarioRoutesModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatOptionModule,
        MatSelectModule,
        MatSidenavModule,
        MatMenuModule,
        MatAutocompleteModule,
        PerfectScrollbarModule,
        BaseModule,
        NgxMaskModule.forRoot({}),
        MatDatepickerModule
        
        //AppModule
    ],
    declarations: [
        UsuariosListaComponent,
        UsuariosCadastrarComponent,
        RoleListaComponent,
        RoleCadastrarComponent,        
        PermissaoComponent,
        FiltrarUsuariosComponent
    ],
    entryComponents: [
        UsuariosListaComponent,
        UsuariosCadastrarComponent,
        RoleListaComponent,
        RoleCadastrarComponent,        
        PermissaoComponent,
        FiltrarUsuariosComponent
    ],
    providers: [
        AuthService,
        UsuariosService,
        RolesService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        }, {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        {
            provide: LOCALE_ID, 
            useValue: 'pt'
        },
    ],
    exports: [
    ]
})
export class UsuariosModule {}