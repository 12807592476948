
import { 
    Component, 
    Inject, 
    OnInit
} from '@angular/core';

import { 
    MatDialog, 
    MatDialogRef, 
    MatSnackBar, 
    MAT_DIALOG_DATA 
} from '@angular/material';

import { FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { CustodiaService } from 'app/movimentacao/services/custodia.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
import { BoletaPendenteService } from 'app/renda-variavel/services/boleta-pendente.service';

interface BoletaPendente {
    id:number;
    cliente:any;
    valor:number;
    qtd:number;
    ativo_id:number;
    cliente_id:number;
    estrategia_id:number;
    ativo: any;
    data_refencia: Date;
}

export interface DialogData {
    boleta: BoletaPendente;
}

@Component({
  selector: 'app-cadastro-boleta-pendente',
  templateUrl: './cadastro-boleta-pendente.component.html',
  styleUrls: ['./cadastro-boleta-pendente.component.scss'],
  providers: [ CustodiaService, BoletaPendenteService ] 
})

export class CadastroBoletaPendenteComponent extends BaseComponent implements OnInit {

    selection = new SelectionModel(true, []);
    displayedColumns = ['select', 'created_at', 'ativo', 'qtd_executado', 'tipo', 'vlr_financeiro'];
    columnsToDisplay: string[] = this.displayedColumns.slice();

    total_financeiro = new FormControl('', [Validators.required]);
    estrategia_id = new FormControl('', [Validators.required]);

    model:any = {
        cliente_id:"",
        valor_financeiro:"",
        estrategia_id:"",
    };

    loadingSpinnerEstrategia:boolean = false;
    listEstrategia:any[]=[];

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,    
                private _service: CustodiaService,            
                private _serviceEstrategia: EstrategiaService,
                private _serviceBoletaPendnete: BoletaPendenteService,
                public dialogRef: MatDialogRef<CadastroBoletaPendenteComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
        super(dialog, snackBar);
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    ngOnInit() {    
        this.getAll();
        this.getAllEstrategias();
        this.estrategia_id.setValue(this.data.boleta.estrategia_id);        
    }

    getAll() {      
        
        this.openLoading();  
        this.columnsToDisplay = this.displayedColumns.slice();        
        this.params.ativo_id = this.data.boleta.ativo_id;
        this.params.cliente_id = this.data.boleta.cliente_id;        
        this._service.getAll(this.params).subscribe(
            data => {                
                this.rows = data.data;
                this.closeLoading();
            },
            error => {                               
                this.closeLoading();
            },
            () => {   
                this.closeLoading();                
            }
        );
    }

    gerar(){
        this.openLoading();  

        const boleta = {
            boleta_pendente_id: this.data.boleta.id,
            cliente_id: this.data.boleta.cliente_id,
            ativo_id: this.data.boleta.ativo_id,
            sigla_ativo: this.data.boleta.ativo.sigla,
            estrategia_id: this.estrategia_id.value,
            vlr_financeiro: this.total_financeiro.value
        }

        this._serviceBoletaPendnete.create(boleta).subscribe(
            data => {
                this.closeLoading();
                this.showSuccess('Boleta Cadastrada com Sucesso!');
                this.getAll();
                this.dialogRef.close(data);
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {                    
                this.closeLoading();
            }
        );
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getLista().subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
            }
        );
    }

     /** Whether the number of selected elements matches the total number of rows. */
     isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.rows.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.rows.forEach(row => this.selection.select(row.id));
    }

    addTogleSelection(row){        
        this.selection.toggle(row);                
        let valorTotal = 0;                
        this.selection.selected.map( ( indice ) => {                
            valorTotal += parseFloat(this.rows[indice].vlr_financeiro);
        });
        this.total_financeiro.setValue(valorTotal);
    }
    
    isInvalidForm(){
        if (!this.total_financeiro.valid){
            return true;
        }

        if (!this.estrategia_id.valid){
            return true;
        }

        return false;
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    getErrorValorMessage() {
        return this.total_financeiro.hasError('required') ? 'Valor Movimentado é um campo obrigatório' : '';
    }

    getErrorEstrategiaMessage() {
        return this.estrategia_id.hasError('required') ? 'Estratégia é um campo obrigatório' : '';
    }

}

