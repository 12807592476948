import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaNoticiasComponent } from './lista-noticias/lista-noticias.component';
import { ListaNoticiaComponent } from './lista-noticia/lista-noticia.component';
import { CadastroNoticiaComponent } from './cadastro-noticia/cadastro-noticia.component';
import { UploadMidiaComponent } from './upload-midia/upload-midia.component';
import { CentralNoticiasRoutesModule } from './central-noticias.routing';
import { PreviewNoticiaComponent } from './preview-noticia/preview-noticia.component';
import { SecuredImageComponent } from './secured-image/secured-image.component';
import { QuillModule } from 'ngx-quill';

import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule
} from '@angular/material';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { ListaNoticiaModalComponent } from './lista-noticia-modal/lista-noticia-modal.component';
import { SecuredDownloadComponent } from './secured-download/secured-download.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SecuredVideoComponent } from './secured-video/secured-video.component';


@NgModule({
    imports: [
        CommonModule,
        CentralNoticiasRoutesModule,
        MatIconModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FileUploadModule,
        QuillModule.forRoot(),
        MatDialogModule,
        InfiniteScrollModule
    ],
    declarations: [
        ListaNoticiasComponent,
        ListaNoticiaComponent,
        CadastroNoticiaComponent,
        UploadMidiaComponent,
        PreviewNoticiaComponent,
        SecuredImageComponent,
        ListaNoticiaModalComponent,
        SecuredDownloadComponent,
        SecuredVideoComponent
    ],
    entryComponents: [
        UploadMidiaComponent,
        ListaNoticiaModalComponent
    ],
    exports: [ListaNoticiaModalComponent]
})
export class CentralNoticiasModule { }
