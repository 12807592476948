import {Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class GeradorOrdemBoletaService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Basket por id
     * @param id ID da Basket
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/gerador-ordens/boleta/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Basket
     * @param id ID da Basket
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/gerador-ordens/boleta"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete Basket
     * @param params Json do Basket
     */
    delete(params): Observable<any> {
        return this._http.post(this.utilitesService.urlPrefix + "renda-variavel/gerador-ordens/boleta/delete", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Basket
     * @param params Json Basket
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "renda-variavel/gerador-ordens/boleta", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * AtualizaBasket
     * @param params Json Basket
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "renda-variavel/gerador-ordens/boleta/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }    

}
