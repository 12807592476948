import {
    Component, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from "../../base-module/base-component/base.component";

export interface DialogData {
    cliente_aceitou: Boolean;
    observacao: string;
    data_resposta: Date;
    valor: Number;
    assunto: string;
    type: string | null;
    historico_relacionamento: Array<HistoricoRelacionamento> | null;
}

export interface HistoricoRelacionamento {
    observacao: string;
}

@Component({
    selector: 'resposta-atividade',
    templateUrl: './resposta-atividade.component.html',
    styleUrls: ['./resposta-atividade.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class RespostaAtividadeComponent extends BaseComponent {
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<RespostaAtividadeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);      
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    getObservacao(){
        if(this.data.type == 'HR' && this.data.historico_relacionamento != null){
            return this.data.historico_relacionamento[0].observacao;
        }

        return this.data.observacao 
    }
}
