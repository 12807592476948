import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { FormControl } from '@angular/forms';
import { BasketService } from '../services/basket.service';
import { StatusOperacaoService } from 'app/clientes/services/status-operacao.service';

export interface DialogData {
    id: number;
} 

@Component({
    selector: 'app-upload-historico-ordens',
    templateUrl: './upload-historico-ordens.component.html',
    styleUrls: ['./upload-historico-ordens.component.scss']
})

export class UploadHistoricoOrdensComponent extends BaseComponent implements OnInit {

    basket:any = {
        id:''
    };

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;

    tipo_historico  = new FormControl('');

    model:any = {
        tipo_basket_id:"",
    }
    
    params:any ={}
    listTipo:any[]=[];

    loadingSpinnerTipo:boolean;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UploadHistoricoOrdensComponent>,
        public service: StatusOperacaoService,
        public utiliesService: UtilitesService,
        public authService: AuthService,
        private changeDetector: ChangeDetectorRef,            
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {

        super(dialog, snackBar);
        
        this.nomeFile = null;        

        this.uploader = new FileUploader({
            url: this.utiliesService.urlPrefix + 'renda-variavel/historico-ordens/uploads',
            isHTML5: true,
            authToken: "Bearer " + this.authService.getToken(),           
        });

        this.uploader.onAfterAddingFile = (file) => { 
            file.withCredentials = false; 
        };
        
        this.uploader.onProgressItem = ( fileItem: FileItem, progress: any ) => { 
            this.changeDetector.detectChanges();
        };
        
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        
            this.closeLoading();
            
            if (status == 200){
                var info = JSON.parse(response);
                
                this.showSuccess(info.message);
                
                this.dialogRef.close(info);
            }else{
                var info = JSON.parse(response);
                this.showError(info.message);                
            }
        
        };
    
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    uploadFile(event) {    //line 1

        //console.log(event);
        let elem = event.target;  //line 2
        //console.log(elem);
        if (elem.files.length > 0) {     //line 3
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }


    isNomeFile(){
        return (this.nomeFile != null);
    } 

    salvar(){
        this.openLoading();              
        
        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('tipo' , this.tipo_historico.value);
        };
        this.uploader.uploadAll();          

    }
   
    startUpload() {        
        this.salvar();
    }

}

