import { Routes } from '@angular/router';

import { AuthGuardService } from "../base-module/base-service/auth-guard.service";
import { CadastroBoletaConsorcioComponent } from './cadastro-boleta-consorcio/cadastro-boleta-consorcio.component';
import { DashboardConsorcioComponent } from './dashboard-consorcio/dashboard-consorcio.component';
import { ListaBoletaCosorcioComponent } from './lista-boleta-consorcio/lista-boleta-consorcio.component';
import { ListaCaracteristicaProdutoCosorcioComponent } from './lista-caracteristica-produto-consorcio/lista-caracteristica-produto-consorcio.component';
import { ListaClienteCarteiraConsorcioComponent } from './lista-cliente-carteira-consorcio/lista-cliente-carteira-consorcio.component';
import { ListaEmpresaCosorcioComponent } from './lista-empresa-consorcio/lista-empresa-consorcio.component';
import { ListaTipoCosorcioComponent } from './lista-tipo-consorcio/lista-tipo-consorcio.component';
import { VisualizarBoletaConsorcioComponent } from './visualizar-boleta-consorcio/visualizar-boleta-consorcio.component';

export const ConsorcioRoutes: Routes = [
    {

        path: '',
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'lista-tipo-consorcio',
                component: ListaTipoCosorcioComponent
            },
            {
                path: 'lista-empresa-consorcio',
                component: ListaEmpresaCosorcioComponent
            },
            {
                path: 'lista-caracteristica-produto-consorcio',
                component: ListaCaracteristicaProdutoCosorcioComponent
            },
            {
                path: 'lista-boleta-consorcio',
                component: ListaBoletaCosorcioComponent
            },
            {
                path: 'visualizar-boleta-consorcio/:id',
                component: VisualizarBoletaConsorcioComponent,
                //component: CadastroBoletaConsorcioComponent,
                //data: { view: true }
            },
            {
                path: 'lista-cliente-carteira-consorcio',
                component: ListaClienteCarteiraConsorcioComponent
            },
            {
                path: 'dashboard',
                component: DashboardConsorcioComponent
            },
            {
                path: 'cadastrar-boleta-consorcio',
                component: CadastroBoletaConsorcioComponent,
                data: { view: false }
            },
            {
                path: 'editar-boleta-consorcio/:id',
                component: CadastroBoletaConsorcioComponent,
                data: { view: false }
            },
        ]
    }
];
