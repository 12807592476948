import {EventEmitter, Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class BoletaCoeService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Ação de Relacionamento por id
     * @param id ID da Ação de Relacionamento
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "rendafixa/boleta-coe/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Ação de Relacionamento
     * @param id ID da Ação de Relacionamento
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "rendafixa/boleta-coe"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Ação de Relacionamento
     * @param id ID da Ação de Relacionamento
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "rendafixa/boleta-coe/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Ação de Relacionamento
     * @param params Json Ação de Relacionamento
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "rendafixa/boleta-coe", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Ação de Relacionamento
     * @param params Json Ação de Relacionamento
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "rendafixa/boleta-coe/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "rendafixa/boleta-coe/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }

    upload(file): Observable<any>{
        const formData = new FormData();
        formData.append('arquivo', file);
        return this._http.post(this.utilitesService.urlPrefix + 'rendafixa/boleta-coe/upload', formData, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Boleta Unica do Cliente
     * @param params Json Boleta Unica do Cliente
     */
    atualizaStatus(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "rendafixa/boleta-coe/atualiza-status", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

}
