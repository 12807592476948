import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators}       from '@angular/forms';
import {BaseComponent, LoadingTeste}    from "../../base-module/base-component/base.component";
import {AuthService}                    from "../../base-module/base-service/auth.service";
import { fuseAnimations }               from '@fuse/animations';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { CustodiaService } from '../services/custodia.service';
import { ImportacaoArquivoService } from '../services/importacao-arquivo.service';

export interface DialogData {
    id: any;
}

@Component({
    selector: 'app-excluir-custodia',
    templateUrl: './excluir-custodia.component.html',
    styleUrls: ['./excluir-custodia.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
  })

export class ExcluirCustodiaComponent extends BaseComponent {

    importacao_id   = new FormControl('', [Validators.required]);
    
    usuarioArquivo: string;
    nomeArquivo: string;    

    paramsStatus: any ={}

    loadingLocal: any;
    loadingSpinnerArquivos:boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ExcluirCustodiaComponent, { static: false }) table: ExcluirCustodiaComponent;

    constructor(public _service: CustodiaService,
                public _arquivoService:ImportacaoArquivoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<ExcluirCustodiaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _statusService:StatusMovimentacaoService )
    {
        super(dialog, snackBar);
               
        this.getLastArchive();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    excluir(): void {
        this.openLoading();

        this._arquivoService.delete(this.importacao_id.value).subscribe(
            data => {
                this.closeLoading();
                this.dialogRef.close(data);
                this.showSuccess(data.message);                                                  
            },
            error => {                    
                this.closeLoading();
                this.showError(error.error.message);

            },
            () => {
                this.closeLoading();
            }
        );

    }

    isInvalidForm(): boolean{
        if (!this.importacao_id.valid){
            return true;
        }

        return false;
    }

    getLastArchive(): void {
        this.loadingSpinnerArquivos = true;
        this._arquivoService.getLastId().subscribe(
            data => {                
                this.nomeArquivo    = data.data.nome_arquivo;
                this.usuarioArquivo = data.data.nome_arquivo;
                this.importacao_id.setValue(data.data.id);
                this.loadingSpinnerArquivos = false;
            },
            error => {
                this.showError(error.error.message);
                this.loadingSpinnerArquivos = false;
            },
            () => {                
                console.log("complete");
            }
        );
        
    }

}
