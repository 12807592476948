import { 
    Component, 
    OnInit, 
    Input 
} from '@angular/core';

import { FormControl } from '@angular/forms';

import { 
    MatDialog, 
    MatSnackBar 
} from '@angular/material';

import { BaseComponent } from 'app/base-module/base-component/base.component';

import { AuthService } from 'app/base-module/base-service/auth.service';

import { Estrategia} from 'app/renda-variavel/services/estrategia.service';
import { PainelService } from 'app/renda-variavel/services/painel.service';
import { CadastraItemPainelComponent } from '../cadastra-item-painel/cadastra-item-painel.component';

interface EstrategiaPainel extends Estrategia{
    total_trades_abertos:number;
    total_trades_fechados:number;
    total_volume_efetivado:number;
}

@Component({
  selector: 'painel-renda-variavel',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
  providers: [ PainelService ]
})

export class ListaComponent extends BaseComponent implements OnInit {

    @Input() cliente_id: number;

    date = new Date();

    primeiroDia = new Date(this.date.getFullYear(), 0, 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

    data_operacao_inicio = new FormControl(this.primeiroDia);
    data_operacao_fim = new FormControl(this.ultimoDia);  
    
    user:any;
    data_atualizacao: any;
    listEstrategia: EstrategiaPainel[]= [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,        
        private _authService: AuthService,
        private _estrategiaPainelService: PainelService
    ) { 
        super(dialog,snackBar);   
        this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {        
        this.getAllEstrategiasPosicaoCliente();
    }

    /**
     * Modal de cadastro de Custódia
     */
     openCadastro(): void {

        let dialogRef = this.dialog.open(CadastraItemPainelComponent, {
            width: '600px',
            maxHeight: '99vh'     
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Painel atualizada com Sucesso!");
                this.getAllEstrategiasPosicaoCliente();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registro");
            }
        });
    }

    getAllEstrategiasPosicaoCliente() {
        this.openLoading();      
        const params = {
            cliente_id :this.cliente_id, 
            is_visible_painel: true            
        }

        this._estrategiaPainelService
        .getAllEstrategias(params)
        .subscribe(
            list => {
                this.listEstrategia = list.data;
            },
            error => {
                this.closeLoading();
            },
            () => { 
                this.closeLoading();
            }
        );
    }

    refreshItem(event){
        if(event){
            this.getAllEstrategiasPosicaoCliente();
        }        
    }
}
