import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { ClienteIndicacaoService } from '../services/cliente-indicacao.service';

export interface DialogData {
    id: any;
    cliente_id: any;
}

@Component({
    selector: 'app-cadastro-cliente-indicacao',
    templateUrl: './cadastro-cliente-indicacao.component.html',
    styleUrls: ['./cadastro-cliente-indicacao.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
    ]
})

export class CadastroClienteIndicacaoComponent extends BaseComponent {

    nome = new FormControl('', [Validators.required]);
    telefone = new FormControl('', [Validators.required]);
    observacao  = new FormControl('', [Validators.required]);
    valor_pl = new FormControl(0.00, [Validators.required]);

    model:any = {
        nome: "",
        telefone: "",
        observacao: "",
        valor_pl:0.00,
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerTipoIndicacao: boolean = false;
    listaTipoIndicacao: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: ClienteIndicacaoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClienteIndicacaoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData)
    {
        super(dialog, snackBar);
        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){

        if (!this.nome.valid){
            return true;
        }
        
        if (!this.telefone.valid) {
            return true;
        }

        return false;
    }

    salvar(){ 
        this.openLoading();
        
        this.model = {
            nome: this.nome.value,
            observacao: this.observacao.value,
            telefone: this.telefone.value,
            cliente_id: this.data.cliente_id,
            valor_pl: this.valor_pl.value,
        };
        
        if(this.data.id == null){
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.nome.setValue(item.data.nome);
                this.telefone.setValue(item.data.telefone);
                this.observacao.setValue(item.data.observacao);
                this.valor_pl.setValue(item.data.valor_pl);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }
}

