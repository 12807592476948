import { Component, ViewEncapsulation,Inject} from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent, LoadingTeste }    from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { BoletaValoresService } from '../services/boleta-valores.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-altera-valor-boleta',
    templateUrl: './altera-valor-boleta.component.html',
    styleUrls: ['./altera-valor-boleta.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ BoletaValoresService]
})


export class AlteraValorBoletaComponent extends BaseComponent {

    valor   = new FormControl('', [Validators.required]);

    model:any = {
        valor:"",
    };

    valor_anterior:number;
    loadingLocal:any;
    user:any;

    constructor(public service: BoletaValoresService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public _authService: AuthService,
                public dialogRef: MatDialogRef<AlteraValorBoletaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);
        this.user = JSON.parse(this._authService.getUser());

        if(this.data != null && this.data != undefined){
            this.getById();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.valor.valid){
            return true;
        }

        return false;
    }

    validaFormulario(){
        if(this.user.role_id != 2 && this.user.role_id != 4 && this.user.role_id != 7 ){
            if(parseFloat(this.valor.value) > this.valor_anterior ){
                this.showError('Não é possivel aumentar o valor. Se deseja aumentar o valor da boleta, insira a informação que deseja no campo VALOR NOVO');
                return false;
            }
        }
        this.salvar();
    }

    salvar(){

        this.openLoading();
        this.model.valor = this.valor.value;
        this.service.update(this.data.id, this.model).subscribe(
            data => {
                this.closeLoading();
                this.dialogRef.close(data);
            },
            error => {
                this.closeLoading();
                this.showError(error.message);
            },
            () => {                    
                this.closeLoading();
            }
        );

    }

    getErrorValorMessage() {
        return this.valor.hasError('required') ? 'Valor é um campo obrigatório' : '';
    }
    
    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item=>{
            this.model = item.data;
            this.valor.setValue(item.data.valor);
            this.valor_anterior = parseFloat(item.data.valor);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

}
