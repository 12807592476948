import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA}          from '@angular/material';
import { FormControl, Validators}       from '@angular/forms';
import {BaseComponent, LoadingTeste}    from "../../base-module/base-component/base.component";
import {AuthService}                    from "../../base-module/base-service/auth.service";
import { fuseAnimations }               from '@fuse/animations';
import { CarteiraService } from '../services/carteira.service';
import { Observable } from 'rxjs';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';

export interface DialogData {
    id: number;
    cliente_id: number;
}

@Component({
    selector: 'app-altera-carteira',
    templateUrl: './altera-carteira.component.html',
    styleUrls: ['./altera-carteira.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers:[ AtivoService, EstrategiaService ]
})


export class AlteraCarteiraComponent extends BaseComponent {

    ativo           = new FormControl('', [Validators.required]);
    qtd             = new FormControl('', [Validators.required]);
    vlr_financeiro  = new FormControl('', [Validators.required]);
    preco_executado = new FormControl('', []);
    ativo_bloqueado = new FormControl('', []);
    tipo_ativo      = new FormControl('', []);
    ativo_casado    = new FormControl('', []);
    migrado         = new FormControl();
    estrategia_id = new FormControl();

    model:any = {
        nome:"",
    };

    loadingLocal:any;
    listEstrategia:any[]=[];
    novoCadastro:boolean = false;
    filteredOptionsAtivo: Observable<string[]>;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerEstrategia: boolean = false;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(AlteraCarteiraComponent, { static: false }) table: AlteraCarteiraComponent;

    constructor(public service: CarteiraService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<AlteraCarteiraComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _ativoService: AtivoService,
                private _serviceEstrategia: EstrategiaService  )
    {
        super(dialog, snackBar);
        this.getAllEstrategias();
        if (this.data.id != null && this.data.id != undefined){
            this.getById();
        }else{
            this.novoCadastro = true;
        }

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;                    
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );
    }

    montaValor(){        
        if (this.preco_executado.value != '' && this.qtd.value != ''){
            this.vlr_financeiro.setValue( parseFloat(this.preco_executado.value) * parseFloat(this.qtd.value));
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){        

        if (!this.qtd.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();        
        
        this.model.vlr_financeiro   = this.vlr_financeiro.value;
        this.model.qtd              = this.qtd.value;
        this.model.preco_executado  = this.preco_executado.value;
        this.model.ativo_bloqueado  = this.ativo_bloqueado.value;
        this.model.ativo_casado     = this.ativo_casado.value;
        this.model.tipo_ativo       = this.tipo_ativo.value;
        this.model.migrado = this.migrado.value;
        this.model.estrategia_id = this.estrategia_id.value;

        if ( this.data.id == null ){
            const ativo_id = this.ativo.value;
            
            this.model.ativo_id         = ativo_id.id;
            this.model.cliente_id       = this.data.cliente_id;
            this.model.ativo_casado     = false;
            this.model.ativo_bloqueado  = false;

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );

        }else{            

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorQtdMessage() {
        return this.qtd.hasError('required') ? 'Quantidade é um campo obrigatório' : '';
    }

    getErrorFinanceiroMessage() {
        return this.vlr_financeiro.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }  

    getById(){
        this.openLoading();        
        this.service.getById(this.data.id).subscribe(
         item => {
            this.model = item.data;
            this.ativo.setValue(item.data.ativo.sigla);
            this.qtd.setValue(item.data.qtd);
            this.vlr_financeiro.setValue(item.data.vlr_financeiro);
            this.preco_executado.setValue(item.data.preco_executado);
            this.ativo_bloqueado.setValue(item.data.ativo_bloqueado);
            this.ativo_casado.setValue(item.data.ativo_casado);
            this.tipo_ativo.setValue(item.data.tipo_ativo);
            this.estrategia_id.setValue(item.data.estrategia_id);
            this.migrado.setValue( item.data.migrado.toString() );
        },
         error => {
            this.closeLoading();
         },
        () => {
            this.closeLoading();
        });
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getAll({}).subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
            }
        );
    }

}
