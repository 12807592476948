import {Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class BoletaService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Boleta por id
     * @param id ID da Boleta do Cliente
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Boleta por cliente
     * @param id ID da Boleta do Cliente
     */
    getByClienteCount(cliente_id, estrategia_id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta/cliente/" + cliente_id+"/" + estrategia_id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Boleta Abertas por Cliente
     * @param id ID da Boleta do Cliente
     */
    getBoletaAberta(cliente_id, estrategia_id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta/aberta/" + cliente_id+"/" + estrategia_id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Boleta do Cliente
     * @param id ID da Boleta do Cliente
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Boleta do Cliente
     * @param id ID da Boleta do Cliente
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "movimentacao/boleta/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete All Boleta do Cliente
     * @param params Json Boleta do Cliente
     */
    deleteAll(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/boleta/deleteAll", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Boleta do Cliente
     * @param params Json AporBoletate do Cliente
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/boleta", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Atualiza Boleta do Cliente
     * @param params Json Boleta do Cliente
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "movimentacao/boleta/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
    
    /**
     * Atualiza Boleta do Cliente
     * @param params Json Boleta do Cliente
     */
    atualizaStatus(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/boleta/atualiza-status", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Bloqueia Boletas 
     * @param params Json Boleta do Cliente
     */
    bloqueio(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/boleta/bloqueio", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
    
    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }

    /**
     * Boleta por id
     * @param id ID da Boleta do Cliente
     */
    getValoresById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + `movimentacao/boleta/${id}/valores`, { headers: this.authService.getAuthHeaders() });
    }
	
}
