import {Injectable}                 from "@angular/core";
import { UtilitesService }          from "../../base-module/base-service/utilites.service";
import { AuthService }              from "../../base-module/base-service/auth.service";
import { Observable }               from "rxjs";
import {HttpClient}                 from "@angular/common/http";

@Injectable()
export class HistoricoMovimentacaoService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Histórico de Movimentações por id
     * @param id ID da Histórico de Movimentações
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/historico/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Histórico de Movimentações
     * @param id ID da ApHistórico de Movimentações
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/historico"+env, { headers: this.authService.getAuthHeaders() });
    }
     
	
}
