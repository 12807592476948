import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRadioModule, 
    MatMenuModule, 
    MatChipsModule, 
    MatExpansionModule, 
    MatFormFieldModule, 
    MatRippleModule, 
    MatSortModule, 
    MatTableModule,
    MatSlideToggleModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule}   from '@angular/forms';
import {FlexLayoutModule}                   from '@angular/flex-layout';
import {NgxDatatableModule}                 from '@swimlane/ngx-datatable';
import {NgxMaskModule}                      from "ngx-mask";
import {BaseModule}                         from "../base-module/base.module";
import { CurrencyMaskModule }               from "ng2-currency-mask";
import { FuseWidgetModule }                 from '@fuse/components/widget/widget.module';
import { NgxChartsModule }                  from '@swimlane/ngx-charts';
import { ChartsModule }                     from 'ng2-charts';

/** Components */
import { 
    CadastroBoletaComponent, 
    ActionDialogBoleta 
}from './cadastro-boleta/cadastro-boleta.component';

import { ListaBoletaComponent } from './lista-boleta/lista-boleta.component';
import { ListaAporteComponent } from './lista-aporte/lista-aporte.component';
import { CadastroAporteComponent } from './cadastro-aporte/cadastro-aporte.component';
import { ListaHistoricoComponent }from './lista-historico/lista-historico.component';
import { MovimentacaoRoutesModule } from './movimentacao.routing';
import { VisualizaHistoricoComponent } from './visualiza-historico/visualiza-historico.component';
import { ListaCustodiaComponent } from './lista-custodia/lista-custodia.component';
import { CadastroCustodiaComponent } from './cadastro-custodia/cadastro-custodia.component';
import { FiltrarBoletaComponent } from './filtrar-boleta/filtrar-boleta.component';
import { FiltrarAporteComponent } from './filtrar-aporte/filtrar-aporte.component';
import { CadastroAtivoComponent } from './cadastro-ativo/cadastro-ativo.component';
import { AtualizaStatusBoletaComponent } from './atualiza-status-boleta/atualiza-status-boleta.component';
import { FiltrarCustodiaComponent } from './filtrar-custodia/filtrar-custodia.component';
import { ListaHistoricoCustodiaComponent } from './lista-historico-custodia/lista-historico-custodia';

/** Services */
import { AporteService } from './services/aporte.service';
import { BoletaService } from './services/boleta.service';
import { HistoricoMovimentacaoService } from './services/historico-movimentacao.service';
import { ConfirmaBoletaComponent } from './confirma-boleta/confirma-boleta.component';
import { ConfirmaAporteComponent } from './confirma-aporte/confirma-aporte.component';
import { CustodiaService} from './services/custodia.service';
import { ImportacaoArquivoService } from './services/importacao-arquivo.service';

import {registerLocaleData} from '@angular/common';
import br from '@angular/common/locales/br';
import { FileUploadModule } from 'ng2-file-upload';
import { ExcluirCustodiaComponent } from './excluir-custodia/excluir-custodia.component';
import { FiltroCustodiaConsolidadaComponent } from './filtro-custodia-consolidada/filtro-custodia-consolidada.component';
import { ListaBoletaUnicaComponent } from './lista-boleta-unica/lista-boleta-unica.component';
import { BoletaUnicaService } from './services/boleta-unica.service';
import { CadastroBoletaUnicaComponent } from './cadastro-boleta-unica/cadastro-boleta-unica.component';
import { StatusOrdemService } from 'app/renda-variavel/services/status-ordem.service';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { AcaoComercialService } from 'app/acao-comercial/services/acao-comercial.service';
import { ParametroService } from 'app/parametros/services/parametro.service';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { CarteiraService } from 'app/clientes/services/carteira.service';
import { RendaVariavelModule } from 'app/renda-variavel/renda-variavel.module';
import { AlteraValorBoletaComponent } from './altera-valor-boleta/altera-valor-boleta.component';
import { ListaCustodiaRvSetorComponent } from './lista-custodia-rv-setor/lista-custodia-rv-setor.component';
import { TipoConsorcioService } from "../consorcio/services/tipo-consorcio.service";

registerLocaleData(br, 'pt-BR');

@NgModule({
    imports: [
        CommonModule,
        MovimentacaoRoutesModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatTabsModule,
        MatRadioModule,
        MatMenuModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatRippleModule,
        MatSortModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgxMaskModule.forRoot(),
        NgxDatatableModule,
        BaseModule,
        CurrencyMaskModule,
        MatSlideToggleModule,
        FileUploadModule,
        FuseWidgetModule,
        NgxChartsModule,
        ChartsModule,
        RendaVariavelModule,
    ],
    declarations: [
        ListaBoletaComponent,
        CadastroBoletaComponent,
        ListaAporteComponent,
        CadastroAporteComponent,
        ListaHistoricoComponent,
        ConfirmaBoletaComponent,
        ConfirmaAporteComponent,
        VisualizaHistoricoComponent,
        ListaCustodiaComponent,
        CadastroCustodiaComponent,
        FiltrarBoletaComponent,
        FiltrarAporteComponent,
        CadastroAtivoComponent,
        AtualizaStatusBoletaComponent,
        FiltrarCustodiaComponent,
        ExcluirCustodiaComponent,
        ListaHistoricoCustodiaComponent,
        FiltroCustodiaConsolidadaComponent,
        ListaBoletaUnicaComponent,
        CadastroBoletaUnicaComponent,
        ActionDialogBoleta,
        AlteraValorBoletaComponent,
        ListaCustodiaRvSetorComponent,
    ],
    entryComponents: [
        ConfirmaBoletaComponent,
        ConfirmaAporteComponent,
        VisualizaHistoricoComponent,
        CadastroCustodiaComponent,
        FiltrarBoletaComponent,
        FiltrarAporteComponent,
        CadastroAtivoComponent,
        AtualizaStatusBoletaComponent,
        FiltrarCustodiaComponent,
        ExcluirCustodiaComponent,
        FiltroCustodiaConsolidadaComponent,
        ActionDialogBoleta,
        AlteraValorBoletaComponent,
    ],
    providers: [
        AporteService,
        BoletaService,
        HistoricoMovimentacaoService,
        CustodiaService,
        ImportacaoArquivoService,
        BoletaUnicaService,
        StatusOrdemService,
        StatusMovimentacaoService,
        ClienteService,
        AcaoComercialService,
        ParametroService,
        AtivoService,
        CarteiraService,
        TipoConsorcioService,
    ],
    exports: [
        CommonModule,
        ActionDialogBoleta,
        FiltrarBoletaComponent,
        CadastroAtivoComponent,
    ],
})
export class MovimentacaoModule {}
