import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { ListaNoticiaModalComponent } from '../lista-noticia-modal/lista-noticia-modal.component';
import { MidiaService, TipoMidia } from '../services/midia.service';
import { NoticiaService } from '../services/noticia.service';

@Component({
    selector: 'app-preview-noticia',
    templateUrl: './preview-noticia.component.html',
    styleUrls: ['./preview-noticia.component.scss'],
    animations: fuseAnimations
})
export class PreviewNoticiaComponent implements OnInit {

    urlNoticia: string;
    urlMidia: string;
    textoPreview: string;
    tipoMidia: Number;
    public tipoMidiaEnum = TipoMidia;
    @Output() deleteNoticia = new EventEmitter<string>();
    @Output() updateNoticia = new EventEmitter<string>();
    @Input() noticia: any;
    constructor(private _service: NoticiaService,
        private _midiaService: MidiaService,
        public dialog: MatDialog) {

    }

    getUrlMidia() {
        return this._service.getUrlMidia(this.noticia.id);
    }

    getUrlThumbnail() {
        return this._service.getUrlThumbnail(this.noticia.id);
    }

    ngOnInit() {
        this.tipoMidia = this.noticia.midia ? this.noticia.midia.tipo_id : 0;
    }

    htmlToText(html: string) {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || ' ' || tmp.innerText || '';
    }


    listarNoticia() {
        let dialogRef = this.dialog.open(ListaNoticiaModalComponent, {
            width: '80%',
            data: this.noticia,
            panelClass: 'scrumboard-noticias-card-dialog'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined){
             if (result == "deleted") {
                this.deleteNoticia.next(this.noticia.id);
             }
             else if (result == "updated") {
                 this.updateNoticia.next(this.noticia.id)
             }
            }
        });
    }
}
