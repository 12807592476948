import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { StatusOperacaoService } from '../services/status-operacao.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
    selector: 'app-lista-clientes-sem-ordem',
    templateUrl: './lista-clientes-sem-ordem.component.html',
    styleUrls: ['./lista-clientes-sem-ordem.component.scss']
})

export class ListaClientesSemOrdemComponent extends BaseComponent{

    params: any = {};
    user: any;
    loadingIndicator: boolean;
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;

    constructor(public service: StatusOperacaoService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.user = JSON.parse(this._authService.getUser());

        this.getAll();

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent){        

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */

    onSort(event: any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator = true;
        this.service.getAllClienteSemOrdem(this.params).subscribe(
            data => {
                this.length = data.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }   

}
