import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { CaracteristicaCoeService } from 'app/renda-fixa/services/caracteristica-coe.service';

export interface DialogData {
    service: any;
    nome_arquivo: string;
    url_upload: string;
    title: string;
}

@Component({
    selector: 'app-upload-caracteristica',
    templateUrl: './upload-caracteristica.component.html',
    styleUrls: ['./upload-caracteristica.component.scss'],
})

export class UploadCaracteristicaComponent extends BaseComponent {

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;    
    
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UploadCaracteristicaComponent>,
        public service: CaracteristicaCoeService,
        public utiliesService: UtilitesService,
        public authService: AuthService,
        private changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {

    super(dialog, snackBar);    
    this.nomeFile = null;    

    this.uploader = new FileUploader({
      url: this.utiliesService.urlPrefix + this.data.url_upload,
      isHTML5: true,
      authToken: "Bearer " + this.authService.getToken()
    });

    this.uploader.onAfterAddingFile = (file) => { 
        file.withCredentials = false; 
    };
    
    this.uploader.onProgressItem = ( fileItem: FileItem, progress: any ) => { 
        this.changeDetector.detectChanges();
    };
    
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      
      this.closeLoading();
      
      if (status == 200){
        var info = JSON.parse(response);
        
        this.showSuccess(info.message);
        
        this.dialogRef.close(info);
      }else{
        var info = JSON.parse(response);
        this.showError(info.message);
        //this.dialogRef.close("cancel");
      }
      
    };
    
  }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    uploadFile(event) {    //line 1

        //console.log(event);
        let elem = event.target;  //line 2
        //console.log(elem);
        if (elem.files.length > 0) {     //line 3
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }

    isNomeFile(){
        return (this.nomeFile != null);
    }

    save(){
        this.service.upload(this.file).subscribe(
        data => {
            console.log(data);
        },
        error => {
            console.log(error);
        },
        () => {
            
        }
        );
    }

    startUpload() {
        this.openLoading();
        this.uploader.queue[0].upload();
    }

}
