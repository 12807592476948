import { Component, ViewEncapsulation} from '@angular/core';
import { 
    MatDialog, 
    MatSnackBar, 
    MatDialogRef 
} from '@angular/material';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { format} from 'date-fns'

import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { RegionalService } from 'app/empreendimento/services/regional.service';
import { CategoriaNotificacaoService } from '../services/notificacao-categoria.service';

@Component({
    selector: 'app-filtro-notificacao',
    templateUrl: './filtro-notificacao.component.html',
    styleUrls: ['./filtro-notificacao.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ 
        EscritorioService,
        RegionalService,
        CategoriaNotificacaoService 
    ]
})

export class FiltroNotificacaoComponent extends BaseComponent {

  
    origem_id = new FormControl();
    usuario = new FormControl();
    regional_id = new FormControl();
    escritorio_id = new FormControl();
    assessor_id = new FormControl();
    data_inicio = new FormControl();
    data_fim = new FormControl();
    categoria_id = new FormControl();

    model: any = {
        origem_id:"",
        escritorio_id:"",
    };

    usuarioLogado: any;
    loadingLocal:any;
    filteredOptions: Observable<string[]>;
    
    loadingSpinner: boolean = false;    
    loadingSpinnerEscritorio: boolean = false;
    loadingSpinnerOrigem: boolean = false;
    loadingSpinnerAssessor: boolean = false;
    loadingSpinnerRegional: boolean = false;
    loadingSpinnerCategoria: boolean = false;

    listEscritorios: any[] = [];
    listOrigem: any[] = [];    
    listAssessor: any[] = [];    
    listRegional: any[] = [];    
    listCategoria: any[] = [];    

    public strCidadeCep:any;

    constructor(
                public matDialogRef: MatDialogRef<FiltroNotificacaoComponent>,                
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading:MatDialog,                
                public _usuarioService: UsuarioService,
                private _authService: AuthService,
                public escritorioService:EscritorioService,                
                public _categoriaService: CategoriaNotificacaoService,                
                private _regionalService: RegionalService)
    {
        super(dialog, snackBar);     
        
        this.usuarioLogado = JSON.parse(this._authService.getUser());
        
        this.usuario.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    let params: any = {
                        search: query,
                        no_paginate: 1
                    }

                    this.filteredOptions = this._usuarioService.search(params);
                }
            }
        );
        
        this.getAllRegional();
        this.getAllEscritorios();     
        this.getAllCategoria();   
    }
   

    displayFn(usuario) {
        if (usuario) {
            return usuario.name;
        }
    }

    getUsuario(item) {
        this.model.user_id = item.id;
        this.loadingSpinner = false;
    }

    onNoClick(): void {
        this.matDialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    getAllEscritorios(regional_id='') {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({ no_paginate:true, regional_id:regional_id }).subscribe(
            list => {        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;                
            });
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this._usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }

    selectRegional(event){
        this.regional_id.setValue(event.value);
        this.getAllEscritorios(event.value);
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    salvar(){

        this.model.escritorio_id = this.escritorio_id.value;
        this.model.assessor_id = this.assessor_id.value; 
        this.model.regional_id = this.regional_id.value;
        this.model.categoria_id = this.categoria_id.value;
        
        if(this.data_inicio.value != '' && this.data_inicio.value != null){            
            this.model.data_inicio = format( new Date(this.data_inicio.value), 'yyyy-MM-dd');
        }
        
        if(this.data_fim.value != '' && this.data_inicio.value != null){
            this.model.data_fim = format( new Date(this.data_fim.value), 'yyyy-MM-dd');
        }

        this.matDialogRef.close(this.model);
    }   

    getAllRegional(){
        this._regionalService.getAllList({no_paginate:true}).subscribe(
            list => {
                this.listRegional = list.data;
            },
            error => { },
            () => { }
        );   
        
    }

    getAllCategoria(){
        this.loadingSpinnerCategoria = true;
        this._categoriaService.getAll({no_paginate:true}).subscribe(
            list => {
                this.listCategoria = list.data;
            },
            error => {
                this.loadingSpinnerCategoria = true;
            },
            () => {
                this.loadingSpinnerCategoria = false;
            }
        );   
        
    }

}
