import { 
    AfterViewInit,     
    Component, 
    Inject, 
    OnDestroy, 
    OnInit, 
    QueryList, 
    ViewChildren, 
    ViewEncapsulation 
} from '@angular/core';

import { 
    MatDialog, 
    MatDialogRef, 
    MatSnackBar, 
    MAT_DIALOG_DATA 
} from '@angular/material';

import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

import { ActionDialog, BaseComponent } from "../../base-module/base-component/base.component";
import { Noticia, NoticiaService } from '../services/noticia.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { TipoMidia } from '../services/midia.service';
import { CadastroNoticiaComponent } from '../cadastro-noticia/cadastro-noticia.component';

@Component({
    selector: 'app-lista-noticia-modal',
    templateUrl: './lista-noticia-modal.component.html',
    styleUrls: ['./lista-noticia-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ListaNoticiaModalComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    loadingIndicator: boolean = false;
    updatedIndicator: boolean = false;

    noticia_id: number;
    animationDirection: 'left' | 'right' | 'none';

    @ViewChildren(FusePerfectScrollbarDirective)
    fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;
    categoria: any;
    midia: any;
    tipoMidia: Number;
    public tipoMidiaEnum = TipoMidia;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * 
     * @param _service 
     * @param dialogRef 
     * @param snackBar 
     * @param noticia 
     */
    constructor(
        private _service: NoticiaService,
        public dialogRef: MatDialogRef<ListaNoticiaModalComponent>,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public noticia: Noticia
    ) {
        super(dialog, snackBar)
        this.noticia_id = noticia.id;
        // Set the defaults
        this.animationDirection = 'none';

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.categoria= noticia.categoria;
        this.midia = noticia.midia
        this.updatedIndicator = false;
    }

    onNoClick(): void {
        if(this.updatedIndicator){
            this.dialogRef.close("updated");
        }
        else{
            this.dialogRef.close("cancel");
        }
    }


    isUserControlAllowed(): Boolean {
        return this.isUserCanDelete() || this.isUserCanEdit()
    }
    
    isUserCanDelete() : Boolean {
        var loggedUser = JSON.parse(this._authService.getUser());
        return (this.noticia.user.id === loggedUser.id) && (this._authService.isPermissao(['Excluir Noticia']));
    }

    isUserCanEdit(): Boolean {
        var loggedUser = JSON.parse(this._authService.getUser());
        return (this.noticia.user.id === loggedUser.id) && (this._authService.isPermissao(['Editar Noticia']));
    }

    getUrlMidia() {
        return this._service.getUrlMidia(this.noticia.id);
    }

    getUrlThumbnail() {
        return this._service.getUrlThumbnail(this.noticia.id);
    }

    /**
         * Exclui registro de empresa
         * @param id
         */
    deleteNoticia() {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '400px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Notícia",
                text: "Deseja realmente excluir este item?",
                callback: function () {

                    lista.loadingIndicator = true;
                    lista._service.delete(lista.noticia.id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.dialogRef.close("deleted");
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {

                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
        this.loadingIndicator = false;
    }

    openAlterar(){
        let dialogRef = this.dialog.open(CadastroNoticiaComponent, {
            panelClass: 'scrumboard-card-dialog',
            data: this.noticia
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel") {
                this.updatedIndicator = true;
                this.onNoClick();
            }
        });
    }
    /**
          * Consulta Noticia pelo ID
          */
    getById() {
        this.loadingIndicator = true;
        this._service.getById(this.noticia_id).subscribe(
            data => {
                this.noticia = data.data;
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.tipoMidia = this.midia ? this.midia.tipo_id : 0;
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
