import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    MatDialogRef, 
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';

import { BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { ListaAtividadeService } from '../services/lista-atividades.service';

export interface DialogData {
    id: number;
    cliente_aceitou: boolean;
    observacao: string;
    valor: number;
}

@Component({
    selector: 'responder-atividade',
    templateUrl: './responder-atividade.component.html',
    styleUrls: ['./responder-atividade.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ResponderAtividadeComponent extends BaseComponent {

    cliente_aceitou = new FormControl(false);
    observacao = new FormControl("", [Validators.required]);
    valor = new FormControl("");

    model: any = {
        cliente_aceitou: false,
        observacao: "",
        assunto_id: "",
    };

    loadingLocal: any;
    
    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;

    constructor(
        public _service: ListaAtividadeService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public _authService: AuthService,
        public dialogRef: MatDialogRef<ResponderAtividadeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);
    }

    ngOnInit(): void {
        this.valor.setValue(this.data.valor);
        this.cliente_aceitou.setValue(this.data.cliente_aceitou);
        this.observacao.setValue(this.data.observacao);
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (!this.observacao.valid) {
            return true;
        }

        if (!this.valor.valid) {
            return true;
        }

        return false;
    }

    salvar() {
        this.openLoading();

        this.model.observacao  = this.observacao.value;
        this.model.valor = this.valor.value;
        this.model.cliente_aceitou = this.cliente_aceitou.value;

        this._service.updateResposta(this.data.id, this.model).subscribe(
            (data) => {
                this.closeLoading();
                this.dialogRef.close(data);
            },
            (error) => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
            }
        );
    
    }

}
