import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import {FileManagerModule} from './file-manager/file-manager.module';

import { FuseSharedModule } from '@fuse/shared.module';
import { ChatModule } from './chat/chat.module';

const routes = [   
    {
        path        : 'chat',
        loadChildren: './chat/chat.module#ChatModule'
    },
    {
        path        : 'calendar',
        loadChildren: './calendar/calendar.module#CalendarModule'
    },    
    {
        path        : 'file-manager',
        loadChildren: './file-manager/file-manager.module#FileManagerModule'
    }    
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        FileManagerModule,
        ChatModule
    ],
})
export class AppsModule { }
