import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders} from "@angular/common/http";

import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";

@Injectable()
export class ClienteService {
    
    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * View
     * @param id ID
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "clientes/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * View
     * @param id ID
     */
    getByConta(contaCliente): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "clientes/conta-cliente/" + contaCliente, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    search(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/search"+env, { headers: this.authService.getAuthHeaders() });
    }   

    /**
     * Delete
     * @param id ID
     */
    delete(id): Observable<any> {        
        return this._http.delete(this.utilitesService.urlPrefix + "clientes/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra
     * @param params Json
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "clientes", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza
     * @param params Json
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "clientes/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Busca Todos os clientes que estão com saldo disponivel     
     * @param params 
     */
    getSaldoDisponivel(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/saldo-disponivel"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista de Saldos em Excel
     * @param id ID
     */
    getAllExportSaldo(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/saldo-disponivel-export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }


    /**
     * Exporta Lista de Saldos em Excel
     * @param id ID
     */
    getAllExportClientes(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }

    /**
     * Consulta de valores do painel do cliente
     * @param id ID
     */
    getValoresPainel(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "clientes/" + id +"/valores-painel", { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Consulta de valores do painel do cliente
     * @param id ID
     */
    getValoresPainelGrafico(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "clientes/" + id + "/valores-painel-grafico", { headers: this.authService.getAuthHeaders() });
    }

    gerarStvm(params): Observable<any>{

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/imprimir-stvm" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/pdf",
                'Accept': 'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            }),
        });
    }

    /**
     * Lista Limitada
     * @param JSON
     */
    getListaLimitada(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/lista-limitada"+env, { headers: this.authService.getAuthHeaders() });
    }
}