import {Component, }                    from '@angular/core';
import {Observable}                     from 'rxjs';
import {MatDialog,MatSnackBar}          from '@angular/material';
import { FormControl, Validators }      from '@angular/forms';
import {BaseComponent}                  from "../../base-module/base-component/base.component";
import { ClienteService }               from 'app/clientes/services/cliente.service';
import { ActivatedRoute, Router }       from '@angular/router';
import { StatusMovimentacaoService }    from 'app/auxiliares/services/status-movimentacao.service';
import { ConfirmaAporteComponent }      from '../confirma-aporte/confirma-aporte.component';
import { AporteService }                from '../services/aporte.service';


@Component({
    selector: 'app-cadastro-aporte',
    templateUrl: './cadastro-aporte.component.html',
    styleUrls: ['./cadastro-aporte.component.scss'] 
})

export class CadastroAporteComponent extends BaseComponent {

    filteredOptions: Observable<string[]>;

    ClienteSelecionado:any;
    data:any = {};
    listStatus:any[]=[];
    valor_custodia:any;

    cliente         = new FormControl('', );
    cliente_id      = new FormControl('', [Validators.required]);
    valor           = new FormControl('', [Validators.required]);
    assessor_id     = new FormControl('', []);
    data_operacao   = new FormControl(new Date(), [Validators.required]);
    numero_xp       = new FormControl('', []);
    telefone        = new FormControl('', []);
    email           = new FormControl('', []);
    escritorio_id   = new FormControl('', []);
    escritorio      = new FormControl('', []);
    observacao      = new FormControl('', []);
    aporte_retirada = new FormControl('', []);
    volume_global       = new FormControl('', []);
    valor_investimento  = new FormControl('', []);
    valor_bloqueado     = new FormControl('', []);

    paramsStatus:any ={
        finalizada:""
    }

    valor_disponivel:number = 0;
    loadingSpinner:boolean;
    loadingSpinnerStatus:boolean;
    titulo:string;

    constructor(private _service:AporteService,
                private _clienteService: ClienteService,
                private _statusService:StatusMovimentacaoService,
                private _Activatedroute:ActivatedRoute,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private route: Router) 
    { 
        super(dialog, snackBar);

        this.data = this._Activatedroute.snapshot.params['id'];

        this.listStatus = [];
        this.getAllStatus();

        if(this.data != null && this.data != undefined){
            this.getAporte();
            this.titulo = "Alterar Aporte/Retirada do Cliente";            
        }else{            
            this.titulo = "Cadastrar Aporte/Retirada do Cliente";            
        }

        this.cliente.valueChanges.subscribe(
            query => {
                if(typeof query === "string" || typeof query === "number"){
                    this.loadingSpinner  = true;
                    this.params.search 	 = query;
                    this.filteredOptions = this._clienteService.search(this.params);   
                }
            }   
        );
    }

    getAporte(){
        this.openLoading();
        this._service.getById(this.data).subscribe(
        aporte=>{
 
            this.cliente_id.setValue(aporte.data.cliente_id);
            this.numero_xp.setValue(aporte.data.cliente.numero_xp);
            this.telefone.setValue(aporte.data.cliente.telefone);
            this.email.setValue(aporte.data.cliente.email);
            this.assessor_id.setValue(aporte.data.cliente.assessor.name);
            this.escritorio.setValue(aporte.data.escritorio.cidade.nome);
            this.escritorio_id.setValue(aporte.data.escritorio_id);
            this.valor_investimento.setValue(aporte.data.cliente.valor_investimento);
            this.volume_global.setValue(aporte.data.cliente.volume_global);
            this.data_operacao.setValue(aporte.data.data_operacao);
            this.valor.setValue(aporte.data.valor);
            this.observacao.setValue(aporte.data.observacao);
            this.cliente.setValue(aporte.data.cliente);
            this.valor_bloqueado.setValue(aporte.data.cliente.valor_bloqueado);            

            if(aporte.data.cliente.carteira != undefined && aporte.data.cliente.carteira != null && aporte.data.cliente.carteira.length > 0){            
                this.valor_custodia = parseFloat(aporte.data.cliente.carteira[0].valor_total);
                this.valor_disponivel = parseFloat(aporte.data.cliente.valor_investimento) - ( parseFloat(aporte.data.cliente.valor_bloqueado) + parseFloat(aporte.data.cliente.carteira[0].valor_total) );
            }else{
                this.valor_disponivel = parseFloat(aporte.data.cliente.valor_investimento) - parseFloat(aporte.data.cliente.valor_bloqueado);                
            }

            if(aporte.data.tipo_operacao == 'A'){
                this.aporte_retirada.setValue(false);
            }else{
                this.aporte_retirada.setValue(true);
            }
            
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    isInvalidForm(){
        
        if (!this.cliente_id.valid){
            return true;
        }

        if (!this.valor.valid){
            return true;
        }

        if (!this.data_operacao.valid){
            return true;
        }

        return false;
    }

    getErrorVolumeGlobalMessage() {
        return this.valor.hasError('required') ? 'Valor Movimentado é um campo obrigatório' : '';
    }

    

    retornarLista(){
        this.route.navigate(['/movimentacao/aporte-retirada']);
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
        this.cliente_id.setValue(cliente.id);
        this.numero_xp.setValue(cliente.numero_xp);
        this.telefone.setValue(cliente.telefone);
        this.email.setValue(cliente.email);
        this.assessor_id.setValue(cliente.assessor.name);
        this.escritorio.setValue(cliente.assessor.escritorio.nome_fantasia);
        this.valor_investimento.setValue(cliente.valor_investimento);
        this.volume_global.setValue(cliente.volume_global);
        this.escritorio_id.setValue(cliente.assessor.escritorio_id);
        this.valor_bloqueado.setValue(cliente.valor_bloqueado);
        this.valor_investimento.setValue( parseFloat(cliente.valor_investimento));
        
        if(cliente.carteira != undefined && cliente.carteira != null && cliente.carteira.length > 0){            
            this.valor_custodia = parseFloat(cliente.carteira[0].valor_total);            
            this.valor_disponivel = parseFloat(cliente.valor_investimento) - (parseFloat(cliente.valor_bloqueado)  + parseFloat(cliente.carteira[0].valor_total));
        }else{
            this.valor_disponivel = parseFloat(cliente.valor_investimento) - (parseFloat(cliente.valor_bloqueado) );            
        }
    }


    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getAllStatus(){
        
        this.paramsStatus.finalizada = false;
        this.loadingSpinnerStatus = true;
        
        this._statusService.getAll(this.paramsStatus).subscribe(
            list=>{
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
                console.log("complete");
            }
        );
    }

    validaCadastro(){
        
        //Valida Aporte do Cliente
        if(!this.aporte_retirada.value && parseFloat(this.volume_global.value) < (parseFloat(this.valor.value) + parseFloat(this.valor_investimento.value)) ){
            this.showError('O valor do Aporte não pode ser maior que o volume global!');
            return false;
        }

        //Valida Retirada
        if(this.aporte_retirada.value && parseFloat(this.valor.value) > (parseFloat(this.valor_investimento.value) - (parseFloat(this.valor_bloqueado.value) + parseFloat(this.valor_custodia)) )){
            const valorDisponivel = (parseFloat(this.valor_investimento.value) -  (parseFloat(this.valor_bloqueado.value) + parseFloat(this.valor_custodia)) );
            this.showError('O valor da Retirada ( R$ '+this.valor.value.toLocaleString('pt-BR')+') não pode ser maior que o valor disponivel ( R$ '+valorDisponivel.toLocaleString('pt-BR')+') do cliente!');
            return false;
        }

        return true;
    }

    /**
     * Modal de Confirmação
     */
    openConfirmaBoleta(): void {
        if(this.validaCadastro())
        {            
        
            let dialogRef = this.dialog.open(ConfirmaAporteComponent, {
                width: '800px',
                data: 
                { 
                    id:this.data,
                    cliente: this.cliente.value,
                    valor:parseFloat(this.valor.value),
                    data_operacao:this.data_operacao.value,
                    aporte_retirada:this.aporte_retirada.value,
                    observacao:this.observacao.value,
                    escritorio_id:this.escritorio_id.value
                }
            });

            dialogRef.afterClosed().subscribe(result => {

                if (result != undefined && result != null && result != "cancel"){
                    if(!this.aporte_retirada.value){
                        this.showSuccess("Dados do Aporte salvo com sucesso!");
                    }else{
                        this.showSuccess("Dados da Retirada salva com sucesso!");
                    }
                    
                    this.retornarLista();
                }else if(result != undefined && result != "cancel"){
                    this.showError("Ocorreu um erro ao tentar salvar o registro");
                }
            });
        }
    }

    

}
