import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl, Validators}       from '@angular/forms';
import {BaseComponent, LoadingTeste}    from "../../base-module/base-component/base.component";
import {AuthService}                    from "../../base-module/base-service/auth.service";
import { fuseAnimations }               from '@fuse/animations';
import { AporteService }                from '../services/aporte.service';

export interface DialogData {
    id: number;
    escritorio_id: number;
    cliente: any,
    valor:number,
    data_operacao:Date,
    observacao:string,
    aporte_retirada:boolean
}


@Component({
    selector: 'app-confirma-aporte',
    templateUrl: './confirma-aporte.component.html',
    styleUrls: ['./confirma-aporte.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ConfirmaAporteComponent extends BaseComponent {

    nome   = new FormControl('', [Validators.required]);

    model:any = {
        cliente_id:"",
        valor:"",
        observacao:"",
        data_operacao:"",
        tipo_operacao:""
    }

    loadingLocal:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ConfirmaAporteComponent, { static: false }) table: ConfirmaAporteComponent;

    constructor(public _service: AporteService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<ConfirmaAporteComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);        
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.nome.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.cliente_id       = this.data.cliente.id;
        this.model.escritorio_id    = this.data.escritorio_id;
        this.model.valor            = this.data.valor;
        this.model.observacao       = this.data.observacao;
        this.model.data_operacao    = this.data.data_operacao;

        if(this.data.aporte_retirada){
            this.model.tipo_operacao = 'R';
        }else{
            this.model.tipo_operacao = 'A';
        }      

        if(this.data.id == null){

            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                           
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    console.log("complete");
                    this.closeLoading();
                }
            );

        }else{

            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {
                    console.log("complete");
                    this.closeLoading();
                }
            );
        }

    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

}
