import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Component({
    selector: 'app-secured-image',
    styleUrls: ['./secured-image.component.scss'],
    template: `
        <img [class]="imgClass" [src]="dataUrl$|async" [alt]="alt"/>
    `
})
export class SecuredImageComponent implements OnChanges, OnInit {
    @Input() public src: string;
    @Input() public alt: string;
    @Input() public imgClass: string = "secured-image";
    public src$ : BehaviorSubject<string>;
    public dataUrl$ :Observable<any>;

    ngOnChanges(): void {
        if (this.src$)
            this.src$.next(this.src);
    }



    // we need HttpClient to load the image
    constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {

    }

    ngOnInit(): void {

        this.src$ = new BehaviorSubject(this.src);
        // this stream will contain the actual url that our img tag will load
        // everytime the src changes, the previous call would be canceled and the
        // new resource would be loaded
        this.dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)))
    }
    

    private loadImage(url: string): Observable<any> {
        return this.httpClient
            .get(url, { responseType: 'blob' })
            .pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))))
    }
}