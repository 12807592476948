import { BaseComponent, LoadingTeste} from "../../../base-module/base-component/base.component";
import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { AuthService} from "../../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { GeradorOrdemBoletaAtivoService } from 'app/renda-variavel/services/gerador-ordem-boleta-ativo.service';
import { CarteiraService } from 'app/clientes/services/carteira.service';

interface DadosCliente {
    id:number;
    cliente: string;
    gerador_id: number;
    motivo_recusa:string;
    valor_financeiro_bruto: number;
    valor_financeiro_liquido: number;
}

export interface DialogData {    
    dadosCliente: DadosCliente;
}

@Component({
    selector: 'cadastro-cliente-ativo',
    templateUrl: './cadastro-cliente-ativo.component.html',
    styleUrls: ['./cadastro-cliente-ativo.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,   
    providers: [GeradorOrdemBoletaAtivoService, CarteiraService ]
})

export class CadastroClienteAtivoComponent extends BaseComponent {

    boletaForm: FormGroup;
    loadingLocal:any;
    novoCadastro:boolean = true;    
    filteredOptionsAtivo: Observable<string[]>;
    loadingSpinnerAtivos: boolean;
    valor_total_cliente:number = 0;

    displayedColumns = ['ativo', 'quantidade', 'percentual', 'vlr_financeiro'];
    columnsToDisplay: string[] = this.displayedColumns.slice();

    displayedColumnsSetor = ['setor', 'percentual', 'vlr_financeiro'];
    columnsToDisplaySetor: string[] = this.displayedColumnsSetor.slice();

    rowsSetor = [];

    constructor(public service: GeradorOrdemBoletaAtivoService,
                private _serviceCarteira: CarteiraService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClienteAtivoComponent>,    
                @Inject(MAT_DIALOG_DATA) public data: DialogData,     
                private _fb: FormBuilder )
    {
        super(dialog, snackBar);     
        this.createForm();      
        this.getAtivos();  
        this.getCarteiraCliente();
        this.getCarteiraClienteSetor();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }
    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });
    }

    getTotalCliente(){
        let total = 0;
        this.rows.map((row) => {
            if(parseFloat(row.vlr_financeiro) > 0){
                total += parseFloat(row.vlr_financeiro);
            }
        });

        this.valor_total_cliente = total;
    }

    percentualFinanceiro(row){
        return (parseFloat(row.vlr_financeiro) / this.valor_total_cliente) * 100;
    }
    

    getAtivos() {
        this.openLoading();
        this.params.gerador_boleta_id = this.data.dadosCliente.id;
        this.service.getAll(this.params).subscribe(
            list => {   
                if(list.data.length > 0) {                    
                    for(var boleta of list.data ){
                        const ativo = {
                            ativo: boleta.ativo.sigla,
                            qtd: boleta.qtd
                        }
                        this.ativos.push(this.buildBoleta(ativo));
                    }
                    this.ativos.removeAt(0);
                }               
            },
            error => {
                this.closeLoading();
            },
            () => { 
                this.closeLoading();
            }
        );
    }

    getCarteiraCliente(){
        const cliente = this.data.dadosCliente.cliente.split(' - ');        
        this._serviceCarteira.getAll({numero_xp: cliente[0]}).subscribe(
            list => {                                
                this.rows = list.data;
            },
            error => { },
            () => {
                this.getTotalCliente();
            }
        );
    }

    getCarteiraClienteSetor(){
        const cliente = this.data.dadosCliente.cliente.split(' - ');        
        this._serviceCarteira.getAllAtivosSetor({numero_xp: cliente[0]}).subscribe(
            list => {                                
                this.rowsSetor = list.data;
            },
            error => { },
            () => { }
        );
    }    

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    salvar(){
        this.openLoading();

        this.service.create(this.boletaForm.value).subscribe(
            data => {
                this.closeLoading();
                this.showSuccess(data.msg);
                this.dialogRef.close(data);
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {                    
                this.closeLoading();
            }
        );
        
    }

    get ativos(): FormArray {
        return <FormArray>this.boletaForm.get('ativos');
    }
 
    buildBoleta(  ativo = null ): FormGroup {
        
        if(ativo == null){
            return this._fb.group({ativo: '', qtd: 0});
        }

        return this._fb.group({ativo: ativo.ativo, qtd: ativo.qtd});
    }

    createForm() {
        this.boletaForm = this._fb.group({        
            gerador_boleta_id: this.data.dadosCliente.id,
            ativos: this._fb.array([this.buildBoleta()])
        });       
    }

    addAtivo(): void {
        this.ativos.push(this.buildBoleta());
    }

    removeAtivo(index) {        
        if(index > 0){
            this.ativos.removeAt(index);
        }
    }

    getTotalBruto(){
        return this.rows.map(gerador => gerador.vlr_financeiro).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    }

    getTotalBrutoSetor(){
        return this.rowsSetor.map(gerador => gerador.vlr_financeiro).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    }

}

