import {Component, ViewChild, TemplateRef}  from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent, MatPaginator}  from '@angular/material';
import {ActionDialog} from '../../base-module/base-component/base.component';
import {BaseComponent} from "../../base-module/base-component/base.component";
import {Router} from "@angular/router";
import { AuthService } from 'app/base-module/base-service/auth.service';
import {SelectionType} from '@swimlane/ngx-datatable';
import { BasketService } from '../services/basket.service';
import { FiltraBasketComponent } from '../filtra-basket/filtra-basket.component';
import { UploadBasketComponent } from '../upload-basket/upload-basket.component';

@Component({
    selector: 'app-lista-basket',
    templateUrl: './lista-basket.component.html',
    styleUrls: ['./lista-basket.component.scss']
})

export class ListaBasketComponent extends BaseComponent{

    params: any = {};
    user: any;
    loadingIndicator: boolean;   
    SelectionType = SelectionType;

    selected = [];
    data: any = {};
    titulo: string;

    pageSize = 10;
    pageSizeOptions: number[] = [10, 100, 200, 500];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaBasketComponent, { static: false }) table: ListaBasketComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;

    constructor(public service: BasketService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                private _authService: AuthService) 
    {
        
        super(dialog, snackBar);        
        this.user = JSON.parse(this._authService.getUser());      
        this.getAll();

    }
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any): void{

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {        

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {               
                this.loadingIndicator = false;
            },
            () => {               
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de boleta
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Basket",
                text: "Deseja realmente excluir este item ?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    openVisualizar(id){
        this.route.navigate(['/renda-variavel/basket-ativos/' + id]);
    }

    openAlterar(id){
        this.route.navigate(['/renda-variavel/basket-ativos/' + id]);
    }

    getPercentual(row){
        if(row.qtd_ordens_aprovadas > 0){
            return Math.round(((row.qtd_ordens_aprovadas / row.qtd_ordens) * 100))
        }
        return 0;
    }

    /**
     * 
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(UploadBasketComponent, {
            width: '400px',            
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                //this.showSuccess("Custódia cadastrada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                //this.showError("Ocorreu um erro ao tentar cadastrar a custódia dos clientes.");
            }
        });
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltraBasketComponent, {
            width: '700px',            
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                if (result.tipo_basket_id != undefined && result.tipo_basket_id != null){
                    this.params.tipo_basket_id = result.tipo_basket_id;
                }

                if (result.nome_arquivo != undefined && result.nome_arquivo != null){
                    this.params.nome_arquivo = result.nome_arquivo;
                }

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_fim = result.data_operacao_fim;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }   

    /**
     * Consulta lista
     */
    getExportClientes() {

        this.openLoading();
        this.service.getAllExport( { baskets: this.selected } ).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {
                console.log(error);
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    /**
     * Cor das linhas
     */
    getRowClass = (row) => {
        if(row.qtd_ordens_aprovadas > 0){
            if(Math.round(((row.qtd_ordens_aprovadas / row.qtd_ordens) * 100)) == 100){
                return {'row-color-sucess': true};
            }
        }
    }

    getTotalOrdens( row ){
        if(row.qtd_ordens_aprovadas > 0 ){
            return row.qtd_ordens_aprovadas+'/'+row.qtd_ordens;
        }else{
            return '0/'+row.qtd_ordens;
        }
    }

}
