import {Component, ViewChild, TemplateRef}  from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent}  from '@angular/material';
import {BaseComponent}                      from "../../base-module/base-component/base.component";
import {Router}                             from "@angular/router";
import {FormControl}                        from "@angular/forms";
import {VisualizaHistoricoComponent}        from '../visualiza-historico/visualiza-historico.component';
import { HistoricoMovimentacaoService }     from '../services/historico-movimentacao.service';
import { FiltrarBoletaComponent } from '../filtrar-boleta/filtrar-boleta.component';

@Component({
    selector: 'app-lista-historico',
    templateUrl: './lista-historico.component.html',
    styleUrls: ['./lista-historico.component.scss']
})

export class ListaHistoricoComponent extends BaseComponent{

    params: any = {};
    usuario:any;
    loadingIndicator: boolean;
    public search = new FormControl();

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaHistoricoComponent, { static: false }) table: ListaHistoricoComponent;

    constructor(public service: HistoricoMovimentacaoService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,
                private route: Router) {
        super(dialog,snackBar);

        this.search.setValue('');
        this.getAll();

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

     /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarBoletaComponent, {
            width: '700px',
            data: { 
                titulo: 'Histórico de Movimentações',
                status_boleta: false,
                status_ordem: false,
                tipo: "RV"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                this.params.data_operacao_inicio    = result.data_operacao_inicio;
                this.params.data_operacao_fim       = result.data_operacao_fim;
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    /**
     * Consulta lista de Histórico
     */
    getAll() {
        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    retornarLista(){
        this.route.navigate(['/movimentacao/historico']);
    }

    getTipo(row)
    {
        if(row.aporte_id != null)
        {
            if(row.tipo_operacao == 'A'){
                return 'Aporte';
            }else{
                return 'Retirada';
            }            
        }

        if(row.tipo_operacao == 'BU'){
            return 'Boleta Única';
        }

        if(row.boleta_id != null){
            return 'Boleta';
        }
    }

    openVisualizar(id): void {
        
        let dialogRef = this.dialog.open(VisualizaHistoricoComponent, {
            width: '800px',
            data: 
            { id:id }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result != undefined && result != null && result != "cancel"){
                
                this.retornarLista();
            }else if(result != undefined && result != "cancel"){
                
            }
        });
        
    }


}
