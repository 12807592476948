import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { EstruturaFamiliarService } from '../services/estrutura-familiar.service';
import { ParentescoService } from '../services/parentesco.service';
import { TipoCustoService } from '../services/tipo-custo.service';
import { ClienteCustoService } from '../services/cliente-custo.service';

export interface DialogData {
    id: any;
    cliente_id: any;
}

@Component({
    selector: 'app-cadastro-cliente-custos',
    templateUrl: './cadastro-cliente-custos.component.html',
    styleUrls: ['./cadastro-cliente-custos.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ 
        EstruturaFamiliarService,
        ParentescoService
    ]
})

export class CadastroClienteCustosComponent extends BaseComponent {

    descricao = new FormControl('', [Validators.required]);
    tipo_custo_id = new FormControl('', []);
    beneficiario  = new FormControl();
    valor  = new FormControl('0', [Validators.required]);
    qtd_meses = new FormControl(0, [Validators.required]);

    model:any = {
        descricao:"",
        beneficiario:"",
        tipo_custo_id:"",
        valor:"",
        qtd_meses: 0,
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerTipoCusto: boolean = false;
    listaTipoCusto: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: ClienteCustoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClienteCustosComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _tipoCustoService: TipoCustoService)
    {
        super(dialog, snackBar);
        
        this.getTipoCusto();

        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){

        if (!this.descricao.valid){
            return true;
        }
        
        if (!this.valor.valid) {
            return true;
        }

        if (this.qtd_meses.value == "" && !this.isSelectRecorrenteMensal()) {
            return true;
        }

        return false;
    }

    salvar(){ 
        this.openLoading();
        
        this.model = {
            descricao: this.descricao.value,
            beneficiario: this.beneficiario.value,
            tipo_custo_id: this.tipo_custo_id.value,
            valor: this.valor.value,
            qtd_meses: this.qtd_meses.value,
            cliente_id: this.data.cliente_id
        };
        
        if(this.data.id == null){
        
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.descricao.setValue(item.data.descricao);
                this.beneficiario.setValue(item.data.beneficiario);
                this.tipo_custo_id.setValue(item.data.tipo_custo_id);
                this.valor.setValue(item.data.valor);
                this.qtd_meses.setValue(item.data.qtd_meses);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );

    }

    getTipoCusto() {
        this.loadingSpinnerTipoCusto = true;
        this._tipoCustoService.getAll(this.params).subscribe(
            resquest => {
                this.listaTipoCusto = resquest.data;
            },
            error => {
                this.loadingSpinnerTipoCusto = false;
            },
            () => {
                this.loadingSpinnerTipoCusto = false;
            }
        );
    }

    isSelectRecorrenteMensal(){
        const recorrent = this.listaTipoCusto.filter(item => (this.tipo_custo_id.value == item.id && item.descricao == 'Recorrente mensal'));
        return (recorrent.length!=0);
    }
}

