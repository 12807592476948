import { 
    Component, 
    Input, 
    OnInit 
} from '@angular/core';
import { EstrategiaClienteService } from '../services/estrategia-cliente.service';
import { Estrategia } from '../services/estrategia.service';

@Component({
  selector: 'legenda-estrategias-rv',
  templateUrl: './legenda-estrategias-rv.component.html',
  styleUrls: ['./legenda-estrategias-rv.component.scss'],
  providers: [ EstrategiaClienteService ]
})

export class LegendaEstrategiasRvComponent implements OnInit {

    @Input() cliente_id: number;
    @Input() tipo: string = 'MOVIMENTACAO';

    listEstrategia: Estrategia[]= [];

    constructor(private _serviceClienteEstrategia: EstrategiaClienteService) {         
    }

    ngOnInit() {
        if(this.tipo == 'MOVIMENTACAO'){
            this.getEstrategiasMovimentacaoCliente();
        }else{
            this.getAllEstrategiasPosicaoCliente();
        }
    }

    getEstrategiasMovimentacaoCliente() {
        this._serviceClienteEstrategia.getEstrategiaMovimentacao({cliente_id :this.cliente_id}).subscribe(
            list => {
                this.listEstrategia = list.data;
            },
            error => { },
            () => { }
        );
    }

    getAllEstrategiasPosicaoCliente() {
        this._serviceClienteEstrategia.getEstrategiaPosicao({cliente_id :this.cliente_id}).subscribe(
            list => {
                this.listEstrategia = list.data;
            },
            error => { },
            () => { }
        );
    }

}
