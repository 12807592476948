import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { BaseComponent, ActionDialog } from "../../../base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BoletaRendaFixaService } from 'app/renda-fixa/services/boleta-rendafixa.service';
import { CadastroProdutoComponent } from '../cadastro-produto/cadastro-produto.component';
import { CadastroBoletaRendaFixaComponent } from 'app/renda-fixa/cadastro-boleta-renda-fixa/cadastro-boleta-renda-fixa.component';
import { ClienteService } from 'app/clientes/services/cliente.service';

@Component({
    selector: 'app-cliente-renda-fixa',
    templateUrl: './cliente-renda-fixa.component.html',
    styleUrls: ['./cliente-renda-fixa.component.scss'],
    providers:[BoletaRendaFixaService]
})

export class ClienteRendaFixaComponent extends BaseComponent implements OnInit{

    params: any = {};
    user: any;    
    loadingIndicator: boolean;
    data_atualizacao: any;

    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 30];
    
    @Input() cliente_id: number;

    constructor(public _service: BoletaRendaFixaService, 
                public dialog: MatDialog,
                private _serviceCliente: ClienteService,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.user = JSON.parse(this._authService.getUser());
        this.params.per_page = this.pageSize;
    }

    ngOnInit(){
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }
  

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator   = true;
        this.params.cliente_id  = this.cliente_id;

        this._service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    alterar(id): void {
        let dialogRef = this.dialog.open(CadastroProdutoComponent, {
            width: '400px',            
            data: {                
                id: id,
                titulo: 'Alterar Boleta Renda Fixa',
                service: this._service
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Histórico atualizado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registro.");
            }
        });
    }

    
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Renda Fixa",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }  

    openCadastro(){        
        let dialogRef = this.dialog.open(CadastroBoletaRendaFixaComponent, {
            width: '500px',
            data: {
                acao_comercial_id: null,
                cliente_id: this.cliente_id,
                id: null
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Boleta Renda Fixa cadastrada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um nova boleta renda fixa.");
            }
        });        
    }

    orgaoEmissor(row){
        
        if (row.produto.orgao_emissor != undefined && row.produto.orgao_emissor != ''){
            return row.produto.orgao_emissor.nome;
        }
        
        return '';
    }

    getVerificado(row){
        if(row.verificado_migracao){
             return 'SIM';
        }else{
             return 'NÃO';
        }    
    }

    gerarStvm(){
        this.openLoading();
        this.params.tipo = 'RF';
        this._serviceCliente.gerarStvm(this.params).subscribe(
            data => {                

                const blob = new Blob([data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.showError('Custódia Não Verificada');
                this.closeLoading();
            },
            () => {

                console.log("complete");
                this.closeLoading();
            }
        );
    }
}
