import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DashboardConsorcioService } from '../services/dashboard-consorcio.service';
import { RegionalService } from 'app/empreendimento/services/regional.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';

@Component({
    selector: 'dashboard-consorcio',
    templateUrl: './dashboard-consorcio.component.html',
    styleUrls: ['./dashboard-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        EscritorioService
    ]
})

export class DashboardConsorcioComponent extends BaseComponent implements OnInit {

    user:any;

    total_clientes: any = {};
    total_novos_clientes: any = {};
    total_clientes_prospects: any = {};
    total_consorcio_clientes: any = {};
    total_consorcios_liberados: any = {};
    total_valor_pago: any = {};
    total_boleta: any = {};
    
    agente_id = new FormControl('');

    loadingSpinnerRegional: boolean;
    loadingSpinnerEscritorio: boolean;
    loadingSpinnerAssessor: boolean;
    loadingIndicator: boolean;
    listRegional: any[] = [];
    listEscritorio: any[] = [];
    listAssessor: any[] = [];

    regional_id = new FormControl('');
    escritorio_id = new FormControl('');
    assessor_id = new FormControl('');

    model: any = {
        regional_id: "",
        escritorio_id: "",
        assessor_id: "",
    }

    public  colors = [
        {
            borderColor    : '#42a5f5',
            backgroundColor: '#42a5f5'
        }
    ];

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _service: DashboardConsorcioService,
                private _authService: AuthService,
                private _regionalService: RegionalService,
                private _escritorioService: EscritorioService ) 
    {
        super(dialog, snackBar);
        this.user = JSON.parse(this._authService.getUser());

        this.loadingIndicator = true;
        this.getAllRegional();
        this.getAllEscritorios();
        this.getAll();
    }

    ngOnInit(){
        
    }

    getAllRegional() {
        this.loadingSpinnerRegional = true;
        this._regionalService.getAllList({}).subscribe(
            data => {
                const add = {
                    id: '',
                    nome: 'TODAS'
                };
                this.listRegional = data.data;
                this.listRegional.push(add);
            },
            error => {
                this.loadingSpinnerRegional = false;
            },
            () => {
                this.loadingSpinnerRegional = false;
                this.regional_id.setValue('');
            }
        );
    }

    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this._escritorioService.getAll({ no_paginate: true }).subscribe(
            data => {
                const add = {
                    id: '',
                    nome_fantasia: 'TODOS'
                };
                this.listEscritorio = data.data;
                this.listEscritorio.push(add);
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                this.escritorio_id.setValue('');
                this.assessor_id.setValue('');
            }
        );
    }

    selectEscritorio(event) {
        this.params.escritorio_id = event.value;
        this.getAll();
    }

    selectRegional(event) {
        this.params.regional_id = event.value;
        this.getAll();
    }

    selectAssessor(event) {
        this.params.assessor_id = event.value;
        this.getAll();
    }

    getAll() {

        this.loadingIndicator = true;
        this._service.getAll(this.params).subscribe(
            data => {

                let totalCliente: any[] = Object.values(data.data.total_clientes);
                let totalClienteGrafico = this.removerUltimoItemArray(totalCliente);

                let totalNovosClientes: any[] = Object.values(data.data.total_novos_clientes);
                let totalNovosClientesGrafico = this.removerUltimoItemArray(totalNovosClientes);

                let totalNovosClientesProspect: any[] = Object.values(data.data.total_clientes_prospects);
                let totalNovosClientesProspectGrafico = this.removerUltimoItemArray(totalNovosClientesProspect);

                let totalConsorcioContratados: any[] = Object.values(data.data.total_consorcios_contratados);
                let totalConsorcioContratadosGrafico = this.removerUltimoItemArray(totalConsorcioContratados);
                
                let totalConsorciosLiberados: any[] = Object.values(data.data.total_consorcios_liberados);
                let totalConsorciosLiberadosGrafico = this.removerUltimoItemArray(totalConsorciosLiberados);

                let totalValorPago: any[] = Object.values(data.data.total_valor_pago);
                let totalValorPagoGrafico = this.removerUltimoItemArray(totalValorPago);

                let totalBoleta: any[] = Object.values(data.data.total_boletas);
                let totalBoletaGrafico = this.removerUltimoItemArray(totalBoleta);

                this.total_clientes = {
                    conversion: {
                        value: (totalCliente[4]) ? totalCliente[4] : 0,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Clientes',
                            data: totalClienteGrafico
                        }
                    ],
                    labels: this.removerUltimoItemArray(data.data.meses),
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (tooltipItem.value) ? tooltipItem.value : 0;
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalClienteGrafico.reduce((a, b) => Math.min(a, b)) - totalClienteGrafico.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalClienteGrafico.reduce((a, b) => Math.max(a, b)) + totalClienteGrafico.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                    
                };

                this.total_novos_clientes = {
                    conversion: {
                        value: (totalNovosClientes[4]) ? totalNovosClientes[4] : 0,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Novos Clientes',
                            data: totalNovosClientesGrafico
                        }
                    ],
                    labels: this.removerUltimoItemArray(data.data.meses),
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (tooltipItem.value) ? tooltipItem.value : 0;
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalNovosClientesGrafico.reduce((a, b) => Math.min(a, b)) - totalNovosClientesGrafico.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalNovosClientesGrafico.reduce((a, b) => Math.max(a, b)) + totalNovosClientesGrafico.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                    
                };

                this.total_clientes_prospects = {
                    conversion: {
                        value: (totalNovosClientesProspect[4]) ? totalNovosClientesProspect[4] : 0,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Clientes Prospects',
                            data: totalNovosClientesProspectGrafico
                        }
                    ],
                    labels: this.removerUltimoItemArray(data.data.meses),
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (tooltipItem.value) ? tooltipItem.value : 0;
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalNovosClientesProspectGrafico.reduce((a, b) => Math.min(a, b)) - totalNovosClientesProspectGrafico.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalNovosClientesProspectGrafico.reduce((a, b) => Math.max(a, b)) + totalNovosClientesProspectGrafico.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }

                };

                this.total_consorcio_clientes = {
                    conversion: {
                        value: (totalConsorcioContratados[4]) ? totalConsorcioContratados[4] : 0,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Consórcio Contratados',
                            data: totalConsorcioContratadosGrafico
                        }
                    ],
                    labels: this.removerUltimoItemArray(data.data.meses),
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return ((tooltipItem.value) ? tooltipItem.value : 0);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalConsorcioContratadosGrafico.reduce((a, b) => Math.min(a, b)) - totalConsorcioContratadosGrafico.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalConsorcioContratadosGrafico.reduce((a, b) => Math.max(a, b)) + totalConsorcioContratadosGrafico.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }

                };

                this.total_consorcios_liberados = {
                    conversion: {
                        value: (totalConsorciosLiberados[4]) ? totalConsorciosLiberados[4] : 0,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Consórcio Liberados',
                            data: totalConsorciosLiberadosGrafico
                        }
                    ],
                    labels: this.removerUltimoItemArray(data.data.meses),
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalConsorciosLiberadosGrafico.reduce((a, b) => Math.min(a, b)) - totalConsorciosLiberadosGrafico.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalConsorciosLiberadosGrafico.reduce((a, b) => Math.max(a, b)) + totalConsorciosLiberadosGrafico.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }

                };

                this.total_valor_pago = {
                    conversion: {
                        value: (totalValorPago[4]) ? totalValorPago[4] : 0,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Total Valor Pago',
                            data: totalValorPagoGrafico
                        }
                    ],
                    labels: this.removerUltimoItemArray(data.data.meses),
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,   
                                    })).format((tooltipItem.value / 100));
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalValorPagoGrafico.reduce((a, b) => Math.min(a, b)) - totalValorPagoGrafico.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalValorPagoGrafico.reduce((a, b) => Math.max(a, b)) + totalValorPagoGrafico.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }

                };

                this.total_boleta = {
                    conversion: {
                        value: this.formataCasaDecimal((totalBoleta[4]) ? totalBoleta[4] : 0),
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Total Boleta',
                            data: totalBoletaGrafico
                        }
                    ],
                    labels: this.removerUltimoItemArray(data.data.meses),
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return new Intl.NumberFormat("pt-BR", {
                                        style: "percent",
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    }).format(tooltipItem.value / 100);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalBoletaGrafico.reduce((a, b) => Math.min(a, b)) - totalBoletaGrafico.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalBoletaGrafico.reduce((a, b) => Math.max(a, b)) + totalBoletaGrafico.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }

                };

            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    } 
        
    isEmptyObject(obj) {
        var name;
        for (name in obj) {
          return false;
        }
        return true;
    }

    removerUltimoItemArray(array){
        let list = [];
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            if(i+1 != array.length){
                list.push(element);
            }
        }

        return list;
    }

    formataCasaDecimal(num){
        let numero = 0.00;
        
        if (num != null && num != undefined){
            numero = parseFloat(num);
            return numero.toFixed(2);
        }

        return numero;
    }
}
