import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialog,MatSnackBar,MatDialogRef } from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import {BaseComponent, LoadingTeste} from "../../../base-module/base-component/base.component";
import {AuthService} from "../../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { GeradorOrdemService } from 'app/renda-variavel/services/gerador-ordem.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { BoletaValoresService } from 'app/movimentacao/services/boleta-valores.service';

@Component({
    selector: 'app-cadastro-gerador-ordem',
    templateUrl: './cadastro-gerador-ordem.component.html',
    styleUrls: ['./cadastro-gerador-ordem.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations, 
    providers: [ StatusMovimentacaoService, BoletaValoresService ]   
})

export class CadastroGeradorOrdemComponent extends BaseComponent {

    estrategia_id = new FormControl('', [Validators.required]);
    rotulo = new FormControl('', [Validators.required]);
    tipo = new FormControl('', [Validators.required]);
    status_id = new FormControl('');
    qtd_trade = new FormControl('');

    model:any = {
        tipo:"",
        estrategia_id:"",
        rotulo:"",
        qtd_trade:"",
        status_id:""
    };

    params:any = {
        estrategia_id:"",        
        qtd_trade:"",
        status_id:""
    };

    totalBoletas:number = 0;
    loadingLocal:any;
    listEstrategia:any[]=[];
    listStatus:any[]=[];
    listTrades:any[]=[];
    loadingSpinnerEstrategia:boolean;
    loadingSpinnerTrades:boolean;
    loadingSpinnerStatus:boolean;

    constructor(public service: GeradorOrdemService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                private _estrategiaService: EstrategiaService,
                private _statusService: StatusMovimentacaoService,
                private _boletaValores: BoletaValoresService,
                public dialogRef: MatDialogRef<CadastroGeradorOrdemComponent>)
    {
        super(dialog, snackBar);   
        this.getEstrategia();  
        this.getStatus(); 
        this.getTradeBoleta();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    getEstrategia(){
        
        this.loadingSpinnerEstrategia = true;
        
        this._estrategiaService.getLista().subscribe(
            list => {
                this.loadingSpinnerEstrategia = false;
                this.listEstrategia = list.data;
            },
            error => {
                this.loadingSpinnerEstrategia = false;
            },
            () => {
                this.loadingSpinnerEstrategia = false;                
            }
        );
    }

    getTradeBoleta(){
        
        this.loadingSpinnerTrades = true;
        
        this._boletaValores.getTotalTrades().subscribe(
            list => {
                this.loadingSpinnerTrades = false;
                this.listTrades = list.data;
            },
            error => {
                this.loadingSpinnerTrades = false;
            },
            () => {
                this.loadingSpinnerTrades = false;                
            }
        );
    }   

    getStatus(){
        
        this.loadingSpinnerEstrategia = true;
        
        this._statusService.getLista({tipo_produto: 'RV', finalizada: false}).subscribe(
            list => {
                this.loadingSpinnerStatus = false;
                this.listStatus = list.data;
            },
            error => {
                this.loadingSpinnerStatus = false;
            },
            () => {
                this.loadingSpinnerStatus = false;                
            }
        );
    }

    getTotalBoletas(){
        this._boletaValores.getTotalBoletas(this.params).subscribe(
            list => {
                this.loadingSpinnerStatus = false;
                this.totalBoletas = list.data;
            },
            error => {
                this.loadingSpinnerStatus = false;
            },
            () => {
                this.loadingSpinnerStatus = false;                
            }
        );
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.rotulo.valid){
            return true;
        }

        if (!this.estrategia_id.valid){
            return true;
        }

        if (!this.tipo.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.estrategia_id = this.estrategia_id.value;
        this.model.rotulo = this.rotulo.value;
        this.model.tipo = this.tipo.value;
        this.model.status_id = this.status_id.value;
        this.model.qtd_trade = this.qtd_trade.value;

        this.service.create(this.model).subscribe(
            data => {
                this.closeLoading();
                this.dialogRef.close(data);                                                       
            },
            error => {                    
                this.closeLoading();
                this.showError(error.error.message);

            },
            () => {
                this.closeLoading();
            }
        );

    }

    getErrorRotuloMessage() {
        return this.rotulo.hasError('required') ? 'Rotulo é um campo obrigatório' : '';
    }

    getErrorEstrategiaMessage() {
        return this.estrategia_id.hasError('required') ? 'Estratégia é um campo obrigatório' : '';
    }

    getErrorTipoMessage() {
        return this.tipo.hasError('required') ? 'Tipo é um campo obrigatório' : '';
    }

    selectBoletas(event, campo){
        if(campo == 'estrategia_id'){
            this.params.estrategia_id = event.value;
        }else if(campo == 'status_id'){
            this.params.status_id = event.value;
        }else{
            this.params.qtd_trade = event.value;
        }

        this.getTotalBoletas();
    }

}
