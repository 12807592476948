import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { PrimeiroContatoService } from 'app/auxiliares/services/primeiro-contato.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
import { SetorAtivoService } from 'app/auxiliares/services/setor-ativo.service';
import { Setor } from '../models/setor.interface';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-filtro-custodia-consolidada',
    templateUrl: './filtro-custodia-consolidada.component.html',
    styleUrls: ['./filtro-custodia-consolidada.component.scss'],
    providers: [ 
        PrimeiroContatoService, 
        EstrategiaService, 
        SetorAtivoService,
        EscritorioService
    ]
})

export class FiltroCustodiaConsolidadaComponent extends BaseComponent {
    
    cliente = new FormControl('');
    ativo = new FormControl('');
    assessor_id = new FormControl('');
    escritorio_id = new FormControl('');
    percentual = new FormControl('');
    tipo_percentual = new FormControl('MA');
    tipo_cliente = new FormControl('');
    tipo_custodia = new FormControl('');
    migrada = new FormControl('');
    primeiro_contato_id = new FormControl('');
    estrategia_id = new FormControl('');
    setor_id = new FormControl('');

    model:any = {
        escritorio_id: '',
        tipo_custodia: '',
        tipo_cliente: '',
        migrada: '',
        setor_id: '',
    }

    loadingSpinner: boolean;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerEstrategia: boolean;
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerEscritorio:boolean = false;
    loadingSpinnerClassificacao:boolean = false;
    loadingSpinnerSetor:boolean = false;

    filteredOptions: Observable<string[]>;
    filteredOptionsAtivo: Observable<string[]>;
    listAssessor: any[] = [];
    listEscritorios: any[] = [];
    listClassificacao: any[] = [];
    listEstrategia: any[] = [];
    listAtivos: Object[] = [];
    listSetor: Setor[] = [];
    usuario:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltroCustodiaConsolidadaComponent, { static: false }) table: FiltroCustodiaConsolidadaComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltroCustodiaConsolidadaComponent>,
        private _clienteService: ClienteService,
        private _ativoService: AtivoService,
        private usuarioService: UsuarioService,
        private _authService: AuthService,
        public escritorioService: EscritorioService,
        public _primeiroService: PrimeiroContatoService,
        private _serviceEstrategia: EstrategiaService,
        private _serviceSetorAtivo: SetorAtivoService, 
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        ); 

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;
                    this.params.opcao = true;
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );
                
        this.getAllEscritorios();
        this.getAssessores();
        this.getClassificacao();
        this.getAllEstrategias();
        this.getSetor();
    }

    displayFn(cliente): void {
        if (cliente) {
            return cliente.nome; 
        }
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    getCliente(cliente): void{
        this.loadingSpinner  = false;
    }

    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    filtrar(): void {
        
        this.dialogRef.close({
            cliente: this.cliente.value,
            escritorio_id: this.escritorio_id.value,
            assessor_id: this.assessor_id.value,
            ativo: this.ativo.value,
            percentual: this.percentual.value,
            tipo_percentual: this.tipo_percentual.value,
            tipo_cliente: this.tipo_cliente.value,
            tipo_custodia: this.tipo_custodia.value,
            migrado: this.migrada.value,
            primeiro_contato_id: this.primeiro_contato_id.value,
            estrategia_id: this.estrategia_id.value,
            setor_id: this.setor_id.value,
        });
    }


    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }
    
    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                console.log("complete");
            });
    }

    getClassificacao() {
        this.loadingSpinnerClassificacao = true;
        this._primeiroService.getLista()
            .subscribe(
                resquest => {
                    this.listClassificacao = resquest.data;
                },
                error => {
                    this.loadingSpinnerClassificacao = false;
                },
                () => {
                    this.loadingSpinnerClassificacao = false;                    
                }
            );
    }

    getSetor() {
        this.loadingSpinnerSetor = true;
        this._serviceSetorAtivo.getLista()
            .subscribe(
                resquest => {
                    this.listSetor = resquest.data;
                },
                error => {
                    this.loadingSpinnerSetor = false;
                },
                () => {
                    this.loadingSpinnerSetor = false;                    
                }
            );
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getLista().subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
                console.log("complete");
            }
        );
    }

}