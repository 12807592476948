import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import {BaseComponent} from "app/base-module/base-component/base.component";
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';

export interface DialogData {
    id: number;
    tipo: string;
    escritorio_id: number;
    assessor_id: number;
}

@Component({
    selector: 'app-altera-visao',
    templateUrl: './altera-visao.component.html',
    styleUrls: ['./altera-visao.component.scss'],
    providers: [ 
        EscritorioService
    ]
})

export class AlteraVisaoComponent extends BaseComponent {
    
    assessor_id             = new FormControl('');
    escritorio_id           = new FormControl('');

    habilitaEscritorio: boolean = false;
    loadingSpinnerAssessor: boolean;
    loadingSpinnerEscritorio: boolean;
    listAssessor: Object[] = [];
    listEscritorios: Object[] = [];
    usuario:any;    

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(AlteraVisaoComponent, { static: false }) table: AlteraVisaoComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<AlteraVisaoComponent>,     
        private _usuarioService: UsuarioService,       
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _authService: AuthService,
        public escritorioService:EscritorioService) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());        

        if (this.usuario.visao_escritorio_id != null && this.usuario.visao_escritorio_id != undefined){
            this.escritorio_id.setValue(this.usuario.visao_escritorio_id);
        }
        
        if (this.data.id != null && this.data.id != undefined){
            
            if (this.data.tipo == 'escritorio'){
                this.habilitaEscritorio = true;
            }
        }

        this.getAllEscritorios();

        if ( this.data.escritorio_id != null && this.data.escritorio_id != undefined ){
            this.getAssessores(this.data.escritorio_id);
        }
        
        
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(){

        this.dialogRef.close({            
            'assessor_id': this.assessor_id.value,
            'escritorio_id': this.escritorio_id.value,
        });
    }
    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.params.no_paginate = true;

        if( this.data.escritorio_id != null && this.data.escritorio_id != undefined ){
            this.params['escritorio_id'] = this.data.escritorio_id;
        }

        this.escritorioService.getAllList(this.params).subscribe(
            list => {
         
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;                
            },
            () => {
                this.loadingSpinnerEscritorio = false;                
            });
    }

    getAssessores(escritorio_id): void {
        this.loadingSpinnerAssessor = true;
        this._usuarioService.getLista({ tipo_usuario: 'A', escritorio_id: escritorio_id })
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }

}
