import {Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class ClienteRelacionentoService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Histório de Relacionamento do Cliente por id
     * @param id ID da Histório de Relacionamento do Cliente
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "clientes/relacionamento/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Histório de Relacionamento do Cliente
     * @param id ID da Histório de Relacionamento do Cliente
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/relacionamento"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Histório de Relacionamento do Cliente
     * @param id ID da Histório de Relacionamento do Cliente
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "clientes/relacionamento/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Histório de Relacionamento do Cliente
     * @param params Json Histório de Relacionamento do Cliente
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "clientes/relacionamento", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Histório de Relacionamento do Cliente
     * @param params Json Histório de Relacionamento do Cliente
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "clientes/relacionamento/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "clientes/relacionamento/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }  

    /**
     * Lista de Gestão de Relacionamento do Cliente
     * @param id ID da Gestão de Relacionamento do Cliente
     */
     getAllGestaoRelacionamento(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/relacionamento/lista"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
     getAllGestaoRelacionamentoExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "clientes/relacionamento/lista/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    } 
}
