import {EventEmitter, Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ClientEquityService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Lista de Histório do Cliente
     * @param id ID da Histório do Cliente
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/equity/position"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Histório do Cliente
     * @param id ID da Histório do Cliente
     */
     getVariation(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/equity/variation"+env, { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Lista de Histório do Cliente
     * @param id ID da Histório do Cliente
     */
     getVariationYear(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/equity/variation-year"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Histório do Cliente
     * @param id ID da Histório do Cliente
     */
     getAllDateHistoryInvestiments(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "clientes/equity/history"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Histório do Cliente
     * @param id ID da Histório do Cliente
     */
     getAllMovimentEquity(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/produtos"+env, { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Lista de Histório do Cliente
     * @param id ID da Histório do Cliente
     */
     getLastUpdate(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "clientes/equity/last-update", { headers: this.authService.getAuthHeaders() });
    }

}
