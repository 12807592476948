import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { CaracteristicaProdutoConsorcioService } from '../services/caracteristica-produto-consorcio.service';
import { TipoConsorcioService } from '../services/tipo-consorcio.service';
import { EmpresaConsorcioService } from '../services/empresa-consorcio.service';
import { ClienteCarteiraConsorcioService } from '../services/cliente-carteira-consorcio.service';

export interface DialogData {
    cliente_id: any;
    id: any;
    cliente: any;
    type:any;
}

@Component({
    selector: 'app-cadastro-carteira-consorcio',
    templateUrl: './cadastro-carteira-consorcio.component.html',
    styleUrls: ['./cadastro-carteira-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class CadastroCarteiraConsorcioComponent extends BaseComponent {

    cliente = new FormControl('', [Validators.required]);
    currentCliente = new FormControl('', [Validators.required]);
    cliente_id = new FormControl('', [Validators.required]);
    loadingSpinner: boolean;
    filteredOptions: Observable<string[]>;
    tipo_consorcio_id = new FormControl();
    empresa_consorcio_id = new FormControl();
    valor_contratado = new FormControl();
    data_atualizacao = new FormControl();
    data_operacao = new FormControl();
    grupo = new FormControl();
    cota = new FormControl();
    prazo_grupo = new FormControl();
    prazo_cliente = new FormControl();
    taxa_administracao = new FormControl();
    valor_lance_potencial = new FormControl();
    valor_lance_efetivado_contemplacao = new FormControl();
    valor_reembolso = new FormControl();
    valor_pago = new FormControl();
    saldo_devedor = new FormControl();
    liberacao_credito = new FormControl();
    valor_credito_liberado = new FormControl();
    credito_autorizado = new FormControl();

    listStatus: any[] = [];
    loadingSpinnerStatus: boolean = false;

    listProdutoConsorcio: any[] = [];
    loadingSpinnerProduto: boolean = false;

    listTipoConsorcio: any[] = [];
    loadingSpinnerTipo: boolean = false;

    listEmpresaConsorcio: any[] = [];
    loadingSpinnerEmpresaConsorcio: boolean = false;

    model:any = {
        cliente_id:null,
        tipo_consorcio_id:null,
        empresa_consorcio_id:null,
        valor_contratado:null,
        data_atualizacao:null,
        grupo:null,
        cota:null,
        prazo_grupo:null,
        prazo_cliente:null,
        taxa_administracao:null,
        valor_lance_potencial:null,
        valor_lance_efetivado_contemplacao:null,
        valor_reembolso:null,
        valor_pago:null,
        saldo_devedor:null,
        liberacao_credito:null,
        valor_credito_liberado:null,
        credito_autorizado:null,
    };

    loadingLocal:any;

    constructor(public _service: ClienteCarteiraConsorcioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroCarteiraConsorcioComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _clienteService: ClienteService,
                private _statusService: StatusMovimentacaoService,
                private _tipoService: TipoConsorcioService,
                private _empresaConsorcioService: EmpresaConsorcioService
                )
    {
        super(dialog, snackBar);

        console.log(this.data.cliente)
        if (this.data != null && this.data != undefined && this.data.id != null && this.data.id != undefined){
            this.getById();
        }
        if (this.data.type != undefined && this.data.type == "by_cliente") {
            this.cliente.setValue(this.data.cliente);
            this.model.cliente = this.data.cliente;
        }else{
            this.cliente.valueChanges.subscribe(
                query => {
                    if (typeof query === "string" || typeof query === "number") {
                        this.loadingSpinner = true;
                        this.params.search = query;
                        this.filteredOptions = this._clienteService.search(this.params);
                    }
                }
            );
        }
        
        this.getAllStatus();
        this.getAllTipoConsorcio();
        this.getAllEmpresaConsorcio();
    }

    ngOnInit(){
        
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        
        if (!this.tipo_consorcio_id.valid) {
            return true;
        }

        if (this.data.type != undefined && this.data.type =="by_cliente"){

            if (this.data.cliente == null || this.data.cliente == undefined) {
                return true;
            }

        }else{

            if (!this.cliente.valid) {
                return true;
            }
        }

        return false;
    }

    salvar(){
        this.openLoading();
        if(this.data.type == "by_cliente"){
            this.model.cliente_id = this.data.cliente.id;
        }else{
            let cliente = this.cliente.value;
            this.model.cliente_id = cliente.id;
        }
        
        //this.model.tipo_consorcio_id = this.tipo_consorcio_id.value;
        this.model.data_atualizacao = this.data_atualizacao.value;
        this.model.valor_contratado = this.valor_contratado.value;
        this.model.grupo = this.grupo.value;
        this.model.cota = this.cota.value;
        this.model.prazo_grupo = this.prazo_grupo.value;
        this.model.prazo_cliente = this.prazo_cliente.value;
        this.model.taxa_administracao = this.taxa_administracao.value;
        this.model.valor_lance_potencial = this.valor_lance_potencial.value;
        this.model.valor_lance_efetivado_contemplacao = this.valor_lance_efetivado_contemplacao.value;
        this.model.valor_reembolso = this.valor_reembolso.value;
        this.model.valor_pago = this.valor_pago.value;
        this.model.saldo_devedor = this.saldo_devedor.value;
        this.model.liberacao_credito = this.liberacao_credito.value;
        this.model.valor_credito_liberado = this.valor_credito_liberado.value;
        this.model.credito_autorizado = this.credito_autorizado.value;

        if (this.data.id == null || this.data.id == undefined) {
            this._service.create(this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        } else {
            this._service.update(this.data.id, this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }

    }

    getErrorClienteMessage() {
        return this.cliente_id.hasError('required') ? 'Cliente é um campo obrigatório' : '';
    }

    getErrorTipoMessage() {
        return this.tipo_consorcio_id.hasError('required') ? 'Tipo é um campo obrigatório' : '';
    }

    getErrorValorMessage() {
        return this.valor_contratado.hasError('required') ? 'Valor é um campo obrigatório' : '';
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
         item => {

            this.model = item.data;

            this.cliente.setValue(item.data.cliente);
            this.cliente_id.setValue(item.data.cliente_id);
            this.tipo_consorcio_id.setValue(item.data.tipo_consorcio_id);
            this.data_atualizacao.setValue(item.data.data_operacao);
            this.valor_contratado.setValue(item.data.valor_contratado);
            this.grupo.setValue(item.data.grupo);
            this.cota.setValue(item.data.cota);
            this.prazo_grupo.setValue(item.data.prazo_grupo);
            this.prazo_cliente.setValue(item.data.prazo_cliente);
            this.taxa_administracao.setValue(item.data.taxa_administracao);
            this.valor_lance_potencial.setValue(item.data.valor_lance_potencial);
            this.valor_lance_efetivado_contemplacao.setValue(item.data.valor_lance_efetivado_contemplacao);
            this.valor_reembolso.setValue(item.data.valor_reembolso);
            this.valor_pago.setValue(item.data.valor_pago);
            this.saldo_devedor.setValue(item.data.saldo_devedor);
            this.liberacao_credito.setValue(item.data.liberacao_credito);
            this.valor_credito_liberado.setValue(item.data.valor_credito_liberado);
            this.credito_autorizado.setValue(item.data.credito_autorizado);

        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome;
        }
    }

    getCliente(cliente) {
        this.loadingSpinner = false;
    }

    getAllStatus() {

        this.loadingSpinnerStatus = true;

        this._statusService.getLista({ tipo_produto: 'CS' }).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
                console.log("complete");
            }
        );
    }

    getAllTipoConsorcio() {

        this.loadingSpinnerTipo = true;

        this._tipoService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listTipoConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerTipo = false;
                console.log("complete");
            }
        );
    }

    getAllEmpresaConsorcio() {

        this.loadingSpinnerEmpresaConsorcio = true;

        this._empresaConsorcioService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listEmpresaConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerEmpresaConsorcio = false;
                console.log("complete");
            }
        );
    }

}
