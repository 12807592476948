import { Component, Input } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from "app/base-module/base-component/base.component";
import { ClienteModel } from 'app/clientes/models/cliente.model';

import { ClientEquityService } from 'app/clientes/services/client-equity.service';
import { ModalDetailNnmComponent } from '../modal-detail-nnm/modal-detail-nnm.component';

@Component({
  selector: 'evolucao-patrimonial',
  templateUrl: './evolucao-patrimonial.component.html',
  styleUrls: ['./evolucao-patrimonial.component.scss'],
  providers: [ ClientEquityService ]
})

export class EvolucaoPatrimonialComponent extends BaseComponent{

    @Input() client: ClienteModel;

    loadingList:boolean = false;
    data:any;

    lengthYear: number;
    rowsYear: any = [];
    tempYear: any;

    constructor( 
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _equityClientService: ClientEquityService,
        private _Activatedroute: ActivatedRoute) 
        { 
            super(dialog, snackBar);
            this.data = this._Activatedroute.snapshot.params['id'];
            this.getVariationEquity();
        }

    getVariationEquity(){
        this.loadingList = true;
        this._equityClientService.getVariation({
                cliente_id: this.data
            }
        ).subscribe(
        equity =>{            
            if(Object.keys(equity.data).length > 0){
                this.length = equity.data['months'].length;
                this.rows = equity.data['months'];
                this.temp = [...this.rows];

                this.lengthYear = equity.data['year'].length;
                this.rowsYear = equity.data['year'];
                this.tempYear = [...this.rowsYear];
            }
        },
        error => {
            this.loadingList = false;
        },
        () => {
            this.loadingList = false;
        });
    }

    getVariationEquityForYear(year = null){
        this.loadingList = true;
        this._equityClientService.getVariationYear({
                cliente_id: this.data,
                year
            }
        ).subscribe(
        equity =>{            
            if(Object.keys(equity.data).length > 0){
                this.length = equity.data['months'].length;
                this.rows = equity.data['months'];
                this.temp = [...this.rows];               
            }
        },
        error => {
            this.loadingList = false;
        },
        () => {
            this.loadingList = false;
        });
    }

    onClickEquityVariationYear(event){
        if(event.type == 'click'){
            this.getVariationEquityForYear(event.row.year);
        }
    }

    onClickModalInfomation(row){
        let dialogRef = this.dialog.open(ModalDetailNnmComponent, {
            minWidth: '980px',
            maxWidth: '99vh',
            maxHeight: '99vh',
            data: {                 
                dataMes: row.date,
                cliente_id: this.data
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            
        });
    }

}
