import {
    Component, 
    ViewChild, 
    TemplateRef
} from '@angular/core';

import {
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator
} from '@angular/material';

import {
    Router, 
    RouterOutlet
} from "@angular/router";

import { FormControl } from "@angular/forms";
import { Subscription } from 'rxjs';
import { SelectionType } from '@swimlane/ngx-datatable';

import {
    BaseComponent, 
    ActionDialog
} from "../../base-module/base-component/base.component";

import { BoletaService } from '../services/boleta.service';
import { FiltrarBoletaComponent } from '../filtrar-boleta/filtrar-boleta.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { AtualizaStatusBoletaComponent } from '../atualiza-status-boleta/atualiza-status-boleta.component';
import { CadastroBoletaExecucaoComponent } from 'app/renda-variavel/boletas/cadastro-boleta-execucao/cadastro-boleta-execucao.component';

@Component({
    selector: 'app-lista-boleta',
    templateUrl: './lista-boleta.component.html',
    styleUrls: ['./lista-boleta.component.scss']
})

export class ListaBoletaComponent extends BaseComponent{

    params: any = {};
    user:any;
    loadingIndicator: boolean;
    public search = new FormControl();
    SelectionType = SelectionType;
    inputSubscription: Subscription; 

    selected = [];
    data:any = {};
    titulo:string = 'Boleta de Renda Variável';

    model: any = {
        status_id: "",
        boletas:[]
    };

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaBoletaComponent, { static: false }) table: ListaBoletaComponent;
    @ViewChild('paginator',{ static: false }) paginator: MatPaginator;

    constructor(public service: BoletaService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                public _authService: AuthService,
                private outlet: RouterOutlet) {
        super(dialog, snackBar);

        this.data = this.outlet.activatedRouteData['tipo'];

        if(this.data == 1){      
            this.params.tipo_boleta = 1;
        }else if(this.data == 2){     
            this.params.tipo_boleta = 2;
        }else{
            this.params.tipo_boleta = 3;
        }
        
        this.user = JSON.parse(this._authService.getUser());
        this.search.setValue('');
        this.getAll();
    }

    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event: any): void {

        this.params.sortColumn = event.column.prop;
        
        if(event.column.name == "Responsavel Operação"){
            this.params.sortColumn = "operador_id";
        }else if(event.column.name == "Escritório"){
            this.params.sortColumn = "escritorio_id";
        }else if(event.column.name == "Conta Cliente"){
            this.params.sortColumn = "numero_xp";
        }else if(event.column.name == "Cliente"){
            this.params.sortColumn = "nome_cliente";
        }else if(event.column.name == "Status"){
            this.params.sortColumn = "status_id";
        }        

        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent): void {

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    getSaldo(row){
        return parseFloat(row.valor) - parseFloat(row.vlr_executado);
    }

    /**
     * Consulta lista de Boletas
     */
    getAll() {
        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.inputSubscription = this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
                
                if(error.status == 401){        
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                }
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de boleta
     * @param boleta
     */
    delete(boleta) {
        var lista = this;

        const valorBoleta = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(this.exibeValor(boleta));

        this.dialog.open(ActionDialog, {
            width: '400px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir boleta",
                text: `Deseja realmente excluir a boleta do cliente ${boleta.nome_cliente} no valor de ${valorBoleta} ?`,
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(boleta.id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    deleteAll(){

        if(this.selected.length == 0){
            this.showError("Selecione pelo menos uma boleta para realizar a exclusão!");
            return false;
        }

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir boleta(s)",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.deleteAll(lista.selected).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    openCadastro(){        
        this.route.navigate(['/movimentacao/boleta/cadastro/'+this.data]);
    }

    openAlterar(id){
        this.route.navigate(['/movimentacao/boleta/cadastro/'+this.data+'/'+id]);
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarBoletaComponent, {
            width: '700px',
            maxHeight: '99vh',
            data: { 
                titulo: 'Filtrar Boleta',
                status_boleta: true,
                status_ordem: false,
                tipo: "RV"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                this.params.data_operacao_inicio    = result.data_operacao_inicio;
                this.params.data_operacao_fim       = result.data_operacao_fim;
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }else{
                    this.params.cliente_id = '';
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.status_id != undefined && result.status_id != null) {
                    this.params.status_id = result.status_id;
                }

                if (result.acao_comercial_id != undefined && result.acao_comercial_id != null) {
                    this.params.acao_comercial_id = result.acao_comercial_id;
                }

                if (result.primeiro_contato_id != undefined && result.primeiro_contato_id != null) {
                    this.params.primeiro_contato_id = result.primeiro_contato_id;
                }else{
                    this.params.primeiro_contato_id = '';
                }

                if (result.estrategia_id != undefined && result.estrategia_id != null) {
                    this.params.estrategia_id = result.estrategia_id;
                }else{
                    this.params.estrategia_id = '';
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    onSelect({ selected }) {        
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    openAtualizar(){

        if(this.selected.length == 0){
            this.showError("Selecione pelo menos uma boleta para realizar a atualização do status");
            return false;
        }

        let dialogRef = this.dialog.open(AtualizaStatusBoletaComponent, {
            width: '700px',
            data: { 
                boletas: this.selected,
                tipo: 'RV'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {               
                this.getAll();
                this.selected = [];
            }
        });
    }

    /**
     * Consulta lista
     */
    getAllExport() {

        this.openLoading();
        this.service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    alterarBoleta(row): boolean
    { 
        if(row.bloqueio && (this.user.role_id != 2 && this.user.role_id != 4 && this.user.role_id != 7 ) ){
            return false;
        }

        if ( 
            (row.status_id != 1 && row.status_id != 2 && row.status_id != 17 && row.status_id != 8 && row.status_id != 9 && row.status_id != 4 && row.status_id != 24  ) 
            && (this.user.role_id != 2 && this.user.role_id != 4 && this.user.role_id != 7 ) 
        ){
            return false;
        }else
        {
            if (this.data == 2 && row.status_id != 17 && (this.user.role_id != 2 && this.user.role_id != 4 && this.user.role_id != 7 )){
                return false;
            }else{
                return true;
            }
           
        }
    }

    aprovar(id){
        
        this.openLoading();
        this.model.status_id = 1;
        this.model.boletas.push({id:id});

        this.service.atualizaStatus(this.model).subscribe(
            data => {
                this.closeLoading();                
                this.showSuccess(data.message);                                                  
            },
            error => {                    
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
                this.getAll();
            }
        );
    }

    aprovarBoletas() {
        
        if(this.selected.length == 0){
            this.showError("Selecione pelo menos uma boleta para realizar a aprovação");
            return false;
        }

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            data: {
                title: "Aprovar boleta",
                text: "Deseja realmente aprovar as boletas selecionadas ?",
                callback: function () {
                    lista.openLoading();

                    lista.model.status_id = 1;
                    lista.model.boletas = lista.selected;

                    lista.service.atualizaStatus(lista.model).subscribe(
                        data => {
                            lista.closeLoading();                
                            lista.showSuccess(data.message);                                                  
                        },
                        error => {                    
                            lista.closeLoading();
                            lista.showError(error.error.message);
                        },
                        () => {
                            lista.closeLoading();
                            lista.getAll();
                            this.selected = [];
                        }
                    );
                }
            }
        });
    }

    exibeValor(row){        
        if(parseFloat(row.vlr_executado) < (parseFloat(row.valor) + parseFloat(row.valor_total))  && (parseFloat(row.vlr_executado) > 0 && row.status_id == 10) ){
            return parseFloat(row.vlr_executado);
        }else{
            return parseFloat(row.valor) + parseFloat(row.valor_total);
        }           
    }

    bloqueiarBoletas(tipo){
        this.openLoading();

        let status;

        if(tipo == 'B'){
            status = true;
        }else{
            status = false;
        }    
        
        this.service.bloqueio({ bloqueio: status  }).subscribe(
            data => {
                console.log(data);
                this.closeLoading();
                this.showSuccess(data.message);
            },
            error => {                    
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
                this.getAll();
            }
        );
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    onEnter(value: string) {    
        this.stopNow();     
        this.params.search = value;
        this.search.setValue(value);
        this.getAll();
    }

    openCadastroBoletaExecucacao(boleta_id){
        let dialogRef = this.dialog.open(CadastroBoletaExecucaoComponent, {
            width: '800px',
            maxHeight: '99vh',
            data: { 
                id: null,
                boleta_id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {               
                this.getAll();                
            }
        });
    }

    getRowClass = (row) => {
        const chave = row.cor_hexadecimal;
        return { [chave] : true};
    }

}
