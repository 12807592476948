import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable()
export class UtilitesService {


    public urlPrefix 	= environment.url;
    public server 		= environment.server;
    public localCliente = "http://localhost:8001/";

    constructor() {
    }


    /**
     * Converte objeto JSON em String no formato de parâmetro http para requisição GET
     * @param data
     * @returns {any}
     */
    formurlencoded(data: any) {
        const opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        let sorted = Boolean(opts.sorted),
            skipIndex = Boolean(opts.skipIndex),
            ignorenull = Boolean(opts.ignorenull),
            encode = function encode(value) {
                return String(value).replace(/(?:[\0-\x1F"-&\+-\}\x7F-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])/g, encodeURIComponent).replace(/ /g, '+').replace(/[!'()~\*]/g,
                    function (ch: any) {
                        return '%' + ch.charCodeAt().toString(16).slice(-2).toUpperCase();
                    });
            },
            keys = function keys(obj) {
                const keyarr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Object.keys(obj);
                return sorted ? keyarr.sort() : keyarr;
            },
            filterjoin = function filterjoin(arr) {
                return arr.filter(function (e) {
                    return e;
                }).join('&');
            },
            objnest = function objnest(name, obj) {
                return filterjoin(keys(obj).map(function (key) {
                    return nest(name + '[' + key + ']', obj[key]);
                }));
            },
            arrnest = function arrnest(name, arr) {
                return arr.length ? filterjoin(arr.map(function (elem, index) {
                    return skipIndex ? nest(name + '[]', elem) : nest(name + '[' + index + ']', elem);
                })) : encode(name + '[]');
            },
            nest = function nest(name, value) {
                const type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : typeof value === 'undefined' ? 'undefined' : typeof(value);
                let f = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

                if (value === f) f = ignorenull ? f : encode(name) + '=' + f; else if (/string|number|boolean/.test(type)) f = encode(name) + '=' + encode(value); else if (Array.isArray(value)) f = arrnest(name, value); else if (type === 'object') f = objnest(name, value);

                return f;
            };

        return data && filterjoin(keys(data).map(function (key) {
            return nest(key, data[key]);
        }));
    }

    public timeDiff(data1, data2) {
        var d1 = new Date(data1).getTime();
        var d2 = new Date(data2).getTime();
        var df = Math.abs(d1 - d2);
        var td = {
            d: Math.round(df / (24 * 60 * 60 * 1000)), //dias
            h: Math.round(df / (60 * 60 * 1000)), //horas
            m: Math.abs(Math.round(df / (60 * 1000)) - (60 * 1000)), //minutos
            s: Math.abs(Math.round(df / 1000) - 1000)
        };
        var result = '';
        td.d > 0 ? result += td.d + ' dias ' : '';
        td.h > 0 ? result += ('0' + td.h).slice(-2) + ':' : '00:';
        td.m > 0 ? result += ('0' + td.m).slice(-2) + ':' : '00:';
        td.s > 0 ? result += ('0' + td.s).slice(-2) : '00';
        return result;
    }

    public setOnline(online:boolean){
        window.localStorage.setItem('online', JSON.stringify(online));
    }

    public getOnline(){
        return window.localStorage.getItem('online');
    }

    /**
     * Dia, atual, da semana
     */
    public diaDaSemana() {
        var semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

        var teste = new Date();
        var dia = teste.getDay();
        return semana[dia];
    }

    /**
     * Hora atual
     */
    public hora() {
        var horas = new Date().getHours();
        var horasStr: String = ("00" + horas).slice(-2);

        var minutos = new Date().getMinutes();
        var minutosStr: String = ("00" + minutos).slice(-2);

        return horasStr + ":" + minutosStr;
    }

    /**
     * Mes atual
     */
    public mesAtual() {
        var data = new Date();
        var mes = data.getMonth() + 1;
        var mesStr: String = "";
        switch (mes) {
            case 1:
                mesStr = "Janeiro";
                break;
            case 2:
                mesStr = "Fevereiro";
                break;
            case 3:
                mesStr = "Março";
                break;
            case 4:
                mesStr = "Abril";
                break;
            case 5:
                mesStr = "Maio";
                break;
            case 6:
                mesStr = "Junho";
                break;
            case 7:
                mesStr = "Julho";
                break;
            case 8:
                mesStr = "Agosto";
                break;
            case 9:
                mesStr = "Setembro";
                break;
            case 10:
                mesStr = "Outubro";
                break;
            case 11:
                mesStr = "Novembro";
                break;

            default:
                mesStr = "Dezembro";
                break;
        }

        return mesStr;
    }

    /**
     * Data de hoje
     */
    public dataHoje() {
        var data = new Date();
        var ano = data.getFullYear();
        var dia = data.getDate();
        var diaStr: String = ("00" + dia).slice(-2);

        var mes = data.getMonth() + 1;
        var mesStr: String = ("00" + mes).slice(-2);

        var horas = new Date().getHours();
        var horasStr: String = ("00" + horas).slice(-2);

        var minutos = new Date().getMinutes();
        var minutosStr: String = ("00" + minutos).slice(-2);

        var result = diaStr + "/" + mesStr + "/" + ano + " - " + horasStr + "h" + minutosStr;
        return result;
    }

    public getDataDmy(){
        var data = new Date();
        var ano = data.getFullYear();
        var dia = data.getDate();
        var diaStr: String = ("00" + dia).slice(-2);

        var mes = data.getMonth() + 1;
        var mesStr: String = ("00" + mes).slice(-2);

        var result = diaStr + "/" + mesStr + "/" + ano;
        return result;
    }

    /**
     * Data por extenso
     */
    public dataPorExtenso() {

        var data = new Date();
        var ano = data.getFullYear();
        var dia = data.getDate();

        return dia + " de " + this.mesAtual() + " de " + ano;
    }

    /**
     * Retorna Bom dia, Boa tarde ou Boa noite
     */
    public comprimentar() {
        var d = new Date();
        var n = d.getHours();

        if (n <= 12) {
            return "Bom dia";
        } else if (n <= 18) {
            return "Boa tarde";
        }

        return "Boa noite";
    }

    /**
     * Converter string em Date dd/mm/yyyy
     * @param {string} dateString
     * @returns {Date}
     */
    public convertStringToDate(dateString:string){
        var dateParts = dateString.split("/");
        var dateObject = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0])); // month is 0-based
        return dateObject;
    }

    /**
     * Converter string em Date yyyy-mm-dd hh:mm:ss
     * @param {string} dateString
     * @returns {Date}
     */
    public convertToDate(dateString:string){
        var dateObject = new Date(dateString); // month is 0-based
        return dateObject;
    }

    public generateUrl (str){
        str = String(str).toString();
        str = str.replace(/^\s+|\s+$/g, ""); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        const swaps = {
            '0': ['°', '₀', '۰', '０'],
            '1': ['¹', '₁', '۱', '１'],
            '2': ['²', '₂', '۲', '２'],
            '3': ['³', '₃', '۳', '３'],
            '4': ['⁴', '₄', '۴', '٤', '４'],
            '5': ['⁵', '₅', '۵', '٥', '５'],
            '6': ['⁶', '₆', '۶', '٦', '６'],
            '7': ['⁷', '₇', '۷', '７'],
            '8': ['⁸', '₈', '۸', '８'],
            '9': ['⁹', '₉', '۹', '９'],
            'a': ['à', 'á', 'ả', 'ã', 'ạ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ā', 'ą', 'å', 'α', 'ά', 'ἀ', 'ἁ', 'ἂ', 'ἃ', 'ἄ', 'ἅ', 'ἆ', 'ἇ', 'ᾀ', 'ᾁ', 'ᾂ', 'ᾃ', 'ᾄ', 'ᾅ', 'ᾆ', 'ᾇ', 'ὰ', 'ά', 'ᾰ', 'ᾱ', 'ᾲ', 'ᾳ', 'ᾴ', 'ᾶ', 'ᾷ', 'а', 'أ', 'အ', 'ာ', 'ါ', 'ǻ', 'ǎ', 'ª', 'ა', 'अ', 'ا', 'ａ', 'ä'],
            'b': ['б', 'β', 'ب', 'ဗ', 'ბ', 'ｂ'],
            'c': ['ç', 'ć', 'č', 'ĉ', 'ċ', 'ｃ'],
            'd': ['ď', 'ð', 'đ', 'ƌ', 'ȡ', 'ɖ', 'ɗ', 'ᵭ', 'ᶁ', 'ᶑ', 'д', 'δ', 'د', 'ض', 'ဍ', 'ဒ', 'დ', 'ｄ'],
            'e': ['é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'ë', 'ē', 'ę', 'ě', 'ĕ', 'ė', 'ε', 'έ', 'ἐ', 'ἑ', 'ἒ', 'ἓ', 'ἔ', 'ἕ', 'ὲ', 'έ', 'е', 'ё', 'э', 'є', 'ə', 'ဧ', 'ေ', 'ဲ', 'ე', 'ए', 'إ', 'ئ', 'ｅ'],
            'f': ['ф', 'φ', 'ف', 'ƒ', 'ფ', 'ｆ'],
            'g': ['ĝ', 'ğ', 'ġ', 'ģ', 'г', 'ґ', 'γ', 'ဂ', 'გ', 'گ', 'ｇ'],
            'h': ['ĥ', 'ħ', 'η', 'ή', 'ح', 'ه', 'ဟ', 'ှ', 'ჰ', 'ｈ'],
            'i': ['í', 'ì', 'ỉ', 'ĩ', 'ị', 'î', 'ï', 'ī', 'ĭ', 'į', 'ı', 'ι', 'ί', 'ϊ', 'ΐ', 'ἰ', 'ἱ', 'ἲ', 'ἳ', 'ἴ', 'ἵ', 'ἶ', 'ἷ', 'ὶ', 'ί', 'ῐ', 'ῑ', 'ῒ', 'ΐ', 'ῖ', 'ῗ', 'і', 'ї', 'и', 'ဣ', 'ိ', 'ီ', 'ည်', 'ǐ', 'ი', 'इ', 'ی', 'ｉ'],
            'j': ['ĵ', 'ј', 'Ј', 'ჯ', 'ج', 'ｊ'],
            'k': ['ķ', 'ĸ', 'к', 'κ', 'Ķ', 'ق', 'ك', 'က', 'კ', 'ქ', 'ک', 'ｋ'],
            'l': ['ł', 'ľ', 'ĺ', 'ļ', 'ŀ', 'л', 'λ', 'ل', 'လ', 'ლ', 'ｌ'],
            'm': ['м', 'μ', 'م', 'မ', 'მ', 'ｍ'],
            'n': ['ñ', 'ń', 'ň', 'ņ', 'ŉ', 'ŋ', 'ν', 'н', 'ن', 'န', 'ნ', 'ｎ'],
            'o': ['ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ø', 'ō', 'ő', 'ŏ', 'ο', 'ὀ', 'ὁ', 'ὂ', 'ὃ', 'ὄ', 'ὅ', 'ὸ', 'ό', 'о', 'و', 'θ', 'ို', 'ǒ', 'ǿ', 'º', 'ო', 'ओ', 'ｏ', 'ö'],
            'p': ['п', 'π', 'ပ', 'პ', 'پ', 'ｐ'],
            'q': ['ყ', 'ｑ'],
            'r': ['ŕ', 'ř', 'ŗ', 'р', 'ρ', 'ر', 'რ', 'ｒ'],
            's': ['ś', 'š', 'ş', 'с', 'σ', 'ș', 'ς', 'س', 'ص', 'စ', 'ſ', 'ს', 'ｓ'],
            't': ['ť', 'ţ', 'т', 'τ', 'ț', 'ت', 'ط', 'ဋ', 'တ', 'ŧ', 'თ', 'ტ', 'ｔ'],
            'u': ['ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'û', 'ū', 'ů', 'ű', 'ŭ', 'ų', 'µ', 'у', 'ဉ', 'ု', 'ူ', 'ǔ', 'ǖ', 'ǘ', 'ǚ', 'ǜ', 'უ', 'उ', 'ｕ', 'ў', 'ü'],
            'v': ['в', 'ვ', 'ϐ', 'ｖ'],
            'w': ['ŵ', 'ω', 'ώ', 'ဝ', 'ွ', 'ｗ'],
            'x': ['χ', 'ξ', 'ｘ'],
            'y': ['ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'ÿ', 'ŷ', 'й', 'ы', 'υ', 'ϋ', 'ύ', 'ΰ', 'ي', 'ယ', 'ｙ'],
            'z': ['ź', 'ž', 'ż', 'з', 'ζ', 'ز', 'ဇ', 'ზ', 'ｚ'],
            'aa': ['ع', 'आ', 'آ'],
            'ae': ['æ', 'ǽ'],
            'ai': ['ऐ'],
            'ch': ['ч', 'ჩ', 'ჭ', 'چ'],
            'dj': ['ђ', 'đ'],
            'dz': ['џ', 'ძ'],
            'ei': ['ऍ'],
            'gh': ['غ', 'ღ'],
            'ii': ['ई'],
            'ij': ['ĳ'],
            'kh': ['х', 'خ', 'ხ'],
            'lj': ['љ'],
            'nj': ['њ'],
            'oe': ['ö', 'œ', 'ؤ'],
            'oi': ['ऑ'],
            'oii': ['ऒ'],
            'ps': ['ψ'],
            'sh': ['ш', 'შ', 'ش'],
            'shch': ['щ'],
            'ss': ['ß'],
            'sx': ['ŝ'],
            'th': ['þ', 'ϑ', 'ث', 'ذ', 'ظ'],
            'ts': ['ц', 'ც', 'წ'],
            'ue': ['ü'],
            'uu': ['ऊ'],
            'ya': ['я'],
            'yu': ['ю'],
            'zh': ['ж', 'ჟ', 'ژ'],
            '(c)': ['©'],
            'A': ['Á', 'À', 'Ả', 'Ã', 'Ạ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Å', 'Ā', 'Ą', 'Α', 'Ά', 'Ἀ', 'Ἁ', 'Ἂ', 'Ἃ', 'Ἄ', 'Ἅ', 'Ἆ', 'Ἇ', 'ᾈ', 'ᾉ', 'ᾊ', 'ᾋ', 'ᾌ', 'ᾍ', 'ᾎ', 'ᾏ', 'Ᾰ', 'Ᾱ', 'Ὰ', 'Ά', 'ᾼ', 'А', 'Ǻ', 'Ǎ', 'Ａ', 'Ä'],
            'B': ['Б', 'Β', 'ब', 'Ｂ'],
            'C': ['Ç', 'Ć', 'Č', 'Ĉ', 'Ċ', 'Ｃ'],
            'D': ['Ď', 'Ð', 'Đ', 'Ɖ', 'Ɗ', 'Ƌ', 'ᴅ', 'ᴆ', 'Д', 'Δ', 'Ｄ'],
            'E': ['É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Ë', 'Ē', 'Ę', 'Ě', 'Ĕ', 'Ė', 'Ε', 'Έ', 'Ἐ', 'Ἑ', 'Ἒ', 'Ἓ', 'Ἔ', 'Ἕ', 'Έ', 'Ὲ', 'Е', 'Ё', 'Э', 'Є', 'Ə', 'Ｅ'],
            'F': ['Ф', 'Φ', 'Ｆ'],
            'G': ['Ğ', 'Ġ', 'Ģ', 'Г', 'Ґ', 'Γ', 'Ｇ'],
            'H': ['Η', 'Ή', 'Ħ', 'Ｈ'],
            'I': ['Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Î', 'Ï', 'Ī', 'Ĭ', 'Į', 'İ', 'Ι', 'Ί', 'Ϊ', 'Ἰ', 'Ἱ', 'Ἳ', 'Ἴ', 'Ἵ', 'Ἶ', 'Ἷ', 'Ῐ', 'Ῑ', 'Ὶ', 'Ί', 'И', 'І', 'Ї', 'Ǐ', 'ϒ', 'Ｉ'],
            'J': ['Ｊ'],
            'K': ['К', 'Κ', 'Ｋ'],
            'L': ['Ĺ', 'Ł', 'Л', 'Λ', 'Ļ', 'Ľ', 'Ŀ', 'ल', 'Ｌ'],
            'M': ['М', 'Μ', 'Ｍ'],
            'N': ['Ń', 'Ñ', 'Ň', 'Ņ', 'Ŋ', 'Н', 'Ν', 'Ｎ'],
            'O': ['Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ø', 'Ō', 'Ő', 'Ŏ', 'Ο', 'Ό', 'Ὀ', 'Ὁ', 'Ὂ', 'Ὃ', 'Ὄ', 'Ὅ', 'Ὸ', 'Ό', 'О', 'Θ', 'Ө', 'Ǒ', 'Ǿ', 'Ｏ', 'Ö'],
            'P': ['П', 'Π', 'Ｐ'],
            'Q': ['Ｑ'],
            'R': ['Ř', 'Ŕ', 'Р', 'Ρ', 'Ŗ', 'Ｒ'],
            'S': ['Ş', 'Ŝ', 'Ș', 'Š', 'Ś', 'С', 'Σ', 'Ｓ'],
            'T': ['Ť', 'Ţ', 'Ŧ', 'Ț', 'Т', 'Τ', 'Ｔ'],
            'U': ['Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Û', 'Ū', 'Ů', 'Ű', 'Ŭ', 'Ų', 'У', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'Ｕ', 'Ў', 'Ü'],
            'V': ['В', 'Ｖ'],
            'W': ['Ω', 'Ώ', 'Ŵ', 'Ｗ'],
            'X': ['Χ', 'Ξ', 'Ｘ'],
            'Y': ['Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ', 'Ÿ', 'Ῠ', 'Ῡ', 'Ὺ', 'Ύ', 'Ы', 'Й', 'Υ', 'Ϋ', 'Ŷ', 'Ｙ'],
            'Z': ['Ź', 'Ž', 'Ż', 'З', 'Ζ', 'Ｚ'],
            'AE': ['Æ', 'Ǽ'],
            'Ch': ['Ч'],
            'Dj': ['Ђ'],
            'Dz': ['Џ'],
            'Gx': ['Ĝ'],
            'Hx': ['Ĥ'],
            'Ij': ['Ĳ'],
            'Jx': ['Ĵ'],
            'Kh': ['Х'],
            'Lj': ['Љ'],
            'Nj': ['Њ'],
            'Oe': ['Œ'],
            'Ps': ['Ψ'],
            'Sh': ['Ш'],
            'Shch': ['Щ'],
            'Ss': ['ẞ'],
            'Th': ['Þ'],
            'Ts': ['Ц'],
            'Ya': ['Я'],
            'Yu': ['Ю'],
            'Zh': ['Ж'],
        };

        Object.keys(swaps).forEach((swap) => {
                swaps[swap].forEach(s => {
                str = str.replace(new RegExp(s, "g"), swap);
            })
        });

        return str
            .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
            .replace(/\s+/g, "-") // collapse whitespace and replace by -
            .replace(/-+/g, "-") // collapse dashes
            .replace(/^-+/, "") // trim - from start of text
            .replace(/-+$/, "");
    };

    /**
     * Return yyyy-mm-dd
     */
    public formatDateToString(date: Date): string {
        let day: string = date.getDate().toString();
        day = +day < 10 ?'0' + day : day;
        let month: string = (date.getMonth() + 1).toString();
        month = +month < 10 ?'0' + month : month;
        let year = date.getFullYear();
        return year+"-"+month+"-"+day;
    }

}
