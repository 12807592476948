import { Component }  from '@angular/core';

import {
    MatDialog, 
    MatSnackBar, 
    PageEvent    
}  from '@angular/material';

import { Subscription, Subject} from "rxjs";
import {  RouterOutlet } from '@angular/router';

import {BaseComponent} from "../../../../base-module/base-component/base.component";

import {AuthService } from 'app/base-module/base-service/auth.service';

import { RendaFixaComissionamentoService } from 'app/renda-fixa/services/renda-fixa-comissionamento.service';
import { UploadRendaFixaComponent } from '../../upload-renda-fixa/upload-renda-fixa.component';


@Component({
    selector: 'app-lista-comissionamento-rf',
    templateUrl: './lista-comissionamento-rf.component.html',
    styleUrls: ['./lista-comissionamento-rf.component.scss'],
    providers: [ RendaFixaComissionamentoService ]
})

export class ListaComissionamentoRfComponent extends BaseComponent{

    params: any = {};
    user:any;
    loadingIndicator: boolean;    
    inputSubscription: Subscription;         
    dialogRef: any;            
    refresh: Subject<any> = new Subject();
    model:any = {};
    title: string;
    enableSummary = true;
    summaryPosition = 'top';
    totalComission: number;
    totalFinacialVolume: number;

    constructor(public _service: RendaFixaComissionamentoService,
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                public _authService: AuthService,
                private outlet: RouterOutlet) 
    {
        super(dialog,snackBar);

        this.params.mercado_id = this.outlet.activatedRouteData['mercado_id'];

        this.user = JSON.parse(this._authService.getUser());
        this.getAll();
        this.getTitle();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    getTitle(){
        
        if(this.params.mercado_id == 5){
            this.title = 'Renda Fixa';
        }else{
            this.title = 'COE';
        } 
    }

    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */

    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */

    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de eventos programados
     */
    getAll() {
        this.loadingIndicator = true;
        this.inputSubscription = this._service.getAll(this.params)
        .subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
        this.refresh.next();
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    openCadastro(): void {
        let dialogRef = this.dialog.open(UploadRendaFixaComponent, {
            width: '400px', 
            data:{
                url: 'comissionamento/importacao/renda-fixa-secundario/upload',
                title: 'Upload Comissão Secundário',
                urlModelDownload: 'downloads/modelo_importacao_renda_fixa.xlsx'
            }          
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){                
                this.getAll();
            }
        });
    }

    /**
     * Modal de edição
     */
    openFiltrar(): void {
        /*let dialogRef = this.dialog.open(FiltrarDemonstrativoComponent, {
            disableClose: true,
            width: '700px',
            data: {
                layout: "importacao"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel") {
                this.params.mes_ano_referencia_inicio = result.mes_ano_referencia_inicio;
                this.params.mes_ano_referencia_fim = result.mes_ano_referencia_fim;

                this.params.ativo_id = result.ativo_id;
                this.params.cliente_id = result.cliente_id;
                this.params.mercado_id = result.mercado_id;
                this.params.categoria_id = result.categoria_id;
                this.params.produto_id = result.produto_id;
                this.params.user_id = result.user_id;
                this.getAll();
            }
        });*/
    }

    /**
     * Exporta demonstrativo
     */
    export() {

        this.openLoading();
        this._service.exportList(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

}
