import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { TipoBasketService } from '../services/tipo-basket.service';


export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-filtra-basket',
    templateUrl: './filtra-basket.component.html',
    styleUrls: ['./filtra-basket.component.scss']
})

export class FiltraBasketComponent extends BaseComponent {
    
    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

    rotulo                  = new FormControl('');    
    data_operacao_inicio    = new FormControl(this.primeiroDia);
    data_operacao_fim       = new FormControl(this.ultimoDia);    
    tipo_id                 = new FormControl('');

    paramsStatus: any = {
        finalizada: ""
    }

    loadingSpinner: boolean;
    listTipos: Object[] = [];
    

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltraBasketComponent, { static: false }) table: FiltraBasketComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltraBasketComponent>,        
        private _utilitesService: UtilitesService,        
        private _tipoBasketService: TipoBasketService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);
        
        
        this.getAllTipos();
    }


    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(): void {


        var inic = null;
        if (this.data_operacao_inicio.value != null){
            inic = this._utilitesService.formatDateToString(new Date(this.data_operacao_inicio.value));
        }

        var fim = null;
        if (this.data_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(new Date(this.data_operacao_fim.value));
        }
        
        this.dialogRef.close({
            'tipo_basket_id': this.tipo_id.value,
            'data_operacao_inicio': inic,
            'data_operacao_fim': fim,
            'nome_arquivo': this.rotulo.value,            
        });
    }

    
    getAllTipos(): void {
        this.loadingSpinner = true;
        this._tipoBasketService.getAll({ no_paginate: true })
            .subscribe(
                resquest => {
                    this.listTipos = resquest.data;
                },
                error => {
                    this.loadingSpinner = false;
                },
                () => {
                    this.loadingSpinner = false;                    
                }
            );
    }
    

}