import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation, 
    Input,
    OnInit
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent 
} from '@angular/material';

import { FormControl } from '@angular/forms';
import { BaseComponent } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { AcaoService } from '../../auxiliares/services/acao-service';
import { ClienteOportunidadeService } from '../services/cliente-oportunidade.service';
import { ActionDialog } from 'app/base-module/base-component/base.component';
import { CadastroOportunidadeComponent } from '../cadastro-oportunidade/cadastro-oportunidade.component';
import { FiltrarRelacionamentoComponent } from '../filtrar-relacionamento/filtrar-relacionamento.component';
import { Subscription } from 'rxjs';
import { RespostaAtividadeComponent } from 'app/lista-atividades/resposta-atividade/resposta-atividade.component';

@Component({
    selector: "lista-oportunidade-cliente",
    templateUrl: "./lista-oportunidade-cliente.component.html",
    styleUrls: ["./lista-oportunidade-cliente.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ AcaoService ],
})

export class ListaOportunidadeClienteComponent extends BaseComponent implements OnInit {
    public params: any = {};
    public loadingIndicator: boolean;
    public search = new FormControl();
    usuario:any;
    inputSubscription: Subscription;

    @Input() searchParam: FormControl;
    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @Input() cliente_id: number;

    constructor(
        public _service: ClienteOportunidadeService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public authService: AuthService
    ) {        
        super(dialog, snackBar);    
        
        this.usuario = JSON.parse(this.authService.getUser());
        this.search.setValue("");
    }

    ngOnInit(): void {
        this.searchParam.valueChanges.subscribe(val => {
            this.stopNow();
            this.params.search = val;
            this.getAll();
        });
        this.params.cliente_id = this.cliente_id;

        this.getAll();
    }
    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent) {
        this.params.page = event.pageIndex + 1;
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista
     */
    getAll() {
        this.loadingIndicator = true;
        this.inputSubscription = this._service.getAll(this.params).subscribe(
            (data) => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            (error) => {               
                this.loadingIndicator = false;
            },
            () => {               
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarRelacionamentoComponent, {
            width: '700px',
            data:{
                cliente: true,
                titulo: 'FILTRAR OPORTUNIDADE',
                type_page: 'oportunidade'
            }          
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                this.clearFilter();
                if (result.data_inicio != undefined && result.data_inicio != null) {                    
                    this.params.data_inicio = result.data_inicio;                    
                }

                if (result.data_fim != undefined && result.data_fim != null) {
                    this.params.data_fim = result.data_fim;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }else{
                    this.params.escritorio_id = '';
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }else{
                    this.params.assessor_id = '';
                }

                if (result.acao_id != undefined && result.acao_id != null) {
                    this.params.acao_id = result.acao_id;
                }

                if (result.cliente != undefined && result.cliente != null) {
                    this.params.cliente_id = result.cliente.id;
                }else{
                    this.params.cliente_id = '';
                }

                if (result.assunto_id != undefined && result.assunto_id != null) {
                    this.params.assunto_id = result.assunto_id;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    clearFilter(): void {
        this.params.data_inicio = null;
        this.params.data_fim = null;
        this.params.escritorio_id = null;
        this.params.assessor_id = null;
        this.params.acao_id = null;
        this.params.cliente_id = null;
        this.params.assunto_id = null;
    }

    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroOportunidadeComponent, {
            width: "500px",
            data: {
                cliente_id: this.cliente_id,
                cliente: true,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Oportunidade cadastrada com sucesso!");
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar cadastrar o registro."
                );
            }
        });
    }

    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastroOportunidadeComponent, {
            width: "500px",
            data: {
                cliente_id: this.cliente_id,
                id: id,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Oportunidade atualizada com sucesso!");
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar atualizar o registro."
                );
            }
        });
    }

    exportar(){
        this.openLoading();

        this._service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    /**
     * Exclui Oportunidade
     * @param id
     */
    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: "250px",
            disableClose: true,
            panelClass: "modal-delete",
            data: {
                title: "Excluir Oportunidade",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        (data) => {
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        (error) => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);
                        },
                        () => {
                            console.log("complete");
                        }
                    );
                },
            },
        });
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    openResposta(row){
        if(row.atividade != null && row.atividade.atividade_clientes != null)
        this.dialog.open(RespostaAtividadeComponent, {
            width: '500px',
            data: { 
                cliente_aceitou: row.atividade.atividade_clientes[0].cliente_aceitou,
                data_resposta: row.atividade.atividade_clientes[0].data_resposta,
                observacao: row.atividade.atividade_clientes[0].observacao,
                valor: row.atividade.atividade_clientes[0].valor,
                assunto: row.atividade.assunto.nome
            }
        });
    }

    isResponse(row) {
        return ((row.atividade != null && row.atividade.atividade_clientes != null) && row.atividade.atividade_clientes[0].data_resposta != null);
    }
}
