import {Component, ViewChild  } from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog, MatSnackBar, MatRadioChange, MatTable, MatSelectChange} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent, ActionDialog } from "../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BoletaUnicaService } from '../services/boleta-unica.service';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { CarteiraService } from 'app/clientes/services/carteira.service';
import { AcaoComercialService } from 'app/acao-comercial/services/acao-comercial.service';

@Component({
    selector: 'app-cadastro-boleta-unica',
    templateUrl: './cadastro-boleta-unica.component.html',
    styleUrls: ['./cadastro-boleta-unica.component.scss']
})

export class CadastroBoletaUnicaComponent extends BaseComponent {

    filteredOptions: Observable<string[]>;

    ClienteSelecionado:any;
    data:any = {};
    tipo:any = {};
    acao_comercial:any = {};
    listStatus:any[]=[];
    listCustodia:any[]=[];
    listAcaoComercial:any[]=[];
    valorBoletado:any;
    titulo: string;
    retornoLista: string;
    valor_custodia:any;
    validacao_quantidade:any = {
        data: ''
    };

    cliente         = new FormControl('', );
    cliente_id      = new FormControl('', [Validators.required]);
    valor           = new FormControl('');
    assessor_id     = new FormControl('', []);
    data_operacao   = new FormControl(new Date(), [Validators.required]);
    statusBoleta    = new FormControl('Informe o cliente...', []);
    status_id       = new FormControl('', [Validators.required]);
    numero_xp       = new FormControl('', []);
    telefone        = new FormControl('', []);
    email           = new FormControl('', []);
    escritorio_id   = new FormControl('', []);
    escritorio      = new FormControl('', []);
    observacao      = new FormControl('', []);
    volume_global       = new FormControl('', []);    
    valor_investimento  = new FormControl('', []);
    tipo_boleta         = new FormControl('', []);
    saldo               = new FormControl(0, []);
    ativo               = new FormControl('');
    qtd                 = new FormControl('');
    tipo_ativo          = new FormControl('', [Validators.required]);

    tipo_posicao = new FormControl('');
    tipo_preco = new FormControl('');
    valor_mercado = new FormControl('');
    quantidade_valor = new FormControl('');
    preco_executado = new FormControl('');
    custodia_id = new FormControl('');
    acao_comercial_id = new FormControl('');

    model:any = {
        cliente_id: "",
        valor: "",
        observacao: "",
        data_operacao: "",
        status_id: "",
        tipo_boleta: "",
        acao_comercial_id: "",
    }

    paramsStatus:any ={
        finalizada:""
    }

    paramsCliente:any = {};
    paramsAtivo:any = {};
    paramsCarteira:any = {};
    
    user:any;
    new_cliente_id:any;
    loadingSpinner:boolean;
    loadingSpinnerStatus:boolean;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerCustodia: boolean;
    loadingIndicator:boolean = false;
    exibeQuantidade: boolean = false;
    exibePreco: boolean = false;
    exibeValor: boolean = false;
    desativaOpcoes: boolean = true;
    ativaVenda: boolean = true;
    loadingSpinnerAcaoComercial:boolean;
    filteredOptionsAtivo: Observable<string[]>;

    @ViewChild(MatTable, {static: true}) table: MatTable<any>;
    
    constructor(private _service: BoletaUnicaService,
                private _clienteService: ClienteService,
                private _statusService: StatusMovimentacaoService,
                private _Activatedroute: ActivatedRoute,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private route: Router,
                private _ativoService: AtivoService,
                private outlet: RouterOutlet,
                private _authService: AuthService,
                private _carteiraService: CarteiraService,
                private _serviceAcaoComercial: AcaoComercialService) 
    {
        super(dialog, snackBar);

        this.data = this._Activatedroute.snapshot.params['id'];
        this.tipo = this.outlet.activatedRouteData['tipo'];
        this.acao_comercial = this._Activatedroute.snapshot.params['acao_comercial_id'];

        this.new_cliente_id = this._Activatedroute.snapshot.params['cliente_id'];

        if( this.acao_comercial != null && this.acao_comercial != undefined){
            this.tipo_ativo.setValue('V');
        }

        this.user = JSON.parse(this._authService.getUser());

        this.listStatus = [];

        this.getAllAcaoComercial();
        
        if (this.data != null && this.data != undefined){
            this.getBoleta();
            this.getAllStatus();
        }

        if (this.new_cliente_id != undefined && this.new_cliente_id != ''){
            this.getDadosCliente(this.new_cliente_id);
        }

       

        this.cliente.valueChanges.subscribe(
            query => {
                if(typeof query === "string" || typeof query === "number"){
                    this.loadingSpinner  = true;
                    this.paramsCliente.search 	 = query;
                    this.paramsCliente.homebroker = false;
                    this.filteredOptions = this._clienteService.search(this.paramsCliente);   
                }
            }   
        );

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.paramsAtivo.search = query;                    
                    this.filteredOptionsAtivo = this._ativoService.search(this.paramsAtivo);
                }
            }
        );

        if(this.tipo_ativo.value == 'B'){
            this.tipoBoletaBloqueado();
        }else{
            this.desativaOpcoes = true;
            this.ativaVenda     = true;
            this.exibeQuantidade = false;
        }

        if (this.tipo_ativo.value == 'V' ){
            this.ativaVenda = false;
        }
        
    }

    isInvalidForm(){
        
        if (!this.cliente_id.valid){
            return true;
        }

        if (!this.valor.valid){
            return true;
        }

        if (!this.data_operacao.valid){
            return true;
        }

        return false;
    }


    getErrorVolumeGlobalMessage() {
        return this.valor.hasError('required') ? 'Valor Movimentado é um campo obrigatório' : '';
    }

    retornarLista():void{
        this.route.navigate(['/movimentacao/boleta-unica']);
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
        this.cliente_id.setValue(cliente.id);        
        this.volume_global.setValue(cliente.volume_global);

        if(cliente.carteira != undefined && cliente.carteira != null && cliente.carteira.length > 0){            
            this.valor_custodia = parseFloat(cliente.carteira[0].valor_total);
            this.valor_investimento.setValue( parseFloat(cliente.valor_investimento) - ( parseFloat(cliente.valor_bloqueado) + parseFloat(cliente.carteira[0].valor_total) ) );
        }else{
            this.valor_investimento.setValue( parseFloat(cliente.valor_investimento) - parseFloat(cliente.valor_bloqueado));
        }

        this.getAllCarteiraCliente();
    }

    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.numero_xp+' - '+cliente.nome; 
        }
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    getByClienteBoleta(){
        
        this._service.getByClienteCount(this.cliente_id.value).subscribe(
            result=>{
                if(result.data.qtd == 0){
                    this.statusBoleta.setValue('Primeira Operação');
                    this.status_id.setValue(2);
                }else{
                    this.statusBoleta.setValue('Boleta MRV');
                    this.status_id.setValue(1);
                }
            },
            error => {
            },
            () => {
                console.log("complete");
            }
        );
    }
    
    
    validaCadastro(){
        
        //Valida Cadastro de Boleta Unica

        const ativoSelecionado = this.ativo.value;

        if (this.tipo_ativo.value != 'B' && this.ativo.value == ''){
            this.showError('Escolha um Ativo!');
            return false;
        }

        if (this.tipo_ativo.value === 'B' && this.custodia_id.value === ''){
            this.showError('Escolha uma Custódia!');
            return false;
        }        

        if (this.tipo_ativo.value != 'B' && this.tipo_preco.value == ''){
            this.showError('Escolha uma opção:  Definir Preço ou Valor a Mercado');
            return false;
        } 
        
        if (this.tipo_ativo.value != 'B' && this.tipo_ativo.value != 'V' && this.tipo_posicao.value == ''){
            this.showError('Escolha uma opção: Bloquear Custódia ou Disponivel P/ Venda');
            return false;
        } 
        
        if (this.tipo_ativo.value != 'B' && this.quantidade_valor.value == ''){
            this.showError('Escolha uma opção: Definir Quantidade ou Informar Valor Investimento');
            return false;
        }  
        
        if (this.qtd.value % 100 != 0 && ativoSelecionado.tipo != 'FIIs' && ativoSelecionado.tipo != 'FRACIONARIO' && this.tipo_ativo.value != 'T'){
            this.showError('Quantidade informada errada! Só é aceita quantidades múltiplas de 100 devido aos lotes em bolsa.');            
            return false;
        }

        if(this.cliente.value == null || this.cliente.value == ''){
            this.showError('Escolha um cliente antes de adicionar o ativo!');
            return false;
        }

        return true;
    }

    SaveTableAsync(data){
        this.validacao_quantidade = data;
    }

    getAllStatus(){

        this.loadingSpinnerStatus = true;
        
        this._statusService.getLista({ tipo_produto: 'RV' }).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
                console.log("complete");
            }
        );       
    }   

    getBoleta(){
        this.openLoading();
        this._service.getById(this.data).subscribe(
            boleta => 
            {
                this.length = boleta.data.ativo.length;
                this.rows = boleta.data.ativo;
                this.temp = [...this.rows];                

                this.cliente_id.setValue(boleta.data.cliente.id);
                this.numero_xp.setValue(boleta.data.cliente.numero_xp);
                this.volume_global.setValue(boleta.data.cliente.volume_global);
                this.data_operacao.setValue(boleta.data.data_operacao);                
                this.observacao.setValue(boleta.data.observacao);
                this.status_id.setValue(boleta.data.status_id);
                this.cliente.setValue(boleta.data.cliente);
                this.tipo_ativo.setValue(boleta.data.status_boleta);
                if (boleta.data.acao_comercial_id != null){
                    this.acao_comercial_id.setValue(boleta.data.acao_comercial_id);
                }

                if(boleta.data.status_boleta === 'B'){
                    this.tipoBoletaBloqueado();
                }

                if ( boleta.data.cliente.carteira != undefined && boleta.data.cliente.carteira != null && boleta.data.cliente.carteira.length > 0){            
                    this.valor_custodia = parseFloat(boleta.data.cliente.carteira[0].valor_total);
                    this.valor_investimento.setValue( parseFloat(boleta.data.cliente.valor_investimento) - ( parseFloat(boleta.data.cliente.valor_bloqueado) + parseFloat(boleta.data.cliente.carteira[0].valor_total) ) );
                }else{
                    this.valor_investimento.setValue( parseFloat(boleta.data.cliente.valor_investimento) - parseFloat(boleta.data.cliente.valor_bloqueado));
                }
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
                console.log("complete");
            }
        );

    }

    tipoChange(event: MatSelectChange){
        
        if(event.value == 'B'){
            this.tipoBoletaBloqueado();
        }else if (event.value == 'V' ){
            this.ativaVenda = false;            
        }else{
            this.desativaOpcoes = true;
            this.ativaVenda     = true;
            this.exibeQuantidade = false;
        }

        
    }

    tipoBoletaBloqueado(){
        this.desativaOpcoes = false;        
        this.ativaVenda     = false;        
        this.getAllCarteiraCliente();
    }

    quantidadeChange(event: MatRadioChange){
        this.qtd.setValue('');
        this.valor.setValue('');
        
        if ( event.value === "definir_qtd" ){
            this.exibeQuantidade = true;
            this.exibeValor = false;            
        }else{
            this.exibeQuantidade = false;
            this.exibeValor = true;            
        }
    }

    precoChange(event: MatRadioChange){
        if ( event.value === "definir_preco" ){
            this.exibePreco = true;
            this.valor_mercado.setValue(false);
        }else{
            this.exibePreco = false;
            this.valor_mercado.setValue(true);
            this.preco_executado.setValue('');
        }
    }
    
    seguraPrecoChange(event: MatRadioChange){
        if ( event.value === "segura" ){
            this.model.segura_posicao = true;
            this.model.disponivel_venda = false;
        }else{
            this.model.segura_posicao = false;
            this.model.disponivel_venda = true;
        }
    }

    /**
     * Modal de edição
     */
    openConfirmaBoleta(): void {
       
        var lista = this;

        this.model.cliente_id = this.cliente_id.value;
        
        this.model.tipo_boleta = this.tipo_ativo.value;
        this.model.ativos = this.rows;
        this.model.observacao = this.observacao.value;
        this.model.data_operacao = this.data_operacao.value;
        this.model.status_id = this.status_id.value;
        this.model.acao_comercial_id = this.acao_comercial_id.value;

        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            data: {
                title: "Cadastro de boleta única",
                text: "Deseja realmente cadastrar esta boleta?",
                callback: function () {
                    lista.dialog.closeAll();

                    if(lista.data == null){

                        if(lista.tipo_ativo.value === 'B' || lista.tipo_ativo.value === 'T'){
                            lista.model.status_id  = 16;
                        }else{
                            lista.model.status_id  = 1;
                        }    

                        lista._service.create(lista.model).subscribe(
                            data => {
                                lista.loadingIndicator = false;
                                lista.showSuccess('Boleta Única Cadastrada Com Sucesso!');
                                lista.retornarLista();
                            },
                            error => {
                                lista.loadingIndicator = false;                            
                                lista.showError(error.error.message);
                            },
                            () => {                            
                                lista.loadingIndicator = false;
                            }
                        );
                    }else{
                        
                        lista._service.update(lista.data, lista.model).subscribe(
                            data => {
                                lista.loadingIndicator = false;
                                lista.showSuccess('Boleta Única Atualizada Com Sucesso!');
                                lista.retornarLista();
                            },
                            error => {
                                lista.loadingIndicator = false;                            
                                lista.showError(error.error.message);
                            },
                            () => {                            
                                lista.loadingIndicator = false;
                            }
                        );
                    }

                    lista.loadingIndicator = true;
                    
                }
            }
        });
               
    }
   

    addAtivo(){

        const ativoSelecionado = this.ativo.value;
        
        if ( this.tipo_ativo.value == 'V' && ativoSelecionado.tipo != 'FRACIONARIO'){

            const parametros = {
                cliente_id: this.cliente_id.value,
                ativo_id: this.ativo.value.id                
            };

            const promisse = this._carteiraService.getQtdAtivo(parametros);            
            promisse.then((data) => {
                this.validacao_quantidade = data;                
                if (this.validacao_quantidade.data < this.qtd.value){                    
                    this.confirmacao();
                    return false;
                }else{
                    this.insereAtivo();
                }
            });            
        }else{
            this.insereAtivo();
        }

                
    }
    
    insereAtivo(){
        if ( this.validaCadastro())
        {
            const id = this.rows.length + 1;
            let ativo = {};

            if ( this.custodia_id.value != '' && this.custodia_id.value != undefined ){
                const custodia = this.custodia_id.value;
                ativo = 
                {
                    id: id,
                    ativo_id: custodia.ativo_id,
                    ativo_sigla: custodia.ativo,
                    ativo: this.ativo.value,
                    qtd: custodia.qtd,
                    valor: this.valor.value,
                    preco_compra: custodia.preco_executado,
                    valor_mercado: this.valor_mercado.value,
                    segura_posicao: this.model.segura_posicao,
                    disponivel_venda: this.model.disponivel_venda
                };
            }else{
                ativo = 
                {
                    id: id,
                    ativo_id: this.ativo.value.id,
                    ativo_sigla: this.ativo.value.sigla,
                    ativo: this.ativo.value,
                    qtd: this.qtd.value,
                    valor: this.valor.value,
                    preco_compra: this.preco_executado.value,
                    valor_mercado: this.valor_mercado.value,
                    segura_posicao: this.model.segura_posicao,
                    disponivel_venda: this.model.disponivel_venda
                };
            }

            this.rows = [...this.rows, ativo];
        }
    }

    confirmacao() {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '350px',
            disableClose: true,
            data: {
                title: "Validação de Ativo",
                text: "O cliente não tem essa quantidade na carteira dele, tem certeza que deseja adicionar na boleta?",
                callback: function () {
                   lista.insereAtivo();
                }
            }
        });
    }


    delete(id){
        this.rows = this.rows.filter((value, key)=>{
            return value.id != id;
        });
    }

    /**
     * Consulta lista de Boletas
     */
    getAllCarteiraCliente() {
        this.loadingSpinnerCustodia = true;
        this.paramsCarteira.cliente_id  = this.cliente_id.value;
        this.paramsCarteira.tipo_ativo  = 'NORMAL';
        this.paramsCarteira.no_paginate = true;
        this.paramsCarteira.bloqueado = false;
        this.paramsCarteira.ativo_casado = false;

        this._carteiraService.getCarteiraCliente(this.paramsCarteira).subscribe(
            data => {
                this.listCustodia = data.data;
                this.loadingSpinnerCustodia = false;            
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerCustodia = false;
            },
            () => {
                console.log("complete");
                this.loadingSpinnerCustodia = false;    
            }
        );
    }

    getAllAcaoComercial(){

        this.loadingSpinnerAcaoComercial = true;
        
        this._serviceAcaoComercial.getAll( {no_paginate: true }).subscribe(
            list => {
                this.listAcaoComercial = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerAcaoComercial = false;
                console.log("complete");
                if (this.acao_comercial != null && this.acao_comercial != undefined){
                    setTimeout( () => {
                        this.acao_comercial_id.setValue(Number(this.acao_comercial));
                        this.model.acao_comercial_id = Number(this.acao_comercial);   
                    }, 1000);
                    
                } 
            }
        );
    }

    getDadosCliente(cliente_id){
        this.openLoading();
        this._clienteService.getById(cliente_id).subscribe(
            cliente => {                
                this.cliente.setValue(cliente.data);
                this.getCliente(cliente.data);
            },
            error => {
                this.closeLoading();
                console.log(error);
            },
            () => {
                this.closeLoading();
                console.log("complete");
            }
        );
    }

}
