import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametrosRoutesModule } from './parametro.routing';

import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRadioModule, 
    MatMenuModule, 
    MatChipsModule, 
    MatExpansionModule, 
    MatFormFieldModule, 
    MatRippleModule, 
    MatSortModule, 
    MatTableModule,
    MatSlideToggleModule
} from '@angular/material';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BaseModule} from "../base-module/base.module";
import {NgxMaskModule} from "ngx-mask";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ListaParametroComponent } from './lista-parametro/lista-parametro.component';
import { ParametroService } from './services/parametro.service';

@NgModule({    
    imports: [
        CommonModule,
        ParametrosRoutesModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatTabsModule,
        MatRadioModule, 
        MatMenuModule, 
        MatChipsModule, 
        MatExpansionModule, 
        MatFormFieldModule, 
        MatRippleModule, 
        MatSortModule, 
        MatTableModule,
        MatSlideToggleModule,
        FormsModule, 
        ReactiveFormsModule,
        NgxDatatableModule,
        FlexLayoutModule,
        BaseModule,
        NgxMaskModule,
        CurrencyMaskModule
    ],
    entryComponents:[], 
    declarations: [
        ListaParametroComponent
    ],
    providers: [
        ParametroService
    ],
})

export class ParametrosModule { }
