import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';

import {
    BaseComponent, 
    LoadingTeste
} from "../../base-module/base-component/base.component";

import {AuthService} from "../../base-module/base-service/auth.service";
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { ClienteHistoricoService } from '../services/cliente-historico.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';

export interface DialogData {
    id: number;    
    cliente_id: number;    
}

@Component({
    selector: 'app-cadastro-historico-cliente',
    templateUrl: './cadastro-historico-cliente.component.html',
    styleUrls: ['./cadastro-historico-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers:[ AtivoService, EstrategiaService ]
})

export class CadastroHistoricoClienteComponent extends BaseComponent {

    ativo = new FormControl('', [Validators.required]);
    tipo = new FormControl('', [Validators.required]);
    qtd = new FormControl('', [Validators.required]);
    tipo_ativo = new FormControl('B', [Validators.required]);
    vlr_financeiro  = new FormControl('', [Validators.required]);
    preco_executado = new FormControl('', []);        
    data_historico = new FormControl('', [Validators.required]);
    corretagem = new FormControl();
    estrategia_id = new FormControl();
    user:any;

    model:any = {
        tipo:'',
        tipo_ativo:'B'
    };

    loadingLocal:any;
    novoCadastro:boolean = false;
    listEstrategia:any[]=[];
    filteredOptionsAtivo: Observable<string[]>;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerEstrategia: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroHistoricoClienteComponent, { static: false }) table: CadastroHistoricoClienteComponent;

    constructor(public service: ClienteHistoricoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroHistoricoClienteComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _ativoService: AtivoService,
                private _serviceEstrategia: EstrategiaService )
    {
        super(dialog, snackBar);

        this.getAllEstrategias();

        this.user = JSON.parse(this.authService.getUser());

        if (this.data.id != null && this.data.id != undefined){
            this.getById();
            
            if(this.user.role_id == 2 || this.user.role_id == 4 || this.user.role_id == 7 ){
                this.novoCadastro = true;
            }
            
        }else{                      
           this.novoCadastro = true;
        }

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );
    }

    montaValor(){        
        if (this.preco_executado.value != '' && this.qtd.value != ''){
            this.vlr_financeiro.setValue( parseFloat(this.preco_executado.value) * parseFloat(this.qtd.value));
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){        

        if (!this.qtd.valid){
            return true;
        }

        if (!this.ativo.valid){
            return true;
        }

        if(!this.data_historico.valid){
            return true;
        }   
        
        if(!this.tipo_ativo.valid){
            return true;
        }  


        return false;
    }  

    salvar(){
        
        this.openLoading();        
        
        this.model.vlr_financeiro = this.vlr_financeiro.value;
        this.model.qtd_executado = this.qtd.value;
        this.model.preco_executado = this.preco_executado.value;        
        this.model.corretagem = this.corretagem.value;
        this.model.estrategia_id = this.estrategia_id.value;
        this.model.tipo_ativo = this.tipo_ativo.value;

        if ( this.data.id == null ){
            const ativo_id = this.ativo.value;
            
            this.model.ativo_id = ativo_id.id;
            this.model.cliente_id = this.data.cliente_id;
            this.model.tipo_inclusao = 'M';
            this.model.created_at = this.data_historico.value;
            this.model.sigla_ativo = ativo_id.sigla;
            this.model.tipo = this.tipo.value;

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );

        }else{
            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorQtdMessage() {
        return this.qtd.hasError('required') ? 'Quantidade é um campo obrigatório' : '';
    }

    getErrorFinanceiroMessage() {
        return this.vlr_financeiro.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }    


    getById(){
        this.openLoading();        
        this.service.getById(this.data.id).subscribe(
         item => {
            this.model = item.data;
            this.model.tipo_ativo = item.data.tipo_ativo.trim();
            
            this.data_historico.setValue(item.data.created_at);
            this.ativo.setValue(item.data.ativo);
            this.qtd.setValue(item.data.qtd_executado);
            this.vlr_financeiro.setValue(item.data.vlr_financeiro);
            this.preco_executado.setValue(item.data.preco_executado);                               
            this.corretagem.setValue(item.data.corretagem);
            this.estrategia_id.setValue(item.data.estrategia_id);
            this.tipo_ativo.setValue(item.data.tipo_ativo.trim());
        },
         error => {
            this.closeLoading();
            this.showError(error.error.message);                   
         },
        () => {
            this.closeLoading();            
        });
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getAll({}).subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
            }
        );
    }

}
