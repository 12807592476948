import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { EmpresaConsorcioService } from '../services/empresa-consorcio.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-cadastro-empresa-consorcio',
    templateUrl: './cadastro-empresa-consorcio.component.html',
    styleUrls: ['./cadastro-empresa-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class CadastroEmpresaConsorcioComponent extends BaseComponent {

    nome = new FormControl('', [Validators.required]);

    model:any = {
        nome:""
    };

    loadingLocal:any;
    listEstrutura: any[] = [];
    loadingSpinnerEstrutura:boolean = false;

    constructor(public service: EmpresaConsorcioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroEmpresaConsorcioComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);
        if(this.data != null && this.data != undefined){
            this.getById();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }


    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.nome.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.nome = this.nome.value;

        if(this.data == null){

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                                                       
                },
                error => {                    
                    this.closeLoading();
                    this.showError(error.error.message);

                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {

            this.model = item.data;
            this.nome.setValue(item.data.nome);

        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

}
