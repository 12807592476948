import {EventEmitter, Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ClienteProjetoService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Cliente projetos por id
     * @param id ID da Cliente projetos
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "projetos-clientes/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Cliente projetos
     * @param id ID da Cliente projetos
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "projetos-clientes"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Cliente projetos
     * @param id ID da Cliente projetos
     */
    search(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "projetos-clientes"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Cliente projetos
     * @param id ID da Cliente projetos
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "projetos-clientes/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Cliente projetos
     * @param params Json Cliente projetos
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "projetos-clientes", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Cliente projetos
     * @param params Json Cliente projetos
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "projetos-clientes/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

}
