import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { MidiaService } from '../services/midia.service';

export interface DialogData {
    id: number;
} 

@Component({
    selector: 'app-upload-midia',
    templateUrl: './upload-midia.component.html',
    styleUrls: ['./upload-midia.component.scss']
})

export class UploadMidiaComponent extends BaseComponent implements OnInit {

    midia:any = {
        id:''
    };

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;

    
    
    params:any ={}
    listTipo:any[]=[];

    loadingSpinnerTipo:boolean;

    model: any = {
        url: "",
    }

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UploadMidiaComponent>,
        public service: MidiaService,
        public utiliesService: UtilitesService,
        public authService: AuthService,
        private changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {

        super(dialog, snackBar);
        
        this.nomeFile = null;

        this.uploader = new FileUploader({
            url: this.service.CENTRAL_NOTICIAS_MIDIA_PATH +  "uploads/",
            isHTML5: true,
            authToken: "Bearer " + this.authService.getToken(),           
        });

        this.uploader.onAfterAddingFile = (file) => { 
            file.withCredentials = false; 
        };
        
        this.uploader.onProgressItem = ( fileItem: FileItem, progress: any ) => { 
            this.changeDetector.detectChanges();
        };
        
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        
            this.closeLoading();
            
            if (status == 200){
                var info = JSON.parse(response);
                
                this.showSuccess(info.message);
                
                this.dialogRef.close(info);
            }else{
                var info = JSON.parse(response);
                console.log('entrou');
                this.showError(info.message);      
                this.excluirMidia();
            }
        
        };
    
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    uploadFile(event) {    //line 1

        //console.log(event);
        let elem = event.target;  //line 2
        //console.log(elem);
        if (elem.files.length > 0) {     //line 3
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }

    isNomeFile(){
        return (this.nomeFile != null);
    } 

    excluirMidia(){
        this.service.delete(this.midia.id).subscribe(
            data => {
            },
            error => {
            },
            () => {
                console.log("complete");                
            }
        );
    }

    salvar() {
        // this.openLoading();
        // this.model.tipo_basket_id = this.tipo_basket_id.value;
        // this.model.nome_arquivo = this.rotulo.value;

        this.service.create(this.model).subscribe(
            data => {
                this.midia = data.data;
            },
            error => {
                this.showError(error.error.message);
            },
            () => {
                this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
                    form.append('midia_id', this.midia.id);
                };
                this.uploader.uploadAll();
                console.log("complete");
            }
        );

    }

    startUpload() {
        this.salvar();
    }
}

