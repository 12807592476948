import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { CommissionProjectedService } from '../services/commission-projected.service';

@Component({
  selector: 'receita-projetada-component',
  templateUrl: './receita-projetada-component.component.html',
  styleUrls: ['./receita-projetada-component.component.scss'],
  animations: fuseAnimations,
  providers: [ CommissionProjectedService ]
})

export class ReceitaProjetadaComponentComponent 
    extends BaseComponent 
    implements OnInit {

    comissionProjected:number = 0;
    isVisibled: boolean = false;
    user:any;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,        
        public _route: Router,
        private _commisionProjectedService: CommissionProjectedService,
        public _authService: AuthService
    ) 
    { 
        super(dialog, snackBar);
        this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getCommission({});
    }

    handleBalanceClient(){
        this._route.navigate(['/saldo-clientes']);
    }

    handleVisibleValueCommission(){
        if(!this.isVisibled){
            this.isVisibled = true;
        }else{
            this.isVisibled = false;
        }
    }

    getCommission(params) {

        this.loadingIndicator = true;
        this._commisionProjectedService.getCommission(params).subscribe(
            commission => {               
               this.comissionProjected = parseFloat(commission.data);                
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }
}
