import {
    Component, 
    ViewChild, 
    TemplateRef
}  from '@angular/core';
import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator
} from '@angular/material';

import {FormControl} from "@angular/forms";
import { Router } from '@angular/router';

import {BaseComponent} from '../../base-module/base-component/base.component';
import { FiltroListaDisponivelComponent } from 'app/home/saldo-disponivel/filtro-lista-disponivel/filtro-lista-disponivel.component';
import { DashboardService } from 'app/renda-variavel/services/dashboard.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
    selector: 'app-lista-incosistencias',
    templateUrl: './lista-incosistencias.component.html',
    styleUrls: ['./lista-incosistencias.component.scss'],
    providers:[ DashboardService ]
})

export class ListaIncosistenciasComponent extends BaseComponent{

    params: any = {};
    loadingIndicator: boolean;
    user:any;

    lengthOpcao = 0;
    lengthBoletaPendente = 0;
    pageSizeOpcao = 100;
    pageSizeOptionsOpcao: number[] = [100, 200, 300, 500];
    rowsOpcao = [];
    tempOpcao = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaIncosistenciasComponent, { static: false }) table: ListaIncosistenciasComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;

    public search = new FormControl();

    constructor(public service: DashboardService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public route: Router,
                private _authService: AuthService) {
        super(dialog, snackBar);       

        this.search.setValue('');
        this.getAll();
        this.getAllOpcao();
        this.user = JSON.parse(this._authService.getUser());

    }  

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */

    pageEvent(event: PageEvent){        

        this.params.page = ( event.pageIndex + 1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

     /**
     * Evento da paginação
     * @param {PageEvent} event
     */

    pageEventOpcao(event: PageEvent){        

        this.params.page = ( event.pageIndex + 1);
        this.params.per_page = event.pageSize;

        this.getAllOpcao();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {        

        this.loadingIndicator = true;
        this.service.getListaIncosistencias(this.params).subscribe(
            data => 
            {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Consulta lista de empresas
     */
    getAllOpcao() {        

        this.loadingIndicator = true;
        this.service.getListaIncosistenciasOpcao(this.params).subscribe(
            data => 
            {
                this.lengthOpcao = data.meta.total;
                this.rowsOpcao = data.data;
                this.tempOpcao = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }
    

    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

     /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSortOpcao(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder  = event.newValue;
        this.getAllOpcao();
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltroListaDisponivelComponent, {
            width: '700px',
            data: {
                tipo: 'incosistencia'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) 
            {                
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.percentual != undefined && result.percentual != null) {
                    this.params.percentual = result.percentual;
                }

                if (result.tipo_percentual != undefined && result.tipo_percentual != null) {
                    this.params.tipo_percentual = result.tipo_percentual;
                }

                this.paginator.firstPage();
                
                this.getAll();
            }
        });
    }


    redirecionaCliente(cliente){
        this.route.navigate([`clientes/cadastro/${cliente}`]);

    }

    onLinkClick(e){
        
        if( e.index == 0){
            this.getAll();            
        }

        if( e.index == 1){
            this.getAllOpcao();            
        }
        
    }

    getQtdBoletasPendetes(event){        
        this.lengthBoletaPendente = event;
    }

}