import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    PageEvent
} from '@angular/material';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { FechamentoCartaConsorcioService } from 'app/auxiliares/services/fechamento-carta-consorcio-service';
import { OperadorCartaConsorcioService } from 'app/auxiliares/services/operador-carta-consorcio-service';
import { ParceiroCartaConsorcioService } from 'app/auxiliares/services/parceiro-carta-consorcio-service';
import { StatusCartaConsorcioService } from 'app/auxiliares/services/status-carta-consorcio-service';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { CadastroClienteSimplesComponent } from 'app/clientes/cadastro-cliente-simples/cadastro-cliente-simples.component';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { BoletaConsorcioService } from '../services/boleta-consorcio.service';
import { CaracteristicaProdutoConsorcioService } from '../services/caracteristica-produto-consorcio.service';
import { EmpresaConsorcioService } from '../services/empresa-consorcio.service';
import { TipoConsorcioService } from '../services/tipo-consorcio.service';
import { CartaBoletaConsorcioService } from '../services/carta-boleta-consorcio.service';
import { TipoLanceCartaConsorcioService } from '../services/tipo-lance-carta-consorcio.service';


export interface ILance {
    tipo_lance_id: number;
    carta_id: number;
    valor_lance: number;
}

export interface ICarta {
    id?:number;
    grupo:string;
    cota:string;
    prazo_grupo:Date;
    taxa_administrativa_grupo:number;
    fechamento_id:number;
    status_id:number;
    desconto:number;
    data_fechamento:Date;
    data_pagamento: Date;
    valor_carta:number;
    lances?: ILance[];
    // lance:number;
    valor:number;
}

interface IModel {
    status_id:number;
    parceiro_consorcio_id:number;
    operador_consorcio_id:number;
    cliente_id:number;
    tipo_consorcio_id:number;
    data_operacao:Date;
    data_retorno:Date;
    qtd_cartas:number;
    valor:number;
    observacao:string;
    cartas: ICarta[];
};

@Component({
    selector: 'app-cadastro-boleta-consorcio',
    templateUrl: './cadastro-boleta-consorcio.component.html',
    styleUrls: ['./cadastro-boleta-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class CadastroBoletaConsorcioComponent extends BaseComponent implements OnInit {
    loadingSpinner: boolean;
    filteredOptions: Observable<string[]>;
    boletaFormGroup:FormGroup = new FormGroup({
        cliente: new FormControl('', [Validators.required]),
        cliente_id: new FormControl('', [Validators.required]),
        status_id: new FormControl(null, [Validators.required]),
        produto_consorcio_id: new FormControl(null),
        valor: new FormControl(),
        observacao: new FormControl(),
        data_operacao: new FormControl(null, [Validators.required]),
        data_retorno: new FormControl(null),
        parceiro_consorcio_id: new FormControl(null, [Validators.required]),
        operador_consorcio_id: new FormControl(null, [Validators.required]),
        qtd_cartas: new FormControl(null),
        tipo_consorcio_id: new FormControl(null, [Validators.required]),
    });
    
    //carta consorcio
    cartaFormGroup:FormGroup;
    lancesFormArray: FormArray = new FormArray([]);

    listStatus: any[] = [];
    loadingSpinnerStatus: boolean = false;

    listProdutoConsorcio: any[] = [];
    loadingSpinnerProduto: boolean = false;
    isEfetivado:boolean = false;

    model:IModel = {
        status_id:null,
        parceiro_consorcio_id:null,
        operador_consorcio_id:null,
        cliente_id:null,
        tipo_consorcio_id:null,
        data_operacao:null,
        data_retorno:null,
        qtd_cartas:null,
        valor:null,
        observacao:null,
        cartas: []
    };

    loadingLocal:any;

    listTipoConsorcio: any[] = [];
    loadingSpinnerTipo: boolean = false;

    listEmpresaConsorcio: any[] = [];
    loadingSpinnerEmpresaConsorcio: boolean = false;

    listOperadorConsorcio: any[] = [];
    loadingSpinnerOperadorConsorcio: boolean = false;

    listFechamentoConsorcio: any[] = [];
    loadingSpinnerFechamentoConsorcio: boolean = false;

    listParceiroConsorcio: any[] = [];
    loadingSpinnerParceiroConsorcio: boolean = false;

    listStatusCartaConsorcio: any[] = [];
    loadingSpinnerStatusCartaConsorcio: boolean = false;

    data:any = {
        id:null
    };
    isEditCarta: boolean = false;
    selectCartaEdit: ICarta = null;
    selectCartaIndex: number = null;
    paramsCarta: any = {
        boleta_id: null,
        page: 0,
        per_page: 10
    };
    listTipoLance: any[] = [];
    loadingSpinnerTipoLance: boolean = false;

    constructor(public service: BoletaConsorcioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                private _clienteService: ClienteService,
                private _statusService: StatusMovimentacaoService,
                private _produtoService: CaracteristicaProdutoConsorcioService,
                private _tipoService: TipoConsorcioService,
                private _empresaConsorcioService: EmpresaConsorcioService,
                public _Activatedroute: ActivatedRoute,
                private _parceiroCartaService: ParceiroCartaConsorcioService,
                private _fechamentoCartaService: FechamentoCartaConsorcioService,
                private _operadorCartaService: OperadorCartaConsorcioService,
                private _statusCartaService: StatusCartaConsorcioService,
                private _cartaConsorcioService: CartaBoletaConsorcioService,
                private ref: ChangeDetectorRef,
                private route: Router,
                private _tipoLanceCartaService: TipoLanceCartaConsorcioService,
                private fb:FormBuilder
            )
    {
        super(dialog, snackBar);
    }

    ngOnInit(): void {
        this.pageSize = this.paramsCarta.per_page;
        this.pageSizeOptions = [10, 20, 30, 100];
        this.newFormGroup();
        if(this._Activatedroute.snapshot.data['view']){
            this.disableAllParams();
        }
        if(this._Activatedroute.snapshot.params['id']){
            this.data.id = this._Activatedroute.snapshot.params['id'];
        }
        this.loadingIndicator=false;

        if(this.data.id){
            this.getById();
            this.getCartasByBoletaId();
        }

        this.boletaFormGroup.get('cliente').valueChanges.subscribe(
            async query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = await this._clienteService.search(this.params);
                }
            }
        );

        this.getAllStatus();
        this.getAllProdutoConsorcio();
        this.getAllTipoConsorcio();
        this.getAllEmpresaConsorcio();
        this.getAllStatusCarta();
        this.getAllParceiroCarta();
        this.getAllFechamento();
        this.getAllOperador();
        this.getAllTipoLance();
    }


    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    salvar(){
        this.model = this.buildModel();
        if(this.boletaFormGroup.valid){
            if(this.model.cartas.length > 0){
                if(this.model.qtd_cartas !== this.model.cartas.length){
                    this.showError("Quantidade de cartas diferente do total adicionado.");
                    return;
                }

                if(Number(this.model.valor) !== this.getSumValorTotalCartas()){
                    this.showError("O campo Valor diferente do somatório do valor de cartas adicionadas.");
                    return;
                }
            }
            this.openLoading();
            if(!this.data.id){
                this.service.create(this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.route.navigate(['/consorcio/lista-boleta-consorcio']);
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }else{
                this.service.update(this.data.id, this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.route.navigate(['/consorcio/lista-boleta-consorcio']);
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }
        }else{
            if(this.boletaFormGroup.invalid){
                this.validateAllFormFields(this.boletaFormGroup);
            }
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
            }
        });
    }

    getErrorClienteMessage() {
        return this.boletaFormGroup.get('cliente').hasError('required') ? 'Cliente é um campo obrigatório' : '';
    }

    getErrorStatusMessage() {
        return this.boletaFormGroup.get('status_id').hasError('required') ? 'Status é um campo obrigatório' : '';
    }

    getErrorProdutoMessage() {
        return this.boletaFormGroup.get('produto_consorcio_id').hasError('required') ? 'Produto é um campo obrigatório' : '';
    }

    getErrorValorMessage() {
        return this.boletaFormGroup.get('valor').hasError('required') ? 'Valor é um campo obrigatório' : '';
    }

    getErrorBoletaFormMessage() {
        return this.boletaFormGroup.invalid? 'Preencha campo Parceiro da Boleta de Consórcio antes.' : '';
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {
            this.rows = this.buildCartas(item.data.cartas);
            this.boletaFormGroup.get('cliente').setValue(item.data.cliente);
            this.boletaFormGroup.get('cliente_id').setValue(item.data.cliente_id);
            this.boletaFormGroup.get('status_id').setValue(item.data.status_id);
            this.boletaFormGroup.get('produto_consorcio_id').setValue(item.data.produto_id);
            setTimeout(()=>this.getAllFechamento(), 500);
            this.boletaFormGroup.get('valor').setValue(item.data.valor);
            this.boletaFormGroup.get('observacao').setValue(item.data.observacao);
            if(item.data.data_operacao){
                this.boletaFormGroup.get('data_operacao').setValue(new Date(item.data.data_operacao));
            }
            if(item.data.data_retorno){
                this.boletaFormGroup.get('data_retorno').setValue(new Date(item.data.data_retorno));
            }
            this.boletaFormGroup.get('parceiro_consorcio_id').setValue(item.data.parceiro_consorcio_id);
            this.boletaFormGroup.get('operador_consorcio_id').setValue(item.data.operador_consorcio_id);
            this.boletaFormGroup.get('qtd_cartas').setValue(item.data.qtd_cartas);
            this.boletaFormGroup.get('tipo_consorcio_id').setValue(item.data.tipo_consorcio_id);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome;
        }
    }

    getCliente(cliente) {
        this.boletaFormGroup.get('cliente_id').setValue(cliente.id);
        this.loadingSpinner = false;
    }

    getAllStatus() {

        this.loadingSpinnerStatus = true;

        this._statusService.getLista({ tipo_produto: 'CS' }).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
            }
        );
    }

    getAllProdutoConsorcio() {

        this.loadingSpinnerProduto = true;

        this._produtoService.getLista({}).subscribe(
            list => {
                this.listProdutoConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerProduto = false;
                console.log("complete");
            }
        );
    }

    getAllTipoConsorcio() {
        this.loadingSpinnerTipo = true;
        this._tipoService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listTipoConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerTipo = false;
                console.log("complete");
            }
        );
    }

    getAllEmpresaConsorcio() {
        this.loadingSpinnerEmpresaConsorcio = true;
        this._empresaConsorcioService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listEmpresaConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerEmpresaConsorcio = false;
                console.log("complete");
            }
        );
    }

    cadastroCliente():void {
        let dialogRef = this.dialog.open(CadastroClienteSimplesComponent, {
            width: '600px',  
            data: {
                abordagem: false
            }          
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Cliente cadastrado com sucesso!");
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar cliente.");
            }
        });
        
    }

    getAllStatusCarta() {
        this.loadingSpinnerStatusCartaConsorcio = true;
        this._statusCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listStatusCartaConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatusCartaConsorcio = false;
                console.log("complete");
            }
        );
    }

    getAllParceiroCarta() {
        this.loadingSpinnerParceiroConsorcio = true;
        this._parceiroCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listParceiroConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerParceiroConsorcio = false;
                console.log("complete");
            }
        );
    }

    getAllFechamento() {
        if(this.boletaFormGroup.get('parceiro_consorcio_id').valid){
            this.loadingSpinnerFechamentoConsorcio = true;
            this._fechamentoCartaService.getAll({ no_paginate: true, parceiro_id: this.boletaFormGroup.get('parceiro_consorcio_id').value }).subscribe(
                list => {
                    this.listFechamentoConsorcio = list.data;
                },
                error => {
                },
                () => {
                    this.loadingSpinnerFechamentoConsorcio = false;
                    console.log("complete");
                }
            );
        }
    }

    getAllOperador() {
        this.loadingSpinnerOperadorConsorcio = true;
        this._operadorCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listOperadorConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerOperadorConsorcio = false;
                console.log("complete");
            }
        );
    }

    addCarta() {
        if(this.cartaFormGroup.invalid || this.boletaFormGroup.get('parceiro_consorcio_id').invalid){
            return;
        }

        this.loadingIndicator = false;
        const carta: ICarta = {
            grupo: this.cartaFormGroup.get('grupo').value,
            cota: this.cartaFormGroup.get('cota').value,
            prazo_grupo: this.cartaFormGroup.get('prazo_grupo').value,
            taxa_administrativa_grupo: this.cartaFormGroup.get('taxa_administrativa_grupo').value,
            fechamento_id: this.cartaFormGroup.get('fechamento_id').value,
            status_id: this.cartaFormGroup.get('status_id').value,
            desconto: this.cartaFormGroup.get('desconto').value,
            data_fechamento: this.cartaFormGroup.get('data_fechamento').value,
            data_pagamento: this.cartaFormGroup.get('data_pagamento').value,
            valor_carta: this.cartaFormGroup.get('valor_carta').value,
            valor: this.getValorTotalLances(),
            lances: this.lances.value,
        };

        this.ref.markForCheck();
        this.rows.push(carta);
        this.rows = [...this.rows];
        this.temp = [...this.rows];
        this.length = this.rows.length;
        this.ref.detectChanges();
        this.sumValorTotalCartas();
    }

    saveEditCarta() {
        if(this.cartaFormGroup.invalid){
            return;
        }
        this.deleteCarta(this.selectCartaIndex);
        this.selectCartaEdit = null;
        this.isEditCarta = false;
        this.loadingIndicator = false;
        const carta: ICarta = {
            id: this.cartaFormGroup.get('id').value,
            grupo: this.cartaFormGroup.get('grupo').value,
            cota: this.cartaFormGroup.get('cota').value,
            prazo_grupo: this.cartaFormGroup.get('prazo_grupo').value,
            taxa_administrativa_grupo: this.cartaFormGroup.get('taxa_administrativa_grupo').value,
            fechamento_id: this.cartaFormGroup.get('fechamento_id').value,
            status_id: this.cartaFormGroup.get('status_id').value,
            desconto: this.cartaFormGroup.get('desconto').value,
            data_fechamento: this.cartaFormGroup.get('data_fechamento').value,
            data_pagamento: this.cartaFormGroup.get('data_pagamento').value,
            valor_carta: this.cartaFormGroup.get('valor_carta').value,
            lances: this.lances.value,
            valor: this.getValorTotalLances(),
        };
        this.ref.markForCheck();
        this.rows.push(carta);
        this.rows = [...this.rows];
        this.temp = [...this.rows];
        this.length = this.rows.length;
        this.ref.detectChanges();
        this.sumValorTotalCartas();
    }

     /**
     * Evento da paginação
     * @param {PageEvent} event
     */
     pageEvent(event: PageEvent) {
        console.log(event);

        this.paramsCarta.page = event.pageIndex + 1;
        this.paramsCarta.per_page = event.pageSize;

        this.getCartasByBoletaId();
    }

    sumValorTotalCartas() {
        this.boletaFormGroup.get('qtd_cartas').setValue(this.length);
        this.boletaFormGroup.get('valor').setValue(Number(this.getSumValorTotalCartas()));
    }

    getSumValorTotalCartas() {
        return this.rows.reduce((total, row) => {
            return Number(total) + Number(row.valor_carta);
        }, 0);
    }

    newFormGroup() {
        this.cartaFormGroup = this.fb.group({
            id: new FormControl(null,[]),
            grupo: new FormControl('', [Validators.required]),
            cota: new FormControl('', [Validators.required]),
            prazo_grupo: new FormControl(null, [Validators.required]),
            taxa_administrativa_grupo: new FormControl(0),
            fechamento_id: new FormControl(),
            status_id: new FormControl(),
            desconto: new FormControl(0),
            data_fechamento: new FormControl(),
            data_pagamento: new FormControl(),
            valor_carta: new FormControl(0),
            lances: this.fb.array([]),
            valor: new FormControl(0),
        });
        this.addLance();
        setTimeout(() => this.cartaFormGroup.clearAsyncValidators(), 1000);
    }

    cleanFormGroup() {
        this.cartaFormGroup.get('grupo').setValue(undefined);
        this.cartaFormGroup.get('cota').setValue(undefined);
        this.cartaFormGroup.get('prazo_grupo').setValue(undefined);
        this.cartaFormGroup.get('taxa_administrativa_grupo').setValue(undefined);
        this.cartaFormGroup.get('fechamento_id').setValue(undefined);
        this.cartaFormGroup.get('status_id').setValue(undefined);
        this.cartaFormGroup.get('desconto').setValue(undefined);
        this.cartaFormGroup.get('data_fechamento').setValue(undefined);
        this.cartaFormGroup.get('data_pagamento').setValue(undefined);
        this.cartaFormGroup.get('valor_carta').setValue(undefined);
        this.cartaFormGroup.get('lance').setValue(undefined);
        this.cartaFormGroup.get('valor').setValue(undefined);
    }

    buildModel(): IModel{
        const buildModel:IModel = {
            ...this.boletaFormGroup.value,
            cartas: this.rows
        }
        return buildModel;
    }

    editCarta(row:ICarta, rowIndex){
        console.log(row.lances);
        this.selectCartaEdit = row;
        this.isEditCarta = true;
        this.selectCartaIndex = rowIndex;
        this.ref.markForCheck();
        this.cartaFormGroup.get('id').setValue(row.id),
        this.cartaFormGroup.get('grupo').setValue(row.grupo);
        this.cartaFormGroup.get('cota').setValue(row.cota);
        this.cartaFormGroup.get('prazo_grupo').setValue(Number(row.prazo_grupo));
        this.cartaFormGroup.get('taxa_administrativa_grupo').setValue(row.taxa_administrativa_grupo);
        this.cartaFormGroup.get('fechamento_id').setValue(row.fechamento_id);
        this.cartaFormGroup.get('status_id').setValue(row.status_id);
        this.cartaFormGroup.get('desconto').setValue(Number(row.desconto));
        if(row.data_fechamento){
            this.cartaFormGroup.get('data_fechamento').setValue(new Date(row.data_fechamento));
        }
        if(row.data_pagamento){
            this.cartaFormGroup.get('data_pagamento').setValue(new Date(row.data_pagamento));
        }
        if(row.valor_carta){
            this.cartaFormGroup.get('valor_carta').setValue(Number(row.valor_carta));
        }
        if(row.valor){
            this.cartaFormGroup.get('valor').setValue(Number(row.valor));
        }
        // Atualizar o FormArray de Lances
        this.setLances(row.lances);

        // adiciona um lance vazio para preenchimento;
        this.addLance();
        this.ref.detectChanges();
    }

    deleteCarta(rowIndex) {
        this.ref.markForCheck();
        this.rows.splice(rowIndex,1);
        this.length = this.rows.length;
        this.ref.detectChanges();
        this.sumValorTotalCartas();
    }

    buildCartas(rows){
        return rows.map((item, i)=>{
            return {
                id: item.id,
                grupo: item.grupo,
                cota: item.cota,
                prazo_grupo: Number(item.prazo_grupo),
                taxa_administrativa_grupo: Number(item.taxa_administrativa_grupo),
                fechamento_id: Number(item.fechamento_id),
                status_id: Number(item.status_id),
                desconto: Number(item.desconto),
                data_fechamento: new Date(item.data_fechamento),
                data_pagamento: new Date(item.data_pagamento),
                valor_carta: Number(item.valor_carta),
                valor: Number(item.valor),
                lances: item.lances,
            }
        })
    }

    disableAllParams() {
        this.boletaFormGroup.get('cliente').disable();
        this.boletaFormGroup.get('cliente_id').disable();
        this.boletaFormGroup.get('status_id').disable();
        this.boletaFormGroup.get('produto_consorcio_id').disable();
        this.boletaFormGroup.get('valor').disable();
        this.boletaFormGroup.get('observacao').disable();
        this.boletaFormGroup.get('data_operacao').disable();
        this.boletaFormGroup.get('data_retorno').disable();
        this.boletaFormGroup.get('parceiro_consorcio_id').disable();
        this.boletaFormGroup.get('operador_consorcio_id').disable();
        this.boletaFormGroup.get('qtd_cartas').disable();
        this.boletaFormGroup.get('tipo_consorcio_id').disable();
        this.cartaFormGroup.get('grupo').disable();
        this.cartaFormGroup.get('cota').disable();
        this.cartaFormGroup.get('prazo_grupo').disable();
        this.cartaFormGroup.get('taxa_administrativa_grupo').disable();
        this.cartaFormGroup.get('fechamento_id').disable();
        this.cartaFormGroup.get('status_id').disable();
        this.cartaFormGroup.get('desconto').disable();
        this.cartaFormGroup.get('data_fechamento').disable();
        this.cartaFormGroup.get('data_pagamento').disable();
        this.cartaFormGroup.get('valor_carta').disable();
        this.cartaFormGroup.get('lance').disable();
        this.cartaFormGroup.get('valor').disable();
    }

    findCloseTable(event){
        console.log(event);
        console.log("parceiro_consorcio_id", this.boletaFormGroup.get("parceiro_consorcio_id").valid)
        this.getAllFechamento();
    }

    getCartasByBoletaId(){
        this.loadingIndicator = true;
        this.paramsCarta.boleta_id = this.data.id;
        this._cartaConsorcioService.getAll(this.paramsCarta).subscribe(
        data => {
            this.loadingIndicator = false;
            this.length = data.meta.total;
            this.rows = data.data;
            this.temp = [...this.rows];
        },
         error => {
            this.loadingIndicator = false;
            console.log(error);
         },
        () => {
            this.loadingIndicator = false;
            console.log("complete");
        });
    }

    setLances(lances: ILance[]) {
        // Limpar os lances existentes
        this.lances.clear();
    
        // Adicionar os lances da carta selecionada ao FormArray
        if (lances && lances.length > 0) {
            lances.forEach((lance) => {
                if (
                    lance.tipo_lance_id != null && 
                    !isNaN(lance.tipo_lance_id) && 
                    lance.valor_lance != null && 
                    !isNaN(lance.valor_lance)
                ) {
                    this.lances.push(this.fb.group({
                        tipo_lance_id: [lance.tipo_lance_id, Validators.required],
                        valor_lance: [lance.valor_lance, Validators.required]
                    }));
                }
            });
        }
    }

    get lances(): FormArray {
        return this.cartaFormGroup.get('lances') as FormArray;
    }

    newLance(): FormGroup {
        return this.fb.group({
            tipo_lance_id: '',
            valor_lance: ''
        })
    }

    addLance(): void {
        this.lances.push(this.newLance());
    }

    removeLance(index: number): void {
        this.lances.removeAt(index);
    }

    getLance(index: number) {
        return this.lances.controls[index];
    }

    getAllTipoLance() {
        this.loadingSpinnerTipoLance = true;
        this._tipoLanceCartaService.getAll({}).subscribe(
            list => {
                this.listTipoLance = list.data;
                this.loadingSpinnerTipoLance = false;
            },
            error => {
                this.loadingSpinnerTipoLance = false;
            },
            () => {
                this.loadingSpinnerTipoLance = false;
                console.log("complete");
            }
        );
    }

    getTipoLanceById(id:number){
        return this.listTipoLance.find(item => item.id == id);
    }

    getSimboloTipoValor(i: number): string{
        const tipo_lance = this.getTipoLanceById(this.getLance(i).value.tipo_lance_id)
        if(tipo_lance){
            return tipo_lance.tipo_valor == 'percent'? '% ' : 'R$ ';
        }
        return 'R$ ';
    }

    getOptionsTypeValue(i) {
        return { prefix: this.getSimboloTipoValor(i), thousands: '.', decimal: ',', align: 'center' }
    }

    getValorTotalLances() {
        return this.lances.controls.reduce((acc, item) => {
            if(item.value.valor_lance > 0){
                const tipo_valor = this.getTipoLanceById(item.value.tipo_lance_id);
                if(tipo_valor.tipo_valor == 'percent'){
                    return Number(acc) + ((Number(item.value.valor_lance)/100)*Number(this.cartaFormGroup.get('valor_carta').value));
                }
                return Number(acc) + Number(item.value.valor_lance);
            }
            return Number(acc)+0;
        }, 0);
    }
}
