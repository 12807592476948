import { 
    MatDialog,
    MatSnackBar, 
    MatDialogRef, 
    MAT_DIALOG_DATA, 
    PageEvent
} from '@angular/material';
import { Component,Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent, ActionDialog } from "../../base-module/base-component/base.component";
import { BoletaService } from '../services/boleta.service';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { ConfirmaBoletaComponent } from '../confirma-boleta/confirma-boleta.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ParametroService } from 'app/parametros/services/parametro.service';
import { AcaoComercialService } from 'app/acao-comercial/services/acao-comercial.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
import { Observable } from 'rxjs';
import { BoletaValoresService } from '../services/boleta-valores.service';
import { AlteraValorBoletaComponent } from '../altera-valor-boleta/altera-valor-boleta.component';

@Component({
  selector: 'app-cadastro-boleta',
  templateUrl: './cadastro-boleta.component.html',
  styleUrls: ['./cadastro-boleta.component.scss'],
  providers:[ EstrategiaService, BoletaValoresService ]
})

export class CadastroBoletaComponent extends BaseComponent {

    filteredOptions: Observable<string[]>;

    ClienteSelecionado:any;
    data:any = {};
    acao_comercial:any;
    tipo:any = {};
    listStatus:any[]=[];
    listAcaoComercial:any[]=[];
    listEstrategia:any[]=[];
    valorBoletado:any;
    titulo: string;
    retornoLista: string;
    valor_custodia:any;

    cliente         = new FormControl('', );
    cliente_id      = new FormControl('', [Validators.required]);
    valor           = new FormControl('', [Validators.required]);
    assessor_id     = new FormControl('', []);
    data_operacao   = new FormControl(new Date(), [Validators.required]);
    statusBoleta    = new FormControl('Informe o cliente...', []);
    status_id       = new FormControl('', [Validators.required]);
    numero_xp       = new FormControl('', []);
    telefone        = new FormControl('', []);
    email           = new FormControl('', []);
    escritorio_id   = new FormControl('', []);
    escritorio      = new FormControl('', []);
    observacao      = new FormControl('', []);
    volume_global       = new FormControl('', []);    
    valor_investimento  = new FormControl('', []);
    tipo_boleta         = new FormControl('', []);
    acao_comercial_id   = new FormControl('', []);
    estrategia_id = new FormControl('', [Validators.required]);
    vlr_executado = new FormControl(0, []);
    saldo = new FormControl(0, []);
    valor_trades = new FormControl(0, []);
    valor_total_boleta = new FormControl(0, []);

    new_cliente_id:any;

    model:any = {
        cliente_id:"",
        valor:"",
        observacao:"",
        data_operacao:"",
        status_id:"",
        tipo_boleta:"",
        estrategia_id:"",
        valor_novo: 0,
        valor_total: 0,
        valor_trade: 0,
    }

    paramsStatus:any ={
        finalizada:""
    }
    
    user:any;
    valorDetalhadoBoleta:boolean = false;
    loadingSpinner:boolean = false;
    loadingSpinnerStatus:boolean = false;
    loadingSpinnerAcaoComercial:boolean = false; 
    loadingSpinnerEstrategia:boolean = false;

    vlr_min_boleta:any;
    vlr_min_boleta_sw:any;
    vlr_min_primeira_boleta_sw:any;
    vlr_min_boleta_fop:any;
    vlr_min_primeira_boleta_fop:any;

    constructor(private _service: BoletaService,
                private _clienteService: ClienteService,
                private _statusService:StatusMovimentacaoService,
                private _Activatedroute:ActivatedRoute,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private route: Router,                
                private _authService: AuthService,                
                private _serviceParametro: ParametroService,
                private _serviceAcaoComercial: AcaoComercialService,
                private _serviceEstrategia: EstrategiaService,
                private _serviceBoletaValores: BoletaValoresService) 
    { 
        super(dialog, snackBar);

        this.getAllParametros();
        this.getAllAcaoComercial();
        this.getAllEstrategias();

        this.data = this._Activatedroute.snapshot.params['id'];
        this.new_cliente_id = this._Activatedroute.snapshot.params['cliente_id'];
        this.tipo = this._Activatedroute.snapshot.params['tipo'];
        this.acao_comercial = this._Activatedroute.snapshot.params['acao_comercial'];

        this.titulo = 'Cadastro de Boleta de Renda Variável';
        this.retornoLista = 'Lista de Boletas de Renda Variável';

        if(this.new_cliente_id != undefined && this.new_cliente_id != ''){
            this.getDadosCliente(this.new_cliente_id);
        }

        this.tipo_boleta.setValue(this.tipo);

        this.listStatus = [];
        
        if(this.data != null && this.data != undefined){
            this.getBoleta();
            this.getAllStatus();
        }

        this.cliente.valueChanges.subscribe(
            query => {
                if(typeof query === "string" || typeof query === "number"){
                    this.loadingSpinner  = true;
                    this.params.search 	 = query;
                    this.params.homebroker = false;
                    this.filteredOptions = this._clienteService.search(this.params);   
                }
            }   
        );

        this.user = JSON.parse(this._authService.getUser());
    }

    isInvalidForm(){
        
        if (!this.cliente_id.valid){
            return true;
        }

        if (!this.valor.valid){
            return true;
        }

        if (!this.data_operacao.valid){
            return true;
        }

        if (!this.estrategia_id.valid){
            return true;
        }

        return false;
    }

    getErrorVolumeGlobalMessage() {
        return this.valor.hasError('required') ? 'Valor Movimentado é um campo obrigatório' : '';
    }

    getErrorEstrategiaMessage() {
        return this.estrategia_id.hasError('required') ? 'Estratégia é um campo obrigatório' : '';
    }

    retornarLista():void{
        this.route.navigate(['/movimentacao/boleta/' + this.tipo]);
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
        this.cliente_id.setValue(cliente.id);

        if(this.estrategia_id.value != null && this.estrategia_id.value != ''){
            this.getBoletaAberta(); 
        }

        this.preencheDadosCliente(cliente);
    }

    preencheDadosCliente(cliente){
        this.numero_xp.setValue(cliente.numero_xp);
        this.telefone.setValue(cliente.telefone);
        this.email.setValue(cliente.email);
        this.assessor_id.setValue(cliente.assessor.name);
        this.escritorio.setValue(cliente.escritorio.nome_fantasia);
        this.volume_global.setValue(cliente.volume_global);        

        if(cliente.carteira != undefined && cliente.carteira != null && cliente.carteira.length > 0){            
            this.valor_custodia = parseFloat(cliente.carteira[0].valor_total);
            this.valor_investimento.setValue( parseFloat(cliente.valor_investimento) - ( parseFloat(cliente.valor_bloqueado) + parseFloat(cliente.carteira[0].valor_total) ) );
        }else{
            this.valor_investimento.setValue( parseFloat(cliente.valor_investimento) - parseFloat(cliente.valor_bloqueado));
        }

        this.getByClienteBoleta();
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getByClienteBoleta(){
        
        this._service.getByClienteCount(this.cliente_id.value, this.estrategia_id.value).subscribe(
            result=>{
                if(result.data.qtd == 0){
                    this.statusBoleta.setValue('Primeira Operação');
                    this.status_id.setValue(2);
                }else{
                    this.statusBoleta.setValue('Boleta MRV');
                    this.status_id.setValue(1);
                }
            },
            error => {
            },
            () => { }
        );
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getLista().subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
            }
        );
    }

    getBoletaAberta(){
        
        this._service.getBoletaAberta(this.cliente_id.value, this.estrategia_id.value).subscribe(
            result => {
                if(result.data.qtd == undefined){                    
                    if(result.data.status_id == 3){
                        this.alertaBoletaFechada();
                    }else{
                        this.alertaBoletaAberta(result.data.id);
                    }                    
                }
            },
            error => {
            },
            () => { }
        );        
    }

    alertaBoletaAberta(boleta_id){
        var lista = this;

        let dialogRef = this.dialog.open(ActionDialogBoleta, {
            width: '400px',                        
            data: {
                title: "Boleta Aberta",
                text: "Já existe uma boleta aberta para esse cliente. Você deseja alterar o valor da boleta em aberto ?",
                habilitaBotoes: true,
                callback: function (event) { 
                    console.log(event);                   
                    if(event == 'S'){
                        lista.route.navigate(['/movimentacao/boleta/cadastro/' + lista.tipo+'/'+boleta_id]);
                    }else{
                        lista.limpaFormulario();
                    }
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {     

            if (result != undefined && result != null && result != "cancel"){
                console.log('Aqui', result);
            }else if(result != undefined && result != "cancel"){
                console.log('Aqui 2', result);
            }

            if(result == undefined){
                this.limpaFormulario();
            }            
        });


    }

    limpaFormulario(){
        this.cliente.setValue('');
        this.numero_xp.setValue('');
        this.email.setValue('');
        this.telefone.setValue('');
        this.assessor_id.setValue('');
        this.escritorio.setValue('');
        this.volume_global.setValue('');
        this.valor_investimento.setValue('');
    }

    alertaBoletaFechada(){     
        var lista = this;   
        this.dialog.open(ActionDialogBoleta, {
            width: '400px',                        
            data: {
                title: "Boleta Em Negociação",
                text: "Já existe uma boleta em negociação. Se você deseja alterar o valor da boleta, entre em contato com a Mesa de Operações!",
                habilitaBotoes: false,
                callback: function (event) {
                    if(event == 'N'){
                        lista.cliente.setValue('');
                    }
                }
            }
        });
    }
    
    validaCadastro(){
        
        //Valida Aporte do Cliente

        if(this.data != null && this.data != undefined){
            
            var valorTotal = (parseFloat(this.valor_investimento.value) + parseFloat(this.valorBoletado) + this.valor_custodia );

            if( valorTotal < (parseFloat(this.valor_total_boleta.value)) ){
                this.showError('O valor da Boleta não pode ser maior que o valor disponivel para investimento!');
                return false;
            }
        
        }else{

            if(parseFloat(this.valor_investimento.value) < (parseFloat(this.valor_total_boleta.value)) ){
                this.showError('O valor da Boleta não pode ser maior que o valor disponivel para investimento!');
                return false;
            }    
          
        }

        if(parseFloat(this.valor_total_boleta.value) == 0){
            this.showError('O valor da Boleta não pode ser zerado!');
            return false;
        }

        if(parseFloat(this.valor_total_boleta.value) < this.vlr_min_boleta && this.vlr_min_boleta > 0){
            this.showError('O valor da Boleta não pode ser menor do que R$ '+this.vlr_min_boleta.toLocaleString()+' !');
            return false;
        }

        return true;
    }

    getAllStatus(){

        this.loadingSpinnerStatus = true;
        
        this._statusService.getLista({ tipo_produto: 'RV' }).subscribe(
            list=>{
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
            }
        );
    }

    getAllAcaoComercial(){

        this.loadingSpinnerAcaoComercial = true;
        
        this._serviceAcaoComercial.getAll( {no_paginate: true }).subscribe(
            list=>{
                this.listAcaoComercial = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerAcaoComercial = false;                
                if (this.acao_comercial != null && this.acao_comercial != undefined){
                    setTimeout( () => {
                        this.acao_comercial_id.setValue(Number(this.acao_comercial));
                        this.model.acao_comercial_id = Number(this.acao_comercial);   
                    }, 1000);
                    
                } 
            }
        );
    }

    getBoleta(){
        this.openLoading();
        this._service.getById(this.data).subscribe(
            boleta => {
                this.model = boleta.data;

                if(boleta.data.bloqueio && this.user.role_id != 2 && this.user.role_id != 7 && this.user.role_id != 4){
                    this.showError('Boleta está bloqueada');
                    this.retornarLista();
                }

                this.cliente_id.setValue(boleta.data.cliente.id);
                this.numero_xp.setValue(boleta.data.cliente.numero_xp);
                this.telefone.setValue(boleta.data.cliente.telefone);
                this.email.setValue(boleta.data.cliente.email);
                this.assessor_id.setValue(boleta.data.cliente.assessor.name);
                this.escritorio.setValue(boleta.data.escritorio.cidade.nome);                
                this.volume_global.setValue(boleta.data.cliente.volume_global);
                this.data_operacao.setValue(boleta.data.data_operacao);                
                this.observacao.setValue(boleta.data.observacao);
                this.status_id.setValue(boleta.data.status_id);
                this.cliente.setValue(boleta.data.cliente);
                this.tipo_boleta.setValue(boleta.data.tipo_boleta);
                this.acao_comercial_id.setValue(boleta.data.acao_comercial_id);
                this.estrategia_id.setValue(boleta.data.estrategia_id);
                this.vlr_executado.setValue( parseFloat(boleta.data.vlr_executado) );

                if(boleta.data.cliente.carteira != undefined && boleta.data.cliente.carteira != null && boleta.data.cliente.carteira.length > 0){            
                    this.valor_custodia = parseFloat(boleta.data.cliente.carteira[0].valor_total);
                    this.valor_investimento.setValue( parseFloat(boleta.data.cliente.valor_investimento) - ( parseFloat(boleta.data.cliente.valor_bloqueado) + parseFloat(boleta.data.cliente.carteira[0].valor_total) ) );
                }else{
                    this.valor_investimento.setValue( parseFloat(boleta.data.cliente.valor_investimento) - parseFloat(boleta.data.cliente.valor_bloqueado));
                }

                this.valorBoletado = boleta.data.valor;
                
                this.valor.setValue(parseFloat(boleta.data.valor));
                this.valor_trades.setValue( parseFloat(boleta.data.valor_total));
                
                if(boleta.data.valor_total > 0){
                    this.valorDetalhadoBoleta = true;
                    this.getValores();
                    this.valor_total_boleta.setValue(parseFloat(boleta.data.valor) + parseFloat(boleta.data.valor_total) - parseFloat(boleta.data.vlr_executado));
                }else{
                    this.valor_total_boleta.setValue(boleta.data.valor - parseFloat(boleta.data.vlr_executado) );
                }                
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();                
            }
        );

    }

    getDadosCliente(cliente_id){
        this.openLoading();
        this._clienteService.getById(cliente_id).subscribe(
            cliente=> {                
                this.cliente.setValue(cliente.data);
                this.getCliente(cliente.data);
            },
            error => {
                this.closeLoading();                
            },
            () => {
                this.closeLoading();                
            }
        );
    }

    /**
     * Modal de edição
    */
    openConfirmaBoleta(): void {
        if(this.validaCadastro())
        { 
            const estrategia = this.listEstrategia.find(s => s.id == this.estrategia_id.value);            

            let dialogRef = this.dialog.open(ConfirmaBoletaComponent, {
                width: '800px',
                data: { 
                    id: this.data,
                    cliente: this.cliente.value,
                    valor: this.valor.value,
                    data_operacao: this.data_operacao.value,
                    status_id: this.status_id.value,
                    observacao: this.observacao.value,
                    tipo_boleta: this.tipo_boleta.value,
                    acao_comercial_id: this.acao_comercial_id.value,
                    vlr_executado: this.vlr_executado.value,
                    estrategia_id: this.estrategia_id.value,
                    estrategia: estrategia.nome,
                    valor_total: this.valor_total_boleta.value
                }
            });

            dialogRef.afterClosed().subscribe(result => {                
                if (result != undefined && result != null && result != "cancel"){
                    this.showSuccess("Boleta salva com sucesso!");
                    this.retornarLista();
                }else if(result != undefined && result != "cancel"){
                    this.showError("Ocorreu um erro ao tentar salvar a boleta");
                }
            });
        }
    }    

    getAllParametros() {
        
        this._serviceParametro.getAll({}).subscribe(
            data => {
                for(let dados of data.data){
                    
                    if(dados.codigo == 'VALOR_MIN_BOLETA_SWING'){
                        this.vlr_min_boleta_sw = (parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_PRIMEIRA_BOLETA_SWING'){
                        this.vlr_min_primeira_boleta_sw = (parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_BOLETA_FOP'){
                        this.vlr_min_boleta_fop = (parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_PRIMEIRA_BOLETA_FOP'){
                        this.vlr_min_primeira_boleta_fop = (parseFloat(dados.valor));
                    }                     
                    
                }               
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    onChangeSelectEstrategia(){
        this.getBoletaAberta();
        this.getByClienteBoleta();
        
        const estrategia = this.listEstrategia.find(s => s.id == this.estrategia_id.value);
        this.vlr_min_boleta = parseFloat(estrategia.valor_minimo_boleta);
    }

    getValores(){

        this.loadingIndicator = true;
        this._serviceBoletaValores.getValoresById({ boleta_id: this.data}).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
        
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent): void {

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getValores();
    }
    
    openAlterar(id){

        let dialogRef = this.dialog.open(AlteraValorBoletaComponent, {
            width: '400px',
            data: { 
                id: id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {               
                this.getValores();
                this.getBoleta();
            }
        });
    }

    delete(id){
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Valor",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._serviceBoletaValores.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getValores();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);
                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    atualizaValor(event){                
        const total = parseFloat(this.valor.value) + this.model.valor_total;
        this.valor_total_boleta.setValue(total);
    }

}

@Component({
    selector: 'dialog-boleta',
    template: `<h2 mat-dialog-title>{{data.title}}</h2>
            <div mat-dialog-content>
              <p>{{data.text}}</p>
            </div>
            <div mat-dialog-actions>
              <button mat-button (click)="onNoClick()" *ngIf="data.habilitaBotoes" tabindex="-1">Não</button>
              <button mat-button (click)="callback()" *ngIf="data.habilitaBotoes" tabindex="-1">Sim</button>
              <button mat-button (click)="onNoClick()" *ngIf="!data.habilitaBotoes" tabindex="-1">Fechar</button>
            </div>`,
    styleUrls: ['./cadastro-boleta.component.scss']
})

export class ActionDialogBoleta {
    
    constructor(
        public dialogRef: MatDialogRef<ActionDialogBoleta>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.data.callback('N');
        return this.dialogRef.close('N');
    }

    callback() {
        this.data.callback('S');
        return this.dialogRef.close('S');
    }
}