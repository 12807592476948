import { 
    Component, 
    ViewChild, 
    TemplateRef 
}  from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator 
}  from '@angular/material';

import { ActionDialog } from '../../base-module/base-component/base.component';
import { BaseComponent } from "../../base-module/base-component/base.component";
import { Router } from "@angular/router";
import { ClienteService } from "../services/cliente.service";
import { FormControl } from "@angular/forms";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FiltrarClienteComponent } from '../filtrar-cliente/filtrar-cliente.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import {Subscription} from "rxjs";
import { UploadHistoricoClienteComponent } from '../upload-historico-cliente/upload-historico-cliente.component';
import { ClientEquityService } from '../services/client-equity.service';
import { CadastroRelacionamentoClienteComponent } from '../cadastro-relacionamento-cliente/cadastro-relacionamento-cliente.component';

interface ItemsPermissionActions {
    enabled: boolean;
    permission: string;
}

interface Actions {
    buttonNew: ItemsPermissionActions;
}

@Component({
    selector: 'app-data-table-lista',
    templateUrl: './lista-limitada.component.html',
    styleUrls: ['./lista-limitada.component.scss'],
    providers: [ ClientEquityService ]
})

export class ListaLimitadaComponent extends BaseComponent{

    params: any = { };

    usuario:any;
    loadingIndicator: boolean;
    habilitaEscritorios: boolean = false;
    search = new FormControl('');
    inputSubscription: Subscription; 
    lastDateUpdate: Date;

    _actions: Actions = {
        buttonNew: {
            permission: 'Cadastrar clientes',
            enabled: false
        },
    }; 

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaLimitadaComponent, { static: false }) table: ListaLimitadaComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;
    @ViewChild('table', { static: false }) table2: DatatableComponent;

    constructor(public service: ClienteService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                public _authService:AuthService) {
        super(dialog,snackBar);
        
        this.verifyPermissionForActions();
        this.search.setValue('');
        
        this.usuario = JSON.parse(this._authService.getUser());
        this.getAll();
        
        if ( this.usuario.escritorios.length > 1){
            this.habilitaEscritorios = true;
        }

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;

        if(event.column.prop == "assessor"){
            this.params.sortColumn = "assessor_id";
        }else if(event.column.prop == "escritorio"){
            this.params.sortColumn = "escritorio_id";
        }

        this.params.sortOrder = event.newValue;

        this.getAll();
    }

    /**
     * 
     */
    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {

        if(this.search.value != null && this.search.value != ''){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.inputSubscription = this.service.getListaLimitada(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
                if(error.status == 401){
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                } 
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * 
     * @param value string
     */
    onEnter(value: string) {    
        this.stopNow();     
        this.params.search = value;
        this.search.setValue(value);
        this.paginator.firstPage();
        this.getAll();
    }

     /**
     * Verifica se usuário logado tem permissão dos buttons
     * de Delete e Edit
     */
    verifyPermissionForActions(){

        // this._actions.buttonDelete.enabled = this._authService
        // .isPermissao([this._actions.buttonDelete.permission]);

    }

    /**
     * 
     * @param id 
     */
    viewClient(id){
        this.route.navigate([`/clientes/cadastro/${id}/painel`]);
    }

    historicoRelacionamento(){
        this.route.navigate([`/clientes/historico-relacionamento`]);
    }

    openCadastroHistorico(cliente:any){
        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent, {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                cliente_id: cliente.id,
                cliente: true,
                only_show: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento Cadastrado com Sucesso!");
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o registro");
            }

            this.getAll();
        });
    }
}
