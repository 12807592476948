import {
    Component, 
    Inject, 
    ViewChild
} from '@angular/core';

import {
    MAT_DIALOG_DATA, 
    MatDialog, 
    MatDialogRef, 
    MatPaginator, 
    MatSnackBar
} from "@angular/material";

@Component({
    selector: 'app-base-component',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss']
})
export class BaseComponent {

    //variáveis para datatable
    editing = {};
    rows = [];
    temp = [];
    params: any = {};
    columns = [];
    loadingIndicator: boolean = true;
    reorderable: boolean = true;

    //paginação
    length = 0;
    pageSize = 100;
    pageSizeOptions: number[] = [100, 200, 300, 500];

    //Loading de carregamento
    loadingTest:any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    isSuperAdminPermission:boolean = false;

    public empresaSession: any = null;

    constructor(
        public dialogLoading: MatDialog, 
        public snackBar: MatSnackBar
        ) 
    {

        this.params.per_page = this.pageSize;

        var data = [];

        this.rows = data;
        this.temp = [...data];
    }

    /**
     * Abre loagind
     */
    openLoading(): void {

        this.loadingTest =  this.dialogLoading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoading(): void {
        this.loadingTest.close();
    }

    /**
     * Exibe mensagem de erro
     * @param {string} message
     */
    showError(message: string) {
        this.snackBar.open(message, 'X', {  
            panelClass: 'snackbar-error',
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    /**
     * Exibe mensagem de sucesso
     * @param {string} message
     */
    showSuccess(message: string) {
        this.snackBar.open(message, 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    /**
     * Converte string em date
     * @param {string} d
     * @returns {any}
     */
    dateFormat(d: string) {
        if (d != null && d != "") {
            var data = new Date(Date.parse(d));
            return data;
        }

        return "";
    }

    /**
     * Abre modal para empresa.
     */
    /*openEmpresaPadrao(): void {
        let dialogRef = this.dialogLoading.open(ListaSelecaoComponent, {
            height: '500px',
            width: '700px',
            data: { type: "modal" }
        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log(result);
        });
    }*/

}

// LOADING ATIVADO AO CARREGAR OU FAZER ALGUMA CONSULTA NO WEBSERVICE
@Component({
    selector: 'loading-dialog',
    template: `<mat-spinner></mat-spinner> Carregando...`
})
export class LoadingTeste {

    constructor(
        public dialogRef: MatDialogRef<LoadingTeste>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

// LOADING ATIVADO AO CARREGAR OU FAZER ALGUMA CONSULTA NO WEBSERVICE
@Component({
    selector: 'alert-dialog',
    template: `<h3 mat-dialog-title>{{data.title}}</h3>
            <div mat-dialog-content>
              <p>{{data.text}}</p>
            </div>
            <div mat-dialog-actions>
              <button mat-button (click)="onNoClick()" tabindex="-1">OK</button>
            </div>`
})
export class AlertDialog {

    constructor(
        public dialogRef: MatDialogRef<AlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}

// Executa uma ação ao pressionar o SIM
@Component({
    selector: 'app-dialog',
    template: `<h3 mat-dialog-title>{{data.title}}</h3>
            <div mat-dialog-content>
              <p>{{data.text}}</p>
            </div>
            <div mat-dialog-actions>
              <button mat-button (click)="onNoClick()" tabindex="-1">Não</button>
              <button mat-button (click)="callback()" tabindex="-1">Sim</button>
            </div>`
})
export class ActionDialog {

    constructor(
        public dialogRef: MatDialogRef<ActionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close('N');
    }

    callback() {
        this.data.callback();
        return this.dialogRef.close('S');
    }
}
