import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/base-module/base-service/auth-guard.service';
import { ListaParametroComponent } from './lista-parametro/lista-parametro.component';
import { NgModule } from '@angular/core';

const routes: Routes = [{
    path: '',
    component: ListaParametroComponent,
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ParametrosRoutesModule {} 