import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/router';
import { ListaComponent } from './lista/lista.component';
import { AuthGuardService } from "../base-module/base-service/auth-guard.service";
import { CadastroCompletoComponent } from "./cadastro-completo/cadastro-completo.component";
import { ListaStatusOperacaoComponent } from './lista-status-operacao/lista-status-operacao.component';
import { ListaSaldoClienteComponent } from './lista-saldo-cliente/lista-saldo-cliente.component';
import { ListaHistoricoRelacionamentoComponent } from './lista-historico-relacionamento/lista-historico-relacionamento.component';
import { CadastroCompletoPainelComponent } from './cliente-dados/cadastro-completo-painel/cadastro-completo-painel.component';
import { ListaEstruturaFamiliarComponent } from './cliente-dados/estrutura-familiar/lista-estrutura-familiar/lista-estrutura-familiar.component';
import { ListaLimitadaComponent } from './lista-limita/lista-limitada.component';
import { CadastroCompletoStepperComponent } from './cadastro-completo-stepper/cadastro-completo-stepper.component';
import { ListaTipoCustoComponent } from './lista-tipo-custo/lista-tipo-custo.component';
import { ListaTipoReceitaComponent } from './lista-tipo-receita/lista-tipo-receita.component';

const routes: Routes = [{
    path: '',    
    children: [
        { path: '', component: ListaComponent },
        { path: 'consulta', component: ListaComponent },
        { path: 'lista', component: ListaComponent },
        { path: 'cadastro', component: CadastroCompletoComponent },
        { path: 'cadastro/:id', component: CadastroCompletoComponent },
        { path: 'cadastro/:id/painel', component: CadastroCompletoPainelComponent },
        { path: 'ordens-pendentes', component: ListaStatusOperacaoComponent },
        { path: 'saldo', component: ListaSaldoClienteComponent },
        { path: 'historico-relacionamento', component: ListaHistoricoRelacionamentoComponent },
        { path: 'estrutura-familiar', component: ListaEstruturaFamiliarComponent},
        { path: 'lista-limitada', component: ListaLimitadaComponent},
        { path: 'ficha-cadastral', component: CadastroCompletoStepperComponent },
        { path: 'ficha-cadastral/:id', component: CadastroCompletoStepperComponent },
        { path: 'tipo-custo', component: ListaTipoCustoComponent},
        { path: 'tipo-receita', component: ListaTipoReceitaComponent},
    ],
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ClientesRoutesModule {}
