import {
    Component,     
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { 
    FormControl, 
    Validators
} from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';

import {BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { AtivoService } from '../../auxiliares/services/ativo-service';
import { SetorAtivoService } from 'app/auxiliares/services/setor-ativo.service';
import { Setor } from '../models/setor.interface';
import { Ativo } from '../models/ativo.interface';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-cadastro-ativo',
    templateUrl: './cadastro-ativo.component.html',
    styleUrls: ['./cadastro-ativo.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ SetorAtivoService ]
})

export class CadastroAtivoComponent extends BaseComponent {

    nome = new FormControl('', [Validators.required]);
    sigla = new FormControl('', [Validators.required]);
    valor = new FormControl('', [Validators.required]);
    tipo = new FormControl('', [Validators.required]);
    setor_id = new FormControl('', [Validators.required]);
    tipo_opcao = new FormControl();
    bovespa = new FormControl();
    mesVencimento = new FormControl();    

    public listSetor: Setor[] = [];

    private model: Ativo = {};

    loadingLocal:any;
    readonlyFields:boolean = false;
    habilitaTipoOpcao:boolean = false;
    loadingSpinnerSetor:boolean = false;

    constructor(public service: AtivoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroAtivoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _serviceSetor: SetorAtivoService)
    {
        super(dialog, snackBar);
        if(this.data != null && this.data != undefined){
            this.readonlyFields = true;
            this.getById();
        }

        console.log(this.model);

        this.getSetor();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.nome.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.nome = this.nome.value;
        this.model.sigla = this.sigla.value;
        this.model.cotacao_atual = this.valor.value;
        this.model.tipo = this.tipo.value;
        this.model.tipo_opcao = this.tipo_opcao.value;
        this.model.bovespa = this.bovespa.value;
        this.model.mesVencimento = this.mesVencimento.value;
        this.model.setor_id = this.setor_id.value;

        if(this.data == null){

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                                                       
                },
                error => {                    
                    this.closeLoading();
                    this.showError(error.error.message);

                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getErrorSetorMessage() {
        return this.setor_id.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    checaOpcao(event){
        if(event.value == 'OPCAO'){
            this.habilitaTipoOpcao = true;
        }else{
            this.habilitaTipoOpcao = false;
        }        
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {
            this.model = item.data;
            this.nome.setValue(item.data.nome);
            this.sigla.setValue(item.data.sigla);
            this.valor.setValue(item.data.cotacao_atual);
            this.tipo.setValue(item.data.tipo);
            this.tipo_opcao.setValue(item.data.tipo_opcao);
            this.mesVencimento.setValue(item.data.mesVencimento);
            this.setor_id.setValue(item.data.setor_id);
            
            this.bovespa.setValue( item.data.bovespa.toString() );
            if(item.data.tipo == 'OPCAO'){
                this.habilitaTipoOpcao = true;
            }
            console.log(this.model);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    getSetor(){
        this.loadingSpinnerSetor = true;
        this._serviceSetor.getAll({}).subscribe(
            list => {
                this.listSetor = list.data;
            },
            error => {
                this.loadingSpinnerSetor = false;
            },
            () => {
                this.loadingSpinnerSetor = false;                
            }
        );
    }

}
