import { Component, OnInit } from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar 
} from '@angular/material';

import { Router } from '@angular/router';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { ListaNoticiaModalComponent } from 'app/central-noticias/lista-noticia-modal/lista-noticia-modal.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { NoticiaService } from 'app/central-noticias/services/noticia.service';

@Component({
  selector: 'noticia-component',
  templateUrl: './noticia-component.component.html',
  styleUrls: ['./noticia-component.component.scss'],
  providers: [ NoticiaService ]
})
export class NoticiaComponentComponent  extends BaseComponent implements OnInit {

    user:any;
    loadingIndicator:boolean = false;

    constructor(public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService,
        public _route: Router,
        private _notificacaoService: NoticiaService) { 
            super(dialog, snackBar);
            this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getAll();
    }

    getAll(){
        this.loadingIndicator = true;
        this._notificacaoService.getAll({no_paginate:true, hoje: true}).subscribe(
            data => {
                this.rows = data.data;
                this.temp = [...this.rows];
        },
            error => {
                this.loadingIndicator = false;
            },
        () => {
            this.loadingIndicator = false;
        });    
    }

    handleNotification(){
        this._route.navigate(['/central-noticias']);
    }

    onClickNoticia(event) {
        if(event.type == 'click'){
            let dialogRef = this.dialog.open(ListaNoticiaModalComponent, {
                width: '80%',
                data: event.row,
                panelClass: 'scrumboard-noticias-card-dialog'
            });

            dialogRef.afterClosed().subscribe(result => {            
            });
        }
    }


}
