import { 
    Component, 
    OnInit    
} from '@angular/core';

import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { CalendarService } from 'app/apps/calendar/services/calendar.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
  selector: 'agenda-component',
  templateUrl: './agenda-component.component.html',
  styleUrls: ['./agenda-component.component.scss'],
  providers: [ CalendarService ]
})

export class AgendaComponentComponent extends BaseComponent implements OnInit {
    
    user:any;
    loadingIndicator:boolean = false;

    constructor(public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService,
        public _route: Router,
        private _calendarService: CalendarService) { 
            super(dialog, snackBar);
            this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getAll();
    }

    getAll(){
        this.loadingIndicator = true;
        this._calendarService.getAll({no_paginate:true, semana: true}).subscribe(
            data => {                
                this.rows = data.data;
                this.temp = [...this.rows];
        },
            error => {
                this.loadingIndicator = false;
            },
        () => {
            this.loadingIndicator = false;
        });    
    }

    handleCalendar(){
        this._route.navigate(['/calendar']);
    }

}
