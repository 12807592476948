import {Injectable}         from "@angular/core";
import { UtilitesService }  from "../../../base-module/base-service/utilites.service";
import { AuthService }      from  "../../../base-module/base-service/auth.service";
import { Observable }       from "rxjs";
import {HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable()
export class ArquivoService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Busca a Pasta pelo ID
     * @param id ID da Pasta
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "file-manager/arquivo/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
    *  Upload de arquivo
    * @param file 
    */

    upload(file):Observable<any>{
        const formData = new FormData();
        formData.append('arquivo', file);
        return this._http.post(this.utilitesService.urlPrefix +'file-manager/arquivo/uploads', formData, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista todas as Arquivo do GED
     * @param id ID da Arquivo
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "file-manager/arquivo"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete Arquivo do Usuário
     * @param id ID da Arquivo
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "file-manager/arquivo/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Criar Arquivo do Usuário
     * @param params Json Arquivo
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "file-manager/arquivo", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Atualiza Arquivo do Usuário
     * @param params Json Arquivo
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "file-manager/arquivo/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }    


     /**
     * Exporta Lista em Excel
     * @param id ID
     */
    download(id): Observable<any> {

        return this._http.get(this.utilitesService.urlPrefix + "file-manager/arquivo/download/"+id, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': 'application/png',
                'Accept': 'application/png',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }
	
}
