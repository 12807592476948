import {
    Component, 
    ViewChild, 
    TemplateRef,
    ViewEncapsulation
}  from '@angular/core';

import {
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator
}  from '@angular/material';

import {FormControl} from "@angular/forms";

import { fuseAnimations } from '@fuse/animations';
import {SelectionType} from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {BaseComponent} from "../../base-module/base-component/base.component";
import { CadastroAtivoComponent } from '../cadastro-ativo/cadastro-ativo.component';
import { FiltroCustodiaConsolidadaComponent } from '../filtro-custodia-consolidada/filtro-custodia-consolidada.component';
import { ListaCustodiaRvSetorComponent } from '../lista-custodia-rv-setor/lista-custodia-rv-setor.component';

import { CustodiaService } from '../services/custodia.service';
import { CarteiraService } from 'app/clientes/services/carteira.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ImportacaoArquivoService } from '../services/importacao-arquivo.service';

@Component({
    selector: 'app-lista-custodia',
    templateUrl: './lista-custodia.component.html',
    styleUrls: ['./lista-custodia.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
    providers: [ CarteiraService ]
})

export class ListaCustodiaComponent extends BaseComponent{

    usuario:any;
    loadingIndicator: boolean;
    loadingListaAtivo: boolean;
    public search = new FormControl();
    cotacaoAtual:number;
    inputSubscription: Subscription; 

    rowsAtivo = [];
    tempAtivo = [];
    paramsAtivo: any = {};
    columnsAtivos = [];

    //paginação
    lengthAtivo = 0;
    pageSizeAtivo = 100;
    pageSizeOptionsAtivo: number[] = [100, 200, 300, 500];
    widget8: any = {};
    resultado:any;
    totalFinanceiro:any;
    user:any;
    data_atualizacao:any;
    valor_total_cliente:number;

    SelectionType = SelectionType;

    enableSummary = true;
    summaryPosition = 'top';

    selected = [];

    params:any = {
        per_page:""
    }

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaCustodiaComponent, { static: false }) table: ListaCustodiaComponent;
    @ViewChild('paginator',{ static: false }) paginator: MatPaginator;

    @ViewChild(ListaCustodiaRvSetorComponent, {static: false})
    custodiaRV: ListaCustodiaRvSetorComponent;

    
    @ViewChild(ListaCustodiaComponent, { static: false }) tableAtivo: ListaCustodiaComponent;

    constructor(public service: CustodiaService,
                public _carteiraService: CarteiraService,
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,
                private _authService: AuthService,
                public _arquivoService: ImportacaoArquivoService,
                private route:Router) {
        super(dialog,snackBar);

        this.search.setValue('');
        this.getAll();

        if(this.params.per_page == undefined || this.params.per_page == "" || this.params.per_page == null){
            this.params.per_page = 100;
        }

        var data        = [];
        var dataAtivo   = [];

        this.rows       = data;
        this.temp       = [...data];
        
        this.rowsAtivo  = dataAtivo;
        this.tempAtivo  = [...dataAtivo];

        this.user = JSON.parse(this._authService.getUser());
        
        this.getLastArchive();

    }

    getPrecoExecutado(row){
        if(row.preco_executado == '' || parseFloat(row.preco_executado) == 0 ){
            return parseFloat(row.vlr_financeiro);
        }else{
            return parseFloat(row.vlr_executado);
        }
        
    }

    getValorFinanceiro(row){
        return parseFloat(row.qtd) * parseFloat(row.preco_executado);
    }   

    getCotacaoAtual(row){
        return parseFloat(row.qtd) * parseFloat(row.ativo.cotacao_atual);
    }


    percentualFinanceiro(financeiro){
        
        if(this.params.cliente_id == undefined && this.params.cliente_id == ''){
            var total = (parseFloat(financeiro) / parseFloat(this.totalFinanceiro) ) * 100;
            return total;
        }
        else{
            return (parseFloat(financeiro) / this.valor_total_cliente) * 100;
        }
    }

    percentualFinanceiroDiferenca(row){
        if((row.quantidade * row.cotacao_atual) > row.vlr_financeiro){
            return (((parseFloat(row.quantidade) * parseFloat(row.cotacao_atual)  / parseFloat(row.vlr_financeiro) ) - 1 )  * 100);
        }
        return ((parseFloat(row.quantidade) * parseFloat(row.cotacao_atual)  / parseFloat(row.vlr_financeiro) ) - 1 ) * -100;
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEventAtivo(event:PageEvent){
        this.paramsAtivo.page = (event.pageIndex+1);
        this.paramsAtivo.per_page = event.pageSize;

        this.getAllCarteira();
    }

     /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

     /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSortAtivo(event:any){

        this.paramsAtivo.sortColumn = event.column.prop;
        this.paramsAtivo.sortOrder = event.newValue;

        this.getAllCarteira();
    }

    /**
     * Consulta lista de Boletas
     */
    getAll() {
        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.inputSubscription = this._carteiraService.getAllAtivosCliente(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Consulta lista de Boletas
     */
    getAllCarteira() {
        if(this.search.value != null){
            this.paramsAtivo.search = this.search.value;
        }

        this.loadingListaAtivo = true;
        this.paramsAtivo.homebroker = false;
        this.service.getAllCarteira(this.paramsAtivo).subscribe(
            data => {
                this.lengthAtivo = data.meta.total;
                this.rowsAtivo  = data.data;
                this.tempAtivo  = [...this.rowsAtivo];
            },
            error => {                
                this.loadingListaAtivo = false;
            },
            () => {                
                this.getTotalCliente();                
                this.loadingListaAtivo = false;
            }
        );
    }

    getTotalFinanceiro() 
    {
        if(this.search.value != null){
            this.paramsAtivo.search = this.search.value;
        }
        
        this._carteiraService.getTotalFinanceiro(this.paramsAtivo).subscribe(
            data => {
                this.totalFinanceiro = data.data;                
            },
            error => { },
            () => { }
        );
    }  
    /**
    * Modal de edição
    */
    alterarCotacao(ativo_id): void {
        let dialogRef = this.dialog.open(CadastroAtivoComponent, {
            width: '400px',
            data: { id: ativo_id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Cotação Atual do Ativo alterado!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar alterar o valor da cotação atual do ativo");
            }
        });
    }

    onLinkClick(e){
        
        if(e.index == 0){
            this.getAll();
        }

        if(e.index == 1){
            this.getTotalFinanceiro();
            this.getAllCarteira();
        }
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltroCustodiaConsolidadaComponent, {
            width: '700px',
            maxHeight: '99vh'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) 
            {                
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                    this.paramsAtivo.cliente_id = result.cliente.id;
                }

                if (result.ativo != undefined && result.ativo != null) {
                    this.params.ativo_id = result.ativo.id;
                    this.paramsAtivo.ativo_id = result.ativo.id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                    this.paramsAtivo.escritorio_id = result.escritorio_id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                    this.paramsAtivo.assessor_id = result.assessor_id;
                }

                if (result.percentual != undefined && result.percentual != null) {
                    this.params.percentual = result.percentual;
                }

                if (result.tipo_percentual != undefined && result.tipo_percentual != null) {
                    this.params.tipo_percentual = result.tipo_percentual;
                }

                if (result.tipo_cliente != undefined && result.tipo_cliente != null) {
                    this.params.tipo_cliente = result.tipo_cliente;
                }    

                if (result.tipo_custodia != undefined && result.tipo_custodia != null) {
                    this.params.tipo_custodia = result.tipo_custodia;
                }

                if (result.migrado != undefined && result.migrado != null) {
                    this.params.migrado = result.migrado;
                    this.paramsAtivo.migrado = result.migrado;
                }

                if (result.estrategia_id != undefined && result.estrategia_id != null) {
                    this.params.estrategia_id = result.estrategia_id;                    
                }else{
                    this.params.estrategia_id = '';
                }

                if (result.primeiro_contato_id != undefined && result.primeiro_contato_id != null) {
                    this.params.primeiro_contato_id = result.primeiro_contato_id;
                    this.paramsAtivo.primeiro_contato_id = result.primeiro_contato_id;
                }

                if (result.setor_id != undefined && result.setor_id != null) {
                    this.params.setor_id = result.setor_id;                    
                    this.paramsAtivo.setor_id = result.setor_id;                    
                }else{
                    this.params.setor_id = '';
                    this.paramsAtivo.setor_id = '';
                }

                this.paginator.firstPage();
                
                this.getAll();
                this.getAllCarteira();
                this.custodiaRV.getAll();
            }
        });
    }

    /**
     * Consulta lista
     */
    getAllExport(): void {

        this.openLoading();
        this._carteiraService.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.showError("Usuário Sem Permissão!");
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }  
    
    getTotalCliente(){
        let total = 0;
        this.rowsAtivo.map((row) => {
            if(parseFloat(row.vlr_financeiro) > 0){
                total += parseFloat(row.vlr_financeiro);
            }
        });

        this.valor_total_cliente = total;
    }

    getLastArchive(): void {
        
        this._arquivoService.getLastId().subscribe(
            data => {                
                this.data_atualizacao = data.data.created_at;
            },
            error => {
                this.showError(error.error.message);
            },
            () => { }
        );
        
    }

    redirecionaCliente(cliente){
        this.route.navigate([`clientes/cadastro/${cliente}`]);
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    onEnter(value: string) {    
        this.stopNow();
        this.params.search = value;
        this.search.setValue(value);
        this.getAll();
    }

    totalCustodia():string{
        return 'TOTAL';
    }

    somaFinanceiro(cells){
        
        const filteredCells = cells.filter(cell => !!cell);
        let total = 0;
        filteredCells.map((cell) => {
            if(cell > 0){
                total += parseFloat(cell);
            }
        });

        return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    somaFinanceiroHoje(cells){        
        const filteredCells = cells.filter(cell => !!cell);
        let total = 0;
        filteredCells.map((cell) => {
            
            if(cell > 0){
                total += parseFloat(cell);
            }
        });

        return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    
}
