import {
    Component,
    ViewChild
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    PageEvent
} from '@angular/material';

import { ActivatedRoute } from '@angular/router';

import { ActionDialog, BaseComponent } from "app/base-module/base-component/base.component";
import { CadastroHistoricoClienteComponent } from '../../cadastro-historico-cliente/cadastro-historico-cliente.component';
import { FiltroHistoricoClienteComponent } from '../../filtro-historico-cliente/filtro-historico-cliente.component';
import { UploadHistoricoClienteComponent } from '../../upload-historico-cliente/upload-historico-cliente.component';

import { AuthService } from 'app/base-module/base-service/auth.service';
import { CustodiaService } from 'app/movimentacao/services/custodia.service';
import { ImportacaoArquivoService } from 'app/movimentacao/services/importacao-arquivo.service';

@Component({
    selector: 'app-lista-historico-cliente',
    templateUrl: './lista-historico-cliente.component.html',
    styleUrls: ['./lista-historico-cliente.component.scss']
})

export class ListaHistoricoClienteComponent extends BaseComponent{

    params: any = {};
    user: any;
    data: any;
    loadingIndicator: boolean;
    data_atualizacao: any;   

    enableSummary = true;
    summaryPosition = 'top';
    
    @ViewChild(ListaHistoricoClienteComponent, { static: false }) table: ListaHistoricoClienteComponent;

    constructor(public _custodiaService: CustodiaService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService,                
                private _Activatedroute: ActivatedRoute,
                private _arquivoService: ImportacaoArquivoService) {
        super(dialog,snackBar);

        this.user = JSON.parse(this._authService.getUser());

        this.data = this._Activatedroute.snapshot.params['id'];


        this.getAll();
        this.getLastArchive();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }
  

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator   = true;
        this.params.cliente_id  = this.data;

        this._custodiaService.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {        
                this.showError(error.error.message);
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    getLastArchive(): void {
        
        this._arquivoService.getLastId().subscribe(
            data => {                
                this.data_atualizacao = data.data.created_at;
            },
            error => {
                this.showError(error.error.message);
            },
            () => { }
        );
        
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltroHistoricoClienteComponent, {
            width: '700px',
            maxHeight: '99vh',
            data: { 
                titulo: 'Filtrar Saldo de Clientes',
                status_boleta: false,
                status_ordem: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_fim = result.data_operacao_fim;
                }

                if (result.ativo_id != undefined && result.ativo_id != null) {
                    this.params.ativo_id = result.ativo_id;
                }else{
                    this.params.ativo_id = '';
                }

                if (result.estrategia_id != undefined && result.estrategia_id != null) {
                    this.params.estrategia_id = result.estrategia_id;
                }

                if (result.operacao != undefined && result.operacao != null) {
                    this.params.tipo = result.operacao;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }
    
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroHistoricoClienteComponent, {
            width: '500px',
            maxHeight: '99vh',
            data: { 
                cliente_id: this.data,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Histórico cadastrado com sucesso!");
                this.getAll();
            }
        });
    }

    alterar(id): void {
        let dialogRef = this.dialog.open(CadastroHistoricoClienteComponent, {
            width: '400px',
            maxHeight: '99vh',
            data: { 
                cliente_id: this.data,
                id:id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Histórico atualizado com sucesso!");
                this.getAll();
            }
        });
    }

    openUpload() :void {
        let dialogRef = this.dialog.open(UploadHistoricoClienteComponent, {
            width: '400px',
            data:{
                url: 'clientes/historico/upload',
                title: 'Importação de Histórico do Cliente',
                urlArchiveModule: 'downloads/modelo_importacao_historico.xlsx'
            }           
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Histórico importado com sucesso!");
                this.getAll();
            }
        });
    }

     /**
     * Exclui registro de histórico
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir histórico",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._custodiaService.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    exportarSaldo(){
        this.openLoading();
        this._custodiaService.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    getPrecoExecutado(row){
        if(row.preco_executado == '' || parseFloat(row.preco_executado) == 0 ){
            return parseFloat(row.vlr_financeiro);
        }else{
            return parseFloat(row.preco_executado) * parseFloat(row.qtd_executado);
        }
    }    

    getTipoAtivo(row){
       
        if(row.tipo_ativo !== null && row.tipo_ativo !== undefined){
            if(row.tipo_ativo == 'F'){
                return 'Opção Flexível'            
            }else if(row.tipo_ativo == 'D'){
                return 'Dividendos';
            }else if(row.tipo_ativo == 'I'){
                return 'Juros sobre capital';
            }else if(row.ativo_tipo == 'OPCAO'){
                return 'Opção';
            }else{
                return 'Bolsa';
            }
        }else{
            return 'Bolsa';
        }
        
       
    }

    getOperacao(row){
        if(row.tipo.trim() == 'C'){
            return 'Compra';
        }

        if(row.tipo.trim() == 'E'){
            return 'Exercício'
        }

        if(row.tipo.trim() == 'D'){
            return 'Crédito';
        }

        return 'Venda'
    }

}
