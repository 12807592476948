import {Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from '../../../environments/environment';

@Injectable()
export class NotificacaoService {

    qtd_notificacao: any = 0;    
    user:any;
    messagesChannel: any;
    Echo:any;
    notificEcho:any;
    socket;

    readonly VAPID_PUBLIC_KEY = environment.publicKey;

    constructor(
        private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) 
    {
        this.user = JSON.parse(this.authService.getUser());        
        if(this.user != undefined && this.user != ''){            
            //this.listen();
            this.getNotificacao();
        }        
    }

    /**
     * Notificação por id
     * @param id ID da Notificação
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "notificacao/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Notificação
     * @param id ID da Notificação
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "notificacao"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Contador de Notificação
     */
    async getNotificacao(){
        await this._http.get(this.utilitesService.urlPrefix + "notificacao/quantidade", { headers: this.authService.getAuthHeaders() }).subscribe(
            notificacao => {                
                this.setNotificacao(notificacao);
            },
            error => {
            },
            () => {               
                              
            }
        );
    }


    /**
     * Delete Notificação
     * @param id ID do Objetivo do Pré-agendamento
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "notificacao/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Notificação
     * @param params Json Objetivo do Pré-agendamento
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "notificacao", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Notificação
     * @param params Json Objetivo do Pré-agendamento
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "pre-agendamento/objetivo/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
    
    notificacao(titulo,msg){

        var notification = new Notification(titulo, {
            body: msg,
            icon: 'assets/images/logos/ico-72.png',            
        })

    }

  	setNotificacao(notificacao){
    	window.localStorage.setItem('notificacao', notificacao);
  	}

  	getQtdNotificacao(){
    	return window.localStorage.getItem('notificacao');
    }

}
