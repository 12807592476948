import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from  "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class DashboardConsorcioService {

    constructor(private _authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * Lista Dashboard
     * @param params Object
     */
    getAll(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "consorcio/dashboard" + env, { headers: this._authService.getAuthHeaders() });
    }

}