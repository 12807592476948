import {
    Component, 
    ViewChild, 
    TemplateRef
} from '@angular/core';
import {
    MatDialog, 
    MatSnackBar, 
    PageEvent
} from '@angular/material';

import {FormControl} from "@angular/forms";
import {BaseComponent } from '../../base-module/base-component/base.component';
import { NotificacaoService } from '../services/notificacao.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { VerNotificacaoComponent } from '../ver-notificacao/ver-notificacao.component';
import { FiltroNotificacaoComponent } from '../filtro-notificacao/filtro-notificacao.component';

@Component({
  selector: 'app-lista-notificacao',
  templateUrl: './lista-notificacao.component.html',
  styleUrls: ['./lista-notificacao.component.scss']
})

export class ListaNotificacaoComponent extends BaseComponent 
{
    params: any = {};
    loadingIndicator: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaNotificacaoComponent, { static: false }) table: ListaNotificacaoComponent;

    public search = new FormControl();
    user:any;

    constructor(public service: NotificacaoService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog,snackBar);
       
        this.user = JSON.parse(this._authService.getUser());

        this.search.setValue('');

        this.getAll();

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){        

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {

        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    onActivate(e) {
        if(e.type == 'click'){        

            const dialogRef = this.dialog.open(VerNotificacaoComponent, {               
                data: { 
                    id: e.row.id
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                this.getAll();
                if (this.user != undefined && this.user != "") {
                    this.service.getNotificacao();
                }
                
            });
        }        
    }

    openFiltro(){
        let dialogRef = this.dialog.open(FiltroNotificacaoComponent, {
            width: '700px',                       
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {                

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }else{
                    this.params.escritorio_id = '';
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }else{
                    this.params.assessor_id = '';
                }

                if (result.regional_id != undefined && result.regional_id != null) {
                    this.params.regional_id = result.regional_id;
                }else{
                    this.params.regional_id = '';
                }

                if (result.data_inicio != undefined && result.data_inicio != null) {
                    this.params.data_inicio = result.data_inicio;
                }else{
                    this.params.data_inicio = '';
                }

                if (result.data_inicio != undefined && result.data_inicio != null) {
                    this.params.data_fim = result.data_fim;
                }else{
                    this.params.data_fim = '';
                }

                if (result.categoria_id != undefined && result.categoria_id != null) {
                    this.params.categoria_id = result.categoria_id;
                }else{
                    this.params.categoria_id = '';
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

}
