import { Injectable } from '@angular/core';

import {Observable} from 'rxjs';

//Services
import {UtilitesService} from './utilites.service';
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class EnderecoService {

  constructor(private authService: AuthService,
              private _http: HttpClient,
              public utilitesService: UtilitesService){
  }

  getByCep(cep):Observable<any>{
    return this._http.get(this.utilitesService.urlPrefix+"endereco/"+cep,{ headers: this.authService.getAuthHeaders() });
  }

    /**
     * Buscas os estados
     */
    getEstados():Observable<any>{
        return this._http.get(this.utilitesService.urlPrefix + "estados", { headers: this.authService.getAuthHeaders() });
    }

    getCidades(estado_id):Observable<any>{
        return this._http.get(this.utilitesService.urlPrefix + "estados/"+estado_id+'/cidades', { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Buscas os estados por pais
     */
    getEstadosByPais(pais_id):Observable<any>{
        return this._http.get(this.utilitesService.urlPrefix + "estados/", { headers: this.authService.getAuthHeaders() });
    }

}
