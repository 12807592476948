import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent, MatPaginator} from '@angular/material';
import {ActionDialog} from '../../base-module/base-component/base.component';
import {BaseComponent} from "../../base-module/base-component/base.component";
import {Router, RouterOutlet} from "@angular/router";
import {FormControl} from "@angular/forms";
import { FiltrarBoletaComponent } from '../filtrar-boleta/filtrar-boleta.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import {SelectionType} from '@swimlane/ngx-datatable';
import { AtualizaStatusBoletaComponent } from '../atualiza-status-boleta/atualiza-status-boleta.component';
import { BoletaUnicaService } from '../services/boleta-unica.service';

@Component({
    selector: 'app-lista-boleta-unica',
    templateUrl: './lista-boleta-unica.component.html',
    styleUrls: ['./lista-boleta-unica.component.scss']
})

export class ListaBoletaUnicaComponent extends BaseComponent{

    params: any = {};
    user:any;
    loadingIndicator: boolean;
    public search = new FormControl();
    SelectionType = SelectionType;

    selected = [];
    data:any = {};
    titulo:string;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaBoletaUnicaComponent, { static: false }) table: ListaBoletaUnicaComponent;
    @ViewChild('paginator',{ static: false }) paginator: MatPaginator;

    constructor(public service: BoletaUnicaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private route: Router,
                private _authService: AuthService,
                private outlet: RouterOutlet                ) {
        super(dialog, snackBar);        

        this.data = this.outlet.activatedRouteData['tipo'];       
        
        this.user = JSON.parse(this._authService.getUser());
        this.search.setValue('');
        this.getAll();

    }
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;

        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){        

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de Boletas
     */
    getAll() {
        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];                
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;

                if(error.status == 401)
                {
                    this.showError('Usuário Não Autenticado! Por favor refaça login no sistema');
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                }
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de boleta
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir boleta",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }
    /**
     * 
     */
    openCadastro(){        
        this.route.navigate(['/movimentacao/boleta-unica/cadastro']);
    }
    
    /**
     * 
     * @param id 
     */
    openAlterar(id){
        this.route.navigate(['/movimentacao/boleta-unica/cadastro/' + id]);
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarBoletaComponent, {
            width: '700px',
            maxHeight: '99vh',
            data: {
                titulo: "Filtro Boleta Unica",
                status_boleta: true,
                status_ordem: false,
                tipo: "RV"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                this.params.data_operacao_inicio    = result.data_operacao_inicio;
                this.params.data_operacao_fim       = result.data_operacao_fim;
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.status_id != undefined && result.status_id != null) {
                    this.params.status_id = result.status_id;
                }

                if (result.acao_comercial_id != undefined && result.acao_comercial_id != null) {
                    this.params.acao_comercial_id = result.acao_comercial_id;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    onSelect({ selected }) {        
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    openAtualizar(){

        if(this.selected.length == 0){
            this.showError("Selecione pelo menos uma boleta para realizar a atualização do status");
            return false;
        }

        let dialogRef = this.dialog.open(AtualizaStatusBoletaComponent, {
            width: '700px',
            data: { 
                boletas: this.selected,
                tipo: 'Unica'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {               
                this.getAll();
                this.selected = [];
            }
        });
    }

    /**
     * Visualizar Dados da Boleta Única
     * @param id 
     */
    openVisualizar(id){
        this.route.navigate(['/movimentacao/boleta-unica/visualizar/' + id]);
    }

    /**
     * Consulta lista
     */
    getAllExport() {

        this.openLoading();
        this.service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

}
