import {EventEmitter, Injectable} from "@angular/core";
import { UtilitesService } from "app/base-module/base-service/utilites.service";
import { AuthService } from  "app/base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class DashboardService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }   
 
    /**
     * Lista
     * @param id ID
     */
    getIndicadores(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/indicadores"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getGraficoEscritorio(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/vlrEscritorio"+env, { headers: this.authService.getAuthHeaders() });
    }

    
    /**
     * Lista
     * @param id ID
     */
    graficoTop10Assessores(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/topAssessores"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    totalClienteNovo(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/novoClientesEscr"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    totalCallsClienteEscritorio(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/clienteCall"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getCustodiaAtivos(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/custodiaClientes"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getIncosistencias(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/incosistencias"+env, { headers: this.authService.getAuthHeaders() });
    }

    getListaIncosistencias(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/listas/incosistencias"+env, { headers: this.authService.getAuthHeaders() });
    }

    getListaIncosistenciasOpcao(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "dashboard/listas/opcoes-descasadas"+env, { headers: this.authService.getAuthHeaders() });
    }

    getClientesNegativos(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "dashboard/contasNegativas"+env, { headers: this.authService.getAuthHeaders() });
    }    

}