import { Component }  from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent }  from '@angular/material';
import { BaseComponent } from "../../../base-module/base-component/base.component";
import { Router } from "@angular/router";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SwingTradeService } from 'app/renda-variavel/services/swing-trade.service';

@Component({
    selector: 'app-lista-operacao',
    templateUrl: './lista-operacao.component.html',
    styleUrls: ['./lista-operacao.component.scss']
})

export class ListaOperacaoComponent extends BaseComponent{

    params: any = {};
    user:any;
    loadingIndicator: boolean;   
    SelectionType = SelectionType;

    selected = [];
    data:any = {};
    titulo:string;    

    constructor(public service: SwingTradeService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                private _authService: AuthService) 
    {
        
        super(dialog, snackBar);        
        this.user = JSON.parse(this._authService.getUser());      
        this.getAll();

    }
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {
        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * 
     */
    openVisualizar(id: number): void {
        this.route.navigate([`/renda-variavel/swing-trade/clientes/${id}`]);
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        /*let dialogRef = this.dialog.open(FiltraBasketComponent, {
            width: '700px',            
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                if (result.tipo_basket_id != undefined && result.tipo_basket_id != null){
                    this.params.tipo_basket_id = result.tipo_basket_id;
                }

                if (result.nome_arquivo != undefined && result.nome_arquivo != null){
                    this.params.nome_arquivo = result.nome_arquivo;
                }

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_fim = result.data_operacao_fim;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });*/
    }   

    /**
     * Consulta lista
     */
    getExportClientes() {

        this.openLoading();
        this.service.getAllExport( { baskets: this.selected } ).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {
                console.log(error);
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

}

