import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent, MatPaginator} from '@angular/material';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {UsuariosService} from "../services/usuarios.service";
import {UsuariosCadastrarComponent} from "../usuarios-cadastrar/usuarios-cadastrar.component";
import {FormControl} from "@angular/forms";
import { FiltrarUsuariosComponent } from '../filtrar-usuarios/filtrar-usuarios.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Subscription} from "rxjs";

@Component({
    selector: 'app-data-tabble-usuarios',
    templateUrl: './usuarios-lista.component.html',
    styleUrls: ['./usuarios-lista.component.scss']
})
export class UsuariosListaComponent extends BaseComponent{

    params: any = {};
    loadingLocal:any;
    loadingIndicator: boolean;
    data:any = {};
    public search = new FormControl();
    inputSubscription: Subscription; 
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(UsuariosListaComponent, { static: false }) table: UsuariosListaComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;

    constructor(public service: UsuariosService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,
                public loading: MatDialog,
                public _authService: AuthService,
                public _route:Router,
                private _Activatedroute:ActivatedRoute) {
        super(dialog,snackBar);

        this.data = this._Activatedroute.snapshot.data.type;
        
        if(this.data == 'logout'){
            this.logout();
        }

        this.params.search = "";
        this.getAll();

    }
    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }
    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {

        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.inputSubscription = this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }    

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(UsuariosCadastrarComponent, {
            panelClass: 'contact-form-dialog',
            width: '400px',
            //data: { name: this.name, animal: this.animal }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Usuário cadastrado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo usuário.");
            }
        });
    }

    onSelect(event){
        if(event.type == 'click') {
            const row = event.row;
            this.openAlterar(row.id);            
        }        
    }   

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(UsuariosCadastrarComponent, {
            panelClass: 'contact-form-dialog',
            width: '400px',
            data: { id: id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Usuário alterado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o cadastro do usuário.");
            }
        });
    }

    getNameRoles(roles){
        let names:string = "";

        for (var i = 0; i < roles.length; i++) {
            var v = "";
            if(i!=0){
                v = ", ";
            }
            names = names+v+roles[i].name;
        }

        return names;
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarUsuariosComponent, {
            width: '700px',
            //data: { name: this.name, animal: this.animal }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                this.params.escritorio_id = result.escritorio_id;
                this.params.role_id = result.role_id;
                this.params.name = result.name;
                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;      

        this.params.sortOrder = event.newValue;

        this.getAll();
    }

    getNameEscritorio(escritorio){
        let names:string = "";

        for (var i = 0; i < escritorio.length; i++) {
            var v = "";
            if(i!=0){
                v = ", ";
            }
            names = names+v+escritorio[i].nome_fantasia;
        }

        return names;
    }

    logout(){
        this._authService.logout();
        this._route.navigate(['/auth/login']);
    }

    onEnter(value){
        this.stopNow();     
        this.params.search = value;
        this.search.setValue(value);
        this.getAll();
    }
}
