import {Injectable}                 from "@angular/core";
import { UtilitesService }          from "../../base-module/base-service/utilites.service";
import { AuthService }              from "../../base-module/base-service/auth.service";
import { Observable }               from "rxjs";
import {HttpClient, HttpHeaders}    from "@angular/common/http";

@Injectable()
export class BoletaUnicaService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Boleta Unica por id
     * @param id ID da Boleta Unica do Cliente
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta-unica/" + id, { headers: this.authService.getAuthHeaders() });
    }

     /**
     * Boleta Unica por id
     * @param id ID da Boleta Unica do Cliente
     */
    getAtivosById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta-unica/ativos/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Boleta Unica por cliente
     * @param id ID da Boleta Unica do Cliente
     */
    getByClienteCount(cliente_id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta-unica/cliente/" + cliente_id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista Boleta Unica do Cliente
     * @param id ID da Boleta Unica do Cliente
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta-unica"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Boleta Unica do Cliente
     * @param id ID da Boleta Unica do Cliente
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "movimentacao/boleta-unica/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Boleta Unica do Cliente
     * @param params Json Boleta Unica do Cliente
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/boleta-unica", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Atualiza Boleta Unica do Cliente
     * @param params Json Boleta Unica do Cliente
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "movimentacao/boleta-unica/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
    
    /**
     * Atualiza Boleta Unica do Cliente
     * @param params Json Boleta Unica do Cliente
     */
    atualizaStatus(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/boleta-unica/atualiza-status", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
    
    /**
     * Exporta Lista em Excel de Boleta Unica
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/boleta-unica/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }
	
}
