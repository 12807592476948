import {EventEmitter, Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class RendaFixaComissionamentoService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Ação de Relacionamento por id
     * @param id ID da Ação de Relacionamento
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "comissionamento/importacao/renda-fixa-secundario/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Ação de Relacionamento por id
     * @param id ID da Ação de Relacionamento
     */
    getTotals(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){ 
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "comissionamento/importacao/renda-fixa-secundario/total"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Ação de Relacionamento
     * @param id ID da Ação de Relacionamento
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){ 
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "comissionamento/importacao/renda-fixa-secundario"+env, { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Delete de Ação de Relacionamento
     * @param id ID da Ação de Relacionamento
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "comissionamento/importacao/renda-fixa-secundario/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
     exportList(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "comissionamento/importacao/renda-fixa-secundario/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }


}
