import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-filtra-basket-ativo',
    templateUrl: './filtra-basket-ativo.component.html',
    styleUrls: ['./filtra-basket-ativo.component.scss'],
    providers: [ 
        ClienteService, 
        EscritorioService,
        AtivoService 
    ]
})

export class FiltraBasketAtivoComponent extends BaseComponent {
    
    cliente = new FormControl('');
    ativo = new FormControl('');
    assessor_id = new FormControl('');
    escritorio_id = new FormControl('');

    model:any = {
        escritorio_id: '',        
    }

    loadingSpinner: boolean;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerEscritorio:boolean = false;

    filteredOptions: Observable<string[]>;
    filteredOptionsAtivo: Observable<string[]>;
    listAssessor: any[] = [];
    listEscritorios: any[] = [];
    listAtivos: Object[] = [];
    usuario:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltraBasketAtivoComponent, { static: false }) table: FiltraBasketAtivoComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltraBasketAtivoComponent>,
        private _clienteService: ClienteService,        
        private _ativoService: AtivoService,        
        private usuarioService: UsuarioService,
        private _authService:AuthService,
        public escritorioService:EscritorioService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        ); 

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;
                    this.params.tipo = 'NORMAL';
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );
        
        this.getAllEscritorios();
        this.getAssessores();
    }

    displayFn(cliente): void {
        if (cliente) {
            return cliente.nome; 
        }
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    getCliente(cliente): void{
        this.loadingSpinner  = false;
    }

    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    filtrar(): void {
        
        this.dialogRef.close({
            cliente: this.cliente.value,
            escritorio_id: this.escritorio_id.value,
            assessor_id: this.assessor_id.value,
            ativo: this.ativo.value           
        });
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                }
            );
    }
    
    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;                
            });
    }

}