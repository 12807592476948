import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from "../base-module/base-service/auth-guard.service";
import { ListaPrevidenciaComponent } from './lista-previdencia/lista-previdencia.component';

const routes: Routes = [{
    path: '',
    children: [
        {
            path: 'boleta/consulta',
            component: ListaPrevidenciaComponent
        },        
        
    ],
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class RendaFixaRoutesModule {}
