import { 
    MatDialog, 
    MatSnackBar, 
    MatDialogRef, 
    MAT_DIALOG_DATA 
} from '@angular/material';
import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { BaseComponent, LoadingTeste} from "../../../base-module/base-component/base.component";
import { AuthService} from "../../../base-module/base-service/auth.service";
import { GeradorOrdemBoletaAtivoService } from 'app/renda-variavel/services/gerador-ordem-boleta-ativo.service';

export interface DialogData {    
    gerador_boleta_id: number;
}

@Component({
    selector: 'adiciona-ativo',
    templateUrl: './adiciona-ativo.component.html',
    styleUrls: ['./adiciona-ativo.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,   
    providers: [GeradorOrdemBoletaAtivoService]
})

export class AdicionaAtivoComponent extends BaseComponent {

    boletaForm: FormGroup;
    loadingLocal:any;
    novoCadastro:boolean = true;    
    filteredOptionsAtivo: Observable<string[]>;
    loadingSpinnerAtivos: boolean;

    constructor(public service: GeradorOrdemBoletaAtivoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<AdicionaAtivoComponent>,    
                @Inject(MAT_DIALOG_DATA) public data: DialogData,                    
                private _fb: FormBuilder )
    {
        super(dialog, snackBar);     
        this.createForm();      
        this.getAtivos();  
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }
    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });
    }

    getAtivos() {
        this.openLoading();
        this.params.gerador_id = this.data.gerador_boleta_id;
        this.service.getAllAtivos(this.params).subscribe(
            list => {                
                if(list.data.length > 0) {
                    for(var boleta of list.data ){
                        this.ativos.push(this.buildBoleta(boleta));
                    }
                    this.ativos.removeAt(0);
                }                
            },
            error => {
                this.closeLoading();
            },
            () => { 
                this.closeLoading();
            }
        );
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    salvar(){
        this.openLoading();

        this.service.create(this.boletaForm.value).subscribe(
            data => {
                this.closeLoading();
                this.showSuccess('Odens geradas com Sucesso!');
                this.dialogRef.close(data);
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {                    
                this.closeLoading();
            }
        );
        
    }

    get ativos(): FormArray {
        return <FormArray>this.boletaForm.get('ativos');
    }

    buildBoleta(  ativo = '' ): FormGroup {
        return this._fb.group({ativo: ativo});
    }

    createForm() {
        this.boletaForm = this._fb.group({        
            gerador_id: this.data.gerador_boleta_id,
            ativos: this._fb.array([this.buildBoleta()])
        });       
    }

    addAtivo(): void {
        this.ativos.push(this.buildBoleta());
    }

    removeAtivo(index) {
        if(index > 0){
            this.ativos.removeAt(index);
        }
    }

}

