import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA}          from '@angular/material';
import { FormControl, Validators}       from '@angular/forms';
import {BaseComponent, LoadingTeste}    from "../../../base-module/base-component/base.component";
import {AuthService}                    from "../../../base-module/base-service/auth.service";
import { fuseAnimations }               from '@fuse/animations';
import { PastaService }                 from '../services/pasta.service';

export interface DialogData {
    id: number
    pasta_id: number;
    pasta_pasta_id: any;
    selected: any;

}

@Component({
    selector: 'app-criar-pasta',
    templateUrl: './criar-pasta.component.html',
    styleUrls: ['./criar-pasta.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})


export class CriarPastaComponent extends BaseComponent {

    nome   = new FormControl('', [Validators.required]);

    model:any = {
        nome:"",
    };

    loadingLocal:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(CriarPastaComponent, { static: false }) table: CriarPastaComponent;

    constructor(public service: PastaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CriarPastaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);
        if(this.data.id != null && this.data.id != undefined){
            this.getById();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.nome.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.nome     = this.nome.value;
        
        if(this.data.pasta_pasta_id != null) {
            this.model.pasta_id = this.data.pasta_id;
        }

        if(this.data.selected.tipo == 'Voltar'){
            this.model.pasta_id = this.data.selected.id_pasta_atual;
        }

        if(this.data.id == null){

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                                                       
                },
                error => {                    
                    this.closeLoading();
                    this.showError(error.error.message);

                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item=>{
            this.model = item.data;
            this.nome.setValue(item.data.nome);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

}
