import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";

@Injectable()
export class CategoriaNotificacaoService {

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
    * Aporte do Cliente por id
    * @param id ID da Aporte do Cliente
    */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "notificacao/categoria/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
    * Lista de Aporte do Cliente
    * @param id ID da Aporte do Cliente
    */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "notificacao/categoria"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
    * Delete de Aporte do Cliente
    * @param id ID da Aporte do Cliente
    */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "notificacao/categoria/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
    * Cadastra Aporte do Cliente
    * @param params Json Aporte do Cliente
    */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "notificacao/categoria", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }


    /**
    * Atualiza Aporte do Cliente
    * @param params Json Aporte do Cliente
    */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "notificacao/categoria/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }    

}
