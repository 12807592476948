import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { MatSnackBar} from '@angular/material';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../services/dashboard.service';
import { DashboardRendaVariavelService } from '../services/dasboard-renda-variavel.service';

@Component({
    selector: 'app-dashboard-operacional',
    templateUrl: './dashboard-operacional.component.html',
    styleUrls: ['./dashboard-operacional.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
    providers: [ DashboardService, DashboardRendaVariavelService ]
})

export class DashboardOperacionalComponent extends BaseComponent
{ 

    user:any;
    dateNow = Date.now();
    params: any = {};
    paramsCustodia: any = {};

    qtd_cliente:any;
    qtd_cliente_home:any;
    qtd_socios:any;
    vlr_total_invest:any;
    vlt_total_dispo:any;
    vlr_boletas:any;
    total_clientes:any = 0;
    clientes_rv:any = 0;
    graficosEscritorio:boolean = false;

    vlr_boletasSW:any = 0;
    vlr_boletasFI:any = 0;
    vlr_boletasFO:any = 0;
    vlr_projecao_mrc:any = 0;
    vlr_projecao_fee_mrc:any = 0;

    vlr_investimentoSW:any = 0;
    vlr_investimentoFI:any = 0;
    vlr_investimentoFO:any = 0;

    totalCustodia:any = 0;
    totalCustodiaFop:any = 0;
    totalCustodiaFiis:any = 0;
    totalCustodiaST:any = 0;
    totalIncosistencias:any = 0;    
    qtd_cliente_pendente:any = 0;
    
    vlr_premio:any = 0;
    vlr_exercicio:any = 0;

    resultado:any;
    resultado_2:any;
    resultado_3:any;
    resultado_4:any;
    resultado_5:any;
    resultado_3_total:number = 0;
    resultado_4_total:number = 0;
    resultado_5_total:number = 0;
    exibeGrafico_3:boolean = false;
    exibeGrafico_4:boolean = false;
    exibeGrafico_5:boolean = false;
    efetivacao:number = 0;
    
    widget5: any = {};
    widget6: any = {};
    widget7: any = {};
    widget8: any = {};
    widget9: any = {};
    tituloLabelRv:any;

    widgets = {  
        'widget5' : {
            'title'     : 'Valor Investido em MRV',
            'ranges'    : {
                'hoje': 'Hoje',                
                'semana': 'Semana',                
                'mes': 'Mês',                
                'tres_meses': 'Trimestre',                
                'seis_meses': 'Semestre',                
                'ano': 'Anual',                
                'total': 'Total',                
            },
            'mainChart' : {
                'TW': [ ]
            }            
        },
        'widget6' : {
            'title'     : 'Valor Investido em MRV',
            'ranges'    : {
                'hoje': 'Hoje',                
                'semana': 'Semana',                
                'mes': 'Mês',                
                'tres_meses': 'Trimestre',                
                'seis_meses': 'Semestre',                
                'ano': 'Anual',                
                'total': 'Total',
            },
            'mainChart' : {
                'TW': [ ]
            }            
        },
        'widget7' : {            
            'ranges'    : {
                'hoje': 'Hoje',                
                'semana': 'Semana',                
                'mes': 'Mês',                
                'tres_meses': 'Trimestre',                
                'seis_meses': 'Semestre',                
                'ano': 'Anual',                
                'total': 'Total',
            }            
        },
        'widget8' : {            
            'ranges'    : {
                'hoje': 'Hoje',                
                'semana': 'Semana',                
                'mes': 'Mês',                
                'tres_meses': 'Trimestre',                
                'seis_meses': 'Semestre',                
                'ano': 'Anual',                
                'total': 'Total',
            }            
        },
        'widget9' : {            
            'ranges'    : {
                'total': 'Todos Clientes',
                'homebroker': 'Clientes Home Brokers',
                'mrv': 'Clientes MRV',
            }            
        }
    }   
   

    /**
     * Constructor
     *
     */
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,        
        private _authService:AuthService,
        private _service:DashboardService,
        public router: Router,        
        private _serviceRV: DashboardRendaVariavelService,
    )
    {
        super(dialog, snackBar);
        
        this.user = JSON.parse(this._authService.getUser());
        
        this.qtd_socios         = 0;
        this.qtd_cliente        = 0;
        this.vlr_total_invest   = 0;
        this.vlr_boletas        = 0;

        if ( this.user != undefined &&  this.user != '' && this.user.escritorios.length > 1){
            this.graficosEscritorio = true;
        }

        if (this.user.visao_assessor_id != null && this.user.visao_assessor_id != undefined){
            this.params['user_id'] = this.user.visao_assessor_id;
            this.paramsCustodia['user_id'] = this.user.visao_assessor_id;
        }

        if (this.user.visao_escritorio_id != null && this.user.visao_escritorio_id != undefined){
            this.params['escritorio_id'] = this.user.visao_escritorio_id;
            this.paramsCustodia['escritorio_id'] = this.user.visao_escritorio_id;
        }
        
        this.getIndicadores();
        this.getIncosistencias();       
        this.getCorretagemClientes();
        this.getFeeClientes();


        if(this.user.role_id == 2 || this.user.role_id == 4 || this.user.role_id == 7){
            this.tituloLabelRv = 'Renda Variável Comercial';
        }else{
            this.tituloLabelRv = 'Renda Variável';
        }


        /**
        * Widget 5 (Valor Investido em MRV)
        */
        this.widget5 = {
            currentRange  : 'total',
            xAxis         : true,
            yAxis         : true,
            gradient      : false,
            legend        : false,
            showXAxisLabel: false,
            xAxisLabel    : 'Months',
            showYAxisLabel: false,
            yAxisLabel    : 'Isues',
            scheme        : {
                domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
            },
            onSelect      : (ev) => {
                console.log(ev);
                this.graficoTop10Assessores(ev.name);
            }
        };

        /**
        * Widget 6 (Top 10 Volume (R$) Assessores)
        */
        this.widget6 = {
            currentRange  : 'total',
            xAxis         : true,
            yAxis         : true,
            gradient      : false,
            legend        : false,
            showXAxisLabel: false,
            xAxisLabel    : 'Months',
            showYAxisLabel: false,
            yAxisLabel    : 'Isues',
            scheme        : {
                domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
            },
            onSelect      : (ev) => {
                console.log(ev);
            }
        };

        /**
        * Widget 7 (Total de Clientes)
        */
        this.widget7 = {
            currentRange : 'total',
            legend       : false,
            explodeSlices: false,
            labels       : true,
            doughnut     : true,
            gradient     : false,
            scheme       : {
                domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
            },
            onSelect     : (ev) => {
                console.log(ev);
            }
        };

        /**
        * Widget 8 (Qtd. CALLS por escritório)
        */
       this.widget8 = {
            currentRange : 'total',
            legend       : false,
            explodeSlices: false,
            labels       : true,
            doughnut     : true,
            gradient     : false,
            scheme       : {
            domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
            },
            onSelect     : (ev) => {
                console.log(ev);
            }
        };

        /**
        * Widget 9 (Custódia Cliente)
        */

       this.widget9 = {
            currentRange : 'mrv',
            legend       : false,
            explodeSlices: false,
            labels       : true,
            doughnut     : true,
            gradient     : false,
            scheme       : {
            domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
            },
            onSelect     : (ev) => {
                this.dialoCustodia(ev);
                console.log(ev);
            }
        };

        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);
    }

    ngOnInit(){

        if ( this.user.role_id == 7 || this.user.role_id == 4 || this.user.role_id == 1 || this.user.role_id == 2 || this.graficosEscritorio )
        {
            this.params['periodo'] = 'total';
            this.getGraficoEscritorio();
            this.clientesNovos();
            this.callsClientesEscritorio();
            this.getGraficoCustodia();
        }  
        
        if (this.user.role_id != 5) {
            this.graficoTop10Assessores();            
        }

        this.getCustodia();

    }

    filtraGraficoMrv(periodo){
        
        this.widget5.currentRange = periodo;
        this.params['periodo'] = periodo;
        this.getGraficoEscritorio();
    }

    filtraGraficoAssessor(periodo){
        this.widget6.currentRange = periodo;
        this.params['periodo'] = periodo;
        this.graficoTop10Assessores();
    }

    filtraTotalClientes(){
        this.params['periodo'] = this.widget7.currentRange;
        this.clientesNovos();
    }

    filtraTotalClientesCalls(){
        this.params['periodo'] = this.widget8.currentRange;
        this.callsClientesEscritorio();
    }

    filtraCustodiaClientes(){
        this.params['periodo'] = this.widget9.currentRange;
        this.getGraficoCustodia();
    }


    /**
     * Consulta Indicadores
     */
    getIndicadores() {

        this.loadingIndicator = true;
        this._service.getIndicadores(this.params).subscribe(
            data => {               
               this.total_clientes      = data.data.total_clientes;
               this.clientes_rv         = data.data.clientes_rv;
               this.qtd_cliente         = data.data.qtd_clientes;
               this.qtd_cliente_home    = data.data.qtd_clientes_home;
               this.qtd_socios          = data.data.qtd_assessores;
               this.vlr_total_invest    = data.data.vlr_total_mrv;
               this.vlr_boletas         = data.data.vlr_boletas;
               this.vlt_total_dispo     = data.data.vlt_total_dispo;
               this.qtd_cliente_pendente = data.data.qtd_cliente_pendente;               
               this.vlr_boletasFO       = data.data.vlr_boletasRV;        

               this.vlr_premio          = data.data.vlr_premio;
               this.vlr_exercicio       = data.data.vlr_exercicio;              
               
            },
            error => {                
                this.loadingIndicator = false;                
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Valor Investido em MRV
     */
    getGraficoEscritorio() {

        this.loadingIndicator = true;
        this._service.getGraficoEscritorio(this.params).subscribe(
            data => {               
               this.resultado = data.widget5;
            },
            error => {
                this.loadingIndicator = false;                
            },
            () => {                
                this.loadingIndicator = false;

            }
        );
    }

    /**
     * Top 10 Volume (R$) Assessores
     */
    graficoTop10Assessores(escritorio = null) {

        this.loadingIndicator = true;
        
        if(escritorio != null){
            this.params.escritorio_nome = escritorio;
        }
        
        this._service.graficoTop10Assessores(this.params).subscribe(
            data => {               
               this.resultado_2 = data.widget6;
            },
            error => {                
                this.loadingIndicator = false;                
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Consulta Indicadores
     */
    clientesNovos() {

        this.loadingIndicator = true;
        this._service.totalClienteNovo(this.params).subscribe(
            data => {
               this.resultado_3 = data.widget7;
               this.resultado_3_total = data.total;
               this.exibeGrafico_3 = true;
            },
            error => {                
                this.loadingIndicator = false;               
            },
            () => {                
                this.loadingIndicator = false;

            }
        );
    }

    /**
     * Consulta Indicadores
     */
    callsClientesEscritorio() {

        this.loadingIndicator = true;
        this._service.totalCallsClienteEscritorio(this.params).subscribe(
            data => {
               this.resultado_4 = data.widget8;
               this.resultado_4_total = data.total;
               this.exibeGrafico_4 = true;
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;                
            },
            () => {                
                this.loadingIndicator = false;

            }
        );
    }

    getGraficoCustodia(){

        this.params['periodo'] = this.widget9.currentRange;

        if(this.params['periodo'] == 'homebroker'){
            this.params['homebroker'] = true;
        }else if(this.params['periodo'] == 'mrv'){
            this.params['homebroker'] = false;
        }else{
            this.params['homebroker'] = '';
        }

        this._service.getCustodiaAtivos(this.params).subscribe(
            data => {

                if(this.params['periodo'] == 'mrv'){
                    this.totalCustodia      = data.total;                
                }

                this.resultado_5         = data.data;
                this.resultado_5_total   = data.total;
                this.exibeGrafico_5      = true;
            },
            error => {},
            () => { }
        );
    }

    getCustodia(){
        this.paramsCustodia['homebroker'] = false;
        this._service.getCustodiaAtivos(this.paramsCustodia).subscribe(
            data => {
                this.totalCustodia = data.total;                
            },
            error => {},
            () => { }
        );
    }

    getIncosistencias(){
        this._service.getIncosistencias(this.paramsCustodia).subscribe(
            data => {
                this.totalIncosistencias = parseFloat(data.data.clientes_negativos) + parseFloat(data.data.clientes_opcao);
            },
            error => {},
            () => { }
        );
    }

    dialoCustodia(data){
        
        this.dialog.open(AlertDialog, {
            width: '400px',
            disableClose: true,
            data: {
                title: data.name,
                percentual: "Percentual: "+data.value+" %",
                valor: "R$ "+data.extra.vlr_fin
            }
        });
    }

    redirectListaDisponivel(){
        this.router.navigate(['/total-disponivel']);
    }

    redirectListaNegativas(){
        this.router.navigate(['/clientes/saldo']);
    }

    redirectListaIncosistecias(){
        this.router.navigate(['/renda-variavel/lista-incosistencias']);
    }

    getCorretagemClientes(){

        this._serviceRV.getCorretagemClientes(this.params).subscribe(
            data => {               
               this.vlr_projecao_mrc = data.data.corretagem_bruta;
            },
            error => { },
            () => { }
        );
    }

    getFeeClientes(){

        this._serviceRV.getFeeClientes(this.params).subscribe(
            data => {               
               this.vlr_projecao_fee_mrc = data.data.fee_bruto;
            },
            error => { },
            () => { }
        );
    }
}


// MODAL DE CUSTODIA
@Component({
    selector: 'alert-dialog',
    template: `<h2 mat-dialog-title style="text-align: center;"><b>{{data.title}}</b></h2>
            <div mat-dialog-content style="text-align: center;">
              <p>{{data.percentual}}</p>
              <p>{{data.valor}}</p>
            </div>
            <div mat-dialog-actions>
              <button style="float:right" mat-button (click)="onNoClick()" tabindex="-1">OK</button>
            </div>`
})
export class AlertDialog {

    constructor(
        public dialogRef: MatDialogRef<AlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}