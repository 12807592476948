import {EventEmitter, Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class SituacaoTributariaService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Situação tributária por id
     * @param id ID da Situação tributária
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "situacao-tributaria/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Situação tributária
     * @param id ID da Situação tributária
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "situacao-tributaria"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Situação Tributária de cadastro do cliente
     */
    getLista(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "situacao-tributaria/lista", { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Situação tributária
     * @param id ID da Situação tributária
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "situacao-tributaria/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Situação tributária
     * @param params Json Situação tributária
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "situacao-tributaria", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Situação tributária
     * @param params Json Situação tributária
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "situacao-tributaria/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

}
