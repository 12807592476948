import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';

import { RegionalService } from 'app/empreendimento/services/regional.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { DashboardService } from '../services/dashboard.service';
import { DashboardRendaVariavelService } from 'app/renda-variavel/services/dasboard-renda-variavel.service';

@Component({
  selector: 'app-dash-rv-comercial',
  templateUrl: './dash-rv-comercial.component.html',
  styleUrls: ['./dash-rv-comercial.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [       
      DashboardRendaVariavelService, 
      DashboardService,
      EscritorioService,
      RegionalService
    ]
})

export class DashRvComercialComponent extends BaseComponent {

    user:any;
    qtd_cliente:any;
    qtd_cliente_home:any;
    qtd_socios:any;
    vlr_total_invest:any;
    vlt_total_dispo:any = 0;
    vlr_projecao_mrc:any = 0;
    vlr_projecao_fee_mrc:any = 0;
    vlr_boletas:any;
    total_clientes:any = 0;
    total_boletado:any = 0;
    clientes_rv:any = 0;
    totalCustodia:any = 0;
    totalIncosistencias:any = 0;
    totalContasNegativas:any = 0;
    qtd_cliente_pendente:any = 0;
    vlr_boletasSW:any = 0;
    vlr_boletasFI:any = 0;
    vlr_boletasFO:any = 0;

    vlr_investimentoSW:any = 0;
    vlr_investimentoFI:any = 0;
    vlr_investimentoFO:any = 0;
    efetivacao:number = 0;

    loadingIndicator:boolean = false;
    loadingSpinnerRegional:boolean;
    loadingSpinnerEscritorio:boolean;
    loadingSpinnerAssessor:boolean;
    loadingIndicatorCliente:boolean = false;
    listRegional:any[]=[];
    listEscritorio:any[]=[];
    listAssessor:any[]=[];
    clienteTotal:any;
    clienteNovos:any = {};
    clienteInativos:any = {};
    vlr_mrv:any = {};
    vlr_custodia:any = {};
    vlr_novo:any = {};
    vlr_total_boletado:any = {};
    vlr_total_executado:any = {};
    vlr_comissao:any = {};
    vlr_total_disponivel:any = {};

    regional_id =  new FormControl('');
    escritorio_id = new FormControl('');
    assessor_id = new FormControl('');

    model:any = {        
        regional_id: "",
        escritorio_id: "",
        assessor_id: "",
    }

    public  colors = [
        {
            borderColor    : '#42a5f5',
            backgroundColor: '#42a5f5'
        }
    ];

    public widgets = {       
        widget3: {
            conversion: {
                value   : 20,
                ofTarget: 2
            },
            chartType : 'bar',
            datasets  : [
                {
                    label: 'Clientes',
                    data : [221, 428, 492, 471, 413]
                }
            ],
            labels    : ['Novembro 2019', 'Dezembro 2019', 'Janeiro 2020', 'Fevereiro 2020', 'Março 2020'],
            colors    : [
                {
                    borderColor    : '#42a5f5',
                    backgroundColor: '#42a5f5'
                }
            ],
            options   : {
                spanGaps : false,
                legend : {
                    display: false
                },
                maintainAspectRatio: false,
                layout : {
                    padding: {
                        top   : 24,
                        left  : 16,
                        right : 16,
                        bottom: 16
                    }
                },
                scales : {
                    xAxes: [
                        {
                            display: false
                        }
                    ],
                    yAxes: [
                        {
                            display: false,
                            ticks  : {
                                min: 100,
                                max: 500
                            }
                        }
                    ]
                }
            }
        },
        widget4: {
            conversion: {
                value   : 10,
                ofTarget: 2
            },
            chartType : 'bar',
            datasets  : [
                {
                    label: 'Clientes',
                    data : [30, 20, 45, 100, 89, 96]
                }
            ],
            labels    : ['Novembro 2019', 'Dezembro 2019', 'Janeiro 2020', 'Fevereiro 2020', 'Março 2020', 'Abril 2020'],
            colors    : [
                {
                    borderColor    : '#42a5f5',
                    backgroundColor: '#42a5f5'
                }
            ],
            options   : {
                spanGaps : false,
                legend : {
                    display: false
                },
                maintainAspectRatio: false,
                layout : {
                    padding: {
                        top   : 24,
                        left  : 16,
                        right : 16,
                        bottom: 16
                    }
                },
                scales : {
                    xAxes: [
                        {
                            display: false
                        }
                    ],
                    yAxes: [
                        {
                            display: false,
                            ticks  : {
                                min: 100,
                                max: 500
                            }
                        }
                    ]
                }
            }
        }
    };

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _service: DashboardService,
                private _serviceRV: DashboardRendaVariavelService,
                private _authService: AuthService,
                private router: Router,
                private _regionalService: RegionalService,
                private _escritorioService: EscritorioService) 
    { 
        super(dialog, snackBar);        
        this.user = JSON.parse(this._authService.getUser());
        
        this.init();
        this.getAllRegional();
        this.getAllEscritorios();
    }

    init(){
        this.getIndicadoresCliente();
        this.getIndicadores();
        this.getCustodia();    
        this.getCorretagemClientes();
        this.getFeeClientes();
        this.getClientesNegativos(); 
    }

    getIndicadores() {

        this.loadingIndicator = true;
        this._service.getIndicadores(this.params).subscribe(
            data => {               
               this.total_clientes      = data.data.total_clientes;
               this.clientes_rv         = data.data.clientes_rv;
               this.qtd_cliente         = data.data.qtd_clientes;
               this.qtd_cliente_home    = data.data.qtd_clientes_home;
               this.qtd_socios          = data.data.qtd_assessores;
               
               this.vlr_boletas         = data.data.vlr_boletas;
               this.vlt_total_dispo     = data.data.vlt_total_dispo;
               this.qtd_cliente_pendente = data.data.qtd_cliente_pendente;

               this.total_boletado = parseFloat(data.data.vlr_boletasRV) + parseFloat(data.data.vlr_boletas); 
               this.vlr_boletasFO = parseFloat(data.data.vlr_boletasRV); 

               this.vlr_total_invest  = parseFloat(data.data.vlr_total_mrv) + parseFloat(data.data.vlr_boletasRV);
               
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    getIndicadoresCliente(){
        this.loadingIndicatorCliente = true;
        this._serviceRV.getIndicadoresClientes(this.params).subscribe(
            data => {

                this.clienteTotal  = {
                    conversion: {
                        value   : data.data.clientesTotal,
                        ofTarget: 13
                    },
                    chartType : 'bar',
                    datasets  : [
                        {
                            label: 'Clientes',
                            data : data.data.totalClientes
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.totalClientes.reduce((a, b)=>Math.min(a, b)) - data.data.totalClientes.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.totalClientes.reduce((a, b)=>Math.max(a, b)) + data.data.totalClientes.reduce((a, b)=>Math.max(a, b))*0.10)
                                    }
                                }
                            ]
                        }
                    }                    
                }                
                
                this.clienteNovos  = {
                    conversion: {
                        value   : data.data.clientesNovosHoje,
                        ofTarget: 13
                    },
                    chartType : 'bar',
                    datasets  : [
                        {
                            label: 'Clientes',
                            data : data.data.clientesNovos
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: 1,
                                        max: data.data.clientesNovos.reduce((a, b)=>Math.max(a, b)),
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.clienteInativos  = {
                    conversion: {
                        value   : data.data.clientesInativosHoje,
                        ofTarget: 13
                    },
                    chartType : 'bar',
                    datasets  : [
                        {
                            label: 'Clientes',
                            data : data.data.clientesInativos
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: 1,
                                        max: data.data.clientesInativos.reduce((a, b)=>Math.max(a, b)),
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.vlr_mrv  = {
                    chartType : 'bar',
                    datasets  : [
                        {                            
                            data : data.data.vlr_mrv
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },  
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.vlr_mrv.reduce((a, b)=>Math.min(a, b)) - data.data.vlr_mrv.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.vlr_mrv.reduce((a, b)=>Math.max(a, b)) + data.data.vlr_mrv.reduce((a, b)=>Math.max(a, b))*0.10)
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.vlr_custodia  = {
                    chartType : 'bar',
                    datasets  : [
                        {                            
                            data : data.data.vlr_custodia
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },  
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.vlr_custodia.reduce((a, b)=>Math.min(a, b)) - data.data.vlr_custodia.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.vlr_custodia.reduce((a, b)=>Math.max(a, b)) + data.data.vlr_custodia.reduce((a, b)=>Math.max(a, b))*0.10)
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.vlr_novo  = {
                    conversion: {
                        value   : data.data.valor_novo_hoje,
                        ofTarget: 13
                    },
                    chartType : 'bar',
                    datasets  : [
                        {                            
                            data : data.data.vlr_novo
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },  
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.vlr_novo.reduce((a, b)=>Math.min(a, b)) - data.data.vlr_novo.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.vlr_novo.reduce((a, b)=>Math.max(a, b)) + data.data.vlr_novo.reduce((a, b)=>Math.max(a, b))*0.10)
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.vlr_total_boletado  = {
                    chartType : 'bar',
                    datasets  : [
                        {                            
                            data : data.data.vlr_total_boletado
                        }
                    ],
                    labels: data.data.mes,                                  
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },    
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false,                                    
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.vlr_total_boletado.reduce((a, b)=>Math.min(a, b)) - data.data.vlr_total_boletado.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.vlr_total_boletado.reduce((a, b)=>Math.max(a, b)) + data.data.vlr_total_boletado.reduce((a, b)=>Math.max(a, b))*0.10),
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.vlr_total_executado  = {
                    chartType : 'bar',
                    datasets  : [
                        {                            
                            data : data.data.vlr_total_executado
                        }
                    ],
                    labels: data.data.mes,                                  
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },    
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false,                                    
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.vlr_total_executado.reduce((a, b)=>Math.min(a, b)) - data.data.vlr_total_executado.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.vlr_total_executado.reduce((a, b)=>Math.max(a, b)) + data.data.vlr_total_executado.reduce((a, b)=>Math.max(a, b))*0.10),
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.vlr_total_disponivel  = {
                    chartType : 'bar',
                    datasets  : [
                        {                            
                            data : data.data.vlr_total_disponivel
                        }
                    ],
                    labels: data.data.mes,                                  
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },    
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false,                                    
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.vlr_total_disponivel.reduce((a, b)=>Math.min(a, b)) - data.data.vlr_total_disponivel.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.vlr_total_disponivel.reduce((a, b)=>Math.max(a, b)) + data.data.vlr_total_disponivel.reduce((a, b)=>Math.max(a, b))*0.10),
                                    }
                                }
                            ]
                        }
                    } 
                }

                this.vlr_comissao  = {
                    chartType : 'bar',
                    datasets  : [
                        {                            
                            data : data.data.vlr_comissao
                        }
                    ],
                    labels: data.data.mes,                                  
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },    
                        spanGaps : false,
                        legend : {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout : {
                            padding: {
                                top   : 24,
                                left  : 16,
                                right : 16,
                                bottom: 16
                            }
                        },
                        scales : {
                            xAxes: [
                                {
                                    display: false,                                    
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks  : {
                                        min: (data.data.vlr_comissao.reduce((a, b)=>Math.min(a, b)) - data.data.vlr_comissao.reduce((a, b)=>Math.min(a, b))*0.50),
                                        max: (data.data.vlr_comissao.reduce((a, b)=>Math.max(a, b)) + data.data.vlr_comissao.reduce((a, b)=>Math.max(a, b))*0.10),
                                    }
                                }
                            ]
                        }
                    } 
                }
            },
            error => {                
                this.loadingIndicatorCliente = false;                
            },
            () => {
                this.loadingIndicatorCliente = false;
            }
        );
    }

    getCorretagemClientes(){

        this._serviceRV.getCorretagemClientes(this.params).subscribe(
            data => {               
               this.vlr_projecao_mrc = data.data.corretagem_liquida;
            },
            error => { },
            () => { }
        );
    }

    getFeeClientes(){

        this._serviceRV.getFeeClientes(this.params).subscribe(
            data => {               
               this.vlr_projecao_fee_mrc = data.data.fee_liquida;
            },
            error => { },
            () => { }
        );
    }

    getClientesNegativos(){
        this._service.getClientesNegativos(this.params).subscribe(
            data => {
                this.totalContasNegativas = data.total;
            },
            error => {},
            () => { }
        );
    }

    getCustodia(){
        this.params.migrado = true;
        this._service.getCustodiaAtivos(this.params).subscribe(
            data => {
                this.totalCustodia = data.total;                
            },
            error => {},
            () => {}
        );
    }

    getAllRegional(){
        this.loadingSpinnerRegional = true;
        this._regionalService.getAllList({}).subscribe(
            data => {                
                const add = {
                    id: '',
                    nome: 'TODAS'
                };
                this.listRegional = data.data;
                this.listRegional.push(add);
            },
            error => {               
                this.loadingSpinnerRegional = false;
            },
            () => {
                this.loadingSpinnerRegional = false;
                this.regional_id.setValue('');
            }
        );
    }

    getAllEscritorios(){
        this.loadingSpinnerEscritorio = true;
        this._escritorioService.getAll({ no_paginate: true}).subscribe(
            data => {                
                const add = {
                    id: '',
                    nome_fantasia: 'TODOS'
                };
                this.listEscritorio = data.data;
                this.listEscritorio.push(add);
            },
            error => {               
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                this.escritorio_id.setValue('');
                this.assessor_id.setValue('');
            }
        );
    }

    selectEscritorio(event){
        this.params.escritorio_id = event.value;
        this.init();
    }

    selectRegional(event){
        this.params.regional_id = event.value;
        this.init();
    }

    selectAssessor(event){
        this.params.assessor_id = event.value;
        this.init();
    }

    redirectListaDisponivel(){
        this.router.navigate(['/total-disponivel']);
    }

    redirectListaNegativas(){
        this.router.navigate(['/clientes/saldo']);
    }
    
    onChartClick(e: any, tipo){
        if (e.active.length > 0) 
        {
            const chart = e.active[0]._chart;
            const activePoints = chart.getElementAtEvent(e.event);
            if ( activePoints.length > 0) 
            {
                // get the internal index of slice in pie chart
                const clickedElementIndex = activePoints[0]._index;
                const label = chart.data.labels[clickedElementIndex];
                // get value by index
                const value = chart.data.datasets[0].data[clickedElementIndex];
            }
        }
    }

    openModal(tipo){
        this.download(tipo);
    }    

    /**
     * 
     * @param urlExportacao 
     */
    getExport(urlExportacao) {

        this.openLoading();
        this._serviceRV.getAllExport(this.params, urlExportacao).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },
            error => {
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    /**
     * Download de Arquivo em Excel
     * @param tipo 
     */
    download(tipo){
        
        if(tipo == 'CLIENTE_INATIVO'){
            this.getExport('dashboard-renda-variavel/exportClientesInativos');
        }else if(tipo == 'NOVOS_CLIENTES'){
            this.getExport('dashboard-renda-variavel/exportClientesNovos');
        }else if(tipo == 'TOTAL_CLIENTES'){
            this.getExport('dashboard-renda-variavel/exportClientesTotais');
        }else if(tipo == 'TOTAL_DISPONIVEL'){
            this.getExport('dashboard-renda-variavel/exportClienteVlrDisponivel');
        }else if(tipo == 'CUSTODIA_HOJE'){
            this.getExport('dashboard-renda-variavel/exportClienteCustodia');
        }else if(tipo == 'NOVO_RV'){
            this.getExport('dashboard-renda-variavel/exportClienteIncremento');
        }else if(tipo == 'PENDENCIAS'){
            this.getExport('dashboard-renda-variavel/exportClientePendencias');
        }else if(tipo == 'PROJECAO'){
            this.getExport('renda-variavel/movimentacao/export-commission');
        }
    }

    isEmptyObject(obj) {
        var name;
        for (name in obj) {
          return false;
        }
        return true;
    }

}
