import {
    Component,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {
    MatDialog,
    MatPaginator,
    MatSnackBar,
    PageEvent
} from '@angular/material';

import { FormControl } from "@angular/forms";
import { fuseAnimations } from '@fuse/animations';
import { SelectionType } from '@swimlane/ngx-datatable';

import {
    ActionDialog,
    BaseComponent
} from "../../base-module/base-component/base.component";

import { AuthService } from 'app/base-module/base-service/auth.service';
import { CadastroAtivoComponent } from '../cadastro-ativo/cadastro-ativo.component';
import { CadastroCustodiaComponent } from '../cadastro-custodia/cadastro-custodia.component';
import { FiltrarCustodiaComponent } from '../filtrar-custodia/filtrar-custodia.component';
import { CustodiaService } from '../services/custodia.service';

@Component({
    selector: 'app-lista-historico-custodia',
    templateUrl: './lista-historico-custodia.html',
    styleUrls: ['./lista-historico-custodia.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class ListaHistoricoCustodiaComponent extends BaseComponent{

    usuario:any;
    loadingIndicator: boolean;
    loadingListaAtivo: boolean;
    public search = new FormControl();
    cotacaoAtual:number;
   
    widget8: any = {};
    resultado:any;

    SelectionType = SelectionType;
    
    selected = [];
    user:any;

    params:any = {
        per_page:""
    }

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaHistoricoCustodiaComponent, { static: false }) table: ListaHistoricoCustodiaComponent;    
    @ViewChild(ListaHistoricoCustodiaComponent, { static: false }) tableAtivo: ListaHistoricoCustodiaComponent;
    @ViewChild('paginator',{ static: false }) paginator: MatPaginator;

    constructor(public service: CustodiaService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,
                private _authService: AuthService ) {
        super(dialog, snackBar);

        this.search.setValue('');
        this.getAll();

        if(this.params.per_page == undefined || this.params.per_page == "" || this.params.per_page == null){
            this.params.per_page = 100;
        }

        var data = [];
        this.rows = data;
        this.temp = [...data];

        this.user = JSON.parse(this._authService.getUser());
    }

    getPrecoExecutado(row){
        if(row.preco_executado == '' || parseFloat(row.preco_executado) == 0 ){
            return parseFloat(row.vlr_financeiro);
        }else{
            return parseFloat(row.vlr_executado);
        }        
    }   

    getCotacaoAtual(row){
        return parseFloat(row.qtd_executado) * parseFloat(row.ativo.cotacao_atual);
    }

    getPercentual(row){
        var executado = parseFloat(row.preco_executado) * parseFloat(row.qtd_executado);
        var atual = parseFloat(row.qtd_executado) * parseFloat(row.ativo.cotacao_atual);

        return ((atual/executado) - 1) * 100;
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

     /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        
        if(event.column.prop == "percentual"){
            this.params.sortColumn = "preco";
        }

        this.params.sortOrder = event.newValue;
        this.getAll();
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {
        if(this.search.value != null && this.search.value != '' && this.search.value != undefined){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                this.showError(error.error.message);
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroCustodiaComponent, {
            width: '400px',            
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){                
                this.getAll();
            }
        });
    }

     /**
     * Modal de edição
     */
    alterarCotacao(ativo_id): void {
        let dialogRef = this.dialog.open(CadastroAtivoComponent, {
            width: '400px',
            data: { id: ativo_id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Cotação Atual do Ativo alterado!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar alterar o valor da cotação atual do ativo");
            }
        });
    }

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Custódia",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarCustodiaComponent, {
            width: '700px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_fim = result.data_operacao_fim;
                }
               
                if (result.ativo != undefined && result.ativo != null) {
                    this.params.ativo_id = result.ativo.id;
                }

                if (result.importacao_id != undefined && result.importacao_id != null) {
                    this.params.importacao_id = result.importacao_id;
                }                
                
                this.getAll();

                this.paginator.firstPage();
            }
        });
    }

    /**
     * Consulta lista
     */
    getAllExport(): void {

        this.openLoading();
        this.service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getTipoAtivo(row){
        const tipoAtivo = row.tipo_ativo.toString();
        if(tipoAtivo != null && tipoAtivo != undefined){
            if(tipoAtivo.trim() == 'F'){
                return 'Opção Flexível'            
            }else if(tipoAtivo.trim() == 'D'){
                return 'Dividendos';
            }else if(tipoAtivo.trim() == 'I'){
                return 'Juros sobre capital';
            }else if(tipoAtivo.trim() == 'OPCAO'){
                return 'Opção';
            }else{
                return 'Bolsa';
            }
        }else{
            return 'Bolsa';
        }
    }

    getOperacao(row){
        if(row.tipo.trim() == 'C'){
            return 'Compra';
        }

        if(row.tipo.trim() == 'E'){
            return 'Exercício'
        }

        if(row.tipo.trim() == 'D'){
            return 'Crédito';
        }

        return 'Venda'
    }
    
}
