import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { Subject } from 'rxjs';
import { Noticia, NoticiaService } from '../services/noticia.service';

@Component({
    selector: 'app-lista-noticia',
    templateUrl: './lista-noticia.component.html',
    styleUrls: ['./lista-noticia.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ListaNoticiaComponent implements OnInit, OnDestroy, AfterViewInit
{
    
    loadingIndicator: boolean;
    noticia_id: number;
    noticia: Noticia;
    animationDirection: 'left' | 'right' | 'none';

    @ViewChildren(FusePerfectScrollbarDirective)
    fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _service: NoticiaService,
        private _activatedRoute: ActivatedRoute
    )
    {
        this.noticia_id = this._activatedRoute.snapshot.params['id'];
        this.getById();
        // Set the defaults
        this.animationDirection = 'none';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    /**
      * Consulta Noticia pelo ID
      */
    getById() {
        this.loadingIndicator = true;
        this._service.getById(this.noticia_id).subscribe(
            data => {
                this.noticia = data.data;
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    getUrlMidia() {
        return this._service.getUrlMidia(this.noticia.id);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

    }

    /**
     * After view init
     */
    ngAfterViewInit(): void
    {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    
}
