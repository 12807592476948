import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {BaseComponent, ActionDialog} from '../../base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { TipoPatrimonioService } from '../services/tipo-patrimonio.service';
import { CadastroTipoPatrimonioComponent } from '../cadastro-tipo-patrimonio/cadastro-tipo-patrimonio.component';

@Component({
    selector: 'app-lista-tipo-patrimonio',
    templateUrl: './lista-tipo-patrimonio.component.html',
    styleUrls: ['./lista-tipo-patrimonio.component.scss']
})

export class ListaTipoPatrimonioComponent extends BaseComponent{

    params: any = {};
    loadingIndicator: boolean;
    user:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaTipoPatrimonioComponent, { static: false }) table: ListaTipoPatrimonioComponent;

    constructor(public _service: TipoPatrimonioService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.getAll();
        this.user = JSON.parse(this._authService.getUser());

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex + 1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de Tipo de Patrimonio
     */
    getAll(): void {

        this.loadingIndicator = true;
        this._service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];

            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui Tipo de Patrimonio
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            data: {
                title: "Excluir Tipo de Patrimonio",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();
                    
                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.closeLoading();
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {

                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroTipoPatrimonioComponent, {
            width: '400px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Tipo de Patrimonio cadastrado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo registro.");
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastroTipoPatrimonioComponent, {
            width: '400px',
            data: { 
                id: id 
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Tipo de Patrimonio atualizado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

}