import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { FileManagerService } from 'app/apps/file-manager/file-manager.service';
import { MatDialog } from "@angular/material";


export interface PeriodicElement {
    created: string;
    extention: string;
    id: number;
    location: string;
    modified: string;
    name: string;
    owner: string;
    size: string;
    tipo: string;
    type: string;
    pasta_id: number;
    id_pasta_atual: number;
}

@Component({
    selector     : 'file-list',
    templateUrl  : './file-list.component.html',
    styleUrls    : ['./file-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})



export class FileManagerFileListComponent implements OnInit, OnDestroy
{
    files: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['icon', 'name', 'type', 'owner', 'size', 'modified', 'detail-button'];
    selected: any;
    params: any = {
        tipo:''
    };
    loadingTest:any;
   

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FileManagerService} _fileManagerService
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _fileManagerService: FileManagerService,
        private _fuseSidebarService: FuseSidebarService,
        public dialogLoading: MatDialog,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();        
    }    


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this._fileManagerService);

        this._fileManagerService.onFilesChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(files => {                
                this.files = files;
            });

        this._fileManagerService.onFileSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selected => {
                this.selected = selected;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On select
     *
     * @param selected
     */
    onSelect(selected): void
    {                      
        this._fileManagerService.onFileSelected.next(selected);        
    }

    doubleClick(selected){
        if(selected.tipo == 'Voltar'){
            this.params.tipo = 'voltar';
        }else{
            this.params.tipo = '';
        }

        this.params.pasta_id = selected.id;   
        if(selected.type == 'folder' || selected.tipo == "Voltar"){
            this.entraPasta(selected);
        }

    }

    entraPasta(selected){
        this.params.pasta_id = selected.id;       
        this._fileManagerService.getFiles(this.params);
    }



    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}

export class FilesDataSource extends DataSource<any>
{
    /**
     * Constructor
     *
     * @param {FileManagerService} _fileManagerService
     */
    constructor(
        private _fileManagerService: FileManagerService
    )
    {
        super();
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        return this._fileManagerService.onFilesChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}