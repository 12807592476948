import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import {MatExpansionModule}  from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list'
import {MatSelectModule} from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FileManagerService } from 'app/apps/file-manager/file-manager.service';
import { FileManagerComponent } from 'app/apps/file-manager/file-manager.component';
import { FileManagerDetailsSidebarComponent } from 'app/apps/file-manager/sidebars/details/details.component';
import { FileManagerFileListComponent } from 'app/apps/file-manager/file-list/file-list.component';
import { FileManagerMainSidebarComponent } from 'app/apps/file-manager/sidebars/main/main.component';
import { UploadFileComponent } from './upload-file/upload-file.component';

import { FileUploadModule } from 'ng2-file-upload';
import { PastaService } from './services/pasta.service';
import { ArquivoService } from './services/arquivo.service';
import { CriarPastaComponent } from './criar-pasta/criar-pasta.component';

const routes: Routes = [
    {
        path     : 'file-manager',
        component: FileManagerComponent,
        children : [],
        resolve  : {
            files: FileManagerService
        }
    }
];

@NgModule({
    declarations: [
        FileManagerComponent,
        FileManagerFileListComponent,
        FileManagerMainSidebarComponent,
        FileManagerDetailsSidebarComponent,
        UploadFileComponent,
        CriarPastaComponent
        
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatIconModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatTableModule,
        FuseSharedModule,
        FuseSidebarModule,
        FileUploadModule,
        MatExpansionModule,
        MatListModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule        
    ],
    entryComponents: [
        UploadFileComponent,
        CriarPastaComponent        
    ],
    providers   : [
        FileManagerService,
        PastaService,
        ArquivoService
    ]
})
export class FileManagerModule {}
