import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation, 
    Inject, 
    OnInit
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar, 
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import {  format } from 'date-fns';

import { BaseComponent, LoadingTeste, ActionDialog } from "../../base-module/base-component/base.component";

import {AuthService} from "../../base-module/base-service/auth.service";
import { AcaoService } from '../../auxiliares/services/acao-service';
import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';
import { ClienteService } from '../services/cliente.service';
import { ClienteOportunidadeService } from '../services/cliente-oportunidade.service';
import { TipoOportunidadeService } from 'app/auxiliares/services/tipo-oportunidade-service';
import { ListaAtividadeAssuntoService } from 'app/lista-atividades/services/lista-atividade-assunto.service';
import { IItemsPermissionActions } from 'app/interfaces/IItemsPermissionActions';

export interface DialogData {
    id: number,
    cliente_id: number;
    dados_cliente:object;
    only_show: boolean;
    atividade_cliente_id: number;
}

interface Actions {
    buttonAlterOportunit: IItemsPermissionActions;
    buttonAddOportunit: IItemsPermissionActions;
}

@Component({
    selector: "app-cadastro-relacionamento-cliente",
    templateUrl: "./cadastro-relacionamento-cliente.component.html",
    styleUrls: ["./cadastro-relacionamento-cliente.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [AcaoService],
})
export class CadastroRelacionamentoClienteComponent extends BaseComponent implements OnInit {
    observacao = new FormControl("", [Validators.required]);
    acao_id = new FormControl("", [Validators.required]);
    cliente = new FormControl("");
    assunto_follow_up = new FormControl("", [Validators.required]);
    data_follow_up = new FormControl("");
    assunto_id = new FormControl("");

    descricao = new FormControl("");
    data_retorno = new FormControl("");
    valor = new FormControl("");
    tipo_oportunidade_id = new FormControl("");

    model: any = {
        cliente_id: "",
        acao_id: "",
        acao_retorno_id: "",
        data_follow_up: "",
        observacao: "",
    };

    model_oportunidade: any = {
        cliente_id: "",
        tipo_oportunidade_id: "",
        descricao: "",
        data_oportunidade: "",
    };

    loadingLocal: any;
    loadingSpinnerAcao: boolean = false;
    loadingSpinnerAssunto: boolean = false;
    loadingSpinner: boolean = false;
    loadingSpinnerTipo: boolean = false;
    loadingIndicatorOportunidade: boolean = false;
    isVisibleAgendamentoFuturo: boolean = false;
    isVisibleOportunidade: boolean = false;
    listAcao: any[] = [];
    listAtividade: any[] = [];
    listTipo: any[] = [];
    filteredOptions: Observable<string[]>;
    showClientField:boolean = false;
    todayDate: Date = new Date();

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroRelacionamentoClienteComponent, { static: false })
    table: CadastroRelacionamentoClienteComponent;

    _actions: Actions = {
        buttonAlterOportunit: {
            permission: 'Editar Oportunidade',
            enabled: false
        },
        buttonAddOportunit: {
            permission: 'Cadastrar Oportunidade',
            enabled: false
        },
    }

    constructor(
        public service: ClienteRelacionentoService,
        public _tipoOportunidadeService: TipoOportunidadeService,
        public _serviceOportunidade: ClienteOportunidadeService,
        private _clienteService: ClienteService,
        private _listaAtividadeService: ListaAtividadeAssuntoService,
        private _acaoService: AcaoService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public authService: AuthService,
        public dialogRef: MatDialogRef<CadastroRelacionamentoClienteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);

        if (this.data.id != null && this.data.id != undefined) {
            this.getById();
            this.getAllOportunidades();
            this.isVisibleOportunidade = true;
        }
        
        this.listAcao = [];
        this.listAtividade = [];
        this.getAllAcao();
        this.getAllTipoOportunidade();       
        this.getAllAssuntoListaAtividades();       
    }

    ngOnInit(): void {
        this.verifyPermissionForActions();
        if(this.data.cliente_id == null || this.data.cliente_id == undefined) {
            this.showClientField = true;
            this.cliente.valueChanges.subscribe((query) => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            });
        }else{
            this.showClientField = false;
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }   

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (!this.observacao.valid) {
            return true;
        }

        if (!this.acao_id.valid) {
            return true;
        }

        if (this.data.only_show) {
            return true;
        }

        if(this.validarCamposRetorno()){
            return true;
        }

        return false;
    }

    salvar(verOportunidade) {
        this.openLoading();

        if ( this.data.cliente_id ){
            this.model.cliente_id   = this.data.cliente_id;            
        }else{
            const clienteDados = this.cliente.value;
            this.model.cliente_id   = clienteDados.id;
        }

        if(this.data.atividade_cliente_id){
            this.model.atividade_cliente_id  = this.data.atividade_cliente_id;
        }

        this.model.observacao = this.observacao.value;
        this.model.acao_id = this.acao_id.value;
        this.model.assunto_id = this.assunto_id.value;
        this.model.data_follow_up = this.data_follow_up.value;

        if (this.data.id == null) {
            this.service.create(this.model).subscribe(
                (data) => {
                    this.data.id = data.data.id;
                    this.closeLoading();
                    this.getById();
                    this.getAllTipoOportunidade();

                    if(verOportunidade){
                        this.isVisibleOportunidade = true;
                    }else{
                        this.dialogRef.close(data);
                        this.isVisibleOportunidade = false;
                    }
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);                    
                },
                () => {
                    this.closeLoading();
                }
            );
        } else {
            this.service.update(this.data.id, this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }

       
    }

    getErrorObservacaoMessage() {
        return this.observacao.hasError("required")
            ? "Observação é um campo obrigatório"
            : "";
    }

    getErrorAcaoMessage() {
        return this.acao_id.hasError("required")
            ? "Ação é um campo obrigatório"
            : "";
    }

    getById() {
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
            (item) => {
                this.model = item.data;
                this.acao_id.setValue(item.data.acao_id);
                this.assunto_id.setValue(item.data.assunto_id);
                this.observacao.setValue(item.data.observacao);
                this.cliente.setValue(item.data.cliente);
                this.data_follow_up.setValue(item.data.data_follow_up);
            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getAllAcao() {
        this.loadingSpinnerAcao = true;
        this._acaoService.getAll({}).subscribe(
            (list) => {
                this.listAcao = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerAcao = false;
            }
        );
    }

    getAllTipoOportunidade() {
        this.loadingSpinnerTipo = true;
        this._tipoOportunidadeService.getLista().subscribe(
            (list) => {
                this.listTipo = list.data;
            },
            (error) => {  },
            () => {
                this.loadingSpinnerTipo = false;
            }
        );
    }

    getAllOportunidades() {
        this.loadingIndicatorOportunidade = true;
        this.params.relacionamento_id = this.data.id;
        this._serviceOportunidade.getAll(this.params).subscribe(
            (data) => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            (error) => {               
                this.loadingIndicatorOportunidade = false;
            },
            () => {             
                this.loadingIndicatorOportunidade = false;
            }
        );
    }

    getAllAssuntoListaAtividades() {
        this.loadingSpinnerAssunto = true;
        this._listaAtividadeService.getLista({is_subject_prosperidade: false}).subscribe(
            (list) => {
                this.listAtividade = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerAssunto = false;
            }
        );
    }

    isInvalidFormOportunidade() {
        if (!this.descricao.valid) {
            return true;
        }

        if (!this.tipo_oportunidade_id.valid) {
            return true;
        }

        if (this.data.only_show) {
            return true;
        }

        return false;
    }

    addOportunidade(){
        this.openLoading();
        const cliente = this.cliente.value

        this.model_oportunidade.relacionamento_id = this.data.id;
        this.model_oportunidade.cliente_id = cliente.id;
        this.model_oportunidade.tipo_oportunidade_id = this.tipo_oportunidade_id.value;
        this.model_oportunidade.descricao = this.descricao.value;
        this.model_oportunidade.valor = this.valor.value? this.valor.value : 0;
        this.model_oportunidade.data_retorno = this.data_retorno.value? this.data_retorno.value : 0;
        this.model_oportunidade.data_oportunidade = format(new Date(), 'yyyy-MM-dd');

        this._serviceOportunidade.create(this.model_oportunidade).subscribe(
            (data) => {
                this.closeLoading();
                this.getAllOportunidades();
            },
            (error) => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
            }
        );
        
    }

    deleteOportunidade(id) {
        var lista = this;
       
        this.dialog.open(ActionDialog, {
            width: "250px",
            disableClose: true,
            panelClass: "modal-delete",
            data: {
                title: "Excluir Oportunidade",
                text: "Deseja realmente excluir este item?",
                callback: function () {    
                    lista.loadingIndicatorOportunidade = true;                
                    lista._serviceOportunidade.delete(id).subscribe(
                        (data) => {
                            lista.showSuccess(data.message);
                            lista.getAllOportunidades();
                        },
                        (error) => {                            
                            lista.showError(error.error.message);
                        },
                        () => {
                            lista.loadingIndicatorOportunidade = false;
                        }
                    );
                },
            },
        });
    }

    validarCamposRetorno(){
        if(this.assunto_id.value == '' && this.data_follow_up.value != ''){
            return true;
        }

        if(this.assunto_id.value != '' && this.data_follow_up.value == ''){
            return true;
        }

        return false;

    }

    verifyPermissionForActions(){
        this._actions.buttonAlterOportunit.enabled = this.authService
        .isPermissao([this._actions.buttonAlterOportunit.permission]);
        this._actions.buttonAddOportunit.enabled = this.authService
        .isPermissao([this._actions.buttonAddOportunit.permission]);
    }

    addContatoFuturo(){
        console.log(this.isVisibleAgendamentoFuturo);
        if(this.isVisibleAgendamentoFuturo){
            this.isVisibleAgendamentoFuturo = false;
        }else{
            this.isVisibleAgendamentoFuturo = true;
        }
    }
}
