import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DashboardRendaFixaService } from '../services/dashboard-renda-fixa.service';
import { RegionalService } from 'app/empreendimento/services/regional.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';

@Component({
    selector: 'dashboard-renda-fixa',
    templateUrl: './dashboard-renda-fixa.component.html',
    styleUrls: ['./dashboard-renda-fixa.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        RegionalService,
        EscritorioService
    ]
})

export class DashboardRendaFixaComponent extends BaseComponent implements OnInit {

    user:any;

    date_last_operation: Date;     
    total_leads: any = {};     
    total_leads_disponiveis: any = {};
    
    agente_id = new FormControl('');

    loadingSpinnerRegional: boolean = false;
    loadingSpinnerEscritorio: boolean = false;
    loadingSpinnerAssessor: boolean = false;
    listRegional: any[] = [];
    listEscritorio: any[] = [];
    listAssessor: any[] = [];

    regional_id = new FormControl('');
    escritorio_id = new FormControl('');
    assessor_id = new FormControl('');

    model: any = {
        regional_id: "",
        escritorio_id: "",
        assessor_id: "",
    }

    public  colors = [
        {
            borderColor    : '#42a5f5',
            backgroundColor: '#42a5f5'
        }
    ];

    total_clientes:any;
    total_novos_clientes:any;
    total_percentual_clientes:any;
    total_percentual_renda_fixa:any;
    total_custodia:any;
    total_custodia_por_vencimento:any;
    total_percentual_custodia:any;
    total_boleta:any;
    total_boleta_efetivada:any;
    total_boleta_cancelada:any;
    total_receita_estimada:any;
    total_pendencias_inconsistencias:any;
    roa:any;

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _service: DashboardRendaFixaService,
                private _authService: AuthService,
                private _regionalService: RegionalService,
                private _escritorioService: EscritorioService ) 
    {
        super(dialog, snackBar);
        this.user = JSON.parse(this._authService.getUser());

        this.getAllRegional();
        this.getAllEscritorios();
    }

    ngOnInit(){
        this.getAll();
    }

    getAllRegional() {
        this.loadingSpinnerRegional = true;
        this._regionalService.getAllList({}).subscribe(
            data => {
                const add = {
                    id: '',
                    nome: 'TODAS'
                };
                this.listRegional = data.data;
                this.listRegional.push(add);
            },
            error => {
                this.loadingSpinnerRegional = false;
            },
            () => {
                this.loadingSpinnerRegional = false;
                this.regional_id.setValue('');
            }
        );
    }

    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this._escritorioService.getAll({ no_paginate: true }).subscribe(
            data => {
                const add = {
                    id: '',
                    nome_fantasia: 'TODOS'
                };
                this.listEscritorio = data.data;
                this.listEscritorio.push(add);
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                this.escritorio_id.setValue('');
                this.assessor_id.setValue('');
            }
        );
    }

    selectEscritorio(event) {
        this.params.escritorio_id = event.value;
        this.getAll();
    }

    selectRegional(event) {
        this.params.regional_id = event.value;
        this.getAll();
    }

    selectAssessor(event) {
        this.params.assessor_id = event.value;
        this.getAll();
    }

    getAll() {

        this.loadingIndicator = true;
        this._service.getAll(this.params).subscribe(
            data => {
                
                this.date_last_operation = data.data.last_date_operation;
                
                let totalClientes: any[] = data.data.cliente_anterior;
                let totalNovosClientes: any[] = data.data.novos_clientes;
                let totalPercentualClientes: any[] = data.data.percentual_clientes;
                let totalCustodia: any[] = data.data.total_custodia;
                let totalCustodiaPorVencimento: any[] = data.data.total_custodia_vencimento;
                let totalPercentualCustodia: any[] = data.data.percentual_custodia;
                let totalBoleta: any[] = data.data.total_boleta;
                let totalBoletaEfetivada: any[] = data.data.total_boleta_efetivada;
                let totalBoletaCancelada: any[] = data.data.total_boleta_cancelada;
                let totalReceitaEstimada: any[] = data.data.total_receita_estimada;
                let totalPendenciasInconsistencias: any[] = data.data.total_pendencias_inconsistencias;
                let totalRoa: any[] = data.data.total_roa;

                let $thisLocal:any = this;

                let totalClientesMesAtual = totalClientes.pop();
                const mesAtual = data.data.mes.pop();

                this.total_clientes = {
                    conversion: {
                        value: totalClientesMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Clientes',
                            data: totalClientes
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalClientes.reduce((a, b) => Math.min(a, b)) - totalClientes.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalClientes.reduce((a, b) => Math.max(a, b)) + totalClientes.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                    
                };

                let totalNovosClientesMesAtual = totalNovosClientes.pop()

                this.total_novos_clientes = {
                    conversion: {
                        value: totalNovosClientesMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Novos Clientes',
                            data: totalNovosClientes
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalNovosClientes.reduce((a, b) => Math.min(a, b)) - totalNovosClientes.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalNovosClientes.reduce((a, b) => Math.max(a, b)) + totalNovosClientes.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }

                };

                let totalPercentualClientesMesAtual = totalPercentualClientes.pop();
                this.total_percentual_clientes = {
                    conversion: {
                        value: totalPercentualClientesMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Percentual de Clientes',
                            data: totalPercentualClientes
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,   
                                    })).format((tooltipItem.value / 100));
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalPercentualClientes.reduce((a, b) => Math.min(a, b)) - totalPercentualClientes.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalPercentualClientes.reduce((a, b) => Math.max(a, b)) + totalPercentualClientes.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalCustodiaMesAtual = totalCustodia.pop();
                this.total_custodia = {
                    conversion: {
                        value: totalCustodiaMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Custódia',
                            data: totalCustodia
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalCustodia.reduce((a, b) => Math.min(a, b)) - totalCustodia.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalCustodia.reduce((a, b) => Math.max(a, b)) + totalCustodia.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalCustodiaPorVencimentoMesAtual = totalCustodiaPorVencimento.pop();
                this.total_custodia_por_vencimento = {
                    conversion: {
                        value: totalCustodiaPorVencimentoMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Custódia por Vencimento',
                            data: totalCustodiaPorVencimento
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalCustodiaPorVencimento.reduce((a, b) => Math.min(a, b)) - totalCustodiaPorVencimento.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalCustodiaPorVencimento.reduce((a, b) => Math.max(a, b)) + totalCustodiaPorVencimento.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalPercentualCustodiaMesAtual = totalPercentualCustodia.pop();
                this.total_percentual_custodia = {
                    conversion: {
                        value: totalPercentualCustodiaMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Custódia RF',
                            data: totalPercentualCustodia
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,   
                                    })).format($thisLocal.formataCasaDecimal(($thisLocal.formataCasaDecimal(tooltipItem.value) / 100)));
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalPercentualCustodia.reduce((a, b) => Math.min(a, b)) - totalPercentualCustodia.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalPercentualCustodia.reduce((a, b) => Math.max(a, b)) + totalPercentualCustodia.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalBoletaMesAtual = totalBoleta.pop();
                this.total_boleta = {
                    conversion: {
                        value: totalBoletaMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Total Boleta',
                            data: totalBoleta
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalBoleta.reduce((a, b) => Math.min(a, b)) - totalBoleta.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalBoleta.reduce((a, b) => Math.max(a, b)) + totalBoleta.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalBoletaEfetivadaMesAtual = totalBoletaEfetivada.pop();
                this.total_boleta_efetivada = {
                    conversion: {
                        value: totalBoletaEfetivadaMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Total Boleta Efetivada',
                            data: totalBoletaEfetivada
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalBoletaEfetivada.reduce((a, b) => Math.min(a, b)) - totalBoletaEfetivada.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalBoletaEfetivada.reduce((a, b) => Math.max(a, b)) + totalBoletaEfetivada.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalBoletaCanceladaMesAtual = totalBoletaCancelada.pop();
                this.total_boleta_cancelada = {
                    conversion: {
                        value: totalBoletaCanceladaMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Total Boleta Cancelada',
                            data: totalBoletaCancelada
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalBoletaCancelada.reduce((a, b) => Math.min(a, b)) - totalBoletaCancelada.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalBoletaCancelada.reduce((a, b) => Math.max(a, b)) + totalBoletaCancelada.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalReceitaEstimadaMesAtual = totalReceitaEstimada.pop();
                this.total_receita_estimada = {
                    conversion: {
                        value: totalReceitaEstimadaMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Total Receita Estimada',
                            data: totalReceitaEstimada
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })).format(tooltipItem.value);
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalReceitaEstimada.reduce((a, b) => Math.min(a, b)) - totalReceitaEstimada.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalReceitaEstimada.reduce((a, b) => Math.max(a, b)) + totalReceitaEstimada.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalPendenciasInconsistenciasMesAtual = totalPendenciasInconsistencias.pop();
                this.total_pendencias_inconsistencias = {
                    conversion: {
                        value: totalPendenciasInconsistenciasMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'Pendencia Inconsistências',
                            data: totalPendenciasInconsistencias
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return tooltipItem.value;
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalPendenciasInconsistencias.reduce((a, b) => Math.min(a, b)) - totalPendenciasInconsistencias.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalPendenciasInconsistencias.reduce((a, b) => Math.max(a, b)) + totalPendenciasInconsistencias.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

                let totalRoaMesAtual = totalRoa.pop();
                this.roa = {
                    conversion: {
                        value: totalRoaMesAtual,
                        ofTarget: 13
                    },
                    chartType: 'bar',
                    datasets: [
                        {
                            label: 'ROA',
                            data: totalRoa
                        }
                    ],
                    labels: data.data.mes,
                    options: {
                        tooltips: {
                            callbacks: {

                                
                                label: function (tooltipItem) {
                                    return (new Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,   
                                    })).format(($thisLocal.formataCasaDecimal(tooltipItem.value)/100));
                                }
                            }
                        },
                        spanGaps: false,
                        legend: {
                            display: false
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 24,
                                left: 16,
                                right: 16,
                                bottom: 16
                            }
                        },
                        scales: {
                            xAxes: [
                                {
                                    display: false
                                }
                            ],
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        min: (totalRoa.reduce((a, b) => Math.min(a, b)) - totalRoa.reduce((a, b) => Math.min(a, b)) * 0.50),
                                        max: (totalRoa.reduce((a, b) => Math.max(a, b)) + totalRoa.reduce((a, b) => Math.max(a, b)) * 0.10)
                                    }
                                }
                            ]
                        }
                    }
                };

            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    } 
        
    isEmptyObject(obj) {
        var name;
        for (name in obj) {
          return false;
        }
        return true;
    }

    removerUltimoItemArray(array){
        let list = [];
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            if(i+1 != array.length){
                list.push(element);
            }
        }

        return list;
    }

    formataCasaDecimal(num){
        let numero = 0.00;

        if (num != null && num != undefined){
            numero = parseFloat(num);
            return numero.toFixed(2);
        }

        return numero;
    }

    /**
     * Exporta total de clientes
     */
    getListaClienteExport(types) {

        this.openLoading();
        this.params.export_list_type = types;
        this._service.getExportTotalClientes(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    /**
     * Exporta total de custodias
     */
    getListaCustodiaExport(types) {

        this.openLoading();
        this.params.export_list_type = types;
        this._service.getExportTotalCustodia(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }
}
