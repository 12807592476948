import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {BaseComponent} from "../../base-module/base-component/base.component";
import {FormControl} from "@angular/forms";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { StatusOperacaoService } from '../services/status-operacao.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FiltrarBoletaComponent } from 'app/movimentacao/filtrar-boleta/filtrar-boleta.component';
import { UploadHistoricoOrdensComponent } from 'app/renda-variavel/upload-historico-ordens/upload-historico-ordens.component';
import { ListaIncosistenciasComponent } from 'app/renda-variavel/lista-incosistencias/lista-incosistencias.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-lista-status-operacao',
    templateUrl: './lista-status-operacao.component.html',
    styleUrls: ['./lista-status-operacao.component.scss'],
    providers:[ ListaIncosistenciasComponent ],
})

export class ListaStatusOperacaoComponent extends BaseComponent{

    params: any = {};
    user: any;
    statusOperacao: any;
    loadingIndicator: boolean;
    inputSubscription: Subscription; 

    rowsInconsistencias = [];
    tempInconsistencias= [];  
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaStatusOperacaoComponent, { static: false }) table: ListaStatusOperacaoComponent;

    @ViewChild('table',{ static: false }) table2: DatatableComponent;

    public search = new FormControl();

    constructor(public service: StatusOperacaoService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService,
                private _incosistencia: ListaIncosistenciasComponent,
                private _serviceStatusOperacao: StatusOperacaoService) {
        super(dialog,snackBar);

        this.user = JSON.parse(this._authService.getUser());
        this.getArchive();
        this.getAll();

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }

    onLinkClick(e){        
        if(e.index == 0){
            this.getAll();
        }

        if(e.index == 2){
            this._incosistencia.getAll();
        }
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {

        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.inputSubscription = this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.total;
                this.rows = data.data;
                this.temp = [...this.rows];

            },
            error => {                
                this.loadingIndicator = false;               
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }

    getArchive(){
        this.loadingIndicator = true;

        this._serviceStatusOperacao.getArchiveUpload().subscribe(
            data => {
                this.statusOperacao = data.data;
            },
            error => {                
                this.loadingIndicator = false;               
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
        
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarBoletaComponent, {
            width: '700px',
            maxHeight: '99vh',
            data: { 
                titulo: 'Filtrar Histórico de Ordens',
                status_boleta: false,
                status_ordem:true,
                tipo: "RV"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                this.params.data_operacao_inicio    = result.data_operacao_inicio;
                this.params.data_operacao_fim       = result.data_operacao_fim;
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }else{
                    this.params.cliente_id = '';
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }else{
                    this.params.assessor_id = '';
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.status_ordem_id != undefined && result.status_ordem_id != null) {
                    this.params.status_id = result.status_ordem_id;
                }

                if (result.estrategia_id != undefined && result.estrategia_id != null) {
                    this.params.estrategia_id = result.estrategia_id;
                }

                if (result.ativos != undefined && result.ativos != null) {
                    this.params.ativos = result.ativos;
                }else{
                    this.params.ativos = '';
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }
    

    openCadastro(): void {
        let dialogRef = this.dialog.open(UploadHistoricoOrdensComponent, {
            width: '400px',
            //data: { name: this.name, animal: this.animal }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                //this.showSuccess("Custódia cadastrada com sucesso!");
                this.getAll();
                this._incosistencia.getAll();
            }else if(result != undefined && result != "cancel"){
                //this.showError("Ocorreu um erro ao tentar cadastrar a custódia dos clientes.");
            }
        });
    }

    getEscritorioCliente(cliente){
        if(cliente.escritorio !=undefined && cliente.escritorio != ''){
            return cliente.escritorio.nome_fantasia;
        }

        return '';
    }

    getAssessorCliente(cliente){
        if(cliente.assessor !=undefined && cliente.assessor != ''){
            return cliente.assessor.name;
        }

        return '';
    }

    /**
     * Cor das linhas
     */
    getRowClass = (row) => {
        
        if(row.status_id == 6 || row.status_id == 28){
            return {'row-color-sucess': true};
        }else if(row.status_id == 8 || row.status_id == 35 || row.status_id == 15){
            return {'row-color-failed': true};
        }else if(row.status_id == 7 || row.status_id == 36 || row.status_id == 24 || row.status_id == 17){
            return {'row-color-canceled': true}
        }else if(row.status_id == 10 || row.status_id == 19){
            return {'row-color-wc ': true}
        }else if(row.status_id == 9 || row.status_id == 33){
            return {'row-color-parcial ': true}
        }else if(row.status_id == 5 ){
            return {'row-color-stop ': true}
        }else if(row.status_id == 11 || row.status_id == 31 ){
            return {'row-color-expirada ': true}
        }
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    onEnter(value: string) {    
        this.stopNow();     
        this.params.search = value;
        this.search.setValue(value);
        this.getAll();
    }

}
