import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class StatusOperacaoService {    

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * View
     * @param id ID
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getAllClienteSemOrdem(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens/clientes-sem-ordem"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getAllClienteInconsistencias(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens/clientes-compra-venda"+env, { headers: this.authService.getAuthHeaders() });
    }
    

    /**
     * Delete
     * @param id ID
     */
    delete(id): Observable<any> {        
        return this._http.delete(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra
     * @param params Json
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza
     * @param params Json
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista de Saldos em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/historico-ordens" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }

    /**
     * View
     * @param id ID
     */
     getArchiveUpload(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/importacao-historico-ordens/last-archive", { headers: this.authService.getAuthHeaders() });
    }

}