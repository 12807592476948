import {Component}  from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent}  from '@angular/material';
import {ActionDialog} from '../../../base-module/base-component/base.component';
import {BaseComponent} from "../../../base-module/base-component/base.component";
import {Router, ActivatedRoute} from "@angular/router";
import { AuthService } from 'app/base-module/base-service/auth.service';
import {SelectionType} from '@swimlane/ngx-datatable';
import { GeradorOrdemBoletaService } from 'app/renda-variavel/services/gerador-ordem-boleta.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AdicionaAtivoComponent } from '../adiciona-ativo/adiciona-ativo.component';
import { GeradorOrdemService } from 'app/renda-variavel/services/gerador-ordem.service';

interface GeradorBoleta {
    id: number;
} 

@Component({
    selector: 'app-gerador-ordem-steps',
    templateUrl: './gerador-ordem-steps.component.html',
    styleUrls: ['./gerador-ordem-steps.component.scss'],  
    providers: [GeradorOrdemBoletaService]
})

export class GeradorOrdemStepsComponent extends BaseComponent{

    params: any = {};
    user: any;
    loadingIndicator: boolean;   
    SelectionType = SelectionType;
    
    data: any = {};
    titulo: string = 'Gerador de Ordens de Renda Variável';    
    selection = new SelectionModel<GeradorBoleta>(true, []);
    displayedColumns = ['select', 'cliente', 'valor_financeiro_bruto', 'valor_financeiro_liquido'];
    newColumns = [];
    columnsToDisplay: string[] = this.displayedColumns.slice();
    addColumns = [];
    listaBoletaPendentes: boolean = false;
    rowsPendentes = [];
    totalClientes: number = 0;

    constructor(public _service: GeradorOrdemBoletaService, 
                private _serviceGerador: GeradorOrdemService,
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                private _authService: AuthService,
                private _Activatedroute:ActivatedRoute) 
    {
        
        super(dialog, snackBar);        
        this.data = this._Activatedroute.snapshot.params['id'];        
        this.user = JSON.parse(this._authService.getUser());      
        this.getAll();        
    }
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any): void{
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {      
        
        this.openLoading();  
        this.columnsToDisplay = this.displayedColumns.slice();
        
        this.params.gerador_id = this.data;
        this.params.disponivel = true;
        this._service.getAll(this.params).subscribe(
            data => {                
                this.rows = data.data;
                this.totalClientes = data.data.length;
                this.titulo = data.titulo;                
                this.listaBoletaPendentes = data.pendentes;                
                if(Object.values(data.ativos).length > 0){
                    const ativos = Object.values(data.ativos);
                    this.newColumns = Object.values(data.ativos);
                    ativos.map((ativo:string) =>{                        
                        this.columnsToDisplay.push(ativo);
                    });
                }                
                this.closeLoading();
            },
            error => {                               
                this.closeLoading();
            },
            () => {   
                this.closeLoading();                
            }
        );
    }

    /**
     * Exclui registro de boleta
     * @param id
     */
    delete() {

        if(this.selection.selected.length == 0){
            this.showError("Selecione pelo menos uma boleta para realizar a exclusão");
            return false;
        }

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Remover Boletas do Gerador de Ordens",
                text: "Deseja realmente remover este item ?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(lista.selection.selected).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    openVisualizar(id){
        this.route.navigate(['/renda-variavel/basket-ativos/' + id]);
    }

    openAlterar(id){
        this.route.navigate(['/renda-variavel/basket-ativos/' + id]);
    }

    addAtivo(){        
        let dialogRef = this.dialog.open(AdicionaAtivoComponent, {
            width: '300px',
            maxHeight: '99vh',
            data: {
                gerador_boleta_id: this.data
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Ativos adicionados com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){        

            }
        });
    }
    
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.rows.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.rows.forEach(row => this.selection.select(row.id));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: GeradorBoleta): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    getTotalLiquido(){
        return this.rows.map(gerador => gerador.valor_financeiro_liquido).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    }

    getTotalBruto(){
        return this.rows.map(gerador => gerador.valor_financeiro_bruto).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    }

    basket(){
        this.openLoading();
        this._serviceGerador.getAllExport({gerador_id: this.data, disponivel: true}).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }
}

