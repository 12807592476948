import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class BoletaPendenteService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Basket por id
     * @param id ID da Basket
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/boleta-pendente/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Basket
     * @param id ID da Basket
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "renda-variavel/boleta-pendente"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete Basket
     * @param id ID do Basket
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "renda-variavel/boleta-pendente/"+id, { headers: this.authService.getAuthHeaders() });
    }

     /**
     * Cadastra Boleta do Cliente
     * @param params Json AporBoletate do Cliente
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "movimentacao/boleta/pendente", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

}
