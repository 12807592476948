import { Component } from '@angular/core';
import {FormControl} from '@angular/forms';
import {
    MomentDateAdapter, 
    MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';

import {
    DateAdapter, 
    MAT_DATE_FORMATS, 
    MAT_DATE_LOCALE
} from '@angular/material/core';

import { MatDialog, MatSnackBar,MatDatepicker } from '@angular/material';

import * as _moment from 'moment';
import {Moment} from 'moment';

import { trigger, style, animate, transition } from '@angular/animations';

import {
    BaseComponent, 
    ActionDialog
} from "app/base-module/base-component/base.component";


import { FeedbackService } from '../../services/feedback.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteService } from '../../services/cliente.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

const moment = _moment;

export const MY_FORMATS = {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

@Component({
  selector: 'app-feedback-cliente',
  templateUrl: './feedback-cliente.component.html',
  styleUrls: ['./feedback-cliente.component.scss'],
  providers: [    
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('1s ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
}) 

export class FeedbackClienteComponent extends BaseComponent {

    date_de     = new FormControl(moment());
    date_para   = new FormControl(moment());    

    total_fop:any = 0;
    total_swt:any = 0;         
    total_opcao:any = 0;
    total_dividendos:any = 0;
    total_hb:any = 0;
    totalGeral:number = 0;
    totalEstrategias:any = [];
    valor_opcao_casada:any = 0;    
    estrategia:any;

    opcao_fop:boolean = false;
    opcao_swt:boolean = false;
    disableButton:boolean = false;
    loadingAtivo:boolean = false;

    model:any = {};    
    
    resultadosFop = [];
    resultadosSwt = [];    
    compras = [];
    comprasST = [];
    vendas = [];    
    vendasST = [];
    inconsistencias = [];
    inconsistenciasST = [];
    inconsistenciasHB = [];
    opcoes = [];
    dividendos = [];
    premioList = [];
    data:any;    
    valor_investimento:any = 0;    
    vlr_boleta_fo:any = 0;    
    vlr_boleta_st:any = 0;
    venda_selecionada:any;
    compra_selecionada:any;
    incosistencia_selecionada:any;
    opcao_selecionada:any;

    constructor( private _service: FeedbackService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _Activatedroute: ActivatedRoute,
        private _clienteService: ClienteService,
        private _authService: AuthService,
        private route: Router) { 
        super(dialog, snackBar);

        this.data = this._Activatedroute.snapshot.params['id'];
        this.getCliente();        
    }

    limpaCampos(){
        this.opcoes = [];
        this.compras = [];
        this.comprasST = [];
        this.premioList = [];
        this.dividendos = [];
        this.vendas = [];
        this.vendasST = [];
        this.inconsistencias = [];
        this.inconsistenciasST = [];
        this.resultadosFop = [];
        this.resultadosSwt = [];    
        this.totalEstrategias = [];    
        this.totalGeral = 0;
        this.total_fop = 0;
        this.total_swt = 0;
        this.total_opcao = 0;   
        this.total_dividendos = 0;
    }

    buscar() {
        this.disableButton = true;
        this.limpaCampos();
        
        this.buscaOpcao();
        this.buscaCompra([1,2,5,6,14,15]);
        this.buscaIncosistencia([1,2,5,6,14,15]);
        this.buscaDividendos();
    }

    changeOpcao(tipo){
        this.estrategia = tipo;
        this.opcoes = [];
        this.resultadosFop = [];
        this.resultadosSwt = [];  
        this.totalEstrategias = [];      

        if(tipo == 'fop'){
            this.premioList = [];
            this.compras = [];
            this.vendas = [];
            this.total_fop = 0;
            this.totalGeral = 0;
            this.total_opcao = 0;
            this.total_swt = 0;        
            this.total_dividendos = 0;
            this.inconsistencias = [];  
            this.buscaOpcao();  
            this.buscaCompra([1,2,5,6,14,15]);  
            this.buscaIncosistencia([1,2,5,6,14,15]);
            this.buscaDividendos();
        }
    }

    buscaOpcao(){
        this.params = [];
        this.params.opcao = 'S';
        this.params.compra_saida_id = '';
        this.params.vendas = '';
        this.params.cliente_id = this.data;
        this.params.data_operacao_inicio = this.date_de.value.format('YYYY-MM');
        this.params.data_operacao_fim = this.date_para.value.format('YYYY-MM');
        this.params.operacao = '';        
        
        this._service.getAll(this.params).subscribe(
            item => {
                let i = 0;
                
                for(let opcao of item.data){
                    i++;
                    var corretagem = 0;
                    
                    if(opcao.desconto_corretagem > 0){
                        corretagem = parseFloat(opcao.preco) * (0.006 * (opcao.desconto_corretagem/100));
                    }else{
                        corretagem = parseFloat(opcao.preco) * 0.006;
                    }
                    
                    const objeto = {
                        posicao: i,
                        id:opcao.id,
                        data: opcao.data_operacao,
                        ativo: opcao.sigla,
                        ativo_sigla: opcao.ativo.nome,
                        qtd: opcao.qtd,
                        preco: parseFloat(opcao.preco) - corretagem,
                        valor: parseFloat(opcao.qtd) * (parseFloat(opcao.preco) - corretagem),
                        status: opcao.status.trim(),
                        disabled: opcao.opcao_id != null ? true : false,
                        cor_ativo: opcao.cor_hexadecimal                 
                    }

                    this.total_opcao += parseFloat(opcao.qtd) * (parseFloat(opcao.preco) - corretagem);
                    
                    if(this.totalEstrategias[opcao.estrategia_id] === undefined){
                        this.totalEstrategias[opcao.estrategia_id] = parseFloat(opcao.qtd) * (parseFloat(opcao.preco) - corretagem);
                    }else{                            
                        this.totalEstrategias[opcao.estrategia_id] += parseFloat(opcao.qtd) * (parseFloat(opcao.preco) - corretagem);
                    }                    

                    this.opcoes.push(objeto);
                }               
           },
            error => { },
           () => { });
    }
    
    buscaDividendos(){
        const parametros = {            
            operacao: 'D',           
            tipo_ativo: 'D',
            cliente_id: this.data,
            data_operacao_inicio: this.date_de.value.format('YYYY-MM'),
            data_operacao_fim: this.date_para.value.format('YYYY-MM')
        }
        
        this._service.getAll(parametros).subscribe(
            item => {
                let i = 0;
                
                for(let dividendo of item.data){
                    i++;
                    
                    const objeto = {
                        posicao: i,
                        id:dividendo.id,
                        data: dividendo.data_operacao,
                        ativo: dividendo.sigla,
                        ativo_sigla: dividendo.ativo.nome,
                        tipo_ativo: dividendo.tipo_ativo,
                        valor: parseFloat(dividendo.total_value),                        
                        cor_ativo: dividendo.cor_hexadecimal
                    }

                    this.total_dividendos += parseFloat(dividendo.total_value);
                    this.dividendos.push(objeto);
                }               
           },
            error => { },
           () => { });
    }

    buscaCompra(estrategia){

        const parametros = {
            opcao:'N',
            operacao: 'C',
            vendas: '',
            compra_saida_id: '',        
            estrategia: estrategia,
            cliente_id: this.data,
            data_operacao_inicio: this.date_de.value.format('YYYY-MM'),
            data_operacao_fim: this.date_para.value.format('YYYY-MM'),
            estrategia_id: ''
        }
        
        this._service.getAll(parametros).subscribe(
            item => {
                let i = 0;
                this.valor_opcao_casada = 0;

                for(let compra of item.data)
                {
                    let resultado = {
                        valor: 0, 
                        posicao: 0,
                        percentual: 0,
                        estrategia: '',
                        estrategia_id: null
                    }
                    
                    i++;

                    var corretagem = 0;
                    let valor_opcao = 0;

                    const valorOperado =  compra.preco > 0 ? (parseFloat(compra.preco) * compra.qtd) + parseFloat(compra.corretagem) : 0;
                    const novoPrecoCompra = (valorOperado / compra.qtd);

                    let preco = 0;

                    if(compra.tipo != 'OPCAO'){
                        preco = novoPrecoCompra;
                    }else{
                        preco = parseFloat(compra.preco);
                    }

                    const objeto = {
                        posicao: i,
                        id:compra.id,
                        data: compra.data_operacao,
                        ativo: compra.sigla.trim(),
                        ativo_id: compra.ativo_id,
                        qtd: compra.qtd,
                        preco: preco,
                        valor: compra.preco > 0 ? parseFloat(compra.qtd) * preco : 0,
                        status: compra.status.trim(),
                        ativo_sigla: compra.ativo.nome,
                        carteira: compra.carteira,
                        estrategia: compra.estrategia,
                        estrategia_id: compra.estrategia_id,
                        operacao: 'C',
                        desconto_corretagem:compra.desconto_corretagem,
                        cor_ativo:compra.cor_hexadecimal,
                    }
                    
                    if(compra.opcao_id == null || compra.opcao_id == undefined){

                        const opcao = {
                            posicao: i,
                            id: null,
                            ativo: null,
                            opcao: null,
                            preco: null,
                            ativo_sigla: null,
                            disabled: false,
                            valor: null,
                            selecionado: false,
                            texto: ''
                        }

                        this.premioList.push(opcao);
                    }else{

                        const valorOperado =  (parseFloat(compra.opcao.preco) * compra.opcao.qtd) - parseFloat(compra.opcao.corretagem);
                        const novoPrecoCompra = (valorOperado / compra.opcao.qtd);

                        valor_opcao = parseFloat(compra.opcao.qtd) * novoPrecoCompra;

                        const opcao = {
                            posicao: i,
                            id: compra.opcao_id,
                            ativo: compra.opcao.ativo.sigla,
                            opcao:null,
                            status:'OK',
                            ativo_sigla:compra.opcao.ativo.nome,
                            disabled: true,
                            valor: valor_opcao,
                            selecionado:false,
                            texto: 'Adicione uma Opção'
                        }
                        
                        this.valor_opcao_casada += valor_opcao;

                        if(this.totalEstrategias[compra.estrategia_id] !== undefined){                        
                            this.totalEstrategias[compra.estrategia_id] -= valor_opcao;
                        }

                        this.premioList.push(opcao);                        
                    }

                    if(compra.compra_saida_id == null){
                        
                        const saida = {
                            posicao: i,
                            id:null,
                            data: null,
                            ativo: null,
                            qtd: null,
                            preco: null,
                            valor: null,
                            status: null,
                            ativo_sigla: null,
                            selecionado: false,
                            disabled: compra.status == 'ABE' ? true : false,
                            texto: compra.status == 'ABE' ? 'Custódia Aberta' : 'Clique aqui para adicionar uma venda'
                        }

                        resultado = {
                            valor: valor_opcao,
                            posicao:i,
                            percentual: ((valor_opcao / (parseFloat(compra.qtd) * preco) - 1 ) * 100 ) + 100,
                            estrategia: '',
                            estrategia_id: compra.estrategia_id
                        }

                        this.vendas.push(saida);                        
                        
                        if(compra.opcao_id != null && compra.opcao_id != undefined){
                            resultado.estrategia = 'OP';                            
                            this.total_fop += resultado.valor;
                        }else{
                            resultado.estrategia = 'ST';
                            this.total_swt += resultado.valor;
                        }

                        this.resultadosFop.push(resultado);
                    }else{

                        const valorOperado =  (parseFloat(compra.saida.preco) * compra.saida.qtd) - parseFloat(compra.saida.corretagem);
                        const preco_saida = ( valorOperado / compra.saida.qtd);

                        const saida = {
                            posicao: i,
                            id: compra.saida.id,
                            data: compra.saida.data_operacao,
                            ativo: compra.saida.ativo.sigla,
                            ativo_id: compra.saida.ativo.id,
                            qtd: compra.saida.qtd,
                            preco: preco_saida,
                            estrategia: compra.saida.estrategia,
                            estrategia_id: compra.saida.estrategia_id,
                            operacao: compra.saida.operacao,
                            valor: parseFloat(compra.saida.qtd) * preco_saida,
                            status: 'OK',
                            ativo_sigla: compra.saida.ativo.nome,
                            disabled: true,
                            selecionado: false,
                            texto:'',
                            cor_ativo:compra.saida.cor_hexadecimal
                        }

                        resultado = {
                            valor:  valor_opcao + (parseFloat(compra.saida.qtd) * preco_saida) - (parseFloat(compra.qtd) * preco),
                            posicao: i,
                            percentual: compra.preco > 0 ? (((valor_opcao + (parseFloat(compra.saida.qtd) * preco_saida)) / (parseFloat(compra.qtd) * preco) ) -1 ) * 100 : 100,
                            estrategia_id: compra.estrategia_id,
                            estrategia: ''
                        } 

                        this.vendas.push(saida);      
                        
                        if(compra.opcao_id != null && compra.opcao_id != undefined){
                            resultado.estrategia = 'OP';                            
                            this.total_fop += resultado.valor;
                        }else{
                            resultado.estrategia = 'ST';                            
                            this.total_swt += resultado.valor;
                        }  
                        
                        this.resultadosFop.push(resultado);
                    }
                   
                    if(this.totalEstrategias[compra.estrategia_id] === undefined){                        
                        this.totalEstrategias[compra.estrategia_id] = resultado.valor;
                    }else{                            
                        this.totalEstrategias[compra.estrategia_id] += resultado.valor;
                    }

                    this.totalGeral = this.totalEstrategias
                        .map(gerador => gerador)
                        .reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);

                    this.compras.push(objeto);

                    if(this.loadingAtivo){
                        this.closeLoading();
                    }
                }
                     
            },
            error => { },
           () => {               
                this.disableButton = false;

                if(this.loadingAtivo){
                    this.closeLoading();
                }

           }
        );
    }

    buscaIncosistencia(estrategia){
        this.inconsistencias = [];
        this.inconsistenciasST = [];

        this.params.opcao = 'N';
        this.params.operacao = 'V';
        this.params.vendas = 'S';
        this.params.compra_saida_id = 'VAZIO';
        this.params.estrategia = estrategia;
        this.params.cliente_id = this.data;
        this.params.data_operacao_inicio = this.date_de.value.format('YYYY-MM');
        this.params.data_operacao_fim = this.date_para.value.format('YYYY-MM');
        this.params.estrategia_id = '';
        
        this._service.getAll(this.params).subscribe(
            item => {

                let i = 0;
                
                for(let inconsistencia of item.data){
                    i++;
                    var corretagem = 0;
                    
                    if(inconsistencia.desconto_corretagem > 0){
                        corretagem = parseFloat(inconsistencia.preco) * (0.006 * (inconsistencia.desconto_corretagem / 100));
                    }else{
                        corretagem = parseFloat(inconsistencia.preco) * 0.006;
                    }

                    let preco = 0;

                    if(inconsistencia.tipo != 'OPCAO'){
                        preco = parseFloat(inconsistencia.preco) - corretagem;
                    }else{
                        preco = parseFloat(inconsistencia.preco);
                    }

                    const objeto = {
                        posicao: i,
                        id:inconsistencia.id,
                        data: inconsistencia.data_operacao,
                        ativo: inconsistencia.sigla,
                        qtd: inconsistencia.qtd,
                        preco: preco,
                        valor: parseFloat(inconsistencia.qtd) * preco,
                        status: inconsistencia.status.trim(),
                        ativo_id: inconsistencia.ativo_id,
                        estrategia: inconsistencia.estrategia,
                        estrategia_id: inconsistencia.estrategia_id,
                        operacao: inconsistencia.operacao,
                        ativo_sigla: inconsistencia.ativo.nome,
                        cor_ativo: inconsistencia.cor_hexadecimal,
                    }
                    
                    this.inconsistencias.push(objeto);
                }
           },
            error => { },
           () => {               
           });
    }

    update(id, atualizacao){

        this._service.update(id, atualizacao).subscribe(
            data => {
            },
            error => {               
                this.showError(error.message);
            },
            () => {

            }
        );
    }

    insert(venda, qtd, tipo){        
        
        if(tipo == 'C'){            
            venda.operacao = 'C';
        }
        
        const insert = {
            cliente_id: this.data,
            ativo_id: venda.ativo_id,
            preco: parseFloat(venda.preco),
            qtd: qtd,
            operacao: venda.operacao,
            estrategia: venda.estrategia,
            estrategia_id: venda.estrategia_id,
            status: 'NOK',
            corretagem: venda.corretagem,
            data_operacao: venda.data,
        }

        this._service.create(insert).subscribe(
            data => {
                if(tipo == 'V'){
                    this.buscaIncosistencia([1,2,5,6,14,15]);
                }else{
                    this.loadingAtivo = true;
                    this.openLoading();
                    this.buscar();
                }
            },
            error => {
                this.showError(error.message);
            },
            () => {                   
            }
        );
    }

    chosenYearHandler(normalizedYear: Moment, campo) {       
        if(campo == 'para'){
            const ctrlValue = this.date_para.value;
            ctrlValue.year(normalizedYear.year());
            this.date_para.setValue(ctrlValue);
        }else{
            const ctrlValue = this.date_de.value;
            ctrlValue.year(normalizedYear.year());
            this.date_de.setValue(ctrlValue);
        }
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, campo) {
        if(campo == 'para'){
            const ctrlValue = this.date_para.value;
            ctrlValue.month(normalizedMonth.month());
            this.date_para.setValue(ctrlValue);
        }else{
            const ctrlValue = this.date_de.value;
            ctrlValue.month(normalizedMonth.month());
            this.date_de.setValue(ctrlValue);
        }        
        datepicker.close();
    }

    getCliente(){
        this._clienteService.getById(this.data).subscribe(
        cliente => {
            this.valor_investimento = parseFloat(cliente.data.valor_investimento);
            this.vlr_boleta_fo = parseFloat(cliente.data.vlr_boleta_fo);
            this.vlr_boleta_st = parseFloat(cliente.data.vlr_boleta_st);
        },
        error => {
            if(error.status == 401){                    
                this._authService.logout();
                this.route.navigate(['/auth/login']);
            }            
        },
        () => { });
    }

    addVenda(item): void{        
      
        this.vendas.forEach(objeto => {
            if(!objeto.disabled){
                if(objeto.posicao == item.posicao){
                    if(objeto.selecionado){
                        objeto.selecionado = false;
                        objeto.texto = 'Clique aqui para adicionar uma venda';
                        this.venda_selecionada = '';
                    }else{
                        this.venda_selecionada = objeto;
                        objeto.selecionado = true;
                        objeto.texto = 'Selecione uma operação da lista de incosistência';
                        this.compra_selecionada = this.compras.find(objeto=> objeto.posicao == this.venda_selecionada.posicao);     
                    }
                }else{
                    objeto.selecionado = false;
                }
            }
        });

        if(item.disabled && item.id != null && item.id != ''){
            this.removeAssociacao(item);
        }
          
    }

    addIncosistencia(item){
        this.incosistencia_selecionada = item;
        if(this.compra_selecionada != undefined && this.compra_selecionada != ''){                        
            const data_venda =  new Date((new Date(item.data)).setHours(0, 0, 0, 0));
            const data_compra = new Date((new Date(this.compra_selecionada.data)).setHours(0, 0, 0, 0));

            if(item.ativo_sigla != this.compra_selecionada.ativo_sigla){
                this.showError(`O ativo selecionado da Venda (${item.ativo_sigla}) não corresponde com o ativo da compra (${this.compra_selecionada.ativo_sigla})`);
                return false;
            }if(item.estrategia_id != this.compra_selecionada.estrategia_id){
                this.showError(`A estrategia selecionada do ativo de Venda (${item.ativo_sigla}) não corresponde com a estrategia do ativo da compra (${this.compra_selecionada.ativo_sigla})`);
                return false;
            }else if(data_venda < data_compra){
                this.showError(`A data de Venda (${data_venda.toLocaleDateString()}) não pode ser antes da data da compra (${data_compra.toLocaleDateString()}) do ativo `);
                return false;
            }
            
            this.addObjeto(item);
           

        }else{
            this.showError('Selecione uma compra primeiro');            
        }
    }

    addObjeto(item){

        var total = 0;

        if(item.qtd > this.compra_selecionada.qtd) 
        {
            const qtd = item.qtd - this.compra_selecionada.qtd;            
            this.insert(item, qtd, 'V');
        }else if(item.qtd < this.compra_selecionada.qtd){
            const qtd = this.compra_selecionada.qtd - item.qtd;
            this.insert(this.compra_selecionada, qtd, 'C');
            this.update(this.compra_selecionada.id, {qtd: item.qtd});           
        }
        
        const valor = item.qtd > this.compra_selecionada.qtd ? this.compra_selecionada.qtd * item.preco : item.qtd * item.preco ;        
       
        this.vendas.forEach( objeto => {
            if(objeto.posicao == this.compra_selecionada.posicao)
            {
                objeto.id = item.id;
                objeto.data = item.data,
                objeto.ativo = item.ativo,
                objeto.ativo_sigla = item.ativo_sigla,
                objeto.ativo_id = item.ativo_id,
                objeto.qtd =  item.qtd > this.compra_selecionada.qtd ? this.compra_selecionada.qtd : item.qtd,
                objeto.preco = item.preco,
                objeto.estrategia = item.estrategia,
                objeto.operacao = item.operacao,
                objeto.valor = valor,
                objeto.status = 'OK',                        
                objeto.disabled = true,
                objeto.selecionado= false,
                objeto.texto = ''
            }                
        });

        this.compras.forEach(objeto =>{
            if(objeto.posicao == this.compra_selecionada.posicao){
                objeto.status = 'OK';
            }
            return true;
        })

        const newUpdate = {
            status: 'OK',
            compra_saida_id: item.id,
            result_value: (valor - this.compra_selecionada.valor),
            result_percent: (this.compra_selecionada.preco > 0 ? (((valor - this.compra_selecionada.valor) / this.compra_selecionada.valor - 1) * 100) + 100 : 100)
        }                        

        this.update(this.compra_selecionada.id, newUpdate);
        
        const dadosVenda = {
            status: 'OK',
            compra_saida_id: this.compra_selecionada.id,
            qtd: item.qtd > this.compra_selecionada.qtd ? this.compra_selecionada.qtd : item.qtd
        }
        
        this.update(item.id, dadosVenda);        

        this.totalEstrategias = [];

        this.resultadosFop.forEach(resultado => {
            if(resultado.posicao == this.compra_selecionada.posicao) {
                resultado.valor = valor - this.compra_selecionada.valor;
                if(this.compra_selecionada.valor > 0) {
                    resultado.percentual = this.compra_selecionada.preco > 0 ? (((valor - this.compra_selecionada.valor) / this.compra_selecionada.valor - 1) * 100) + 100 : 100;
                }else{
                    resultado.percentual = 100;
                }
            }   
            
            total += resultado.valor;     
            
            if(this.totalEstrategias[resultado.estrategia_id] === undefined){                        
                this.totalEstrategias[resultado.estrategia_id] = resultado.valor;
            }else{                            
                this.totalEstrategias[resultado.estrategia_id] += resultado.valor;
            }
        })  
        
        this.inconsistencias = this.inconsistencias.filter((value, key)=>{
            return value.id != item.id;
        });

        this.totalGeral = this.totalEstrategias
            .map(gerador => gerador)
            .reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);

        this.compra_selecionada = '';
        this.incosistencia_selecionada = '';
    }

    selecionaOpcao(opcao){
        this.opcao_selecionada = opcao;

        this.premioList.forEach(objeto => {
            if(!objeto.disabled){
                if(objeto.posicao == opcao.posicao){
                    if(objeto.selecionado){
                        objeto.selecionado = false;
                        objeto.texto = '';
                        this.opcao_selecionada = '';               
                        this.compra_selecionada = '';               
                    }else{                        
                        objeto.selecionado = true;
                        objeto.texto = 'Clique no ticker da opção';
                        this.opcao_selecionada = opcao;
                        this.compra_selecionada = this.compras.find(objeto=> objeto.posicao == opcao.posicao);
                    }
                }else{
                    objeto.selecionado = false;
                    objeto.texto = '';
                }
            }
            
        });

        if(opcao.disabled && opcao.id != null && opcao.id != ''){
            this.removerOpcao(opcao);
        }
    }
    
    addPremio(premio){
        
        if(this.opcao_selecionada != undefined && this.opcao_selecionada != ''){
            if(premio.ativo_sigla != this.compra_selecionada.ativo_sigla){
                this.showError(`A Opção selecionada (${premio.ativo_sigla}) não corresponde com o ativo (${this.compra_selecionada.ativo_sigla})`);
                return false;
            }else if(premio.qtd != this.compra_selecionada.qtd){
                this.showError(`A quantidade da Opção (${premio.qtd}) não pode ser diferente da compra (${this.compra_selecionada.qtd}) do ativo `);
                return false;
            }

            this.associaOpcaoCompra(premio);            

        }else{
            this.showError('Selecione o espaço ao lado da operação de compra primeiro');
        }
    }

    associaOpcaoCompra(item){

        this.premioList.forEach(objeto => {

            if(objeto.posicao == this.opcao_selecionada.posicao){
                objeto.id = item.id;
                objeto.ativo = item.ativo;
                objeto.status = 'OK';
                objeto.disabled = true;
            }
        });

        this.opcoes.forEach(objeto => {
            if(objeto.posicao == item.posicao){                
                objeto.status = 'OK';
            }
        });

        if(item.ativo_sigla != null){
            const newOpcao = {
                opcao_id: item.id,
            }
            
            this.update(this.compra_selecionada.id, newOpcao);
            this.update(item.id, {opcao_id: this.compra_selecionada.id, status: 'OK'});
        }

        this.resultadosFop.forEach(resultado => {
            if(resultado.posicao == this.compra_selecionada.posicao){                

                if(this.totalEstrategias[resultado.estrategia_id] === undefined){                        
                    this.totalEstrategias[resultado.estrategia_id] = resultado.valor;
                }else{                            
                    this.totalEstrategias[resultado.estrategia_id] += resultado.valor;
                }

                if(resultado.valor != 0 && resultado.valor != null ){
                    resultado.valor = (resultado.valor + item.valor);
                    resultado.percentual = (((resultado.valor + item.valor) / this.compra_selecionada.valor - 1) * 100) + 100;                   
                }else{
                    resultado.valor = item.valor;
                    resultado.percentual = ((item.valor / this.compra_selecionada.valor - 1) * 100) + 100 ;                
                } 
            }           
        });

        this.totalGeral = this.totalEstrategias
            .map(gerador => gerador)
            .reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);

        this.opcao_selecionada = '';
        this.compra_selecionada = '';
       
    }

    removeAssociacao(item){
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            data: {
                title: "Desassociar Venda",
                text: "Deseja desassociar a venda da compra ?",
                callback: function () {
                    const compra = lista.compras.find(objeto=> objeto.posicao == item.posicao);
                    lista.update(compra.id, {compra_saida_id: null, status: 'NOK', result_value: 0, result_percent: 0});
                    lista.update(item.id, {compra_saida_id: null, status: 'NOK'});
                    lista.showSuccess('Atualização realizada com sucesso!');  

                    lista.compras.forEach(objeto => {
                        if(objeto.posicao == item.posicao){
                            objeto.status = 'NOK';
                        }
                    });

                    lista.resultadosFop.forEach(objeto => {
                        
                        if(objeto.posicao == item.posicao){
                            
                            const premio = lista.premioList.find( search =>  search.posicao == compra.posicao);

                            if(premio.id != null){     
                                lista.total_fop = lista.total_fop - ( item.valor - compra.valor );
                            }else{        
                                lista.total_swt = lista.total_swt - ( item.valor - compra.valor );;
                            }

                            objeto.valor = objeto.valor - ( item.valor - compra.valor );
                            objeto.percentual = 0;

                            if(lista.totalEstrategias[objeto.estrategia_id] != undefined){
                                lista.totalEstrategias[objeto.estrategia_id] -= (item.valor - compra.valor);
                            }
                        }
                    });  

                    lista.vendas.forEach(objeto => {
                        if(objeto.posicao == item.posicao){
                            objeto.status = 'NOK';
                            objeto.id = null;
                            objeto.data = null;
                            objeto.ativo = null;
                            objeto.qtd = null;
                            objeto.preco = null;
                            objeto.valor = null;
                            objeto.status = null;
                            objeto.ativo_sigla = null;
                            objeto.selecionado = false;
                            objeto.disabled = false;
                            objeto.texto = 'Clique aqui para adicionar uma venda';
                        }           
                    });

                    lista.buscaIncosistencia([1,2,5,6,14,15]);

                    lista.totalGeral = lista.totalEstrategias
                        .map(gerador => gerador)
                        .reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
                }
            }
        });   
        
       
    }

    removerOpcao(item){
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            data: {
                title: "Desassociar a Opção",
                text: "Deseja desassociar a opção da compra ?",
                callback: function () {
                    const compra = lista.compras.find(objeto=> objeto.posicao == item.posicao);

                    lista.update(compra.id, {opcao_id: null});
                    lista.update(item.id, {status: 'NOK'});
                    lista.showSuccess('Atualização realizada com sucesso!');  

                    lista.opcoes.forEach(objeto => {                       
                        if(objeto.ativo == item.ativo){
                            objeto.status = '';
                        }
                    });

                    lista.resultadosFop.forEach(objeto => {
                        if(objeto.posicao == compra.posicao){
                            objeto.valor = objeto.valor - item.valor;
                        }
                    }); 

                    lista.premioList.forEach(objeto => {
                        if(objeto.posicao == item.posicao){
                            objeto.id = null;
                            objeto.ativo = null;
                            objeto.opcao = null;
                            objeto.preco = null;
                            objeto.ativo_sigla = null;
                            objeto.disabled = false;
                            objeto.valor = null;
                            objeto.selecionado = false;
                            objeto.status = '';
                            objeto.texto = '';
                        }           
                    });
                }
            }
        });
    }
}
