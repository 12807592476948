import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class EstadoCivilService {

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * View
     * @param id ID
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "estado-civil/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "estado-civil"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Profissão do Cliente Para Campo Do Cadastro
     */
    getLista(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "estado-civil/lista", { headers: this.authService.getAuthHeaders() });
    }    

    /**
     * Delete
     * @param id ID
     */
    delete(id): Observable<any> {        
        return this._http.delete(this.utilitesService.urlPrefix + "estado-civil/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra
     * @param params Json
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "estado-civil", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza
     * @param params Json
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "estado-civil/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

}