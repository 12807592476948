import {Component, ViewChild, TemplateRef, Input, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {BaseComponent, ActionDialog} from '../../base-module/base-component/base.component';
import { CadastroProdutoRendafixaComponent } from '../cadastro-produto-rendafixa/cadastro-produto-rendafixa.component';
import { ProdutoRendaFixaService } from '../services/produto-rendafixa.service';

@Component({
    selector: 'app-lista-produto',
    templateUrl: './lista-produto.component.html',
    styleUrls: ['./lista-produto.component.scss']
})

export class ListaProdutoComponent extends BaseComponent implements OnInit{

    @Input() params: any ;
    loadingIndicator: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaProdutoComponent, { static: false }) table: ListaProdutoComponent;

    constructor(public service: ProdutoRendaFixaService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar) {
        super(dialog, snackBar);
    }

    ngOnInit() {
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex + 1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll(): void {
        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];

            },
            error => {               
                this.loadingIndicator = false;
            },
            () => {               
                this.loadingIndicator = false;
            }
        );
    }
    

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Produto de Renda Fixa",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();
                    
                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.closeLoading();
                            lista.showError(error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }
    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastroProdutoRendafixaComponent, {
            width: '600px',
            data: { id: id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Produto atualizado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

    getEmissor(row){
        if(row.orgao_emissor != undefined){
            return row.orgao_emissor.nome;
        }

        return 'Sem Emissor Vinculado';
    }

    ativeAndInative(id){
        var lista = this;
        let message = ''; 
        var parametros = {
            inative: null
        };

        if(this.params.inative){
            parametros.inative = false;
            message = 'Inativar Produto de Renda Fixa'
        }else{
            parametros.inative = true;
            message = 'Ativar Produto de Renda Fixa'
        }

        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-edit',
            data: {
                title: message,
                text: "Deseja realmente atualizar o status este item?",
                callback: function () {
                    lista.dialog.closeAll();                    
                    lista.loadingIndicator = true;
                    lista.service.ativeOrInative(id, parametros).subscribe(
                        data => {
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.closeLoading();                           
                            lista.showError(error.message);
                            lista.loadingIndicator = false;
                        },
                        () => {    }
                    );
                }
            }
        });
    }

}