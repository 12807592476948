import { Injectable } from '@angular/core';
import Echo from "laravel-echo"
import { AuthService } from 'app/base-module/base-service/auth.service';
import * as io from 'socket.io-client';
import {environment} from '../../../../environments/environment';
import { ChatService } from '../chat.service';
import { SwPush } from '@angular/service-worker';

@Injectable()
export class SocketService {
    echo: Echo = null;
    user:any;
    socket;

    readonly VAPID_PUBLIC_KEY = 'BAyfbJL4qBeB5ykwayUrf25odTySqEePOq6pCaS6Th4bGNBhPNLTDxSNC97aGKsvNNuS6OzS-O5PzcKCrx38FUo';

    constructor(public _authService: AuthService,
                private _chatService: ChatService,
                private swPush: SwPush) {
        this.user = JSON.parse(this._authService.getUser());
    }

    setupSocketConnection() {        
        this.socket = io(environment.SOCKET_ENDPOINT,{
            query: {
                token: environment.SOCKET_TOKEN
              }
        });
        
        if( this.user != undefined && this.user != '')
        {
            if(this.user.usuario_chat != undefined && this.user.usuario_chat != ''){
                this.socket.on(`channel.messages.${this.user.usuario_chat.usuario_id}`, (data: string) => {
                    this.msgChat(data);
                });
            }

            this.socket.on(`channel.notification.${this.user.id}`, (data: string) => {
                this.notificacao(data['title'], data['body']);                
            });
        }
    }

    msgChat(e)
    {
        const titulo = "Chat da Plataforma";
        const msg = 'Você recebeu uma nova mensagem!';
        if(this.user.usuario_chat.usuario_id == e.contactId)
        {
            if(this._chatService.onChatSelectedNow != undefined && this._chatService.onChatSelectedNow != null)
            {
                if (this._chatService.onChatSelectedNow.chatId == e.conversaId){                    
                    this._chatService.getChat(e.user);
                }else{
                    this.atualizaNotificacao(e);                    
                    this.notificacao(titulo, msg);
                }
            }else{                
                this.atualizaNotificacao(e);
                this.notificacao(titulo,msg);
            }

            this._chatService.getUser().then( usuario => {               
                this._chatService.user = usuario;
            });
        }
    }   

    atualizaNotificacao(e){
        this._chatService.atualizaNotificacao(e.conversaId,[]).subscribe(
            data => {
                this._chatService.atualizaUsuario();                  
            },
            error => {
              console.log(error);
            },
            () => {
              
            }
        );
    }

    notificacao(titulo,msg){

        new Notification(titulo, {
            body: msg,
            icon: 'assets/images/logos/logo_nova.png',
        })

    }

    subscribeToNotifications(titulo, mensagem) {
        
        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
        .then(sub => {           

            const title = titulo;
            const options = {
                body: mensagem,
                icon: 'assets/images/logos/ico-72.png'                
            }            

            var n = new Notification(title, options);
        })
        .catch(err => console.error("Could not subscribe to notifications", err));
    } 

}