import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

export interface DialogData {
    id: number;
    tipo: string;
}

@Component({
    selector: 'app-filtro-lista-disponivel',
    templateUrl: './filtro-lista-disponivel.component.html',
    styleUrls: ['./filtro-lista-disponivel.component.scss'],
    providers: [
        ClienteService, 
        EscritorioService
    ]
})

export class FiltroListaDisponivelComponent extends BaseComponent {
    
    cliente                 = new FormControl('');
    ativo                   = new FormControl('');
    assessor_id             = new FormControl('');
    escritorio_id           = new FormControl('');
    percentual              = new FormControl('');
    tipo_percentual         = new FormControl('MA');

    model:any = {
        escritorio_id: '',
        tipo_custodia: '',
        tipo_cliente: ''
    }

    loadingSpinner: boolean;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerEscritorio:boolean = false;

    filteredOptions: Observable<string[]>;
    filteredOptionsAtivo: Observable<string[]>;
    listAssessor: any[] = [];
    listEscritorios: any[] = [];
    listAtivos: Object[] = [];
    usuario:any;
    titulo:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltroListaDisponivelComponent, { static: false }) table: FiltroListaDisponivelComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltroListaDisponivelComponent>,
        private _clienteService: ClienteService,        
        private usuarioService: UsuarioService,
        private _authService: AuthService,
        public escritorioService: EscritorioService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        ); 
        
        this.getAllEscritorios();
        this.getAssessores();

        if(this.data.tipo == 'incosistencia'){
            this.titulo = "Filtrar Clientes com Inconsistências";
        }else{
            this.titulo = "Filtrar Clientes que estão disponiveis para investir";
        }

    }

    displayFn(cliente): void {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente): void{
        this.loadingSpinner  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    filtrar(): void {
        
        this.dialogRef.close({
            cliente: this.cliente.value,
            escritorio_id: this.escritorio_id.value,
            assessor_id: this.assessor_id.value,
            percentual: this.percentual.value,
            tipo_percentual: this.tipo_percentual.value,
        });
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }

    
    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                console.log("complete");
            });
    }

}