import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { BaseComponent, ActionDialog } from "../../../base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BoletaCoeService } from 'app/renda-fixa/services/boleta-coe.service';
import { CadastroProdutoComponent } from '../cadastro-produto/cadastro-produto.component';
import { CadastroBoletaCoeComponent } from 'app/renda-fixa/cadastro-boleta-coe/cadastro-boleta-coe.component';
import { ClienteService } from 'app/clientes/services/cliente.service';


@Component({
    selector: 'app-cliente-coe',
    templateUrl: './coe.component.html',
    styleUrls: ['./coe.component.scss'],
    providers: [BoletaCoeService]
})

export class CoeComponent extends BaseComponent implements OnInit{

    params: any = {};
    user: any;    
    loadingIndicator: boolean;
    data_atualizacao: any;

    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 30];
    
    @Input() cliente_id: number;

    constructor(public _service: BoletaCoeService, 
                public dialog: MatDialog,
                private _serviceCliente: ClienteService,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.user = JSON.parse(this._authService.getUser());
        this.params.per_page = this.pageSize;
    }

    ngOnInit(){
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent){

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }
  

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator = true;
        this.params.cliente_id = this.cliente_id;
        this.params.historico = true;

        this._service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }
    
    openCadastro(){
        let dialogRef = this.dialog.open(CadastroBoletaCoeComponent, {
            width: '80%',
            data: {
                acao_comercial_id: null,
                cliente_id: this.cliente_id,
                id: null,
                historico: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Boleta COE cadastrada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um nova boleta COE.");
            }
        });        
    } 
   

    alterar(id): void {
        let dialogRef = this.dialog.open(CadastroProdutoComponent, {
            width: '400px',            
            data: { 
                id: id,
                titulo: 'Alterar Boleta COE',
                service: this._service
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("COE atualizado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registro");
            }
        });
    }

    
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir COE",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }   

    getVerificado(row){
       if(row.verificado_migracao){
            return 'SIM';
       }else{
            return 'NÃO';
       }    
    }  
    
    gerarStvm(){
        this.openLoading();
        this.params.tipo = 'COE';
        this._serviceCliente.gerarStvm(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },
            error => {                          
                this.showError('Não foi possivel gerar a STVM, por favor verifique o CPF, Endereço e se a(s) custódia(s) esta(ão) verificada(s)');                
                this.closeLoading();
            },
            () => {
                console.log("complete");
                this.closeLoading();
            }
        );
    }

}
