import {
    Component, 
    Output,
    EventEmitter 
}  from '@angular/core';
import {
    MatDialog, 
    MatSnackBar, 
    PageEvent 
}  from '@angular/material';
import {SelectionType} from '@swimlane/ngx-datatable';

import { ActionDialog } from '../../../base-module/base-component/base.component';
import { BaseComponent } from "../../../base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FiltraBasketComponent } from '../../filtra-basket/filtra-basket.component';
import { BoletaPendenteService } from '../../services/boleta-pendente.service';
import { CadastroBoletaPendenteComponent } from '../cadastro-boleta-pendente/cadastro-boleta-pendente.component';

@Component({
    selector: 'lista-boleta-pendente',
    templateUrl: './lista-boleta-pendente.component.html',
    styleUrls: ['./lista-boleta-pendente.component.scss'],
    providers: [ BoletaPendenteService ]
})

export class ListaBoletaPendenteComponent extends BaseComponent {

    params: any = {};
    user: any;
    loadingIndicator: boolean;   
    SelectionType = SelectionType;

    selected = [];
    data: any = {};
    titulo: string;

    @Output() quantidadeBoletas = new EventEmitter();

    constructor(public service: BoletaPendenteService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar,                 
                private _authService: AuthService) 
    {
        
        super(dialog, snackBar);        
        this.user = JSON.parse(this._authService.getUser());      
        this.getAll();

    }
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any): void{

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {        

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {                
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];

                this.quantidadeBoletas.emit(this.length);
            },
            error => {               
                this.loadingIndicator = false;
            },
            () => {               
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de boleta
     * @param id
     */
    delete(boleta) {

        var lista = this;

        const valorBoleta = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(boleta.valor);
        
        this.dialog.open(ActionDialog, {
            width: '400px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Boleta",
                text: `Deseja realmente excluir a boleta do cliente ${boleta.cliente.nome} no valor de ${valorBoleta} ?`,
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(boleta.id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);
                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }
    /**
     * 
     */
    openCadastro(boleta): void {
        let dialogRef = this.dialog.open(CadastroBoletaPendenteComponent, {
            maxWidth: '99vh',
            minWidth: '600px',
            maxHeight: '99vh',            
            data: {
                boleta
            }      
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){                
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar a custódia dos clientes.");
            }
        });
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltraBasketComponent, {
            width: '700px',            
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                if (result.tipo_basket_id != undefined && result.tipo_basket_id != null){
                    this.params.tipo_basket_id = result.tipo_basket_id;
                }

                if (result.nome_arquivo != undefined && result.nome_arquivo != null){
                    this.params.nome_arquivo = result.nome_arquivo;
                }

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_fim = result.data_operacao_fim;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }   

}
