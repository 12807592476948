import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

export interface DialogData {
    id: number;
} 

@Component({
    selector: 'dialog-filtrar-aporte',
    templateUrl: './filtrar-aporte.component.html',
    styleUrls: ['./filtrar-aporte.component.scss'],
    providers: [ 
        EscritorioService
    ]
})
export class FiltrarAporteComponent extends BaseComponent {

    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

    cliente = new FormControl('');
    assessor_id = new FormControl('');
    escritorio_id = new FormControl('');

    data_inicio_operacao = new FormControl(this.primeiroDia);
    data_fim_operacao = new FormControl(this.ultimoDia);
    
    paramsStatus: any = {
        finalizada: ""
    }

    model:any = {
        escritorio_id: '',
    }

    loadingSpinner: boolean;
    loadingSpinnerStatus: boolean;
    loadingSpinnerAssessor: boolean;
    filteredOptions: Observable<string[]>;
    listAssessor: any[] = [];
    listEscritorios: any[] = [];    
    loadingSpinnerEscritorio:boolean = false;
    usuario:any;


    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltrarAporteComponent, { static: false }) table: FiltrarAporteComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltrarAporteComponent>,
        private _clienteService: ClienteService,        
        private _utilitesService: UtilitesService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private escritorioService: EscritorioService,
        private usuarioService: UsuarioService,
        private _authService: AuthService) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        );

        this.getAllEscritorios();
        this.getAssessores();

    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(){
        this.dialogRef.close({
            'cliente': this.cliente.value,
            'escritorio_id': this.escritorio_id.value,
            'assessor_id': this.assessor_id.value,
            'data_inicio_operacao': this._utilitesService.formatDateToString(new Date(this.data_inicio_operacao.value)),
            'data_fim_operacao': this._utilitesService.formatDateToString(new Date(this.data_fim_operacao.value))
        });
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }

    
    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                console.log("complete");
            });
    }



}