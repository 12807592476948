import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class BalanceAvailableService {

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }  

    /**
     *
     * @param params
     * @returns {Observable<any>}
     */
    getBalance(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"dashboard/saldo-disponivel"+env,{headers: this.authService.getAuthHeaders()});
    }

}
