import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseHighlightModule } from '@fuse/components';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';

import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatCardModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
} from '@angular/material';


import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { HomeRoutesModule } from './home.routing';

import { CentralNoticiasModule } from 'app/central-noticias/central-noticias.module';

import { ListaAtividadesModule } from 'app/lista-atividades/lista-atividades.module';
import { AgendaComponentComponent } from './agenda-component/agenda-component.component';
import { HomeComponent } from './home/home.component';
import { ListaAtividadesComponent } from './lista-atividades/lista-atividades.component';
import { NoticiaComponentComponent } from './noticia-component/noticia-component.component';
import { NotificacaoComponentComponent } from './notificacao-component/notificacao-component.component';
import { ReceitaProjetadaComponentComponent } from './receita-projetada-component/receita-projetada-component.component';
import { SaldoDisponivelComponentComponent } from './saldo-disponivel-component/saldo-disponivel-component.component';
import { FiltroListaDisponivelComponent } from './saldo-disponivel/filtro-lista-disponivel/filtro-lista-disponivel.component';
import { ListaDisponivelComponent } from './saldo-disponivel/lista-disponivel/lista-disponivel.component';



@NgModule({
    declarations: [    
        HomeComponent, 
        AgendaComponentComponent, 
        NotificacaoComponentComponent, 
        NoticiaComponentComponent, 
        SaldoDisponivelComponentComponent,
        ListaDisponivelComponent,
        FiltroListaDisponivelComponent,
        ReceitaProjetadaComponentComponent,
        ListaAtividadesComponent
    ],
    entryComponents: [
        FiltroListaDisponivelComponent
     ],
    imports: [
        HomeRoutesModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        ChartsModule,
        NgxChartsModule,        
        FuseSidebarModule,
        FuseWidgetModule,
        FuseSharedModule,
        FuseHighlightModule,
        NgxDatatableModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatBadgeModule,
        MatCardModule,
        CentralNoticiasModule,
        MatTooltipModule,
        ListaAtividadesModule
    ],
    exports: [ 
        FiltroListaDisponivelComponent
    ],
    providers: [ ]
})

export class HomeModule {}
