import {Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

export interface Midia {
    id: number;
    caminho: string;
    caminho_thumbnail: string;
    noticia_id: number;
    deleted: boolean;
    created_at: Date; 
    updated_at: Date;
}

export enum TipoMidia {
    Imagem = 1,
    Video = 2,
    Download = 3
}

@Injectable({
    providedIn: 'root'
})
export class MidiaService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    public readonly CENTRAL_NOTICIAS_MIDIA_PATH = this.utilitesService.urlPrefix + "central-noticias/midia";

     
    /**
     * Delete Basket
     * @param id ID do Basket
     */
    delete(id): Observable<any> {
        return this._http.delete(this.CENTRAL_NOTICIAS_MIDIA_PATH+'/'+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Basket
     * @param params Json Basket
     */
    create(params):Observable<any>{
        return this._http.post(this.CENTRAL_NOTICIAS_MIDIA_PATH, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * AtualizaBasket
     * @param params Json Basket
     */
    update(id, params):Observable<any>{
        return this._http.put(this.CENTRAL_NOTICIAS_MIDIA_PATH+'/'+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Upload de arquivo
     * @param file 
     */
    upload(file):Observable<any>{
        const formData = new FormData();
        formData.append('arquivo', file);
        return this._http.post(this.CENTRAL_NOTICIAS_MIDIA_PATH, formData, { headers: this.authService.getAuthHeaders() });
    }

}
