import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {LoadingTeste, AlertDialog, ActionDialog} from '../base-module/base-component/base.component';
import {BaseComponent} from "./base-component/base.component";
import { AuthService } from './base-service/auth.service';
import { EnderecoService } from './base-service/endereco.service';
import { PasswordService } from './base-service/password.service';
import { UsuarioService } from './base-service/usuario.service';
import { UtilitesService } from './base-service/utilites.service';
import { AuthGuardService } from './base-service/auth-guard.service';
import { BaseInterceptor } from './base-provider/base.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        //RouterModule.forChild(EmpresasRoutes),
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule
    ],
    declarations: [
        LoadingTeste,
        AlertDialog,
        ActionDialog,
        BaseComponent
    ],
    entryComponents: [
        LoadingTeste,
        AlertDialog,
        ActionDialog
    ],
    providers: [
        AuthService,
        EnderecoService,
        PasswordService,
        UsuarioService,
        UtilitesService,
        AuthGuardService,
        BaseInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseInterceptor,
            multi: true,
        },
    ],
    exports: [
        LoadingTeste,
        AlertDialog,
        ActionDialog
    ]
})

export class BaseModule {
}
