import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { Observable } from 'rxjs';
import { RolesService } from '../services/roles.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';

export interface DialogData {
    id: number;
    role_id: number;
}

@Component({
    selector: 'dialog-ffiltrar-usuarios',
    templateUrl: './filtrar-usuarios.component.html',
    styleUrls: ['./filtrar-usuarios.component.scss'],
    providers: [ 
        EscritorioService
    ]
})
export class FiltrarUsuariosComponent extends BaseComponent {
    
    name = new FormControl('');
    escritorio_id = new FormControl('');
    role_id = new FormControl('');
    loadingSpinner: boolean;
    loadingRoleSpinner: boolean;
    loadingEscritorioSpinner: boolean;
    filteredOptions: Observable<string[]>;
    listRoles: Observable<string[]>;
    listEscritorios: Observable<string[]>;
    exibeRoles:boolean = true;


    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltrarUsuariosComponent, { static: false }) table: FiltrarUsuariosComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltrarUsuariosComponent>,
        public _roleService: RolesService,
        public _escritorioService: EscritorioService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);

        if (this.data != undefined && this.data != null && this.data.role_id != null && this.data.role_id != undefined){
            this.exibeRoles = false;
        }

        this.getAllRoles();
        this.getAllEscritorios();

    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    getAllRoles() {
        this.loadingRoleSpinner = true;
        this._roleService.getAll({no_paginate:true}).subscribe(
            list => {
                
                this.listRoles = list.data;

            },
            error => {
                this.loadingRoleSpinner = false;
            },
            () => {
                this.loadingRoleSpinner = false;
            });
    }

    getAllEscritorios() {
        this.loadingEscritorioSpinner = true;
        this._escritorioService.getAll({no_paginate: true}).subscribe(
            list => {

                this.listEscritorios = list.data;

            },
            error => {
                this.loadingEscritorioSpinner = false;
            },
            () => {
                this.loadingEscritorioSpinner = false;
            });
    }

    filtrar(){
        this.dialogRef.close({
            'name': this.name.value,
            'role_id': this.role_id.value,
            'escritorio_id': this.escritorio_id.value
        });
    }



}