import {
    Component, 
    ViewChild,      
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import {BaseComponent, LoadingTeste} from "../../../base-module/base-component/base.component";
import {AuthService} from "../../../base-module/base-service/auth.service";
import { PainelService } from 'app/renda-variavel/services/painel.service';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';

export interface DialogData {
    id: number | null;
}

@Component({
    selector: 'cadastra-item-painel',
    templateUrl: './cadastra-item-painel.component.html',
    styleUrls: ['./cadastra-item-painel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ 
        PainelService, 
        AtivoService, 
        EstrategiaService 
        ]
})

export class CadastraItemPainelComponent extends BaseComponent {
    
    data_inicio = new FormControl('', [Validators.required]);
    estrategia_id = new FormControl('', [Validators.required]);
    ativo = new FormControl('', [Validators.required]);
    rv_clientes_qtd_enviados = new FormControl('', [Validators.required]);
    rv_clientes_qtd_efetivados = new FormControl('', [Validators.required]);
    rv_volume_enviado = new FormControl('', [Validators.required]);
    rv_volume_efetivado = new FormControl('', [Validators.required]);
    rv_volatilidade = new FormControl('', [Validators.required]);
    rv_sharpe = new FormControl('', [Validators.required]);
    ativo_preco_compra = new FormControl('', [Validators.required]);
    ativo_preco_alvo_corretora = new FormControl('', [Validators.required]);
    ativo_preco_alvo_escritorio = new FormControl('', [Validators.required]);
    valor_protecao = new FormControl();
    valor_proventos = new FormControl();
    valor_premio = new FormControl();
    qtd_dias_trade = new FormControl();
    data_fim = new FormControl();
    valor_perfomance = new FormControl();

    model:any = {
        nome:"",
    };

    loadingLocal:any;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerEstrategia: boolean;
    filteredOptionsAtivo: Observable<string[]>;
    listEstrategia:any[]=[];

    @ViewChild(CadastraItemPainelComponent, { static: false }) table: CadastraItemPainelComponent;

    constructor(public service: PainelService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastraItemPainelComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _ativoService: AtivoService,
                private _serviceEstrategia: EstrategiaService,
                private _utilitesService: UtilitesService )
    {
        super(dialog, snackBar);
        if(this.data != null && this.data != undefined){
            this.getById();
        }

        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;                    
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );

        this.getAllEstrategias();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    isInvalidForm(){
        if (!this.data_inicio.valid){
            return true;
        }

        if (!this.estrategia_id.valid){
            return true;
        }

        if (!this.rv_clientes_qtd_enviados.valid){
            return true;
        }

        if (!this.rv_clientes_qtd_efetivados.valid){
            return true;
        }

        if (!this.rv_volume_enviado.valid){
            return true;
        }

        if (!this.rv_volume_efetivado.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();

        const ativoSelected = this.ativo.value;     
        this.model.data_inicio = this._utilitesService.formatDateToString(new Date(this.data_inicio.value));
        this.model.estrategia_id = this.estrategia_id.value;
        this.model.ativo_id = ativoSelected.id;    
        this.model.rv_clientes_qtd_enviados = this.rv_clientes_qtd_enviados.value;
        this.model.rv_clientes_qtd_efetivados = this.rv_clientes_qtd_efetivados.value;
        this.model.rv_volume_enviado = this.rv_volume_enviado.value;
        this.model.rv_volume_efetivado = this.rv_volume_efetivado.value;
        this.model.rv_volatilidade = this.rv_volatilidade.value;
        this.model.rv_sharpe = this.rv_sharpe.value;
        this.model.ativo_preco_compra = this.ativo_preco_compra.value;
        this.model.ativo_preco_alvo_corretora = this.ativo_preco_alvo_corretora.value;
        this.model.ativo_preco_alvo_escritorio = this.ativo_preco_alvo_escritorio.value;
        this.model.valor_protecao = this.valor_protecao.value;
        this.model.valor_proventos = this.valor_proventos.value;
        this.model.valor_premio = this.valor_premio.value;
        this.model.qtd_dias_trade = this.qtd_dias_trade.value;
        this.model.data_fim = this.data_fim.value != undefined ? this._utilitesService.formatDateToString(new Date(this.data_fim.value)) : null;
        this.model.valor_perfomance = this.valor_perfomance.value;

        if(this.data == null || this.data == undefined){

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                                                       
                },
                error => {                    
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorDataMessage() {
        return this.data_inicio.hasError('required') ? 'DATA é um campo obrigatório' : '';
    }

    getErrorNameMessage(campo){
        return this.data_inicio.hasError('required') ? 'Campo obrigatório' : '';
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item=>{
            this.data_inicio.setValue(item.data.data_inicio)
            this.estrategia_id.setValue(item.data.estrategia_id)
            this.ativo.setValue(item.data.ativo)
            this.rv_clientes_qtd_enviados.setValue(item.data.rv_clientes_qtd_enviados)
            this.rv_clientes_qtd_efetivados.setValue(item.data.rv_clientes_qtd_efetivados)
            this.rv_volume_enviado.setValue(item.data.rv_volume_enviado)
            this.rv_volume_efetivado.setValue(item.data.rv_volume_efetivado)
            this.rv_volatilidade.setValue(item.data.rv_volatilidade)
            this.rv_sharpe.setValue(item.data.rv_sharpe)
            this.ativo_preco_compra.setValue(item.data.ativo_preco_compra)
            this.ativo_preco_alvo_corretora.setValue(item.data.ativo_preco_alvo_corretora)
            this.ativo_preco_alvo_escritorio.setValue(item.data.ativo_preco_alvo_escritorio)
            this.valor_protecao.setValue(item.data.valor_protecao)
            this.valor_proventos.setValue(item.data.valor_proventos)
            this.valor_premio.setValue(item.data.valor_premio)
            this.qtd_dias_trade.setValue(item.data.qtd_dias_trade)
            this.data_fim.setValue(item.data.data_fim)
            this.valor_perfomance.setValue(item.data.valor_perfomance)
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getAll({is_visible_painel: true}).subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
            }
        );
    }

}
