import { Component, ViewEncapsulation, Inject} from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste } from "../../../base-module/base-component/base.component";
import { AuthService} from "../../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { StatusMigracaoService } from 'app/base-module/base-service/status-migracao.service';


export interface DialogData {
    id: number;
    service: any;
    titulo: string;
}

@Component({
    selector: 'app-cadastro-produto',
    templateUrl: './cadastro-produto.component.html',
    styleUrls: ['./cadastro-produto.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ StatusMigracaoService ]
})


export class CadastroProdutoComponent extends BaseComponent {

    status_migracacao_id = new FormControl('', [Validators.required]);
    verificado_migracao = new FormControl('', [Validators.required]);

    model:any = {
        status_migracacao_id: "",
        verificado_migracao: "",
    };

    loadingLocal:any;
    titulo:string;
    listStatus:any[]=[];
    loadingSpinnerStatus:boolean = false;

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                private _serviceStatus: StatusMigracaoService,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroProdutoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);

        this.getById();
        this.getAllStatus();
        this.titulo = this.data.titulo;
        
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        
        if (!this.status_migracacao_id.valid){
            return true;
        }

        if (!this.verificado_migracao.valid){
            return true;
        }

        return false;
    }

    salvar(){
        
        this.openLoading();
        
        this.model.status_migracacao_id    = this.status_migracacao_id.value;
        this.model.verificado_migracao    = this.verificado_migracao.value;

        this.data.service.update(this.data.id, this.model).subscribe(
            data => {
                this.closeLoading();
                this.dialogRef.close(data);
            },
            error => {
                this.closeLoading();
                this.showError(error.message);
            },
            () => {                    
                this.closeLoading();
            }
        );

    }

    
    /**
     * Consulta lista de empresas
     */
    getAllStatus() {

        this.loadingSpinnerStatus = false;

        this._serviceStatus.getAll({}).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
                this.loadingSpinnerStatus = false;
            },
            () => {
                this.loadingSpinnerStatus = false;
            }
        );
    }


    getById(){
        
        this.openLoading();

        this.data.service.getById(this.data.id).subscribe(
        item => {
            this.model = item.data;
            this.model.verificado_migracao = String(item.data.verificado_migracao);
            this.status_migracacao_id.setValue(item.data.status_migracacao_id);
            this.verificado_migracao.setValue( String(item.data.verificado_migracao));
            console.log(item.data.verificado_migracao);
        },
        error => {
            this.closeLoading();
            console.log(error);
        },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

}
