import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/base-module/base-service/auth-guard.service';
import { NgModule } from '@angular/core';
import { ListaAssuntoComponent } from './lista-assunto/lista-assunto.component';
import { ListaTodasAtividadesComponent } from './lista-todas-atividades/lista-todas-atividades.component';


const routes: Routes = [{
    path: '',
    children: [
        {
            path: 'lista-assunto',
            component: ListaAssuntoComponent
        },
        {
            path: 'atividades',
            component: ListaTodasAtividadesComponent
        },
    ],
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ListaAtividadesRoutesModule {}