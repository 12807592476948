import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { NotificacaoService } from '../services/notificacao.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-ver-notificacao',
    templateUrl: './ver-notificacao.component.html',
    styleUrls: ['./ver-notificacao.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class VerNotificacaoComponent extends BaseComponent {

    titulo: string;
    texto: any;

    loadingLocal:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(VerNotificacaoComponent, { static: false }) table: VerNotificacaoComponent;

    constructor(public service: NotificacaoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<VerNotificacaoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData )
    {
        super(dialog, snackBar);
        if(this.data != null && this.data != undefined){
            this.getById();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");      
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null) {
            this.loadingLocal.close();
        }
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => { 
            this.titulo = item.data.titulo;
            this.texto  = JSON.stringify(item.data.texto);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {            
            this.closeLoading();
            console.log("complete");
        });

        this.service.getNotificacao();
    }

}
