import {
    MatInputModule, 
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule
} from '@angular/material';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { AlteraVisaoComponent } from './altera-visao/altera-visao.component';
import { UsuariosModule } from 'app/usuarios/usuarios.module';
import { MudarPerfilComponent } from './mudar-perfil/mudar-perfil.component';

@NgModule({
    declarations: [
        ToolbarComponent,
        AlteraVisaoComponent,
        MudarPerfilComponent
    ],
    imports: [
        RouterModule,
        UsuariosModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule
    ],
    exports: [
        ToolbarComponent,
        MudarPerfilComponent
    ],
    entryComponents:[
        AlteraVisaoComponent,
        MudarPerfilComponent
    ]
})
export class ToolbarModule {}
