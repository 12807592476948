import { Injectable }   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class AbordagemService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Estrutura Familiar por id
     * @param id ID da Estrutura Familiar 
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "patrimonial/abordagens/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Estrutura Familiar 
     * @param id ID da Estrutura Familiar 
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "patrimonial/abordagens"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Estrutura Familiar 
     * @param id ID da Estrutura Familiar 
     */
    getAllClientes(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "patrimonial/abordagens/getAllClientes"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Estrutura Familiar 
     * @param id ID da Estrutura Familiar 
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "patrimonial/abordagens/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Estrutura Familiar 
     * @param params Json Estrutura Familiar 
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "patrimonial/abordagens", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Estrutura Familiar 
     * @param params Json Estrutura Familiar 
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "patrimonial/abordagens/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "patrimonial/abordagens/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }

    /**
	 * Imprime Abordagem
	 * @param id ID
	 */
    gerarDocumento(id): Observable<any> {

        return this._http.get(this.utilitesService.urlPrefix + "patrimonial/abordagens/"+id+"/imprimir", {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/pdf",
                'Accept': 'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            }),
        });
    }

}
