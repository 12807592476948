import {Component, ViewChild, TemplateRef, ViewEncapsulation, Inject} from '@angular/core';
import {MatDialog,MatSnackBar, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import {BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { AcaoService } from '../../auxiliares/services/acao-service';
import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';
import { Observable } from 'rxjs';
import { ClienteService } from '../services/cliente.service';

export interface DialogData {
    id: number;
    cliente_id: number;
    acao_comercial_id: number;
    cliente:boolean;
}

@Component({
    selector: 'app-cadastro-relacionamento',
    templateUrl: './cadastro-relacionamento.component.html',
    styleUrls: ['./cadastro-relacionamento.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ AcaoService ]
})

export class CadastroRelacionamentoComponent extends BaseComponent {

    observacao = new FormControl('', [Validators.required]);
    acao_id = new FormControl('', [Validators.required]);
    cliente = new FormControl('');

    model:any = {
        cliente_id:"",
        acao_id:"",
        observacao:"",
    };

    loadingLocal:any;
    loadingSpinnerAcao:boolean = false;
    loadingSpinner:boolean = false;
    listAcao:any[]=[];
    filteredOptions: Observable<string[]>;
    showClient: boolean = true;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroRelacionamentoComponent, { static: false }) table: CadastroRelacionamentoComponent;

    constructor(public service: ClienteRelacionentoService,
                private _clienteService: ClienteService,
                private _acaoService:AcaoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroRelacionamentoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        );

        if(this.data.id != null && this.data.id != undefined){
            this.getById();
        }

        if(this.data.cliente_id != null  && this.data.cliente_id != undefined){
            this.showClient = false;
        }

        this.listAcao = [];
        this.getAllAcao();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }   

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.observacao.valid){
            return true;
        }

        if (!this.acao_id.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.observacao   = this.observacao.value;
        this.model.acao_id      = this.acao_id.value;
        if ( this.data.cliente ){
            const clienteDados = this.cliente.value;
            this.model.cliente_id   = clienteDados.id;
        }else{
            this.model.cliente_id   = this.data.cliente_id;
        }

        if(this.data.id == null){

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                                                       
                },
                error => {                    
                    this.closeLoading();
                    this.showError(error.error.message);

                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorObservacaoMessage() {
        return this.observacao.hasError('required') ? 'Observação é um campo obrigatório' : '';
    }

    getErrorAcaoMessage() {
        return this.acao_id.hasError('required') ? 'Ação é um campo obrigatório' : '';
    }


    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {
            this.model = item.data;
            this.acao_id.setValue(item.data.acao_id);
            this.observacao.setValue(item.data.observacao);
            this.cliente.setValue(item.data.cliente);
        },
         error => {
            this.closeLoading();
         },
        () => {
            this.closeLoading();
        });
    }

    getAllAcao(){
        
        this.loadingSpinnerAcao = true;
        this._acaoService.getAll({acao_comercial_id: this.data.acao_comercial_id}).subscribe(
            list => {
                this.listAcao = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerAcao = false;
            }
        );
    }

}
