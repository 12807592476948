import { 
    Component, 
    OnInit, 
    ChangeDetectorRef, 
    Inject 
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    MatDialogRef, 
    MAT_DIALOG_DATA 
} from '@angular/material';

import { 
    FileUploader, 
    FileItem 
} from 'ng2-file-upload/ng2-file-upload';

import { FormControl, Validators } from '@angular/forms';

import { BaseComponent } from 'app/base-module/base-component/base.component';

import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ClienteHistoricoService } from '../services/cliente-historico.service';

export interface UploadFile {
    id: number;
    url: string;
    title: string;
    urlArchiveModule: string;
} 
@Component({
    selector: 'app-upload-historico-cliente',
    templateUrl: './upload-historico-cliente.component.html',
    styleUrls: ['./upload-historico-cliente.component.scss']
})

export class UploadHistoricoClienteComponent extends BaseComponent implements OnInit {

    basket:any = {
        id:''
    };

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;
    hasFile:any;

    tipo_historico  = new FormControl('', [Validators.required]);    

    model:any = {
        tipo_basket_id:"",
    }
    
    params:any ={}
    listTipo:any[]=[];

    loadingSpinnerTipo:boolean;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UploadHistoricoClienteComponent>,
        public service: ClienteHistoricoService,
        public utiliesService: UtilitesService,
        public authService: AuthService,
        private changeDetector: ChangeDetectorRef,        
        @Inject(MAT_DIALOG_DATA) public _fileUpload: UploadFile,
    ) {

        super(dialog, snackBar);
        
        this.nomeFile = null;        

        this.uploader = new FileUploader({
            url: this.utiliesService.urlPrefix + this._fileUpload.url,
            isHTML5: true,
            authToken: "Bearer " + this.authService.getToken(),           
        });

        this.uploader.onAfterAddingFile = (file) => { 
            file.withCredentials = false; 
        };
        
        this.uploader.onProgressItem = ( fileItem: FileItem, progress: any ) => { 
            this.changeDetector.detectChanges();
        };
        
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        
            this.closeLoading();
            
            if (status == 200){
                var info = JSON.parse(response);                
                this.showSuccess(info.message);                
                this.dialogRef.close(info);
            }else{
                var info = JSON.parse(response);
                this.showError(info.message); 
                this.dialogRef.close("cancel");
            }
        };
    
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    uploadFile(event) {
        let elem = event.target;  //line 2
        if (elem.files.length > 0) {     //line 3
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }

    isInvalidForm(){
        if(this.hasFile == undefined){
            return true;
        }

        return false;
    }


    isNomeFile(){
        return (this.nomeFile != null);
    } 

    salvar(){
        this.openLoading();
        this.uploader.uploadAll();
    }
   
    startUpload() {        
        this.salvar();
    }

}

