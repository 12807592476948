import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatDialog, MatSnackBar } from '@angular/material';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';

import { Router } from '@angular/router';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empresas/services/escritorio.service';
import { navigation } from 'app/navigation/navigation';
import { UsuariosCadastrarComponent } from 'app/usuarios/usuarios-cadastrar/usuarios-cadastrar.component';
import { AlteraVisaoComponent } from './altera-visao/altera-visao.component';
import { MudarPerfilComponent } from './mudar-perfil/mudar-perfil.component';

@Component({
    selector : 'toolbar',
    templateUrl : './toolbar.component.html',
    styleUrls : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [EscritorioService]
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user:any;
    
    escritorio:any;
    assessor:any;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private authService: AuthService,
        private router: Router,
        public dialog:MatDialog,
        public snackBar: MatSnackBar,
        public _usuarioService: UsuarioService,
        public _escritorioService: EscritorioService
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'tr',
                title: 'Turkish',
                flag : 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.user = JSON.parse(this.authService.getUser());
        
        if (this.user != null && this.user != undefined){

            if(this.user.escritorio_padrao != null && this.user.escritorio_padrao != undefined){
                this.escritorio = this.user.escritorio_padrao.nome_fantasia;
            }
           
            this.assessor   = this.user.name;

            if (this.user.visao_escritorio_id != null && this.user.visao_escritorio_id != undefined){
                
                if(this.user.visao_escritorio_id != ''){
                    this.getEscritorio(this.user.visao_escritorio_id);                    
                }else{
                    this.escritorio = 'TODOS';
                }
            }
    
            if (this.user.visao_assessor_id != null && this.user.visao_assessor_id != undefined){                
                if(this.user.visao_assessor_id != ''){
                    this.getUsuario(this.user.visao_assessor_id);                  
                }else{
                    this.assessor   = this.user.name;
                }
            }
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(){
        this.authService.logout();
        this.router.navigate(['/auth/login']);
    }

    /**
     * Exibe mensagem de erro
     * @param {string} message
     */
    showError(message: string) {
        this.snackBar.open(message, 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    /**
     * Exibe mensagem de sucesso
     * @param {string} message
     */
    showSuccess(message: string) {
        this.snackBar.open(message, 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(): void {
        let dialogRef = this.dialog.open(UsuariosCadastrarComponent, {
            panelClass: 'contact-form-dialog',
            width: '400px',
            data: { id: this.user.id }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Seu Usuário atualizado com sucesso!");
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualiza os dados do seu usuário.");
            }
        });
    }
    /**
     * Modal de Alterar Perfil
     */
    openMudarPerfil(): void {
        if(this.user.switch_role){
            let dialogRef = this.dialog.open(MudarPerfilComponent, {
                panelClass: 'contact-form-dialog',
                width: '450px',
                minHeight: '320px',
                maxHeight: "99vh",
                data: { id: this.user.id }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                console.log(result);
            });
        } else {
            this.showError("Este usuário não está habilitado para mudar visão do perfil de usuário dele.");
        }
        
    }

    alteraVisao(tipoAlteracao: string): void{
        let dialogRef = this.dialog.open(AlteraVisaoComponent, {
            width: '400px',
            data: { 
                id: this.user.id,
                tipo: tipoAlteracao,
                escritorio_id: this.user.visao_escritorio_id,
                assessor_id: this.user.visao_assessor_id
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            
            if (result != undefined && result != null && result != "cancel"){
                
                if(result.assessor_id != '') {
                    this.user.visao_assessor_id = result.assessor_id;
                    this.getUsuario(result.assessor_id);
                }else{
                    this.user.visao_assessor_id = '';
                    this.assessor   = this.user.name;
                }
        
                if(result.escritorio_id != '') {
                    this.user.visao_escritorio_id = result.escritorio_id;
                    this.getEscritorio(result.escritorio_id);
                }else{
                    this.user.visao_escritorio_id = '';
                    this.escritorio = 'TODOS';
                }

                this.authService.setUser(this.user);

                this.showSuccess("Visão do Usuário atualizada com sucesso!");
                
                this.refresh();
                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualiza a visão do seu usuário, tente novamente se o problema persistir entre em contato com suporte técnico.");
            }
        });
    }

    getUsuario(id){

        this._usuarioService.getById(id).subscribe(
            data => {                
                this.assessor = data.data.name;
            },
            error => {
                this.showError(error.error.mensagem);
            },
            () => {
            }
        );
    }

    getEscritorio(id){
        this._escritorioService.getById(id).subscribe(
            data => {                
                this.escritorio = data.data.nome_fantasia;                
            },
            error => {
                this.showError(error.error.mensagem);
            },
            () => {
            }
        );
        
    }

    refresh(): void {
        window.location.reload();
    }

    
}
