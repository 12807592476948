import {
    Component, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from "../../base-module/base-component/base.component";

export interface DialogData {
    title: string;
    text: string
}

@Component({
  selector: 'app-modal-escolha-acao',
  templateUrl: './modal-escolha-acao.component.html',
  styleUrls: ['./modal-escolha-acao.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})

export class ModalEscolhaAcaoComponent extends BaseComponent {

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ModalEscolhaAcaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
            super(dialog, snackBar);
        }

    onNoClick(): void {
        this.dialogRef.close('N');
    }    

}
