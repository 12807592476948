import {Routes, RouterModule} from '@angular/router';
import {UsuariosListaComponent} from './lista/usuarios-lista.component';
import {AuthGuardService} from "../base-module/base-service/auth-guard.service";
import {RoleListaComponent} from "./role-lista/role-lista.component";
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        children: [    
            {
                path: 'lista',
                component: UsuariosListaComponent,
            },        
            {
                path: 'sair',
                component: UsuariosListaComponent,
                data: { type: 'logout' }
            },            
            {
                path: 'grupos',
                children: [
                    {
                        path: '',
                        component: RoleListaComponent
                    }
                ],                
            }
        ],            
        canActivate: [AuthGuardService]
    },
    
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UsuarioRoutesModule {}