import {Component, Input, OnInit}  from '@angular/core';
import {MatDialog, MatSnackBar}  from '@angular/material';
import {ActionDialog} from '../../../base-module/base-component/base.component';
import {BaseComponent} from "../../../base-module/base-component/base.component";
import {SelectionType} from '@swimlane/ngx-datatable';
import { GeradorOrdemBoletaService } from 'app/renda-variavel/services/gerador-ordem-boleta.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AdicionaAtivoComponent } from '../adiciona-ativo/adiciona-ativo.component';
import { GeradorOrdemService } from 'app/renda-variavel/services/gerador-ordem.service';
import { CadastroClienteAtivoComponent } from '../cadastro-cliente-ativo/cadastro-cliente-ativo.component';

interface GeradorBoleta {
    id: number;
} 

@Component({
    selector: 'gerador-ordem-lista-pendente',
    templateUrl: './gerador-ordem-lista-pendente.component.html',
    styleUrls: ['./gerador-ordem-lista-pendente.component.scss'],
    providers: [GeradorOrdemBoletaService]
})

export class GeradorOrdemListaPendenteComponent extends BaseComponent implements OnInit  {

    params: any = {};
    user: any;
    loadingIndicator: boolean;   
    SelectionType = SelectionType;
    
    data: any = {};    
    selection = new SelectionModel<GeradorBoleta>(true, []);
    displayedColumns = ['cliente','motivo_recusa', 'valor_financeiro_liquido', 'valor_financeiro_disponivel' ,'ativos_cliente'];
    newColumns = [];
    columnsToDisplay: string[] = this.displayedColumns.slice();
    addColumns = [];
    listaBoletaPendentes: boolean = false;
    rowsPendentes = [];
    totalClientes:number = 0;

    @Input() gerador_id: number;

    constructor(public _service: GeradorOrdemBoletaService, 
                private _serviceGerador: GeradorOrdemService,
                public dialog: MatDialog, 
                public snackBar: MatSnackBar) 
    {
        super(dialog, snackBar);        
    }

    ngOnInit(){
        this.getAll();
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {      
        
        this.openLoading();  
        this.columnsToDisplay = this.displayedColumns.slice();
        
        this.params.gerador_id = this.gerador_id;
        this.params.disponivel = false;
        this._service.getAll(this.params).subscribe(
            data => {                
                this.rows = data.data;
                this.totalClientes = data.data.length;                             
                this.closeLoading();
            },
            error => {               
                this.loadingIndicator = false;
                this.closeLoading();
            },
            () => {   
                this.closeLoading();            
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de boleta
     * @param id
     */
    delete() {

        if(this.selection.selected.length == 0){
            this.showError("Selecione pelo menos uma boleta para realizar a exclusão");
            return false;
        }

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Remover Boletas do Gerador de Ordens",
                text: "Deseja realmente remover este item ?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(lista.selection.selected).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    addAtivo(){        
        let dialogRef = this.dialog.open(AdicionaAtivoComponent, {
            width: '300px',
            maxHeight: '99vh',
            data: {
                gerador_boleta_id: this.data
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Ativos adicionados com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){        

            }
        });
    }
    
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.rows.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        console.log('click');
        this.isAllSelected() ?
            this.selection.clear() :
            this.rows.forEach(row => this.selection.select(row.id));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: GeradorBoleta): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    getTotalLiquido(){
        return this.rows.map(gerador => gerador.valor_financeiro_liquido).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    }

    getTotalBruto(){
        return this.rows.map(gerador => gerador.valor_financeiro_bruto).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    }

    getTotalAtivo(){
        return this.rows.map(gerador => gerador.financeiro_ativo).reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
    }

    basket(){
        this.openLoading();
        this._serviceGerador.getAllExport({gerador_id: this.gerador_id, disponivel: 'false'}).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    selectionRow(row){        

        let dialogRef = this.dialog.open(CadastroClienteAtivoComponent, {
            width: '99vh',
            maxHeight: '99vh',
            data: {
                dadosCliente: row
            }    
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){                
                this.getAll();
            }
        });        
    }
}

