import { Injectable } from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class UsuariosService {

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * Usuario por id
     * @param id ID
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "usuarios/" + id, {headers: this.authService.getAuthHeaders()} );
    }

    /**
     * Lista de Usuário
     * @param id ID
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"usuarios"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Lista de Usuários
     * @param id ID
     */
    getAllList(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/all-list",{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Delete de Usuário
     * @param id ID
     */
    delete(id): Observable<any> {

        return this._http.delete(this.utilitesService.urlPrefix + "usuarios/"+id, {headers: this.authService.getAuthHeaders()});
    }

    /**
     * Cadastra Usuários
     * @param params Json Usuário
     */
    create(params){        
        return this._http.post(this.utilitesService.urlPrefix + "usuarios", params,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Atualiza Usuário
     * @param params Json Usuário
     */
    update(id, params){        
        return this._http.put(this.utilitesService.urlPrefix + "usuarios/"+id, params, {headers: this.authService.getAuthHeaders()});
    }

    /**
     * Informações do dashboard
     * @returns {Observable<Object>}
     */
    dashboard(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/dashboard",{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Consulta Autocomplete
     * @param {string} query
     * @returns {Observable<any>}
     */
    search(query: string): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/search?name="+query,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Consulta Tipo de Usuario
     * @param {string} query
     * @returns {Observable<any>}
     */
    tipoUsuario(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/tipo-usuario",{headers: this.authService.getAuthHeaders()});
    }
}
