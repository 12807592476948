import { 
    Component, 
    OnInit, 
    Input 
} from '@angular/core';
import { 
    MatDialog, 
    MatSnackBar 
} from '@angular/material';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AlteraCarteiraComponent } from 'app/clientes/altera-carteira/altera-carteira.component';

import { AuthService } from 'app/base-module/base-service/auth.service';
import { ImportacaoArquivoService } from 'app/movimentacao/services/importacao-arquivo.service';
import { EstrategiaClienteService } from 'app/renda-variavel/services/estrategia-cliente.service';
import { Estrategia } from 'app/renda-variavel/services/estrategia.service';

interface EstrategiaCliente extends Estrategia {
    valor_inicial_total_estrategia:number;
    valor_atual_total_estrategia:number
}

@Component({
  selector: 'lista-estrategia-custodia',
  templateUrl: './lista-estrategia-custodia.component.html',
  styleUrls: ['./lista-estrategia-custodia.component.scss'],
  providers: [ EstrategiaClienteService ]
})

export class ListaEstrategiaCustodiaComponent  extends BaseComponent implements OnInit {

    @Input() cliente_id: number;
    
    user:any;
    data_atualizacao: any;
    listEstrategia: EstrategiaCliente[]= [];

    total_financeiro_atual:number = 0;
    total_financeiro_inicial:number = 0;
    variacao_percentual:number = 0;
    total_provento:number = 0;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public _arquivoService: ImportacaoArquivoService,
        private _authService: AuthService,
        private _estrategiaClienteService: EstrategiaClienteService
    ) { 
        super(dialog,snackBar);   
        this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getLastArchive();
        this.getAllEstrategiasPosicaoCliente();
    }

    /**
     * Modal de cadastro de Custódia
     */
    openAddAtivo(): void {

        let dialogRef = this.dialog.open(AlteraCarteiraComponent, {
            width: '400px',
            data:{
                cliente_id: this.cliente_id
            }         
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Carteira atualizada com Sucesso!");                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registro");
            }
        });
    }

    getLastArchive(): void {
        
        this._arquivoService.getLastId().subscribe(
            data => {                
                this.data_atualizacao = data.data.created_at;
            },
            error => {
                this.showError(error.error.message);
            },
            () => { }
        );
        
    }

    getAllEstrategiasPosicaoCliente() {
        this._estrategiaClienteService.getEstrategiaPosicao({cliente_id :this.cliente_id}).subscribe(
            list => {
                
                this.listEstrategia = list.data;
            },
            error => { },
            () => {
                this.getTotalFinanceiroCustodiaCliente();
            }
        );
    }

    getPercentual(estrategia: EstrategiaCliente): number{
        return (((estrategia.valor_atual_total_estrategia / estrategia.valor_inicial_total_estrategia) -1 ) * 100);
    }

    getTotalFinanceiroCustodiaCliente(){
        
        this.total_financeiro_atual = this.listEstrategia
            .map(item => item.valor_atual_total_estrategia)
            .reduce((acumulador:number, valorAtual:number) => Number(acumulador) + Number(valorAtual), 0);
        
        if(this.total_provento > 0){
            this.total_financeiro_atual += this.total_provento;
        }

        this.total_financeiro_inicial = this.listEstrategia
            .map(item => item.valor_inicial_total_estrategia)
            .reduce((acumulador, valorAtual) => Number(acumulador) + Number(valorAtual), 0);

        this.variacao_percentual = (((this.total_financeiro_atual / this.total_financeiro_inicial) - 1 ) * 100);
        
    }

    getAlocacao(estrategia: EstrategiaCliente): number {
        const total = this.listEstrategia
            .map(item => item.valor_inicial_total_estrategia)
            .reduce((acumulador, valorAtual) => Number(acumulador) + Number(valorAtual), 0);

        if( total > 0 && total != undefined){
            return ((estrategia.valor_inicial_total_estrategia / total)  * 100);
        }
    }

    refreshItem(event){
        if(event){
            this.getAllEstrategiasPosicaoCliente();            
        }        
    }

    refreshValue(event){
        if(event){
            this.total_provento = event;
            this.total_financeiro_atual += event;
        }
    }

}
