import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from '../services/cliente.service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ProfissaoService } from 'app/auxiliares/services/profissao.service';
import { PrimeiroContatoService } from 'app/auxiliares/services/primeiro-contato.service';

export interface DialogData {
    id: number;
    escritorio_id: number;
    assessor_id: number;
    nome: string;
    numero_xp: number;
    tipo_cliente: string;
    homebroker: boolean;
}

@Component({
    selector: 'dialog-filtrar-cliente',
    templateUrl: './filtrar-cliente.component.html',
    styleUrls: ['./filtrar-cliente.component.scss'],
    providers: [ EscritorioService ]
})
export class FiltrarClienteComponent extends BaseComponent {
    
    tipo_cliente    = new FormControl('', []);
    numero_xp       = new FormControl('', []);
    nome            = new FormControl('', []);
    razao_social    = new FormControl('', []);
    documento       = new FormControl('', []);
    estado_id       = new FormControl('', []);
    cidade_id       = new FormControl('', []);
    assessor_id     = new FormControl('', []);
    escritorio_id   = new FormControl('', []);
    homebroker      = new FormControl('', []);
    prospect        = new FormControl('', []);
    profissao_id    = new FormControl('', []);
    primeiro_contato_id = new FormControl('');

    model:any = {
        tipo_cliente:'',
        homebroker:'',
        prospect:'',
        primeiro_contato_id:''
    }
    
    usuario:any;
    listAssessor: any[] = [];
    listEscritorios: any[] = [];
    listProfissao: any[] = [];
    listClassificacao: any[] = [];

    isCpf: boolean = true;
    isCnpj: boolean = false;
    habilitaEscritorios: boolean = false;
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerClassificacao:boolean = false;
    loadingSpinnerEscritorio:boolean = false;
    loadingSpinnerProfissao:boolean = false;
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltrarClienteComponent, { static: false }) table: FiltrarClienteComponent;

    constructor(public service: ClienteService,
                private usuarioService: UsuarioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<FiltrarClienteComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public escritorioService:EscritorioService,
                private _authService:AuthService,
                private _profissaoService:ProfissaoService,
                private _primeiroService: PrimeiroContatoService) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());

        if ( this.usuario.escritorios.length > 1){
            this.habilitaEscritorios = true;
        }

        this.getAllEscritorios();
        this.getAssessores();
        this.getProfissao();
        this.getClassificacao();

        if(this.data.homebroker != null && this.data.homebroker != undefined){
            this.homebroker.setValue(this.data.homebroker);
            this.model.homebroker = this.data.homebroker;
        }

    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    isInvalidForm(){
        if (!this.nome.valid){
            return true;
        }
        
        return false;
    }


    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    filtrar(){
        this.dialogRef.close({
            razao_social: this.razao_social.value,
            tipo_cliente: this.tipo_cliente.value,
            numero_xp: this.numero_xp.value,
            nome: this.nome.value,
            documento: this.documento.value,
            estado_id: this.estado_id.value,
            cidade_id: this.cidade_id.value,
            assessor_id:this.assessor_id.value,
            escritorio_id: this.escritorio_id.value,
            homebroker: this.model.homebroker,
            profissao_id:this.profissao_id.value,
            prospect:this.prospect.value,
            primeiro_contato_id:this.primeiro_contato_id.value,
        });
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }
    
    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                console.log("complete");
            });
    }

    getProfissao() {
        this.loadingSpinnerProfissao = true;
        this._profissaoService.getLista()
            .subscribe(
                resquest => {

                    this.listProfissao = resquest.data;
                },
                error => {
                    this.loadingSpinnerProfissao = false;
                },
                () => {
                    this.loadingSpinnerProfissao = false;
                    console.log("complete");
                }
            );
    }

    getClassificacao() {
        this.loadingSpinnerClassificacao = true;
        this._primeiroService.getLista()
            .subscribe(
                resquest => {
                    this.listClassificacao = resquest.data;
                },
                error => {
                    this.loadingSpinnerClassificacao = false;
                },
                () => {
                    this.loadingSpinnerClassificacao = false;                    
                }
            );
    }
    

    /**
     * Set value tipo_cliente
     * @param event
     */
    changeTipoCliente() {        
        if (this.tipo_cliente.value == "PF") {
            this.isCpf = true;
            this.isCnpj = false;
        } else {
            this.isCpf = false;
            this.isCnpj = true;
        }
    }
}