import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { FormControl, Validators } from '@angular/forms';
import { OperacaoEstruturadaService } from '../services/operacao-estruturada.service';

@Component({
    selector: 'app-upload-operacao-estruturada',
    templateUrl: './upload-operacao-estruturada.component.html',
    styleUrls: ['./upload-operacao-estruturada.component.scss'],
    providers: [ OperacaoEstruturadaService ]
})

export class UploadOperacaoEstruturadaComponent extends BaseComponent implements OnInit {

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;

    model:any = {
        data_referencia:"",
    }

    data_referencia = new FormControl(new Date(), [Validators.required]);

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UploadOperacaoEstruturadaComponent>,
        public service: OperacaoEstruturadaService,
        public utiliesService:UtilitesService,
        public authService: AuthService,
        private changeDetector: ChangeDetectorRef,
        private _utilitesService: UtilitesService
    ) {

        super(dialog, snackBar);

        this.nomeFile = null;    

        this.uploader = new FileUploader({
        url: this.utiliesService.urlPrefix + 'renda-variavel/cliente-fee/uploads',
        isHTML5: true,
        authToken: "Bearer " + this.authService.getToken()
        });

        this.uploader.onAfterAddingFile = (file) => { 
            file.withCredentials = false; 
        };

        this.uploader.onProgressItem = ( fileItem: FileItem, progress: any ) => { 
            this.changeDetector.detectChanges();
        };

        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

            this.closeLoading();

            if (status == 200){
                var info = JSON.parse(response);
                
                this.showSuccess(info.message);
                
                this.dialogRef.close(info);
            }else{
                var info = JSON.parse(response);
                this.showError(info.message);                
            }

        };

    }

    isInvalidForm(){
        if (!this.data_referencia.valid){
            return true;
        }
        return false;
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    uploadFile(event) {    //line 1

        //console.log(event);
        let elem = event.target;  //line 2
        //console.log(elem);
        if (elem.files.length > 0) {     //line 3
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }

    isNomeFile(){
        return (this.nomeFile != null);
    }

    startUpload() {
        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('data_refencia' , this._utilitesService.formatDateToString(new Date(this.data_referencia.value)));
        };
        this.openLoading();
        this.uploader.queue[0].upload();
    }

}
