import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SituacaoTributariaService } from 'app/auxiliares/services/situacao-tributaria.service';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { ClienteModel } from 'app/clientes/models/cliente.model';
import { ClienteService } from 'app/clientes/services/cliente.service';

@Component({
  selector: 'cadastro-dados-financeiro',
  templateUrl: './cadastro-dados-financeiro.component.html',
  styleUrls: ['./cadastro-dados-financeiro.component.scss']
})
export class CadastroDadosFinanceiroComponent extends BaseComponent implements OnInit {

    listSituacaoTributaria: any[] = [];   
    loadingSpinnerSituacao:boolean = false;

    tipo_situacao_tributaria = new FormControl();
    valor_internacional = new FormControl();
    valor_gobal = new FormControl();
    despesas_mensais = new FormControl();
    receita_mensal = new FormControl();
    receita_familiar = new FormControl();

    model:any = {
    }

    @Input() client: ClienteModel;

    constructor( 
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _situacaoTributaria: SituacaoTributariaService,
        public service: ClienteService) 
    { 
        super(dialog, snackBar);
    }

    ngOnInit() {
        this.getSituacaoTributaria();
        this.getClient();
    }

    getClient(){
        this.tipo_situacao_tributaria.setValue(this.client.tipo_situacao_tributaria);
        this.valor_gobal.setValue(this.client.volume_global);
        this.valor_internacional.setValue(this.client.valor_internacional);
        this.despesas_mensais.setValue(this.client.despesas_mensais);
        this.receita_mensal.setValue(this.client.receita_mensal);
        this.receita_familiar.setValue(this.client.receita_familiar);

    }

    handleSave(){
              
        this.model.tipo_situacao_tributaria = this.tipo_situacao_tributaria.value;
        this.model.valor_internacional = this.valor_internacional.value;
        this.model.valor_gobal = this.valor_gobal.value;
        this.model.despesas_mensais = this.despesas_mensais.value;
        this.model.receita_mensal = this.receita_mensal.value;
        this.model.receita_familiar = this.receita_familiar.value;

        this.openLoading();

        this.service.update(this.client.id, this.model).subscribe(
            data => {
                this.showSuccess("Cliente atualizado com sucesso.");                    
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {                        
                this.closeLoading();
            }
        );      

    }

    getSituacaoTributaria() {
        this.loadingSpinnerSituacao = true;
        this._situacaoTributaria.getLista().subscribe(
            resquest => {

                this.listSituacaoTributaria = resquest.data;
            },
            error => {

            },
            () => {
                this.loadingSpinnerSituacao = false;                
            }
        );
    }

}
