import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
    MatSnackBar
} from '@angular/material';
import { BaseComponent, LoadingTeste } from "app/base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { RolesService } from 'app/usuarios/services/roles.service';

export interface DialogData {
    id: number;
    tipo: string;
    escritorio_id: number;
    assessor_id: number;
}

@Component({
    selector: 'app-mudar-perfil',
    templateUrl: './mudar-perfil.component.html',
    styleUrls: ['./mudar-perfil.component.scss'],
    providers: [ 
        EscritorioService
    ]
})

export class MudarPerfilComponent extends BaseComponent {
    
    role_id = new FormControl('');
    tipo_usuario_id = new FormControl('');

    habilitaEscritorio: boolean = false;
    loadingSpinnerRole: boolean;
    loadingSpinnerTipoUsuario: boolean;
    usuario:any;
    listRoles:any[]=[];
    listTipoUsuario: any[] = [];
    loadingLocal:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(MudarPerfilComponent, { static: false }) table: MudarPerfilComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<MudarPerfilComponent>,
        private _usuarioService: UsuarioService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _authService: AuthService,
        public escritorioService:EscritorioService,
        public roleService: RolesService,
        public loading: MatDialog) {

        super(dialog, snackBar);
        this.usuario = JSON.parse(this._authService.getUser());

        this.getAllRoles();
        this.getAllTipoUsuario();
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(){
        const data = {
            role_id: this.role_id.value,
            tipo_usuario_id: this.tipo_usuario_id.value,
        }
        this.openLoadingLocal();
        this._usuarioService.switchRole(data).subscribe(
            data => {
                this._authService.setUser(data.data);
                this._authService.setToken(data.data.token);
                setTimeout(function(){
                    window.location.reload();
                    this.closeLoadingLocal();
                }, 500)
                
            },
            error => {
                this.closeLoadingLocal();
            },
            () => {
                this.closeLoadingLocal();
            });
    }
    
    
    getAllRoles(){
        this.loadingSpinnerRole = true;
        this.roleService.getAll({no_paginate:true}).subscribe(
            list=>{
                this.loadingSpinnerRole = false;
                this.listRoles = list.data;
                this.role_id.setValue(this.usuario.role_id)
            },
            error => {
                this.loadingSpinnerRole = false;
            },
            () => {
                this.loadingSpinnerRole = false;
            });
    }

    getAllTipoUsuario() {
        this.loadingSpinnerTipoUsuario = true;
        this._usuarioService.tipoUsuario().subscribe(
            list => {
                this.loadingSpinnerTipoUsuario = false;
                this.listTipoUsuario = list.data;
                this.tipo_usuario_id.setValue(this.usuario.tipo_usuario_id)
            },
            error => {
                this.loadingSpinnerTipoUsuario = false;
            },
            () => {
                this.loadingSpinnerTipoUsuario = false;
            });
    }

}
