import {EventEmitter, Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ParceiroPlanoSaudeService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Parceiro Plano de Saúde por id
     * @param id ID da Parceiro Plano de Saúde
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "plano-saude/parceiros/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Parceiro Plano de Saúde
     * @param id ID da Parceiro Plano de Saúde
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "plano-saude/parceiros"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Parceiro Plano de Saúde
     * @param id ID da Parceiro Plano de Saúde
     */
    search(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "plano-saude/parceiros/search"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Parceiro Plano de Saúde
     * @param id ID da Parceiro Plano de Saúde
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "plano-saude/parceiros/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Parceiro Plano de Saúde
     * @param params Json Parceiro Plano de Saúde
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "plano-saude/parceiros", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Parceiro Plano de Saúde
     * @param params Json Parceiro Plano de Saúde
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "plano-saude/parceiros/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
}
