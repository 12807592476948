import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import {BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { ProdutoRendaFixaService } from '../services/produto-rendafixa.service';
import { EmissorService } from '../services/emissor.service';
import { TipoProdutoRendaFixaService } from '../services/tipo-produto-renda-fixa.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-cadastro-produto-rendafixa',
    templateUrl: './cadastro-produto-rendafixa.component.html',
    styleUrls: ['./cadastro-produto-rendafixa.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ EmissorService, TipoProdutoRendaFixaService ]
})

export class CadastroProdutoRendafixaComponent extends BaseComponent {

    nome = new FormControl('', [Validators.required]);
    codigo = new FormControl();
    orgao_emissor_id = new FormControl('');
    tipo_id = new FormControl('');
    data_vencimento = new FormControl('', []);
    vlr_roa = new FormControl('', []);

    model:any = {
        nome:"",
        codigo:"",
        orgao_emissor_id:"",
        tipo_id:"",
        vlr_roa:0,
    };

    loadingLocal:any;

    listOrgaoEmissor: any[] = [];    
    listTipoProduto: any[] = [];   
    loadingSpinnerEmissor:boolean = false;     
    loadingSpinnerProduto:boolean = false;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;

    constructor(public service: ProdutoRendaFixaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                public authService: AuthService,
                private _serviceEmissor: EmissorService,
                private _serviceTipo: TipoProdutoRendaFixaService,
                public dialogRef: MatDialogRef<CadastroProdutoRendafixaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);
        if(this.data != null && this.data != undefined){
            this.getById();
        }

        this.getEmissor();
        this.getTipo();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        
        if (!this.nome.valid){
            return true;
        }

        if (!this.tipo_id.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.nome = this.nome.value;
        this.model.codigo = this.codigo.value;
        this.model.orgao_emissor_id = this.orgao_emissor_id.value;
        this.model.tipo_id = this.tipo_id.value;
        this.model.data_vencimento = this.data_vencimento.value;
        this.model.vlr_roa = this.vlr_roa.value;

        if(this.data == null){

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                                                       
                },
                error => {                    
                    this.closeLoading();
                    this.showError(error.error.message);

                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item=>{
            this.model = item.data;
            this.nome.setValue(item.data.nome);
            this.codigo.setValue(item.data.codigo);
            this.orgao_emissor_id.setValue(item.data.orgao_emissor_id);
            this.tipo_id.setValue(item.data.tipo_id);
            this.data_vencimento.setValue(item.data.data_vencimento);
            this.vlr_roa.setValue(item.data.vlr_roa);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    getEmissor(){        
        this.loadingSpinnerEmissor = true;
        this._serviceEmissor.getAll({no_paginate:true}).subscribe(
            list => {
                this.listOrgaoEmissor = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerEmissor = false;
                console.log("complete");
            }
        );
    }

    getTipo(){        
        this.loadingSpinnerProduto = true;
        this._serviceTipo.getAll({no_paginate:true}).subscribe(
            list => {
                this.listTipoProduto = list.data;
            },
            error => {
                this.loadingSpinnerProduto = false;
            },
            () => {
                this.loadingSpinnerProduto = false;
                console.log("complete");
            }
        );
    }

    

    

}
