import {EventEmitter, Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ModuloService {

    public modulosEmitter = new EventEmitter<any>();
    //public modulosLateralEmitter = new EventEmitter<any>();

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
     * Busca por ID
     * @param id
     * @returns {Observable<any>}
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "modulos/" + id, { headers: this.authService.getAuthHeaders()});
    }

    /**
     * Busca todos registros
     * @param params
     * @returns {Observable<any>}
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "modulos"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Busca todos registros
     * @param params
     * @returns {Observable<any>}
     */
    getAllByEmpresaId(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "modulos/empresa"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     *
     * @param lista
     */
    setEventListaEmmiter(lista){

        this.modulosEmitter.emit({modulos:lista});
        window.localStorage.setItem('modulos', JSON.stringify({modulos:lista}));
        //this.modulosLateralEmitter.emit({modulos:lista});
    }

    getModuloSession(){
        return window.localStorage.getItem('modulos');
    }

    getAllPermissoes(params): Observable<any>{
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "modulos/permissoes"+env, { headers: this.authService.getAuthHeaders() });
    }

}