import { 
    Component, 
    OnInit, 
    Input 
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent 
} from '@angular/material';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { CarteiraService } from 'app/clientes/services/carteira.service';

@Component({
  selector: 'lista-custodia-rv-setor',
  templateUrl: './lista-custodia-rv-setor.component.html',
  styleUrls: ['./lista-custodia-rv-setor.component.scss']
})
export class ListaCustodiaRvSetorComponent extends BaseComponent implements OnInit {

    totalFinanceiro:number = 0;
    valor_total_cliente:number = 0;
    loadingListaAtivo: boolean;

    enableSummary = true;
    summaryPosition = 'top';    

    @Input() parametro: any = {};

    constructor(public dialog: MatDialog, 
        public snackBar: MatSnackBar,        
        public _carteiraService: CarteiraService) { 
        super(dialog,snackBar);
    }

    ngOnInit() {        
        this.getAll();
    }

    totalCustodia():string{
        return 'TOTAL';
    }

    somaFinanceiro(cells){
        
        const filteredCells = cells.filter(cell => !!cell);
        let total = 0;
        filteredCells.map((cell) => {
            if(cell > 0){
                total += parseFloat(cell);
            }
        });

        return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    somaFinanceiroHoje(cells){
        
        const filteredCells = cells.filter(cell => !!cell);
        let total = 0;
        filteredCells.map((cell) => {
            if(cell > 0){                       
                total += parseFloat(cell);
            }
        });

        return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

     /**
     * Consulta lista de Boletas
     */
    getAll(){
        this.loadingListaAtivo = true;
        this.getTotalFinanceiro();
        
        this._carteiraService.getAllAtivosSetor(this.parametro).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows  = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingListaAtivo = false;
            },
            () => {                
                this.loadingListaAtivo = false;
            }
        );
    }

    getTotalFinanceiro(){
        this._carteiraService.getTotalFinanceiro(this.parametro).subscribe(
            data => {
                this.totalFinanceiro = parseFloat(data.data);
            },
            error => { },
            () => { }
        );
    } 

    percentualFinanceiro(financeiro){
        return (parseFloat(financeiro) / this.totalFinanceiro) * 100;
    }

    percentualFinanceiroDiferenca(row){
        return ((( parseFloat(row.vlr_financeiro_hoje)  / parseFloat(row.vlr_financeiro) ) - 1 )  * 100);
    }

}
