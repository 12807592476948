import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule, 
    MatAutocompleteModule, 
    MatTabsModule, 
    MatRadioModule, 
    MatDatepickerModule, 
    MatMenuModule,
    MatChipsModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import {NgxMaskModule} from "ngx-mask";
import {BaseModule} from "../base-module/base.module";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { ListaPrevidenciaComponent } from './lista-previdencia/lista-previdencia.component';
import { RendaFixaRoutesModule } from './previdencia.routing';
import { CadastroBoletaPrevidenciaComponent } from './cadastro-boleta-previdencia/cadastro-boleta-previdencia.component';
import { UploadPrevComponent } from './upload-prev/upload-prev.component';

/** Services */
import { CaracteristicaPrevidenciaService } from './services/caracteristica-previdencia.service';
import { BoletaPrevidenciaService } from './services/boleta-previdencia.service';
import { MovimentacaoModule } from 'app/movimentacao/movimentacao.module';
import { ClienteService } from 'app/clientes/services/cliente.service';

@NgModule({
    imports: [        
        CommonModule,
        RendaFixaRoutesModule,
        MovimentacaoModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatAutocompleteModule,
        BaseModule,
        FileUploadModule,
        MatSelectModule,
        MatOptionModule,
        NgxMaskModule,
        MatTabsModule,
        MatRadioModule,
        MatDatepickerModule,
        MatSelectModule,        
        CurrencyMaskModule,
        MatMenuModule,
        MatChipsModule,
        NgxChartsModule,
        ChartsModule,
        FuseWidgetModule,
        DragDropModule
    ],
    declarations: [
        ListaPrevidenciaComponent,
        CadastroBoletaPrevidenciaComponent,
        UploadPrevComponent
    ],
    entryComponents: [   
        CadastroBoletaPrevidenciaComponent,
        UploadPrevComponent
    ],
    providers: [
        CaracteristicaPrevidenciaService,
        BoletaPrevidenciaService,
        ClienteService
    ],
    
})
export class PrevidenciaModule { }
