import {Routes, RouterModule} from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService } from "../base-module/base-service/auth-guard.service";
import { HomeComponent } from './home/home.component';
import { ListaDisponivelComponent } from './saldo-disponivel/lista-disponivel/lista-disponivel.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,                   
        canActivate: [AuthGuardService]
    },
    {
        path: 'home',
        component: HomeComponent,                   
        canActivate: [AuthGuardService]
    },     
    {
        path: 'saldo-clientes',
        component: ListaDisponivelComponent,                   
        canActivate: [AuthGuardService]
    }, 
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class HomeRoutesModule {}