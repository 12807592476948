import { 
    Component, 
    ViewEncapsulation, 
    ViewChild, 
    ChangeDetectorRef, 
    OnInit, 
    Inject
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    MatDialogRef, 
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FileItem, FileUploader } from 'ng2-file-upload'

import { ActionDialog, BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { CategoriaNoticiaService } from '../services/categoria.service';
import { Noticia, NoticiaService } from '../services/noticia.service';
import { MidiaService, TipoMidia } from '../services/midia.service';

@Component({
    selector: 'app-cadastro-noticia',
    templateUrl: './cadastro-noticia.component.html',
    styleUrls: ['./cadastro-noticia.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class CadastroNoticiaComponent extends BaseComponent implements OnInit {

    categoria_id = new FormControl('', [Validators.required]);
    titulo = new FormControl('', [Validators.required]);
    richTextEditor = new FormControl();
    formGroupCadastro: FormGroup;
    initialText : string = '';
    quillConfiguration = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            ['code-block', { 'color': ['#FFFFFF', '#1E2129', '#3C4252', '#219623', '#4CAF50', '#3F51B5', '#673AB7', '#FFC107', '#FF5722'] }],
            [{ 'align': [] }],
            ['clean']
        ]
    };

    model: any = {
        id: "",
        titulo: "",
        categoria_id: "",
        midia_id: "",
        texto: ""
    };

    midia: any = {
        id: "",
        tipoMidia: 0,
        deleted: false
    };

    formatsAccepts = [
        '.jpg',
        '.jpeg',
        '.gif',
        '.png',
        '.tiff',
        '.bmp',
        '.pdf',
        '.xlsx',
        '.mp4',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx'
    ]

    public tipoMidiaEnum = TipoMidia;
    params: any = {
        categoria_id: ""
    };

    loadingLocal: any;
    listCategorias: any[] = [];
    loadingSpinnerCategoria: boolean;

    file: any;
    nomeFile: String;
    uploader: FileUploader;

    constructor(public service: NoticiaService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public authService: AuthService,
        private _categoriaService: CategoriaNoticiaService,
        public dialogRef: MatDialogRef<CadastroNoticiaComponent>,
        public midiaService: MidiaService,
        private changeDetector: ChangeDetectorRef,
        public fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public noticia: Noticia
    ) {
        super(dialog, snackBar);
        this.getCategoria();

        this.nomeFile = null;

        this.uploader = new FileUploader({
            url: this.midiaService.CENTRAL_NOTICIAS_MIDIA_PATH,
            isHTML5: true,
            authToken: "Bearer " + this.authService.getToken()
        });

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
            if (!this.changeDetector['destroyed']){
                this.changeDetector.detectChanges();
            }
        };

        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

            this.closeLoading();
            if (status == 200) {
                var info = JSON.parse(response);
                this.showSuccess(info.message);
                this.dialogRef.close(info);
            } else {
                var info = JSON.parse(response);
                this.showError(info.message);
            }
            
        };

    }
    
    ngOnInit(): void {
        this.formGroupCadastro = this.fb.group({
            editor: this.initialText
        });

        if (this.noticia != null && this.noticia != undefined) {
            this.fillNoticia(this.noticia);
        }
    }

    @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    fillNoticia(noticia : Noticia){
        this.titulo.setValue(noticia.titulo);
        this.categoria_id.setValue(noticia.categoria.id);
        this.initialText = noticia.texto;

        if(noticia.midia != null && noticia.midia != undefined) {
            this.midia.id = noticia.midia.id;
            this.midia.tipoMidia = noticia.midia.tipo_id;
        }
    }

    getCategoria() {

        this.loadingSpinnerCategoria = true;

        this._categoriaService.getLista().subscribe(
            list => {
                this.loadingSpinnerCategoria = false;
                this.listCategorias = list.data;
            },
            error => {
                this.loadingSpinnerCategoria = false;
                console.error(error);

            },
            () => {
                this.loadingSpinnerCategoria = false;
                console.log("Lista de Categorias Carregada");
            }
        );
    }


    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (!this.titulo.valid) {
            return true;
        }

        if (!this.categoria_id.valid) {
            return true;
        }

        return false;
    }

    salvar() {
        this.openLoading();
        if(this.noticia != null && this.noticia != undefined){
            this.model.id = this.noticia.id;
        }
        this.model.categoria_id = this.categoria_id.value;
        this.model.titulo = this.titulo.value;
        this.model.texto = this.formGroupCadastro.get('editor').value;
        if(this.midia.deleted){
            this.deleteMidia();
        }
        this.service.createOrUpdate(this.model).subscribe(
            data => {
                this.model = data.data;                                          
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);

            },
            () => {
                if(!this.uploadFiles()){
                    this.closeLoading();
                    this.dialogRef.close(this.model);
                }
            }
        );

    }

    private uploadFiles() : Boolean {
        console.log("Not Uploaded Itens: " + this.uploader.getNotUploadedItems().length);
        if (this.uploader.getNotUploadedItems().length) {
            this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
                form.append('noticia_id', this.model.id);
            };
            this.uploader.uploadAll();
            return true;
        }
        else {
            return false;
        }
    }

    getErrorTituloMessage() {
        return this.titulo.hasError('required') ? 'Título é um campo obrigatório' : '';
    }

    getErrorCategoriaMessage() {
        return this.categoria_id.hasError('required') ? 'Categoria é um campo obrigatório' : '';
    }

    deleteMidia() {
        this.loadingIndicator = true;
        this.midiaService.delete(this.midia.id).subscribe(
            data => {
                this.loadingIndicator = false;
                this.midia = null;
            },
            error => {
                this.loadingIndicator = false;
                console.log("Erro na Exclusão da Mídia: " + error);
                this.showError(error.message);
            },
            () => {
                console.log("Mídia Excluída");
                this.loadingIndicator = false;
            }
        );
        this.loadingIndicator = false;
    }

    prepareToDeleteMidia() {
        this.midia.deleted = true;
        console.log("Preparado para Deletar");
    }

    startUpload() {
        this.salvar();
    }

    uploadFile(event) {
        
        let elem = event.target;        
        if (elem.files.length > 0) {
            this.file = elem.files[0];
            this.nomeFile = this.file.name;
        }
    }    

    getUrlMidia() {
        return this.service.getUrlMidia(this.noticia.id);
    }

    getUrlThumbnail() {
        return this.service.getUrlThumbnail(this.noticia.id);
    }
}
