import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import {BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { BoletaService } from '../services/boleta.service';

export interface DialogData {
    id: number;
    cliente: any;
    valor:number;
    data_operacao:Date;
    status_id:number;
    observacao:string;    
    aporte_retirada:boolean;
    tipo_boleta:number;
    acao_comercial_id:number;
    vlr_executado:number;
    estrategia_id:number;
    valor_total:number;
    estrategia:string;
}

@Component({
    selector: 'app-confirma-boleta',
    templateUrl: './confirma-boleta.component.html',
    styleUrls: ['./confirma-boleta.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ConfirmaBoletaComponent extends BaseComponent {

    nome   = new FormControl('', [Validators.required]);

    model:any = {
        cliente_id:"",
        valor:"",
        observacao:"",
        data_operacao:"",
        tipo_operacao:"",
        tipo_boleta:"",
        estrategia_id:"",
    }

    loadingLocal:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ConfirmaBoletaComponent, { static: false }) table: ConfirmaBoletaComponent;

    constructor(public _service: BoletaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<ConfirmaBoletaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);        
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.nome.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.cliente_id = this.data.cliente.id;
        this.model.valor = this.data.valor;
        this.model.valor_dinheiro_novo = this.data.valor;
        this.model.observacao = this.data.observacao;
        this.model.data_operacao = this.data.data_operacao;
        this.model.status_id = this.data.status_id;
        this.model.tipo_boleta = this.data.tipo_boleta;
        this.model.acao_comercial_id  = this.data.acao_comercial_id;
        this.model.vlr_executado = this.data.vlr_executado;
        this.model.estrategia_id = this.data.estrategia_id;

        if(this.data.id == null){

            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                           
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }

    }

}
