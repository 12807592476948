import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation, 
    OnInit
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    PageEvent, 
    MatPaginator
} from '@angular/material';

import { 
    FormControl, 
    Validators 
} from '@angular/forms';

import { ActivatedRoute, Router} from "@angular/router";
import { DatatableComponent } from '@swimlane/ngx-datatable';


/** Components */

import {
    BaseComponent, 
    LoadingTeste, 
    ActionDialog
} from "../../base-module/base-component/base.component";
import { fuseAnimations } from '@fuse/animations';

import { CadastroRelacionamentoComponent } from '../cadastro-relacionamento/cadastro-relacionamento.component';
import { FiltrarRelacionamentoComponent } from '../filtrar-relacionamento/filtrar-relacionamento.component';
import { CadastroAporteRetiradaComponent } from '../cadastro-aporte-retirada/cadastro-aporte-retirada.component';

/** Services */
import { ClienteService } from '../services/cliente.service';
import { EnderecoService } from "../../base-module/base-service/endereco.service";
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { SituacaoTributariaService } from 'app/auxiliares/services/situacao-tributaria.service';
import { PrimeiroContatoService } from 'app/auxiliares/services/primeiro-contato.service';
import { OrigemService } from 'app/auxiliares/services/origem-cliente.service';
import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';
import { ProfissaoService } from 'app/auxiliares/services/profissao.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ClientePerfilService } from '../services/cliente-perfil.service';
import { EstadoCivilService } from '../services/estado-civil.service';
import { ClienteCarteiraConsorcioService } from 'app/consorcio/services/cliente-carteira-consorcio.service';
import { CadastroCarteiraConsorcioComponent } from 'app/consorcio/cadastro-carteira-consorcio/cadastro-carteira-consorcio.component';
import { VisualizarCarteiraConsorcioComponent } from 'app/consorcio/visualizar-carteira-consorcio/visualizar-carteira-consorcio.component';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'dialog-cliente-cadastro-completo',
    templateUrl: './cadastro-completo.component.html',
    styleUrls: ['./cadastro-completo.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class CadastroCompletoComponent extends BaseComponent implements OnInit {

    nome = new FormControl('', [Validators.required]);
    razao_social = new FormControl('', [Validators.required]);
    cpf = new FormControl('', []);
    cnpj = new FormControl('', []);
    rg = new FormControl('', []);
    endereco = new FormControl('', []);
    bairro = new FormControl('', []);
    numero = new FormControl('', []);
    estado_id = new FormControl('', []);
    cidade_id = new FormControl('', []);
    telefone = new FormControl('', []);
    complemento = new FormControl('', []);
    cep = new FormControl('', []);
    email = new FormControl('', []);
    tipo_pessoa = new FormControl('', [Validators.required]);
    conta_btg = new FormControl();
    numero_xp = new FormControl();
    investimento_inicial = new FormControl('', [Validators.required]);
    assessor_id = new FormControl('', [Validators.required]);
    operador_id = new FormControl('', []);
    volume_global = new FormControl('0', [Validators.required]);
    tipo_situacao_tributaria = new FormControl('', [Validators.required]);
    origem_cliente_id = new FormControl('', []);
    primeiro_contato_id = new FormControl('', []);
    habilitado_bovespa = new FormControl(false, [Validators.required]);
    termo_push = new FormControl(false, [Validators.required]);
    data_nascimento = new FormControl('', []);
    tipo_endereco = new FormControl('R', [Validators.required]);
    valor_investimento = new FormControl('0', []);
    realizou_call = new FormControl(false, []);
    valor_disponivel = new FormControl('', []);
    profissao_id = new FormControl('', []);
    cliente_prosp = new FormControl(false, []);
    homebroker = new FormControl(false, []);
    assessor = new FormControl();
    perfil_id = new FormControl('', []);

    vlr_boleta_fo = new FormControl('', []);
    vlr_boleta_st = new FormControl('', []);
    vlr_boleta_fii = new FormControl('', []);
    desconto_corretagem = new FormControl('', []);
    operacao_stop = new FormControl();
    estado_civil_id = new FormControl();
    sexo = new FormControl();
    digito_xp = new FormControl();
    digito_btg = new FormControl();
    estrategia_rv_alavancagem = new FormControl();

    public strCidadeCep:any;

    @ViewChild('tableRelacionamento',{ static: false }) table2: DatatableComponent;    

    
    params:any = {
        per_page:""
    }

    model:any = {
        empresa_id:"",
        nome:"",
        telefone:"",
        cep:"",
        tipo_pessoa:'PF',
        numero_xp:"",
        investimento_inicial:"",
        assessor_id:"",
        operador_id:"",
        volume_global:"",
        tipo_situacao_tributaria:"",
        rg:"",
        origem_cliente_id: '',
        primeiro_contato_id: '',
        habilitado_bovespa: false,
        termo_push: false,
        tipo_endereco: 'R',
        realizou_call: false,
        homebroker: false,
        estado_civil_id: '',
        sexo: '',
        digito_xp: '',
        conta_btg: '',        
        digito_btg: '',
        estrategia_rv_alavancagem: false,
    };

    data:any = {};
    

    listEstados:any[]=[];
    listCidades:any[]=[];
    listAssessor: any[] = [];
    listOperador: any[] = [];
    listOrigem: any[] = [];
    listProfissao: any[] = [];
    listPrimeiroContato: any[] = [];
    listSituacaoTributaria: any[] = [];    
    listPerfil: any[] = [];    
    listEstadoCivil: any[] = [];
    loadingLocal:any;
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerOperador:boolean = false;
    loadingSpinnerSituacao:boolean = false;
    loadingSpinnerPrimeiro:boolean = false;
    loadingSpinnerOrigem:boolean = false;
    isDisabledVlrInvest:boolean = true;
    loadingIndicator:boolean = false;
    loadingListaAtivo:boolean = false;
    loadingRelacionamento:boolean = false;
    loadingSpinnerProfissao:boolean = false;
    loadingSpinnerPerfil:boolean = false;
    loadingSpinnerEstadoCivil:boolean = false;
    
    exibeValorInicial:boolean = true;
    exibeValorGlobal:boolean = true;
    isConditionIsTrue:boolean = true;

    totalCustodiaFop:any = 0;
    totalCustodiaSt:any = 0;
    totalCustodiaFii:any = 0;

    isDisabledAssessor:boolean = true;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroCompletoComponent, { static: false }) table: CadastroCompletoComponent;
    @ViewChild('paginatorRelacionamento', { static: false }) paginatorRelacionamento: MatPaginator;

    titulo:string;

    isCpf:boolean   = true;
    isCnpj:boolean  = false;
    isRg:boolean    = true;
    existeCliente   = false;

     //variáveis para datatable
    editing = {};
    rowsRelacionamento = [];
    tempRelacionamento = [];  
     
     //paginação
    lengthRelacionamento = 0;
    pageSizeRelacionameto = 100;
    pageSizeOptionsRelacionamento: number[] = [100, 200, 300, 500];
    user:any;

    //tratamento carteira consorcio
    loadingListaCarteiraConsorcio: boolean = false;
    rowsCarteiraConsorcio = [];
    tempCarteiraConsorcio = [];
    paramsCarteiraConsorcio: any = {};

    lengthCarteiraConsorcio = 0;
    pageSizeCarteiraConsorcio = 100;
    pageSizeOptionsCarteiraConsorcio: number[] = [100, 200, 300, 500];

    currentCliente:any;

    constructor(public service: ClienteService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _Activatedroute:ActivatedRoute,
                private route: Router,
                private loading:MatDialog,
                private enderecoService:EnderecoService,
                private usuarioService: UsuarioService,
                private situacaoTributaria: SituacaoTributariaService,
                private _primeiroService: PrimeiroContatoService,
                private _origemService: OrigemService,                
                private _clienteRelacService: ClienteRelacionentoService,
                private _profissaoService:ProfissaoService,
                private _authService:AuthService,
                private _perfilService: ClientePerfilService,
                private _estadoCivil: EstadoCivilService,
                private _clienteCarteiraConsorcioService: ClienteCarteiraConsorcioService)
    {
        super(dialog, snackBar);
        this.data = this._Activatedroute.snapshot.params['id'];

        this.listEstados=[];
        this.listCidades=[];

        this.user = JSON.parse(this._authService.getUser());        

        this.getAllEstados();
        this.getOperadores();
        this.getAssessores();
        this.getSituacaoTributaria();
        this.getOrigem();
        this.getPrimeiroContato();
        this.getProfissao();
        this.getAllPerfil();
        this.getEstadoCivil();
        this.getAllCarteriraConsorcio();
        
        var dataRow = [];

        if(this.params.per_page == undefined || this.params.per_page == "" || this.params.per_page == null){
            this.params.per_page = 100;
        }

        this.rows = dataRow;
        this.temp = [...dataRow];
        
        this.rowsRelacionamento = dataRow;
        this.tempRelacionamento = [...dataRow];
        
        
        if(this.data != null && this.data != undefined){
            this.getCliente();
            this.titulo = "Alterar Cliente";
            this.isDisabledVlrInvest = true;
            this.exibeValorInicial = false;
            this.exibeValorGlobal = true;
            
            this.existeCliente = true;            

        }else{
            this.model.alter_certificado=true;
            this.model.alter_imagem=true;
            this.titulo = "Cadastrar Cliente";
            this.isDisabledVlrInvest = false;
            this.exibeValorInicial = true;
            this.exibeValorGlobal = false;

            this.existeCliente = false;
        }

        if(this.user.role_id === 2 || this.user.role_id === 7 || this.user.role_id === 4 ){
            this.isDisabledAssessor = false;
        }
    }

    ngOnInit(){        
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    getAllEstados(){
        this.enderecoService.getEstados().subscribe(
            list=>{
                this.listEstados = list.data;
            },
            error => {},
            () => {}
        );
    }

    

    /**
     * Set value tipo_pessoa
     * @param event
     */
    changeTipoCliente(){
        if(this.model.tipo_pessoa == "PF"){
            this.isCpf  = true;
            this.isRg   = true;
            this.isCnpj = false;
        }else{
            this.isCpf  = false;
            this.isRg   = false;
            this.isCnpj = true;
        }
    }

    getAllCidades(campo){
        var valor = '';
        if(Number.isInteger(campo)){
            valor = campo;
        }else{
            valor = campo.value;
        }
        this.enderecoService.getCidades(valor).subscribe(
            list=>{
                this.listCidades = list.data;

                for(var i=0; i<this.listCidades.length; i++){
                    if(this.strCidadeCep != null && this.strCidadeCep != "" && this.strCidadeCep == this.listCidades[i].nome){
                        this.cidade_id.setValue(this.listCidades[i].id);
                    }
                }
            },
            error => {},
            () => {}
        );
    }

    getErrorNomeFantasiaMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getErrorRazaoSocialMessage() {
        return this.razao_social.hasError('required') ? 'Razão Social é um campo obrigatório' : '';
    }

    getErrorEnderecoMessage() {
        return this.endereco.hasError('required') ? 'Endereço é um campo obrigatório' : '';
    }

    getErrorEstadoMessage(){
        return this.estado_id.hasError('required') ? 'Estado é um campo obrigatório' : '';
    }

    getErrorBairroMessage(){
        return this.bairro.hasError('required') ? 'Bairro é um campo obrigatório' : '';
    }

    getErrorNumeroMessage(){
        return this.numero.hasError('required') ? 'Numero é um campo obrigatório' : '';
    }

    getErrorCidadeMessage(){
        return this.cidade_id.hasError('required') ? 'Cidade é um campo obrigatório' : '';
    }

    getErrorEmailMessage(){
        return this.email.hasError('required') ? 'E-mail é um campo obrigatório' : '';
    }

    getErrorNumeroXPMessage() {
        return this.numero_xp.hasError('required') ? 'Conta da Corretora é um campo obrigatório' : '';
    }

    getErrorInvestimentoInicialMessage() {
        return this.investimento_inicial.hasError('required') ? 'Investimento Inicial é um campo obrigatório' : '';
    }

    getErrorAssessorMessage() {
        return this.assessor_id.hasError('required') ? 'Assessor é um campo obrigatório' : '';
    }

    getErrorVolumeGlobalMessage() {
        return this.volume_global.hasError('required') ? 'Valor Global é um campo obrigatório' : '';
    }   

    isInvalidForm(){
        
        if (!this.nome.valid){
            return true;
        }
        
        if (this.model.tipo_pessoa == 'PJ' && !this.razao_social.valid) {
            return true;
        }

        /*if (!this.conta_btg.valid && this.cliente_prosp.value === false) {
            return true;
        }*/

        if (!this.telefone.valid && this.cliente_prosp.value === true) {
            return true;
        }

        if (!this.origem_cliente_id.valid && this.cliente_prosp.value === true) {
            return true;
        }

        if (!this.investimento_inicial.valid && this.cliente_prosp.value === false) {
            return true;
        }
        
        if (!this.assessor_id.valid) {
            return true;
        }
        
        if (!this.volume_global.valid && this.cliente_prosp.value === false) {
            return true;
        }       

        return false;
    }

    salvar(){

        this.model.nome = this.nome.value.toUpperCase();
        this.model.razao_social = this.razao_social.value;
        if(this.cpf.value != ''){
            this.model.cnpj_cpf = this.cpf.value;
        }else{
            this.model.cnpj_cpf = this.cnpj.value;
        }        
        this.model.endereco = this.endereco.value;
        this.model.estado_id = this.estado_id.value;
        this.model.bairro = this.bairro.value;
        this.model.numero = this.numero.value;
        this.model.cidade_id = this.cidade_id.value;
        this.model.telefone = this.telefone.value;
        this.model.cep = this.cep.value;
        this.model.email = this.email.value;
        this.model.conta_antiga = this.numero_xp.value;
        this.model.investimento_inicial = this.investimento_inicial.value;
        this.model.volume_global = this.volume_global.value;
        this.model.tipo_situacao_tributaria = this.tipo_situacao_tributaria.value;
        this.model.assessor_id = this.assessor_id.value;
        this.model.operador_id = this.operador_id.value;
        this.model.rg = this.rg.value;
        this.model.primeiro_contato_id = this.primeiro_contato_id.value;
        this.model.origem_cliente_id = this.origem_cliente_id.value;
        this.model.termo_push = this.termo_push.value;
        this.model.habilitado_bovespa = this.habilitado_bovespa.value;
        this.model.data_nascimento = this.data_nascimento.value;
        this.model.tipo_endereco = this.tipo_endereco.value;
        this.model.realizou_call = this.realizou_call.value;
        this.model.profissao_id = this.profissao_id.value;
        this.model.homebroker = this.homebroker.value;
        this.model.cliente_prosp = this.cliente_prosp.value;
        
        this.model.vlr_boleta_fo = this.vlr_boleta_fo.value;
        this.model.vlr_boleta_st = this.vlr_boleta_st.value;
        this.model.vlr_boleta_fii = this.vlr_boleta_fii.value;
        this.model.perfil_id = this.perfil_id.value;
        this.model.desconto_corretagem = this.desconto_corretagem.value;
        this.model.operacao_stop = this.operacao_stop.value;
        this.model.estado_civil_id = this.estado_civil_id.value;
        this.model.sexo = this.sexo.value;
        this.model.digito_conta_antiga = this.digito_xp.value;
        this.model.numero_xp = this.conta_btg.value;        
        this.model.digito_btg = this.digito_btg.value;
        this.model.complemento = this.complemento.value;
        this.model.estrategia_rv_alavancagem = this.estrategia_rv_alavancagem.value;

        const valorTotal = parseFloat(this.vlr_boleta_fii.value) + parseFloat(this.vlr_boleta_st.value) + parseFloat(this.vlr_boleta_fo.value);

        if ( valorTotal > parseFloat(this.valor_investimento.value) ){
            this.showError(`O valor total das 3 estratégias ( ${valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) } ) tem que ser menor que o valor de investimento em MRV.`);
        }else{
            
            this.openLoading();

            if(this.data == null){

                this.service.create(this.model).subscribe(
                    data => {
                        this.showSuccess("Cliente cadastrado com sucesso.");
                        this.retornarLista();
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
    
                        if(error.error.errors != undefined){
                            
                            var $this = this;
                            Object.entries(error.error.errors).forEach(function(erro){
                                var str = erro[1].toString();
                                $this.showError(str);
                            });
    
                        }else{
                            this.showError(error.error.message);
                        }
    
                    },
                    () => {                        
                        this.closeLoading();
                    }
                );
    
            }else{
    
                this.service.update(this.data, this.model).subscribe(
                    data => {
                        this.showSuccess("Cliente atualizado com sucesso.");                    
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {                        
                        this.closeLoading();
                    }
                );
            }
        }       

    }

    getCliente(){
        this.openLoading();
        this.service.getById(this.data).subscribe(
         cliente => {
            this.currentCliente = cliente.data;

            if(cliente.data.cnpj_cpf != '' && cliente.data.cnpj_cpf != undefined){
                if(cliente.data.cnpj_cpf.length > 12){
                    this.cnpj.setValue(cliente.data.cnpj_cpf);
                }else{
                    this.cpf.setValue(cliente.data.cnpj_cpf);
                }
            }

            this.nome.setValue(cliente.data.nome);
            this.razao_social.setValue(cliente.data.razao_social);
            
            this.endereco.setValue(cliente.data.endereco);
            this.numero.setValue(cliente.data.numero);
            this.bairro.setValue(cliente.data.bairro);
            this.complemento.setValue(cliente.data.complemento);
            this.estado_id.setValue(cliente.data.estado_id);
            this.telefone.setValue(cliente.data.telefone);
            this.cep.setValue(cliente.data.cep);
            this.email.setValue(cliente.data.email);
            this.numero_xp.setValue(cliente.data.conta_antiga);
            this.investimento_inicial.setValue(cliente.data.investimento_inicial);
            this.valor_investimento.setValue(cliente.data.valor_investimento);
            this.volume_global.setValue(cliente.data.volume_global);
            this.tipo_situacao_tributaria.setValue(cliente.data.tipo_situacao_tributaria);
            this.assessor_id.setValue(cliente.data.assessor_id);
            this.operador_id.setValue(cliente.data.operador_id);

            this.habilitado_bovespa.setValue(cliente.data.habilitado_bovespa);
            this.termo_push.setValue(cliente.data.termo_push);
            this.realizou_call.setValue(cliente.data.realizou_call);
            this.origem_cliente_id.setValue(cliente.data.origem_cliente_id);
            this.primeiro_contato_id.setValue(cliente.data.primeiro_contato_id);
            this.data_nascimento.setValue(cliente.data.data_nascimento);
            this.tipo_endereco.setValue(cliente.data.tipo_endereco);
            this.profissao_id.setValue(cliente.data.profissao_id);
            this.perfil_id.setValue(cliente.data.perfil_id);

            this.homebroker.setValue(cliente.data.homebroker);
            this.cliente_prosp.setValue(cliente.data.cliente_prosp.toString());
            this.desconto_corretagem.setValue(cliente.data.desconto_corretagem);

            this.vlr_boleta_fo.setValue(cliente.data.vlr_boleta_fo);
            this.vlr_boleta_st.setValue(cliente.data.vlr_boleta_st);
            this.vlr_boleta_fii.setValue(cliente.data.vlr_boleta_fii);
            this.operacao_stop.setValue(cliente.data.operacao_stop);

            this.tipo_pessoa.setValue(cliente.data.tipo_pessoa);
            this.estado_civil_id.setValue(cliente.data.estado_civil_id);
            this.sexo.setValue(cliente.data.sexo);
            this.digito_xp.setValue(cliente.data.digito_conta_antiga);
            this.conta_btg.setValue(cliente.data.numero_xp);            
            this.digito_btg.setValue(cliente.data.digito_btg);

            this.estrategia_rv_alavancagem.setValue(cliente.data.estrategia_rv_alavancagem);

            this.model.tipo_pessoa = cliente.data.tipo_pessoa;
            this.model.operacao_stop = cliente.data.operacao_stop;
            this.model.estado_civil_id = cliente.data.estado_civil_id;
            this.model.sexo = cliente.data.sexo;
            this.model.cliente_prosp = cliente.data.cliente_prosp.toString();
            
            this.changeTipoCliente();

            if(cliente.data.assessor != undefined && cliente.data.assessor != null){
                this.assessor.setValue(cliente.data.assessor.name);
            }

            if( cliente.data.custodia_fop != undefined && cliente.data.custodia_fop != null && cliente.data.custodia_fop.length > 0) {
                this.totalCustodiaFop = parseFloat(cliente.data.custodia_fop[0].valor_total);
            }

            if( cliente.data.custodia_st != undefined && cliente.data.custodia_st != null && cliente.data.custodia_st.length > 0) {
                this.totalCustodiaSt  = parseFloat(cliente.data.custodia_st[0].valor_total);
            }

            if( cliente.data.custodia_fii != undefined && cliente.data.custodia_fii != null && cliente.data.custodia_fii.length > 0) {
                this.totalCustodiaFii =parseFloat(cliente.data.custodia_fii[0].valor_total);
            }
            
            if( cliente.data.carteira != undefined && cliente.data.carteira != null && cliente.data.carteira.length > 0) {
                this.valor_disponivel.setValue( parseFloat(cliente.data.valor_investimento) - ( parseFloat(cliente.data.valor_bloqueado) + parseFloat(cliente.data.carteira[0].valor_total) ) );
            }else{
                this.valor_disponivel.setValue( parseFloat(cliente.data.valor_investimento) - parseFloat(cliente.data.valor_bloqueado));    
            }

            if(cliente.data.estado_id != undefined && cliente.data.estado_id != null) {
                this.getAllCidades(cliente.data.estado_id);
                this.cidade_id.setValue(cliente.data.cidade_id);
            }
        },
         error => {
            this.closeLoading();
         },
        () => {
            this.closeLoading();            
        });
    }

    isDisabledEstado(){
        if(this.listEstados.length > 0){
            return false;
        }
        return true;
    }

    isDisabledCidade(){
        if(this.listCidades.length > 0){
            return false;
        }
        return true;
    }

    retornarLista(){
        this.route.navigate(['/clientes/consulta']);
    }
    /**
     * Seleciona estado na busca por cep
     * @param {string} str
     */
    selecionaEstado(str:string){

        for(var i=0; i<this.listEstados.length;i++){
            if(this.listEstados[i].sigla == str){
                this.estado_id.setValue(this.listEstados[i].id);
                this.getAllCidades(this.listEstados[i].id);
            }
        }


    }

    /* consulta de endereco por cep */
    getEnderecoByCep(){

        this.enderecoService.getByCep(this.cep.value)
            .subscribe(
                address => {

                    this.model.endereco = address.logradouro;
                    this.model.bairro = address.bairro;

                    this.strCidadeCep = address.localidade;
                    this.endereco.setValue(address.logradouro);
                    this.selecionaEstado(address.uf);
                    this.bairro.setValue(address.bairro);

                },
                error => {},
                () => {}
            );
    }

    getAllPerfil(){
        this._perfilService.getLista().subscribe(
            list=>{
                this.listPerfil = list.data;
            },
            error => {
            },
            () => {}
        );
    }

    getAssessores() {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({tipo_usuario_id: 'todos'})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;                    
                }
            );
    }

    getOperadores() {
        this.loadingSpinnerOperador = true;
        this.usuarioService.getLista({ tipo_usuario_id: 2 })
            .subscribe(
                resquest => {

                    this.listOperador = resquest.data;
                },
                error => {
                    this.loadingSpinnerOperador = false;
                },
                () => {
                    this.loadingSpinnerOperador = false;                    
                }
            );
    }

    getSituacaoTributaria() {
        this.loadingSpinnerSituacao = true;
        this.situacaoTributaria.getLista().subscribe(
            resquest => {

                this.listSituacaoTributaria = resquest.data;
            },
            error => {

            },
            () => {
                this.loadingSpinnerSituacao = false;                
            }
        );
    }

    getOrigem() {
        this.loadingSpinnerOrigem = true;
        this._origemService.getLista()
            .subscribe(
                resquest => {

                    this.listOrigem = resquest.data;
                },
                error => {
                    this.loadingSpinnerOrigem = false;
                },
                () => {
                    this.loadingSpinnerOrigem = false;                    
                }
            );
    }

    getProfissao() {
        this.loadingSpinnerProfissao = true;
        this._profissaoService.getLista()
            .subscribe(
                resquest => {

                    this.listProfissao = resquest.data;
                },
                error => {
                    this.loadingSpinnerProfissao = false;
                },
                () => {
                    this.loadingSpinnerProfissao = false;                    
                }
            );
    }

    getEstadoCivil() {
        this.loadingSpinnerEstadoCivil = true;
        this._estadoCivil.getLista()
            .subscribe(
                resquest => {
                    this.listEstadoCivil = resquest.data;
                },
                error => {
                    this.loadingSpinnerEstadoCivil = false;
                },
                () => {
                    this.loadingSpinnerEstadoCivil = false;                    
                }
            );
    }

    getPrimeiroContato() {
        this.loadingSpinnerPrimeiro = true;
        this._primeiroService.getLista()
            .subscribe(
                resquest => {

                    this.listPrimeiroContato = resquest.data;
                },
                error => {
                    this.loadingSpinnerPrimeiro = false;
                },
                () => {
                    this.loadingSpinnerPrimeiro = false;                    
                }
            );
    }

    onLinkClick(e){               

        if(e.index == 3){
            this.getAllRelacionamento();
        }
        
    }

    verificaContaCliente(){
        
        this.service.getByConta(this.conta_btg.value).subscribe(
            cliente => {
                if(cliente.data){
                    this.showError(`Já existe um cliente: ${cliente.cliente.nome} 
                    cadastrado com esse código de conta: ${cliente.cliente.numero_xp}, 
                    por favor escolha outro código.`);
                    this.conta_btg.setValue('');
                }
                
            },
            error => {},
            () => { }
        );
    }

    getAllRelacionamento(){
        
        this.loadingRelacionamento  = true;
        this.params.cliente_id      = this.data;

        this._clienteRelacService.getAll(this.params).subscribe(
            data => {
                this.lengthRelacionamento   = data.meta.total;
                this.rowsRelacionamento     = data.data;
                this.tempRelacionamento     = [...this.rows];
            },
            error => {
                this.loadingRelacionamento = false;
            },
            () => {                
                this.loadingRelacionamento = false;
            }
        );
    }   

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEventRelacionamento(event:PageEvent){        

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAllRelacionamento();
    }
   

    /**
     * Modal de edição
     */
    cadastrarRelacionamento(): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoComponent, {
            width: '400px',
            data: { 
                cliente_id: this.data,
                cliente:false
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento Cadastrado com Sucesso!");
                this.getAllRelacionamento();                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o registro");
            }
        });
    }

    /**
     * Modal de edição
     */
    alterarRelacionamento(id): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoComponent, {
            width: '400px',
            data: { 
                cliente_id: this.data,
                id: id,
                cliente:false
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento atualizado com sucesso!");              
                this.getAllRelacionamento();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registrod de relacionamento com cliente");
            }
        });
    }

    filtrarHistoricoRelacionamento():void {
        let dialogRef = this.dialog.open(FiltrarRelacionamentoComponent, {
            width: '700px',
            data: { 
                acao_id: this.params.escritorio_id,
                data_hora_operacao_inicio: this.params.assessor_id,
                data_hora_operacao_fim: this.params.nome,
                cliente: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            
            if (result != "cancel" && result != undefined) {
                this.params.acao_id                     = result.acao_id;
                this.params.data_hora_operacao_inicio   = result.data_inicio;
                this.params.data_hora_operacao_fim      = result.data_fim;
                this.paginatorRelacionamento.firstPage();
                this.getAllRelacionamento();
            }
        });
    }

    setRequired() {
        const valor = this.cliente_prosp.value;        
        if(valor){
            this.isConditionIsTrue = true;
            this.conta_btg.setValidators([Validators.required]);
            this.volume_global.setValidators([Validators.required]);
            this.investimento_inicial.setValidators([Validators.required]);            
            this.telefone.setValidators([]);
            this.conta_btg.updateValueAndValidity();
            this.volume_global.updateValueAndValidity();
            this.investimento_inicial.updateValueAndValidity();
            this.telefone.updateValueAndValidity();
        }else{
            this.isConditionIsTrue = false;
            this.conta_btg.setValidators([]);
            this.volume_global.setValidators([]);
            this.investimento_inicial.setValidators([]);
            this.telefone.setValidators([Validators.required]);
            this.conta_btg.updateValueAndValidity();
            this.volume_global.updateValueAndValidity();
            this.investimento_inicial.updateValueAndValidity();
            this.telefone.updateValueAndValidity();
        }
    }

    boleta(tipo){   

        let dialogRef = this.dialog.open(CadastroAporteRetiradaComponent, {
            width: '400px',
            data: { 
                tipo: tipo,
                cliente_id: this.data,
                nome_cliente: this.nome.value,
                valor_disponivel: this.valor_disponivel.value,
                total_fop: this.vlr_boleta_fo.value, 
                total_st: this.vlr_boleta_st.value, 
                total_fii: this.vlr_boleta_fii.value, 
                custodia_total_fop: this.totalCustodiaFop, 
                custodia_total_st: this.totalCustodiaSt, 
                custodia_total_fii: this.totalCustodiaFii, 
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Boleta cadastrada com Sucesso!");
                window.location.reload();               
                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o aprote, tente novamente");
            }
        });
    }
    /**
     * Modal de edição
     */
    openAporte(tipo): void {

        let dialogRef = this.dialog.open(CadastroAporteRetiradaComponent, {
            width: '400px',
            data: { 
                tipo: tipo,
                cliente_id: this.data,
                nome_cliente: this.nome.value,
                valor_disponivel: this.valor_disponivel.value
            }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                if(tipo == 'A'){
                    this.showSuccess("Aporte cadastrado com Sucesso!");                
                }else if(tipo == 'R'){
                    this.showSuccess("Retirada cadastrada com Sucesso!");
                }
                window.location.reload();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o aprote, tente novamente");
            }
        });
    }

    /**
     * Cor das linhas
     */
    getRowClass = (row) => {
        
        if(!row.migrado){
            return {
                'red-900': true
            };
        }
    }

    /**
     * Consulta lista de CarteriraConsorcio
     */
    getAllCarteriraConsorcio() {

        this.loadingListaCarteiraConsorcio = true;
        this.paramsCarteiraConsorcio.cliente_id = this.data;
        this._clienteCarteiraConsorcioService.getAll(this.paramsCarteiraConsorcio).subscribe(
            data => {
                this.lengthCarteiraConsorcio = data.meta.total;
                this.rowsCarteiraConsorcio = data.data;
                this.tempCarteiraConsorcio = [...this.rowsCarteiraConsorcio];
            },
            error => {
                this.loadingListaCarteiraConsorcio = false;
            },
            () => {
                this.loadingListaCarteiraConsorcio = false;
            }
        );
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEventCarteiraConsorcio(event: PageEvent) {

        this.paramsCarteiraConsorcio.page = (event.pageIndex + 1);
        this.paramsCarteiraConsorcio.per_page = event.pageSize;

        this.getAllCarteriraConsorcio();
    }

    /**
     * Modal de cadastro
     */
    openCadastroCarteiraConsorcio(): void {
        let dialogRef = this.dialog.open(CadastroCarteiraConsorcioComponent, {
            width: '800px',
            maxHeight: '99vh',
            data:{
                cliente: this.currentCliente,
                type: 'by_cliente'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Carteira do Crédito/Câmbio/Consórcio cadastrado com sucesso!");
                this.getAllCarteriraConsorcio();
            } else if (result != undefined && result != "cancel") {
                this.showError("Ocorreu um erro ao tentar cadastrar um novo registro.");
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterarCarteiraConsorcio(id): void {
        let dialogRef = this.dialog.open(CadastroCarteiraConsorcioComponent, {
            width: '800px',
            maxHeight: '99vh',
            data: {
                id: id,
                cliente: this.currentCliente,
                type: 'by_cliente'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Carteira do Crédito/Câmbio/Consórcio atualizado com sucesso!");
                this.getAllCarteriraConsorcio();
            } else if (result != undefined && result != "cancel") {
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

    /**
     * Modal de Visualizar Carteira
     */
    openVisualizarCarteiraConsorcio(id): void {
        let dialogRef = this.dialog.open(VisualizarCarteiraConsorcioComponent, {
            width: '800px',
            maxHeight: '99vh',
            data: {
                id: id,
                cliente: this.currentCliente,
                type: 'by_cliente'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
