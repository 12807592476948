import { Component, ViewChild, TemplateRef, ViewEncapsulation,Inject } from '@angular/core';
import { MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService} from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { AporteService } from 'app/movimentacao/services/aporte.service';
import { BoletaService } from 'app/movimentacao/services/boleta.service';
import { ActionDialogBoleta } from 'app/movimentacao/cadastro-boleta/cadastro-boleta.component';
import { Router } from '@angular/router';
import { ParametroService } from 'app/parametros/services/parametro.service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';

export interface DialogData {
    tipo: any;
    cliente_id: any;
    nome_cliente: any;
    valor_disponivel: any;
    total_fop: any;
    total_st: any;
    total_fii: any;
    custodia_total_fop: any;
    custodia_total_st: any;
    custodia_total_fii: any;
}

@Component({
    selector: 'app-cadastro-aporte-retirada',
    templateUrl: './cadastro-aporte-retirada.component.html',
    styleUrls: ['./cadastro-aporte-retirada.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations    
})

export class CadastroAporteRetiradaComponent extends BaseComponent {

    valor       = new FormControl('', [Validators.required]);
    observacao  = new FormControl();
    status_id   = new FormControl();
    estrategia_id   = new FormControl();

    total_disponivel_fop:any;
    total_disponivel_st:any;
    total_disponivel_fii:any;
    valor_disponivel:any;

    vlr_min_boleta:any;
    vlr_min_boleta_sw:any;
    vlr_min_primeira_boleta_sw:any;
    vlr_min_boleta_fop:any;
    vlr_min_primeira_boleta_fop:any;


    model:any = {
        valor:"",
    };

    loadingLocal:any;
    titulo:string;
    texto:string;

    loadingSpinnerEstrategia:boolean = false;
    listEstrategia:any[]=[];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroAporteRetiradaComponent, { static: false }) table: CadastroAporteRetiradaComponent;

    constructor(public _service: AporteService,
                public _boletaService: BoletaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroAporteRetiradaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private route: Router,
                private _serviceParametro: ParametroService,
                private _serviceEstrategia: EstrategiaService )
    {
        super(dialog, snackBar);

        this.getAllEstrategias();
        this.getAll();
        
        if(this.data.tipo == 'A'){
            this.titulo = 'APORTE';
            this.texto = 'aportar';
        }else if(this.data.tipo == 'R'){
            this.titulo = 'RETIRADA';
            this.texto = 'retirar';
        }else{
            this.titulo = 'BOLETA RENDA VARIÁVEL';
            this.texto = 'boletar';            
        }

        this.valor_disponivel = this.data.valor_disponivel;

        if(this.data.valor_disponivel < 0){
            this.valor_disponivel = 0;
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        
        if (!this.valor.valid){
            return true;
        }
        
        if (parseFloat(this.valor_disponivel) <= 0 && this.data.tipo != 'A'){
            return true;
        }

        return false;
    }


    salvar(){
        if(this.validaCadastro())
        { 
            this.openLoading();
            
            this.model.cliente_id   = this.data.cliente_id;
            this.model.valor        = this.valor.value;
            this.model.data_operacao = new Date(); 

            if(this.data.tipo == 'R'){
                this.model.tipo_operacao = 'R';
            }else{
                this.model.tipo_operacao = 'A';
            }

            if(this.data.tipo == 'R' || this.data.tipo == 'A'){
                this._service.create(this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.dialogRef.close(data);                           
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }else{
                this.model.cliente_id = this.data.cliente_id;
                this.model.valor = this.valor.value;            
                this.model.data_operacao = new Date();
                this.model.status_id = this.status_id.value;
                this.model.estrategia_id = this.estrategia_id.value;

                if(this.data.tipo == 'FOP'){
                    this.model.tipo_boleta = 1;
                }else if(this.data.tipo == 'ST'){
                    this.model.tipo_boleta = 2;
                }else{
                    this.model.tipo_boleta = 3;
                }                

                this._boletaService.create(this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.dialogRef.close(data);
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }
        }
        
    }

    getByClienteBoleta(){        
        this._boletaService.getByClienteCount(this.data.cliente_id, this.estrategia_id.value).subscribe(
            result => {
                if(result.data.qtd == 0){
                    this.status_id.setValue(2);
                }else{                    
                    this.status_id.setValue(1);
                }
            },
            error => { },
            () => { }
        );        
    }

    getErrorValorMessage() {
        return this.valor.hasError('required') ? 'Valor é um campo obrigatório' : '';
    }

    validaCadastro(){
        
        //Valida Aporte do Cliente

        if(this.data.tipo == 'FOP' || this.data.tipo == 'ST' || this.data.tipo == 'FI'){
            
            var valorTotal = parseFloat(this.data.valor_disponivel);

            if( valorTotal < (parseFloat(this.valor.value)) ){
                this.showError('O valor da Boleta não pode ser maior que o valor disponivel para investimento!');
                return false;
            }        

            if(parseFloat(this.valor.value) == 0){
                this.showError('O valor da Boleta não pode ser zerado!');
                return false;
            }                

            if(parseFloat(this.valor.value) < this.vlr_min_boleta && this.vlr_min_boleta > 0 ){
                this.showError('O valor da Boleta não pode ser menor do que R$ '+this.vlr_min_boleta.toLocaleString()+' !');
                return false;
            }  
        }

        return true;
    }

    getBoletaAberta(cliente){
        var tipo_boleta = 0;

        if(this.data.tipo == 'ST'){
            tipo_boleta = 2;
        }else if(this.data.tipo == 'FOP'){
            tipo_boleta = 1;
        }else{
            tipo_boleta = 3;
        }
        
        this._boletaService.getBoletaAberta(cliente, this.estrategia_id.value).subscribe(
            result => {
                if(result.data.qtd == undefined){                    
                    if(result.data.status_id == 3){
                        this.alertaBoletaFechada();
                    }else{
                        this.alertaBoletaAberta(result.data.id);
                    }                    
                }
            },
            error => {
            },
            () => { }
        );        
    }

    alertaBoletaAberta(boleta_id){
        var lista = this;

        var tipo_boleta = 0;

        if(this.data.tipo == 'ST'){
            tipo_boleta = 2;
        }else if(this.data.tipo == 'FOP'){
            tipo_boleta = 1;
        }else{
            tipo_boleta = 3;
        }

        this.dialogRef.close("cancel");

        this.dialog.open(ActionDialogBoleta, {
            width: '400px',                        
            data: {
                title: "Boleta Aberta",
                text: "Já existe uma boleta aberta para esse cliente. Você deseja alterar o valor da boleta em aberto ?",
                habilitaBotoes: true,
                callback: function (event) {                    
                    if(event == 'S'){
                        lista.route.navigate(['/movimentacao/boleta/cadastro/' + tipo_boleta +'/'+boleta_id]);
                    }
                }
            }
        });
    }

    alertaBoletaFechada(){
        this.dialogRef.close("cancel");

        var lista = this;   
        this.dialog.open(ActionDialogBoleta, {
            width: '400px',                        
            data: {
                title: "Boleta Em Negociação",
                text: "Já existe uma boleta em negociação. Se você deseja alterar o valor da boleta, entre em contato com a Mesa de Operações!",
                habilitaBotoes: false,
                callback: function (event) {                    
                }
            }
        });
    }

    getAll() {
        
        this._serviceParametro.getAll({}).subscribe(
            data => {
                for(let dados of data.data){
                    
                    if(dados.codigo == 'VALOR_MIN_BOLETA_SWING'){
                        this.vlr_min_boleta_sw = (parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_PRIMEIRA_BOLETA_SWING'){
                        this.vlr_min_primeira_boleta_sw = (parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_BOLETA_FOP'){
                        this.vlr_min_boleta_fop = (parseFloat(dados.valor));
                    }

                    if(dados.codigo == 'VALOR_MIN_PRIMEIRA_BOLETA_FOP'){
                        this.vlr_min_primeira_boleta_fop = (parseFloat(dados.valor));
                    }                     
                    
                }               
            },
            error => {
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    getAllEstrategias() {

        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getLista().subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;
            }
        );
    }

    onChangeSelectEstrategia(){
        this.getBoletaAberta(this.data.cliente_id);

        const estrategia = this.listEstrategia.find(s => s.id == this.estrategia_id.value);
        this.vlr_min_boleta = parseFloat(estrategia.valor_minimo_boleta);
        this.getByClienteBoleta();
    }

}

