import {Component, ViewChild, TemplateRef}  from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent}  from '@angular/material';
import {ActionDialog}                       from '../../base-module/base-component/base.component';
import {BaseComponent}                      from "../../base-module/base-component/base.component";
import {Router}                             from "@angular/router";
import {FormControl}                        from "@angular/forms";
import { AporteService }                    from '../services/aporte.service';
import { FiltrarAporteComponent } from '../filtrar-aporte/filtrar-aporte.component';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
    selector: 'app-lista-aporte',
    templateUrl: './lista-aporte.component.html',
    styleUrls: ['./lista-aporte.component.scss']
})

export class ListaAporteComponent extends BaseComponent{

    params: any = {};
    user:any;
    loadingIndicator: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaAporteComponent, { static: false }) table: ListaAporteComponent;

    constructor(public service: AporteService, 
                private _authService: AuthService,
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router) {
        super(dialog, snackBar);

        this.getAll();
        this.user = JSON.parse(this._authService.getUser());
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de Boletas
     */
    getAll() {       
        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;

                if(error.status == 401){        
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                }
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Aporte/Retirada",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    openCadastro(){
        this.route.navigate(['/movimentacao/aporte-retirada/cadastro']);
    }

    openAlterar(id){
        this.route.navigate(['/movimentacao/aporte-retirada/cadastro/'+id]);
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarAporteComponent, {
            width: '700px',
            //data: { name: this.name, animal: this.animal }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                this.params.cliente_id = result.cliente.id;
                this.params.data_inicio_operacao = result.data_inicio_operacao;
                this.params.data_fim_operacao = result.data_fim_operacao;                

                if (result.escritorio_id != undefined && result.escritorio_id != null){
                    this.params.escritorio_id = result.escritorio_id
                }

                if (result.assessor_id != undefined && result.assessor_id != null){
                    this.params.assessor_id = result.assessor_id
                }

                this.getAll();
            }
        });
    }

}
