import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject,
    OnInit
} from '@angular/core';

import {
    MatDialog, 
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { 
    FormControl, 
    Validators
} from '@angular/forms';

import {
    BaseComponent, 
    LoadingTeste
} from "../../base-module/base-component/base.component";

import { FileItem, FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';

import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { BoletaRendaFixaService } from '../services/boleta-rendafixa.service';
import { ProdutoRendaFixaService } from '../services/produto-rendafixa.service';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';

export interface DialogData {
    acao_comercial_id: any;
    cliente_id: any;
    id: any;
}

@Component({
    selector: 'app-cadastro-boleta-renda-fixa',
    templateUrl: './cadastro-boleta-renda-fixa.component.html',
    styleUrls: ['./cadastro-boleta-renda-fixa.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})


export class CadastroBoletaRendaFixaComponent extends BaseComponent implements OnInit {

    valor = new FormControl('', [Validators.required]);    
    data_futura  = new FormControl();    
    acao_comercial_id  = new FormControl();    
    status_id  = new FormControl();
    qtd  = new FormControl();
    compra_venda  = new FormControl('', [Validators.required]);   
    cliente = new FormControl('',[Validators.required]);     
    cliente_id = new FormControl('', [Validators.required]);
    produto_id = new FormControl('',[Validators.required]); 
    vlr_spread = new FormControl('',[Validators.required]);
    receita_recebida = new FormControl('');
    observacao = new FormControl('');

    model:any = {
        produto_id: "",
        //acao_comercial_id: "",
    };

    file: any;
    nomeFile: String;
    uploader: FileUploader;

    loadingLocal: any;
    titulo: string;
    texto: string;
    listProduto: any[] = [];
    listStatus: any[] = [];
    listAcaoComercial: any[] = [];
    loadingSpinnerProduto:boolean = false;
    loadingSpinnerAcaoComercial:boolean = false;
    loadingSpinnerStatus:boolean = false;
    loadingSpinner: boolean;    
    filteredOptions: Observable<string[]>;
    filteredOptionsProduto: Observable<string[]>;
    user:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _boletaService: BoletaRendaFixaService,
                private _serviceProduto: ProdutoRendaFixaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroBoletaRendaFixaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _clienteService: ClienteService,                
                private _statusService: StatusMovimentacaoService,
                private _utiliesService: UtilitesService)
    {
        super(dialog, snackBar);
        this.user = JSON.parse(this.authService.getUser());
      
        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    const parametrosCliente = {
                        search: query
                    }
                    this.loadingSpinner = true;                    
                    this.filteredOptions = this._clienteService.search(parametrosCliente);
                }
            }
        );

        this.nomeFile = null;

        if (this.data.id == null) {
            this.uploader = new FileUploader({
                url: this._utiliesService.urlPrefix + "rendafixa/boleta-renda-fixa",
                isHTML5: true,
                authToken: "Bearer " + this.authService.getToken(),
                additionalParameter: this.model

            });
        }else{
            this.uploader = new FileUploader({
                url: this._utiliesService.urlPrefix + "rendafixa/boleta-renda-fixa/" + this.data.id+"/update",
                isHTML5: true,
                authToken: "Bearer " + this.authService.getToken(),
                additionalParameter: this.model
            });
        }

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;            
        };

        this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
            //this.changeDetector.detectChanges();            
        };

        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

            this.closeLoading();

            if (status == 200 || status == 201) {
                var info = JSON.parse(response);
                this.showSuccess("Boleta Renda Fixa atualizada com sucesso.");
                this.dialogRef.close(info);

            } else {
                var info = JSON.parse(response);
                this.showError(info.message);                
            }

        };

        this.uploader._onErrorItem = (item:any, response:any) => {
        };
        
    }

    ngOnInit() {

        this.getAllStatus();

        if( this.data.id != null){
            this.getBoleta();
        }else{
            this.getProdutos();
        }

    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }

    selectRoa(event){
        const produto_selected_id = event.value
        this.getRoa(produto_selected_id);
    }

    getRoa(product_id){

        const product_selected = this.listProduto.find(product => product.id === product_id);

        if(product_selected != undefined){
            
            let vlr_roa = (product_selected.vlr_roa || 0)
            this.vlr_spread.setValue(vlr_roa);
            this.loadingSpinnerProduto  = false;
            this.getReceitaRecebidaAssessor();
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        
        if (!this.valor.valid){
            return true;
        }        

        return false;
    }

    salvar(){ 

        this.openLoading();
        if(this.data.cliente_id != null){
            this.model.cliente_id = this.data.cliente_id;
        }else{
            const novaBoletaCliente = this.cliente.value;
            this.model.cliente_id = novaBoletaCliente.id;
        }

        this.model.valor        = this.valor.value;        
        this.model.produto_id   = this.produto_id.value;
        this.model.compra_venda = this.compra_venda.value;
        this.model.data_futura  = this.data_futura.value;
        this.model.status_id    = this.status_id.value;
        this.model.qtd          = this.qtd.value;        
        this.model.vlr_spread   = this.vlr_spread.value;
        this.model.observacao   = this.observacao.value;

        if (this.uploader.getNotUploadedItems().length){
            this.uploader.uploadAll();
        }else{
            if (this.data.id == null) {

                this._boletaService.create(this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.dialogRef.close(data);
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {                        
                        this.closeLoading();
                    }
                );
            } else {

                this._boletaService.update(this.data.id, this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.dialogRef.close(data);
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {                        
                        this.closeLoading();
                    }
                );
            }
        }
        
    }

    getErrorValorMessage() {
        return this.valor.hasError('required') ? 'Valor é um campo obrigatório' : '';
    }

    async getBoleta(){

        await this.getProdutos();
        this.openLoading();
        this._boletaService.getById(this.data.id).subscribe(
             (boleta) => {
                this.valor.setValue(boleta.data.valor);                
                this.produto_id.setValue(boleta.data.produto_id);
                this.cliente.setValue(boleta.data.cliente);
                this.cliente_id.setValue(boleta.data.cliente.id);
                this.data_futura.setValue(boleta.data.data_futura);                          
                this.status_id.setValue(boleta.data.status_id);               
                this.qtd.setValue(boleta.data.qtd);
                this.vlr_spread.setValue(boleta.data.vlr_spread);
                this.observacao.setValue(boleta.data.observacao);
                this.compra_venda.setValue(boleta.data.compra_venda);
                
                const product_selected = this.listProduto.find(product => product.id === boleta.data.produto_id);

                if(product_selected == undefined){
                    this.listProduto.push(boleta.data.produto);
                }

                this.getRoa(boleta.data.produto_id);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();                
            }
        );

    }

    getAllStatus(){

        this.loadingSpinnerStatus = true;
        
        this._statusService.getLista({ tipo_produto: 'RF' }).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => { },
            () => {
                this.loadingSpinnerStatus = false;                
            }
        );
    }

    getProdutos(){

        this.loadingSpinnerProduto = true;        
        this._serviceProduto.getAll({inative: false, no_paginate: true}).subscribe(
            list => {
                if(this.listProduto.length == 0){
                    this.listProduto = list.data;
                }else{
                    this.listProduto.push(list.data)
                }
            },
            error => { },
            () => {
                this.loadingSpinnerProduto = false;                
            }
        );
    }

    getNomeEmissor(produto){
        
        if(produto.orgao_emissor != undefined){
            return produto.orgao_emissor.nome;
        }

        return 'Sem Emissor';
    }

    getReceitaRecebidaAssessor(){
        let valor = 0
        if(this.valor.value && this.vlr_spread.value){
            valor = this.valor.value*(this.vlr_spread.value/100)
        }
        this.receita_recebida.setValue(valor)
    }

}

