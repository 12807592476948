import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { CaracteristicaCoeService } from '../services/caracteristica-coe.service';
import { EstruturaCoeService } from '../services/estrutura-coe.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-cadastro-caracteristica-coe',
    templateUrl: './cadastro-caracteristica-coe.component.html',
    styleUrls: ['./cadastro-caracteristica-coe.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [EstruturaCoeService]
})

export class CadastroCaracteristicaCoeComponent extends BaseComponent {

    nome = new FormControl('', [Validators.required]);
    data_fim = new FormControl('', [Validators.required]);
    data_inicio = new FormControl('');
    codigo_cetip = new FormControl();
    estrutura_id = new FormControl();
    cdi = new FormControl();
    rent_atual = new FormControl();
    codigo = new FormControl();
    vlr_roa = new FormControl();
    dataObj = new FormControl('');

    model:any = {
        nome:"",
        datas_observacao:[]
    };

    loadingLocal:any;
    listEstrutura: any[] = [];
    loadingSpinnerEstrutura:boolean = false;

    constructor(public service: CaracteristicaCoeService,
                private _estruturaSevice: EstruturaCoeService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroCaracteristicaCoeComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, )
    {
        super(dialog, snackBar);
        if(this.data != null && this.data != undefined){
            this.getById();
        }
        this.getEstrutura();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }


    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.nome.valid){
            return true;
        }
        
        if (!this.vlr_roa.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.nome = this.nome.value;
        this.model.data_inicio = this.data_inicio.value;
        this.model.data_fim = this.data_fim.value;
        this.model.codigo_cetip = this.codigo_cetip.value;
        this.model.estrutura_id = this.estrutura_id.value;
        this.model.cdi = this.cdi.value;
        this.model.rent_atual = this.rent_atual.value;
        this.model.codigo = this.codigo.value;
        this.model.vlr_roa = this.vlr_roa.value;

        if(this.data == null){

            this.service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                                                       
                },
                error => {                    
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );

        }else{

            this.service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {                    
                    this.closeLoading();
                }
            );
        }

    }

    getErrorNameMessage() {
        return this.nome.hasError('required') ? 'Nome é um campo obrigatório' : '';
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {

            this.model = item.data;
            this.nome.setValue(item.data.nome);
            this.data_inicio.setValue(item.data.data_inicio);
            this.data_fim.setValue(item.data.data_fim);
            this.codigo_cetip.setValue(item.data.codigo_cetip);
            this.estrutura_id.setValue(item.data.estrutura_id);
            this.model.estrutura_id = item.data.estrutura_id;
            this.cdi.setValue(item.data.cdi);
            this.rent_atual.setValue(item.data.rent_atual);
            this.codigo.setValue(item.data.codigo);
            this.vlr_roa.setValue(item.data.vlr_roa);

            console.log(item.data);
            console.log(this.model);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    getEstrutura(){
        this.loadingSpinnerEstrutura = true;
        this._estruturaSevice.getAll(this.params).subscribe(
            resquest => {
                this.listEstrutura = resquest.data;
            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerEstrutura = false;
            },
            () => {

                console.log("complete");
                this.loadingSpinnerEstrutura = false;
            }
        );
    }

    addDataObservacao(){
        let newArray = [];

        newArray.push({"data":this.dataObj.value.format('YYYY-MM-DD')});

        for (let i = 0; i < this.model.datas_observacao.length; i++) {
            const element = this.model.datas_observacao[i];
            newArray.push(element);
        }

        this.model.datas_observacao = newArray;
    }

    deleteDataObservacao(item){
        this.model.datas_observacao = this.model.datas_observacao.filter((element) => {
            if (item != element){
                return element
            }
        });
    }

}
