import {
    Component,          
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/base-module/base-component/base.component';

export interface DialogData {
    cliente_id: number;
    dados: any
}

@Component({
    selector: "app-modal-relacionamento",
    templateUrl: "./modal-relacionamento.component.html",
    styleUrls: ["./modal-relacionamento.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ModalRelacionamentoComponent extends BaseComponent {
    model: any = {
        nome: "",
    };

    loadingLocal: boolean = false;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ModalRelacionamentoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 
        super(dialog, snackBar);
        console.log(this.data.dados);
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }
}
