import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {BaseComponent} from "../../base-module/base-component/base.component";
import {FormControl} from "@angular/forms";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FiltrarBoletaComponent } from 'app/movimentacao/filtrar-boleta/filtrar-boleta.component';
import { CadastraSaldoClienteComponent } from '../cadastra-saldo-cliente/cadastra-saldo-cliente.component';
import { SaldoClientesService } from '../services/saldo-clientes.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-lista-saldo-cliente',
    templateUrl: './lista-saldo-cliente.component.html',
    styleUrls: ['./lista-saldo-cliente.component.scss']
  })

export class ListaSaldoClienteComponent extends BaseComponent{

    params: any = {};
    user: any;
    loadingIndicator: boolean;
    inputSubscription: Subscription; 
    search = new FormControl();
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaSaldoClienteComponent, { static: false }) table: ListaSaldoClienteComponent;

    @ViewChild('table',{ static: false }) table2: DatatableComponent;

    constructor(public service: SaldoClientesService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService,
                private router: Router) {
        super(dialog,snackBar);

        this.user = JSON.parse(this._authService.getUser());

        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }
  

    /**
     * Consulta lista de empresas
     */
    getAll() {      

        this.loadingIndicator = true;
        this.inputSubscription = this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];

            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;                
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }


    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarBoletaComponent, {
            width: '700px',
            data: { 
                titulo: 'Filtrar Saldo de Clientes',
                status_boleta: false,
                status_ordem: false,
                tipo: "SC"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                this.params.data_operacao_inicio    = result.data_operacao_inicio;
                this.params.data_operacao_fim       = result.data_operacao_fim;
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }else{
                    this.params.cliente_id = '';
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }else{
                    this.params.assessor_id = '';
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.homebroker != undefined && result.homebroker != null) {
                    this.params.homebroker = result.homebroker;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    getEscritorioCliente(row){
        if(row.cliente.escritorio !=undefined && row.cliente.escritorio != ''){
            return row.cliente.escritorio.nome_fantasia;
        }

        return '';
    }

    getAssessorCliente(row){
        if(row.cliente.assessor !=undefined && row.cliente.assessor != ''){
            return row.cliente.assessor.name;
        }

        return '';
    }

    /**
     * Cor das linhas
     */
    getRowClass = (row) => {
        
        if(row.saldo_total < 0){
            return {'row-color-failed': true};
        }
    }

    exportarSaldo(){
        this.openLoading();
        this.service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    openCadastro(){
        let dialogRef = this.dialog.open(CadastraSaldoClienteComponent, {
            width: '400px',
            //data: { name: this.name, animal: this.animal }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                //this.showSuccess("Custódia cadastrada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                //this.showError("Ocorreu um erro ao tentar cadastrar a custódia dos clientes.");
            }
        });
    } 

    getSaldoTotal(row){
        return parseFloat(row.saldo_calculado);
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    onEnter(value: string) {    
        this.stopNow();     
        this.params.search = value;
        this.search.setValue(value);
        this.getAll();
    }

}
