import {
    Component, 
    ViewChild, 
    TemplateRef
}  from '@angular/core';

import {
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator
} from '@angular/material';

import { FormControl } from "@angular/forms";
import { Router } from '@angular/router';

import { BaseComponent } from '../../../base-module/base-component/base.component';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { FiltroListaDisponivelComponent } from '../filtro-lista-disponivel/filtro-lista-disponivel.component';

@Component({
    selector: 'app-lista-disponivel',
    templateUrl: './lista-disponivel.component.html',
    styleUrls: ['./lista-disponivel.component.scss'],
    providers:[ClienteService]
})

export class ListaDisponivelComponent extends BaseComponent{

    params: any = {};
    loadingIndicator: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaDisponivelComponent, { static: false }) table: ListaDisponivelComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;

    public search = new FormControl();

    constructor(public service: ClienteService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public route:Router) {
        super(dialog, snackBar);       

        this.search.setValue('');
        this.getAll();

    }  

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */

    pageEvent(event: PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {        

        this.loadingIndicator = true;
        this.service.getSaldoDisponivel(this.params).subscribe(
            data => 
            {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    getValorRV(row){
        return parseFloat(row.valor_boleta_rv);
    }

    getVlrDisponivel(row){
        return parseFloat(row.valor_disponivel) - parseFloat(row.valor_bloqueado);
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltroListaDisponivelComponent, {
            width: '700px',
            data: {
                tipo: 'disponivel'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) 
            {                
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.percentual != undefined && result.percentual != null) {
                    this.params.percentual = result.percentual;
                }

                if (result.tipo_percentual != undefined && result.tipo_percentual != null) {
                    this.params.tipo_percentual = result.tipo_percentual;
                }

                this.paginator.firstPage();
                
                this.getAll();
            }
        });
    }

    /**
     * Consulta lista
     */
    getAllExport() {

        this.openLoading();
        this.service.getAllExportSaldo(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {
                console.log(error);
                this.showError(error.error.message);
                this.closeLoading();
            },
            () => {

                console.log("complete");
                this.closeLoading();
            }
        );
    }

    redirecionaCliente(cliente){
        this.route.navigate([`clientes/cadastro/${cliente}`]);
    }
}