import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { PastaService } from '../services/pasta.service';
import { ArquivoService } from '../services/arquivo.service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { RolesService } from 'app/usuarios/services/roles.service';
import { FormControl } from '@angular/forms';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';

export interface DialogData {
    pasta_id: number;
}

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
    providers: [ EscritorioService, RolesService ]
})

export class UploadFileComponent extends BaseComponent implements OnInit {

    users  = new FormControl();

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;
    listaGrupo: any[]=[];
    listaUsuario: any[]=[];    
    listaEscritorio: any[]=[];    
    selectedOptions=[];
    selectedOptionsEscritorio=[];
    selectedOption;
    usuario:any;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UploadFileComponent>,
    public service: PastaService,
    public utiliesService:UtilitesService,
    public arquivoService:ArquivoService,
    public authService: AuthService,
    public userService: UsuarioService,
    public roleService: RolesService,
    private _authService: AuthService,
    private _escritoService: EscritorioService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

    super(dialog, snackBar);
    this.nomeFile = null;    

    this.usuario = JSON.parse(this._authService.getUser());

    this.getAllGrupos();
    this.getAllUsuario();
    this.getAllEscritorios();

    this.uploader = new FileUploader({
      url: this.utiliesService.urlPrefix + 'file-manager/arquivo/uploads',
      isHTML5: true,
      authToken: "Bearer " + this.authService.getToken()
    });    

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.closeLoading();
        
        if (status == 200){
            var info = JSON.parse(response);
        
            this.showSuccess(info.message);
            this.dialogRef.close(info);
        }else{
            var info = JSON.parse(response);
            this.showError(info.message);
            //this.dialogRef.close("cancel");
        }      
      
    };
    
  }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    onChangeUser(){        

        this.uploader.onBuildItemForm = (fileItem, form) => {
            form.append('user_id', this.users.value);
            return {fileItem, form};
        };
    }

    onNgModelChange($event){
        this.selectedOption = $event;

        this.uploader.onBuildItemForm = (fileItem, form) => {
            form.append('role_id', this.selectedOptions);
            return {fileItem, form};
        };
    }

    onNgModelChangeEscritorio($event){
        this.selectedOptionsEscritorio = $event;

        this.uploader.onBuildItemForm = (fileItem, form) => {
            form.append('escritorio_id', this.selectedOptionsEscritorio);
            return {fileItem, form};
        };
    }

    uploadFile(event) {    //line 1

        //console.log(event);
        let elem = event.target;  //line 2
        //console.log(elem);
        if (elem.files.length > 0) {     //line 3
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }

    isNomeFile(){
        return (this.nomeFile != null);
    }

    getAllGrupos(): void {    
    this.roleService.getAll({no_paginate:true}).subscribe(
        data => {
            this.listaGrupo = data.data;
        },
        error => {
            console.log("error: " + error);
            this.loadingIndicator = false;
        },
        () => {
            console.log("complete");
            this.loadingIndicator = false;
        }
    );
    }

    getAllUsuario(): void {    
        this.userService.getAllUsuarios({no_paginate:true}).subscribe(
            data => {                
                this.listaUsuario = data;
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }
    
    getAllEscritorios(): void {
        this._escritoService.getTodos({no_paginate: true}).subscribe(
            data => {
                this.listaEscritorio = data.data;
            },
            error => {
                console.log("error: " + error);                
            },
            () => {
                console.log("complete");                
            }
        );
    }
    

    startUpload() {        
        
        if(this.uploader.queue[0] != null && this.uploader.queue[0] != undefined){
            this.openLoading();
            this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
                form.append('pasta_id' , this.data.pasta_id);

                if(this.selectedOptionsEscritorio.length > 0){
                    form.append('escritorio_id', this.selectedOptionsEscritorio);    
                }

                if(this.selectedOptions.length > 0){
                    form.append('role_id', this.selectedOptions);
                }
                
                if(this.users.value != undefined && this.users.value != ''){
                    form.append('user_id', this.users.value);
                }
            };
            this.uploader.queue[0].upload();
        }else{
            this.showError('Escolha um arquivo antes de enviar!');
        }
        
    }

}
