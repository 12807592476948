import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { Observable } from 'rxjs';

export interface Noticia {
    id: number;
    data_cadastro: Date;
    user: any;
    categoria: any;
    texto: string;
    url: string;
    titulo: string;
    created_at: Date; 
    midia: any;
}


@Injectable({
    providedIn: 'root'
})
export class NoticiaService {

    public readonly CENTRAL_NOTICIAS_PATH = `${this.utilitesService.urlPrefix}central-noticias/noticia`;

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
    * categoria por id
    * @param id ID
    */
    getById(id): Observable<any> {
        return this._http.get(this.CENTRAL_NOTICIAS_PATH + '/'+id, { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Lista de  categoria de mídia
     * @param id ID
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.CENTRAL_NOTICIAS_PATH + env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de  categoria de mídia
     * @param id ID
     */
    delete(id): Observable<any> {

        return this._http.delete(this.CENTRAL_NOTICIAS_PATH + '/'+id, { headers: this.authService.getAuthHeaders() });
    }

    createOrUpdate(params): Observable<any> {
        if(!params.id || params.id == undefined || params.id == null || params.id == ""){
            return this.create(params);
        }
        else {
            return this.update(params.id, params);
        }
    }

    /**
     * Cadastra  categoria de mídia
     * @param params Json Usuário
     */
    create(params): Observable<any> {
        return this._http.post(this.CENTRAL_NOTICIAS_PATH, params, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza categoria de mídia
     * @param params Json Usuário
     */
    update(id, params): Observable<any> {
        return this._http.put(this.CENTRAL_NOTICIAS_PATH + '/'+id, params, { headers: this.authService.getAuthHeaders() });
    }

    getUrlMidia(id): string {
        return this.CENTRAL_NOTICIAS_PATH +  '/'+ id + "/midia";
    }

    getUrlThumbnail(id): string {
        return this.getUrlMidia(id) + "?thumbnail=true";
    }

}
