import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { Observable } from 'rxjs';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';


export interface DialogData {
    id: number;
    titulo:string;
    campos: {
        data:boolean,
        cnpj:boolean
    };
} 

@Component({
    selector: 'app-filtro-caracteristica-coe',
    templateUrl: './filtro-caracteristica-coe.component.html',
    styleUrls: ['./filtro-caracteristica-coe.component.scss']
})
export class FiltroCaracteristicaCoeComponent extends BaseComponent {

    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);  

    data_operacao_inicio = new FormControl(this.primeiroDia);
    data_operacao_fim = new FormControl(); 

    nome = new FormControl('');
    cetip = new FormControl('');
    cnpj = new FormControl('');
   

    loadingSpinner: boolean;
    loadingSpinnerStatus: boolean;
    loadingSpinnerAssessor: boolean;
    filteredOptions: Observable<string[]>;
    listAssessor: any[] = [];
    listEscritorios: any[] = [];    
    loadingSpinnerEscritorio:boolean = false;
    usuario:any;
    titulo:string;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;    
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltroCaracteristicaCoeComponent>,  
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _utilitesService: UtilitesService) {

        super(dialog, snackBar);
        this.titulo = this.data.titulo;
    }   

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar()
    {        
        var inic = null;
        if (this.data_operacao_inicio.value != null){
            inic = this._utilitesService.formatDateToString(new Date(this.data_operacao_inicio.value));
        }

        var fim = null;
        if (this.data_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(new Date(this.data_operacao_fim.value));
        }

        this.dialogRef.close({
            'nome': this.nome.value,
            'cnpj': this.cnpj.value,
            'cetip': this.cetip.value,
            'data_inicio': inic,
            'data_fim': fim
        });
    }

}