import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatSnackBar }  from '@angular/material';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { FileManagerService } from 'app/apps/file-manager/file-manager.service';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { CriarPastaComponent } from './criar-pasta/criar-pasta.component';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
    selector     : 'file-manager',
    templateUrl  : './file-manager.component.html',
    styleUrls    : ['./file-manager.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class FileManagerComponent implements OnInit, OnDestroy
{
    selected: any;
    pathArr: string[] = ['Gerenciador de Arquivos'];
    params: any = {
        tipo: ''
    };
    pastaPrincipal:boolean = true;
    usuario:any;    

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FileManagerService} _fileManagerService
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _fileManagerService: FileManagerService,
        private _fuseSidebarService: FuseSidebarService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.usuario = JSON.parse(this._authService.getUser());

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void
    {
        this.getAll();         
    }

    getAll(): void 
    {
        this._fileManagerService.onFileSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selected => {                
                this.selected = selected;                
                this.pathArr = selected.location.split('>');
        });
    }

    /**
     * Exibe mensagem de sucesso
     * @param {string} message
     */
    showSuccess(message: string): void {
        this.snackBar.open(message, 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    /**
     * Exibe mensagem de erro
     * @param {string} message
     */

    showError(message: string): void {
        this.snackBar.open(message, 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Modal de cadastro
     */
    newArchive(): void {

        console.log(this.selected);

        let dialogRef = this.dialog.open(UploadFileComponent, {
            width: '600px',
            data: {
                pasta_id: this.selected.id_pasta_atual,
            }    
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Arquivo Criado com Sucesso!");
                
                this.params.pasta_id = this.selected.id;
                this._fileManagerService.getFiles(this.params);

            }else if(result != undefined && result != "cancel"){
                this.showError("Não foi possivel realizar o upload do arquivo. Tente novamente!");
            }
        });
    }

    /**
     * Modal de cadastro
     */
    novaPasta(): void {

        let dialogRef = this.dialog.open(CriarPastaComponent, {
            width: '600px',
            data: {
                pasta_id: this.selected.id,
                pasta_pasta_id: this.selected.pasta_id,
                selected: this.selected
            }      
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Pasta salva com Sucesso!");

                this.params.pasta_id = this.selected.id;               

                if(this.selected.pasta_id == null){
                    this._fileManagerService.getFiles({});
                }else{
                    this._fileManagerService.getFiles(this.params);
                }

            }else if(result != undefined && result != "cancel"){
                this.showError("Não foi possivel criar a pasta. Tente novamente!");
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

}
