import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})

export class HomeComponent extends BaseComponent implements OnInit  {

    user:any;
    dateNow = Date.now();

    constructor( 
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public _router: Router,
        private _authService: AuthService) { 

            super(dialog, snackBar);
            this.user = JSON.parse(this._authService.getUser());

            setInterval(() => {
                this.dateNow = Date.now();
            }, 1000);
        }

    ngOnInit() {
    }

}
