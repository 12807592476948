import {
    Component,     
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/base-module/base-component/base.component';

export interface DialogData {
    dados: any
}

@Component({
    selector: 'app-modal-show-atividade',
    templateUrl: './modal-show-atividade.component.html',
    styleUrls: ['./modal-show-atividade.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ModalShowAtividadeComponent extends BaseComponent {
    model: any = {
        nome: "",
    };

    loadingLocal: boolean = false;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ModalShowAtividadeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }
}
