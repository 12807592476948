import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { Observable } from 'rxjs';



export interface CategoriaNoticia {
    id: number;
    nome: string;
    deleted: boolean;
    created_at: Date;
    updated_at: Date;
}

@Injectable({
  providedIn: 'root'
})
export class CategoriaNoticiaService {

    private readonly CENTRAL_NOTICIAS_CATEGORIA_PATH = `${this.utilitesService.urlPrefix}central-noticias/categoria`;

    constructor(private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService) {
    }

    /**
    * categoria por id
    * @param id ID
    */
    getById(id): Observable<any> {
        return this._http.get(this.CENTRAL_NOTICIAS_CATEGORIA_PATH + '/'+id, { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Lista de  categoria de mídia
     * @param id ID
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.CENTRAL_NOTICIAS_CATEGORIA_PATH + env, { headers: this.authService.getAuthHeaders() });
    }

    /**
    * Lista de Profissão do Cliente Para Campo Do Cadastro
    */
    getLista(): Observable<any> {
        return this._http.get(this.CENTRAL_NOTICIAS_CATEGORIA_PATH + "/lista", { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Delete de  categoria de mídia
     * @param id ID
     */
    delete(id): Observable<any> {

        return this._http.delete(this.CENTRAL_NOTICIAS_CATEGORIA_PATH + '/'+id, { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Cadastra  categoria de mídia
     * @param params Json Usuário
     */
    create(params) {
        return this._http.post(this.CENTRAL_NOTICIAS_CATEGORIA_PATH, params, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza categoria de mídia
     * @param params Json Usuário
     */
    update(id, params) {
        return this._http.put(this.CENTRAL_NOTICIAS_CATEGORIA_PATH + '/'+id, params, { headers: this.authService.getAuthHeaders() });
    }
}
