import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { ClienteProjetoService } from '../services/cliente-projeto.service';

export interface DialogData {
    id: any;
    cliente_id: any;
    is_financeiro: boolean;
}

@Component({
    selector: 'app-cadastro-cliente-projeto',
    templateUrl: './cadastro-cliente-projeto.component.html',
    styleUrls: ['./cadastro-cliente-projeto.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
    ]
})

export class CadastroClienteProjetosComponent extends BaseComponent {

    projeto = new FormControl('', [Validators.required]);
    descricao = new FormControl('', [Validators.required]);
    quando = new FormControl('', [Validators.required]);
    valor  = new FormControl('0', [Validators.required]);

    model:any = {
        projeto:"",
        descricao:"",
        quando:"",
        valor:"",
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerTipoProjeto: boolean = false;
    listaTipoProjeto: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: ClienteProjetoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClienteProjetosComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData)
    {
        super(dialog, snackBar);
        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){

        if (!this.descricao.valid){
            return true;
        }
        
        if (!this.valor.valid) {
            return true;
        }
        
        if (!this.projeto.valid) {
            return true;
        }

        if (!this.quando.valid) {
            return true;
        }

        return false;
    }

    salvar(){ 
        this.openLoading();
        
        this.model = {
            projeto: this.projeto.value,
            descricao: this.descricao.value,
            quando: this.quando.value,
            valor: this.valor.value,
            cliente_id: this.data.cliente_id
        };
        
        if(this.data.id == null){
        
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.projeto.setValue(item.data.projeto)
                this.descricao.setValue(item.data.descricao)
                this.quando.setValue(item.data.quando)
                this.valor.setValue(item.data.valor)
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }
}

