import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { StatusOperacaoService } from '../services/status-operacao.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FiltrarInconsistenciasOrdensComponent } from '../filtrar-inconsistencias-ordens/filtrar-inconsistencias-ordens.component';

@Component({
    selector: 'app-lista-iconsistencia-cliente',
    templateUrl: './lista-iconsistencia-cliente.component.html',
    styleUrls: ['./lista-iconsistencia-cliente.component.scss']
})

export class ListaIconsistenciaClienteComponent extends BaseComponent{

    params: any = {};
    user: any;
    loadingIndicator: boolean;
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaIconsistenciaClienteComponent, { static: false }) table: ListaIconsistenciaClienteComponent;

    @ViewChild('table',{ static: false }) table2: DatatableComponent;

    constructor(public service: StatusOperacaoService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);

        this.user = JSON.parse(this._authService.getUser());

        this.getAll();

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event: any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {

        this.loadingIndicator = true;        
        this.service.getAllClienteInconsistencias(this.params).subscribe(
            data => {                
                this.length = data.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    } 
    
    filtra() {
        let dialogRef = this.dialog.open(FiltrarInconsistenciasOrdensComponent, {
            width: '700px'
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result != "cancel" && result != undefined) 
            {
               
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }else{
                    this.params.cliente_id = '';
                }

                if (result.data_operacao_inicio != undefined && result.data_operacao_inicio != null) {
                    this.params.data_operacao_inicio = result.data_operacao_inicio;
                }

                if (result.data_operacao_fim != undefined && result.data_operacao_fim != null) {
                    this.params.data_operacao_fim = result.data_operacao_fim;
                }
               
                if (result.ativo != undefined && result.ativo != null) {
                    this.params.ativo_id = result.ativo.id;
                }               
                
                this.getAll();

                this.paginator.firstPage();
            }
        });
    }

}
