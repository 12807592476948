import {Component, ViewEncapsulation,Inject} from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { ClienteCarteiraConsorcioService } from '../services/cliente-carteira-consorcio.service';

export interface DialogData {
    cliente_id: any;
    id: any;
    cliente: any;
    type:any;
}

@Component({
    selector: 'app-visualizar-carteira-consorcio',
    templateUrl: './visualizar-carteira-consorcio.component.html',
    styleUrls: ['./visualizar-carteira-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class VisualizarCarteiraConsorcioComponent extends BaseComponent {

    model:any = {
        cliente_id:null,
        cliente:{
            nome: ""
        },
        tipo_consorcio_id:null,
        empresa_consorcio_id:null,
        empresa_consorcio: {
            nome: ""
        },
        tipo_consorcio:{
            nome:""
        },
        valor_contratado:null,
        data_atualizacao:null,
        grupo:null,
        cota:null,
        prazo_grupo:null,
        prazo_cliente:null,
        taxa_administracao:null,
        valor_lance_potencial:null,
        valor_lance_efetivado_contemplacao:null,
        valor_reembolso:null,
        valor_pago:null,
        saldo_devedor:null,
        liberacao_credito:null,
        valor_credito_liberado:null,
        credito_autorizado:null,
        boleta:{
            historico:[]
        }
    };

    loadingLocal:any;

    constructor(public _service: ClienteCarteiraConsorcioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<VisualizarCarteiraConsorcioComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData
                )
    {
        super(dialog, snackBar);
        this.getById();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
         item => {

            this.model = item.data;

        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }


}
