//Services
import {UtilitesService} from './utilites.service';

//Models
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable()
export class PasswordService {
    constructor(
        private _http: HttpClient,
        public utilitesService: UtilitesService
    ) {}

    /**
     * Envio de link por e-mail
     * @param data
     * @returns {Observable<Object>}
     */
    public sendLinkEmail(data) {
        return this._http.post(
            this.utilitesService.urlPrefix + "password/email",
            data
        );
    }

    /**
     * Altera senha do usuário
     * @param data
     * @returns {Observable<Object>}
     */
    public resetPassword(data): Observable<any> {
        return this._http.post(
            this.utilitesService.urlPrefix + "password/reset",
            data
        );
    }
}