import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/base-module/base-service/auth-guard.service';
import { ListaBoletaComponent } from './lista-boleta/lista-boleta.component';
import { CadastroBoletaComponent } from './cadastro-boleta/cadastro-boleta.component';
import { ListaAporteComponent } from './lista-aporte/lista-aporte.component';
import { CadastroAporteComponent } from './cadastro-aporte/cadastro-aporte.component';
import { ListaHistoricoComponent } from './lista-historico/lista-historico.component';
import { ListaCustodiaComponent } from './lista-custodia/lista-custodia.component';
import { ListaHistoricoCustodiaComponent } from './lista-historico-custodia/lista-historico-custodia';
import { ListaBoletaUnicaComponent } from './lista-boleta-unica/lista-boleta-unica.component';
import { CadastroBoletaUnicaComponent } from './cadastro-boleta-unica/cadastro-boleta-unica.component';

const routes: Routes = [{
    path: '',
    children: [
        {
            path: 'boleta/1',
            component: ListaBoletaComponent,
            data: { tipo: '1' }
        },
        {
            path: 'boleta/2',
            component: ListaBoletaComponent,
            data: { tipo: '2' }
        },
        {
            path: 'boleta/3',
            component: ListaBoletaComponent,
            data: { tipo: '3' }
        },
        {
            path: 'boleta/cadastro/:tipo',
            component: CadastroBoletaComponent
        },
        {
            path: 'boleta/nova/:tipo/:cliente_id',
            component: CadastroBoletaComponent
        },
        {
            path: 'boleta/cadastro/:tipo/:id',
            component: CadastroBoletaComponent
        },
        {
            path: 'boleta/cadastro/acao-comercial/:tipo/:acao_comercial/:cliente_id',
            component: CadastroBoletaComponent
        },
        {
            path: 'boleta-unica',
            component: ListaBoletaUnicaComponent,            
        },
        {
            path: 'boleta-unica/cadastro',
            component: CadastroBoletaUnicaComponent
        }, 
        {
            path: 'boleta-unica/cadastro/:id',
            component: CadastroBoletaUnicaComponent
        },
        {
            path: 'boleta-unica/cadastro/acao-comercial/:acao_comercial_id/:cliente_id',
            component: CadastroBoletaUnicaComponent
        },
        {
            path: 'boleta-unica/visualizar/:id',
            component: CadastroBoletaUnicaComponent,
            data: { tipo: 'Visualizar' }
        },      
        {
            path: 'aporte-retirada',
            component: ListaAporteComponent
        },
        {
            path: 'aporte-retirada/cadastro',
            component: CadastroAporteComponent
        },
        {
            path: 'aporte-retirada/cadastro/:id',
            component: CadastroAporteComponent
        },
        {
            path: 'historico-movimentacao',
            component: ListaHistoricoComponent
        },
        {
            path: 'custodia',
            component: ListaCustodiaComponent
        },
        {
            path: 'historico-custodia',
            component: ListaHistoricoCustodiaComponent
        },
      
    ],
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class MovimentacaoRoutesModule {}