import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject, 
    OnInit
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';

import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { TipoOportunidadeService } from '../../auxiliares/services/tipo-oportunidade-service';
import { ClienteOportunidadeService } from '../services/cliente-oportunidade.service';
import { ClienteService } from '../services/cliente.service';

export interface DialogData {
    id: number;
    cliente_id: number;
}

@Component({
    selector: "app-cadastro-oportunidade",
    templateUrl: "./cadastro-oportunidade.component.html",
    styleUrls: ["./cadastro-oportunidade.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})

export class CadastroOportunidadeComponent extends BaseComponent implements OnInit {
    descricao = new FormControl("", [Validators.required]);
    data_oportunidade = new FormControl(new Date(), [Validators.required]);
    tipo_oportunidade_id = new FormControl("", [Validators.required]);
    valor = new FormControl("");
    data_retorno = new FormControl("");
    cliente = new FormControl("");
    cliente_edit = new FormControl("");
    model: any = {
        cliente_id: "",
        tipo_oportunidade_id: "",
        descricao: "",
        valor: "",
        dara_retorno: "",
        data_oportunidade: "",
    };

    loadingLocal: any;
    listTipo: [];
    loadingSpinnerTipo: any;
    loadingSpinner: boolean = false;
    todayDate: Date = new Date();
    showClientField:boolean = false;

    filteredOptions: Observable<string[]>;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroOportunidadeComponent, { static: false })
    table: CadastroOportunidadeComponent;

    constructor(
        public service: ClienteOportunidadeService,
        public _tipoOportunidadeService: TipoOportunidadeService,
        private _clienteService: ClienteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public authService: AuthService,
        public dialogRef: MatDialogRef<CadastroOportunidadeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);

        if (this.data.id) {
            this.getById();
        }
        this.getAllTipoOportunidade();
    }

    ngOnInit(): void {
        if(this.data.cliente_id == null || this.data.cliente_id == undefined) {
            this.showClientField = true;
            this.cliente.valueChanges.subscribe((query) => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            });
        }else{
            this.showClientField = false;
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }   

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (!this.descricao.valid) {
            return true;
        }

        if (!this.tipo_oportunidade_id.valid) {
            return true;
        }

        return false;
    }

    salvar() {
        this.openLoading();
        if ( this.data.cliente_id ){
            this.model.cliente_id   = this.data.cliente_id;            
        }else{
            const clienteDados = this.cliente.value;
            this.model.cliente_id   = clienteDados.id;
        }

        this.model.descricao = this.descricao.value;
        this.model.data_oportunidade = this.data_oportunidade.value;
        this.model.valor = this.valor.value;
        this.model.data_retorno = this.data_retorno.value;

        if (this.data.id == null) {
            this.service.create(this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        } else {
            this.service.update(this.data.id, this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getErrorNameMessage() {
        return this.descricao.hasError("required")
            ? "Nome é um campo obrigatório"
            : "";
    }

    getById() {
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
            (item) => {
                this.model = item.data;
                this.cliente.setValue(item.data.cliente);
                this.cliente_edit.setValue(item.data.cliente.nome);
                this.cliente_edit.disable();
                this.tipo_oportunidade_id.setValue(item.data.tipo_oportunidade_id);
                this.descricao.setValue(item.data.descricao);
                this.data_oportunidade.setValue(item.data.data_oportunidade);
                this.valor.setValue(item.data.valor);
                this.data_retorno.setValue(item.data.data_retorno);
            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getAllTipoOportunidade() {
        this.loadingSpinnerTipo = true;
        this._tipoOportunidadeService.getLista().subscribe(
            (list) => {
                this.listTipo = list.data;
            },
            (error) => {  },
            () => {
                this.loadingSpinnerTipo = false;
            }
        );
    }

    isEdit(){
        return (this.data.id)? true : false;
    }
}
