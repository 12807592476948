import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl} from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { ClienteService } from '../services/cliente.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { AcaoService } from 'app/auxiliares/services/acao-service';
import { Observable } from 'rxjs';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { TipoOportunidadeService } from 'app/auxiliares/services/tipo-oportunidade-service';
import { ListaAtividadeAssuntoService } from 'app/lista-atividades/services/lista-atividade-assunto.service';

export interface DialogData {    
    cliente: any;
    titulo: string;
    type_page: string;
}

@Component({
    selector: 'app-filtrar-relacionamento',
    templateUrl: './filtrar-relacionamento.component.html',
    styleUrls: ['./filtrar-relacionamento.component.scss'],
    providers: [ AcaoService, EscritorioService, ListaAtividadeAssuntoService ]
})

export class FiltrarRelacionamentoComponent extends BaseComponent {
    
    acao_id = new FormControl('', []);
    data_hora_operacao_inicio = new FormControl(new Date(), []);
    data_hora_operacao_fim = new FormControl(new Date(), []);
    escritorio_id = new FormControl('',[]);
    assessor_id = new FormControl('',[]);
    assunto_id = new FormControl('',[]);

    model:any = {
        tipo_cliente:null
    }

    cliente = new FormControl('');
    filteredOptions: Observable<string[]>;
    usuario:any;
    listAcoes: any[] = [];
    listAssessor: any[] = [];
    listEscritorios: any[] = [];
    listTipoOportunidade: any[] = [];
    listAssuntos: any[] = [];
    loadingSpinnerAcao:boolean = false;
    loadingSpinner:boolean = false;
    loadingSpinnerTipoOportunidade:boolean = false;
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerEscritorio:boolean = false;
    loadingSpinnerAssunto:boolean = false;
    habilitaEscritorios: boolean = false;
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltrarRelacionamentoComponent, { static: false }) table: FiltrarRelacionamentoComponent;

    constructor(public service: ClienteService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<FiltrarRelacionamentoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public acoesService:AcaoService,
                public _tipoOportunidadeService: TipoOportunidadeService,
                private _authService:AuthService,
                private _utilitesService: UtilitesService,
                private usuarioService: UsuarioService,
                private escritorioService: EscritorioService,
                private _listaAtividadeAssuntoService: ListaAtividadeAssuntoService) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this.service.search(this.params);
                }
            }
        );
        
        if ( this.usuario.escritorios.length > 1){
            this.habilitaEscritorios = true;
        }
        
        this.getAssessores();
        this.getAllEscritorios();

        if(this.isOportunidade()){
            this.getAllTipoOportunidade();            
        }else{
            this.getAcoes();
        }

        this.getAllAssuntos();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }

    filtrar(){

        var inic = null;
        if (this.data_hora_operacao_inicio.value != null){
            inic = this._utilitesService.formatDateToString(new Date(this.data_hora_operacao_inicio.value));
        }

        var fim = null;
        if (this.data_hora_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(new Date(this.data_hora_operacao_fim.value));
        }

        this.dialogRef.close({
            acao_id: this.acao_id.value,
            data_inicio: inic,
            data_fim: fim,
            cliente: this.cliente.value,
            assessor_id: this.assessor_id.value,
            escritorio_id: this.escritorio_id.value,
            assunto_id: this.assunto_id.value
        });
    }

    getAcoes() {
        this.loadingSpinnerAcao = true;
        this.acoesService.getLista()
            .subscribe(
                resquest => {
                    this.listAcoes = resquest.data;
                },
                error => {
                    this.loadingSpinnerAcao = false;
                },
                () => {
                    this.loadingSpinnerAcao = false;
                }
            );
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }
    
    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this.escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {
        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
                console.log("complete");
            });
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    getAllTipoOportunidade() {
        this.loadingSpinnerAcao = true;
        this._tipoOportunidadeService.getLista().subscribe(
            (list) => {
                this.listAcoes = list.data;
            },
            (error) => {  },
            () => {
                this.loadingSpinnerAcao = false;
            }
        );
    }

    getAllAssuntos() {
        this.loadingSpinnerAssunto = true;
        this._listaAtividadeAssuntoService.getLista({is_subject_prosperidade: false}).subscribe(
            list => {
                this.listAssuntos = list.data;
            },
            error => {
                this.loadingSpinnerAssunto = false;
            },
            () => {
                this.loadingSpinnerAssunto = false;
            });
    }

    isOportunidade() {
        return this.data.type_page == 'oportunidade';
    }
}