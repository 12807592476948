import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { EstruturaFamiliarService } from '../services/estrutura-familiar.service';
import { ParentescoService } from '../services/parentesco.service';
import { TipoReceitaService } from '../services/tipo-receita.service';
import { ClienteReceitaService } from '../services/cliente-receita.service';

export interface DialogData {
    id: any;
    cliente_id: any;
}

@Component({
    selector: 'app-cadastro-cliente-receitas',
    templateUrl: './cadastro-cliente-receitas.component.html',
    styleUrls: ['./cadastro-cliente-receitas.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ 
        EstruturaFamiliarService,
        ParentescoService
    ]
})

export class CadastroClienteReceitasComponent extends BaseComponent {

    descricao = new FormControl('', [Validators.required]);
    tipo_receita_id = new FormControl('', []);
    valor  = new FormControl('0', [Validators.required]);

    model:any = {
        descricao:"",
        tipo_receita_id:"",
        valor:"",
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerTipoReceita: boolean = false;
    listaTipoReceita: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: ClienteReceitaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClienteReceitasComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _tipoReceitaService: TipoReceitaService)
    {
        super(dialog, snackBar);
        
        this.getTipoReceita();

        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){

        if (!this.descricao.valid){
            return true;
        }
        
        if (!this.valor.valid) {
            return true;
        }

        return false;
    }

    salvar(){ 
        this.openLoading();
        
        this.model = {
            descricao: this.descricao.value,
            tipo_receita_id: this.tipo_receita_id.value,
            valor: this.valor.value,
            cliente_id: this.data.cliente_id
        };
        
        if(this.data.id == null){
        
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.descricao.setValue(item.data.descricao);
                this.tipo_receita_id.setValue(item.data.tipo_receita_id);
                this.valor.setValue(item.data.valor);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );

    }

    getTipoReceita() {
        this.loadingSpinnerTipoReceita = true;
        this._tipoReceitaService.getAll(this.params).subscribe(
            resquest => {
                this.listaTipoReceita = resquest.data;
            },
            error => {
                this.loadingSpinnerTipoReceita = false;
            },
            () => {
                this.loadingSpinnerTipoReceita = false;
            }
        );
    }
}

