import {EventEmitter, Injectable}   from "@angular/core";
import { UtilitesService }          from "../../base-module/base-service/utilites.service";
import { AuthService }              from "../../base-module/base-service/auth.service";
import { Observable }               from "rxjs";
import {HttpClient}                 from "@angular/common/http";

@Injectable()
export class ProfissaoService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Profissão do Cliente por id
     * @param id ID da Profissão do Cliente
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "profissao/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Profissão do Cliente
     * @param id ID da Profissão do Cliente
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "profissao"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Profissão do Cliente Para Campo Do Cadastro
     */
    getLista(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "profissao/lista", { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de Profissão do Cliente
     * @param id ID da Profissão do Cliente
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "profissao/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Profissão do Cliente
     * @param params Json Profissão do Cliente
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "profissao", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza Profissão do Cliente
     * @param params Json Profissão do Cliente
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "profissao/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista
     * @param id ID
     */
    search(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "profissao/search" + env, { headers: this.authService.getAuthHeaders() });
    }

}
