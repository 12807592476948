import {EventEmitter, Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class TipoOperacaoPlanoSaudeService {

    public empresaPadrao = new EventEmitter<any>();

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * TipoOperacao Plano de Saúde por id
     * @param id ID da TipoOperacao Plano de Saúde
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "plano-saude/tipo-operacao/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de TipoOperacao Plano de Saúde
     * @param id ID da TipoOperacao Plano de Saúde
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "plano-saude/tipo-operacao"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de TipoOperacao Plano de Saúde
     * @param id ID da TipoOperacao Plano de Saúde
     */
    search(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "plano-saude/tipo-operacao/search"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete de TipoOperacao Plano de Saúde
     * @param id ID da TipoOperacao Plano de Saúde
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "plano-saude/tipo-operacao/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra TipoOperacao Plano de Saúde
     * @param params Json TipoOperacao Plano de Saúde
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "plano-saude/tipo-operacao", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Atualiza TipoOperacao Plano de Saúde
     * @param params Json TipoOperacao Plano de Saúde
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "plano-saude/tipo-operacao/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }
}
