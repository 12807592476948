import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { CadastroNoticiaComponent } from '../cadastro-noticia/cadastro-noticia.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { Noticia, NoticiaService } from '../services/noticia.service';
import { CategoriaNoticiaService } from '../services/categoria.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';

@Component({
    selector: 'central-noticias-lista',
    templateUrl: './lista-noticias.component.html',
    styleUrls: ['./lista-noticias.component.scss'],
    animations: fuseAnimations
})
export class ListaNoticiasComponent extends BaseComponent implements OnInit {
    readonly QTD_ITEMS_PAGINA = 16;

    listEstrategia: any[] = [];
    loadingSpinnerCategoria: boolean = false;
    loadingSpinnerNoticias: boolean = false;
    filteredNoticias: Noticia[] = [];
    listCategorias: any[] = [];
    

    primeiroDia = new Date();
    ultimoDia = new Date();
    data_noticia_inicio = new FormControl(this.primeiroDia);
    data_noticia_fim = new FormControl(this.ultimoDia);
    categoria_id = new FormControl('');

    constructor(public _categoriaService: CategoriaNoticiaService,
        private _service: NoticiaService,
        private _utilitiesService: UtilitesService,
        public dialog: MatDialog,
        public _authService: AuthService,
        public snackBar: MatSnackBar) {
        super(dialog, snackBar);
        
        this.params.per_page = this.QTD_ITEMS_PAGINA;
        this.pageSize = this.QTD_ITEMS_PAGINA;

        this.primeiroDia.setDate(this.primeiroDia.getDate() - 8);
        this.getCategorias();
        this.getAll();
    }


    getCategorias() {

        this.loadingSpinnerCategoria = true;

        this._categoriaService.getLista().subscribe(
            list => {
                this.loadingSpinnerCategoria = false;
                this.listCategorias = list.data;
            },
            error => {
                this.loadingSpinnerCategoria = false;
                console.error(error);

            },
            () => {
                this.loadingSpinnerCategoria = false;                
            }
        );
    }
    /**
     * Consulta lista de Mídias
     */
    getAll() {
        this.params.page = 1;
        this.filteredNoticias = [];
        this.getNoticias();
    }

    getNextPage() {
        this.params.page = this.params.page + 1;
        this.getNoticias();
    }

    private getNoticias() {
        this.loadingSpinnerNoticias = true;

        if (this.data_noticia_inicio.value != null) {
            this.params.data_inicio = this._utilitiesService.formatDateToString(new Date(this.data_noticia_inicio.value));
        }

        if (this.data_noticia_fim.value != null) {
            this.params.data_fim = this._utilitiesService.formatDateToString(new Date(this.data_noticia_fim.value));
        }

        this.params.categoria_id = this.categoria_id.value;

        this._service.getAll(this.params).subscribe(
            list => {
                this.loadingSpinnerNoticias = false;
                this.filteredNoticias = this.filteredNoticias.concat(list.data);
            },
            error => {
                this.loadingSpinnerNoticias = false;
            },
            () => {
                this.loadingSpinnerNoticias = false;
            }
        );
        this.loadingSpinnerNoticias = false;
    }

    removerDaLista(id){
        var itemKey = Object.keys(this.filteredNoticias).find(key => this.filteredNoticias[key].id === id); 
        if(itemKey != undefined){
            this.filteredNoticias.splice(parseInt(itemKey), 1);
            console.log("Item Apagado: "+ itemKey)
        }
    }

    openCadastro() {
        let dialogRef = this.dialog.open(CadastroNoticiaComponent, {
            panelClass: 'scrumboard-card-dialog'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Notícia Cadastrada com sucesso!");
                this.getAll();
            }
        });
    }

    ngOnInit() {
    }

    onScroll() {
        console.log("scrolled");
        this.loadingSpinnerNoticias = true;
        this.getNextPage();
        this.loadingSpinnerNoticias = false;
    }

}
