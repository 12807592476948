import {
    Component, 
    ViewChild, 
    TemplateRef, 
    Input
} from '@angular/core';

import {
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator
}  from '@angular/material';

import { DatatableComponent }from '@swimlane/ngx-datatable';
import {Router} from "@angular/router";
import {FormControl}from "@angular/forms";

import {BaseComponent} from "../../base-module/base-component/base.component";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';

@Component({
    selector: 'app-time-line-cliente',
    templateUrl: './time-line-cliente.component.html',
    styleUrls: ['./time-line-cliente.component.scss']
})
export class TimeLineClienteComponent extends BaseComponent{

    params: any = {
        linha_tempo: true,
    };
    usuario:any;
    loadingIndicator: boolean;
    habilitaEscritorios: boolean = false;
    public search = new FormControl();
    public _cliente_id: any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(TimeLineClienteComponent, { static: false }) table: TimeLineClienteComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;
    @ViewChild('table', { static: false }) table2: DatatableComponent;
    
    constructor(private _clienteRelacService: ClienteRelacionentoService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                private _authService:AuthService) {
        super(dialog,snackBar);

    }

    ngOnInit() {
        this.search.setValue('');

        this.usuario = JSON.parse(this._authService.getUser());
        this.getAll();

        if (this.usuario.escritorios.length > 1) {
            this.habilitaEscritorios = true;
        }
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {
        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this._clienteRelacService.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
                if(error.status == 401){                    
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                } 
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    @Input()
    set cliente_id(cliente_id) {
        this.params.cliente_id = cliente_id;
        this._cliente_id = (cliente_id && cliente_id.trim()) || '<no name set>';
    }

    get cliente_id(): string { return this._cliente_id; }

}
