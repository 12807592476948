import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visualiza-historico',
  templateUrl: './visualiza-historico.component.html',
  styleUrls: ['./visualiza-historico.component.scss']
})
export class VisualizaHistoricoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
