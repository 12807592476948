import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { BoletaConsorcioService } from '../services/boleta-consorcio.service';

@Component({
    selector: 'app-visualizar-boleta-consorcio',
    templateUrl: './visualizar-boleta-consorcio.component.html',
    styleUrls: ['./visualizar-boleta-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class VisualizarBoletaConsorcioComponent extends BaseComponent implements OnInit{
    data:any = {
        id:null
    };

    displayedColumns: string[] = ['tipo_lance', 'tipo_valor', 'valor_lance', 'valor_calc'];

    model:any = {
        status_id:null,
        status:{
            nome: ""
        },
        cliente_id:null,
        produto_consorcio_id:null,
        data_operacao:null,
        valor:null,
        observacao:null,
        carteira:{
            cliente_id: null,
            produto_consorcio_id: null,
            tipo_consorcio_id:null,
            empresa_consorcio_id:null,
            valor_contratado:null,
            data_atualizacao:null,
            grupo:null,
            cota:null,
            prazo_grupo:null,
            prazo_cliente:null,
            taxa_administracao:null,
            valor_lance_potencial:null,
            valor_lance_efetivado_contemplacao:null,
            valor_reembolso:null,
            valor_pago:null,
            saldo_devedor:null,
            liberacao_credito:null,
            valor_credito_liberado:null,
            credito_autorizado:null
        },
        cartas: []
    };

    constructor(public service: BoletaConsorcioService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public authService: AuthService,
        private _Activatedroute: ActivatedRoute
        )
    {
        super(dialog, snackBar);
    }
    ngOnInit(): void {
        this.data.id = this._Activatedroute.snapshot.params['id'];
        this.getById();
    }


    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {
            this.closeLoading();
            this.model = item.data;
        },
         error => {
            console.log(error)
            this.closeLoading();
         });
    }

    isStatusEfetivado() {
        let isEfetivado = false;
        if (this.model.status.nome == "Efetivada") {
            isEfetivado = true;
        }

        return isEfetivado;
    }

    calcValor(element, item){
        if(element.tipo_lance.tipo_valor == 'percent'){
            return (Number(element.valor_lance)/100)*Number(item.valor_carta);
        }
        return Number(element.valor_lance);
    }

}
