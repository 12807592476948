import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { TipoPatrimonioService } from '../services/tipo-patrimonio.service';
import { ClientePatrimonioService } from '../services/cliente-patrimonio.service';

export interface DialogData {
    id: any;
    cliente_id: any;
    is_financeiro: boolean;
}

@Component({
    selector: 'app-cadastro-cliente-patrimonio',
    templateUrl: './cadastro-cliente-patrimonio.component.html',
    styleUrls: ['./cadastro-cliente-patrimonio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
    ]
})

export class CadastroClientePatrimoniosComponent extends BaseComponent {

    descricao = new FormControl('', [Validators.required]);
    tipo_patrimonio_id = new FormControl('', []);
    valor  = new FormControl('0', [Validators.required]);
    is_financeiro  = new FormControl('', [Validators.required]);

    model:any = {
        descricao:"",
        tipo_patrimonio_id:"",
        valor:"",
        is_financeiro:"",
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerTipoPatrimonio: boolean = false;
    listaTipoPatrimonio: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: ClientePatrimonioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClientePatrimoniosComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _tipoPatrimonioService: TipoPatrimonioService)
    {
        super(dialog, snackBar);
        
        this.getTipoPatrimonio();

        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){

        if (!this.descricao.valid){
            return true;
        }
        
        if (!this.valor.valid) {
            return true;
        }

        return false;
    }

    salvar(){ 
        this.openLoading();
        
        this.model = {
            descricao: this.descricao.value,
            tipo_patrimonio_id: this.tipo_patrimonio_id.value,
            valor: this.valor.value,
            cliente_id: this.data.cliente_id,
            is_financeiro: this.is_financeiro.value
        };
        
        if(this.data.id == null){
        
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.descricao.setValue(item.data.descricao);
                this.tipo_patrimonio_id.setValue(item.data.tipo_patrimonio_id);
                this.valor.setValue(item.data.valor);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );

    }

    getTipoPatrimonio() {
        this.loadingSpinnerTipoPatrimonio = true;
        this._tipoPatrimonioService.getAll(this.params).subscribe(
            resquest => {
                this.listaTipoPatrimonio = resquest.data;
            },
            error => {
                this.loadingSpinnerTipoPatrimonio = false;
            },
            () => {
                this.loadingSpinnerTipoPatrimonio = false;
            }
        );
    }

    changePatrimonioFinanceiro(event) {
        console.log(event);
        this.params.financeiro = event.value;
        this.getTipoPatrimonio();
    }
}

