
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from "@angular/forms";
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { ActionDialog, BaseComponent } from '../../base-module/base-component/base.component';
import { CadastroTipoBasketComponent } from '../cadastro-tipo-basket/cadastro-tipo-basket.component';
import { TipoBasketService } from '../services/tipo-basket.service';


@Component({
  selector: 'lista-tipo-basket',
  templateUrl: './lista-tipo-basket.component.html',
  styleUrls: ['./lista-tipo-basket.component.scss']
})
export class ListaTipoBasketComponent extends BaseComponent {
    params: any = {};
    loadingIndicator: boolean;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaTipoBasketComponent, { static: false })
    table: ListaTipoBasketComponent;

    public search = new FormControl();

    constructor(
        public service: TipoBasketService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar
    ) {
        super(dialog, snackBar);
        this.search.setValue("");

        this.getAll();
    }


    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent) {
       

        this.params.page = event.pageIndex + 1;
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {
        if (this.search.value != null) {
            this.params.nome = this.search.value;
        }

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            (data) => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            (error) => {
                
                this.loadingIndicator = false;
            },
            () => {
                
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: "250px",
            disableClose: true,
            panelClass: "modal-delete",
            data: {
                title: "Excluir Tipo de basket",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        (data) => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        (error) => {
                            lista.closeLoading();
                            lista.showError(error.message);
                        },
                        () => {
                            lista.loadingIndicator = false;
                        }
                    );
                },
            },
        });
    }

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroTipoBasketComponent, {
            width: "400px",
            
        });

        dialogRef.afterClosed().subscribe((result) => {
            
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess(
                    "Tipo de basket cadastrado com sucesso!"
                );
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar cadastrar um novo registro."
                );
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastroTipoBasketComponent, {
            width: "400px",
            data: { id: id },
        });

        dialogRef.afterClosed().subscribe((result) => {
            
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Tipo de Oportunidade atualizado com sucesso!");
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }
}