import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { ProfissaoService } from 'app/auxiliares/services/profissao.service';
import { ParentescoService } from '../../../services/parentesco.service';
import { EstadoCivilService } from 'app/clientes/services/estado-civil.service';
import { EstruturaFamiliarService } from '../../../services/estrutura-familiar.service';

export interface DialogData {
    id: any;
    cliente_id: any;
}

@Component({
    selector: 'app-cadastro-estrutura-familiar',
    templateUrl: './cadastro-estrutura-familiar.component.html',
    styleUrls: ['./cadastro-estrutura-familiar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ 
        EstruturaFamiliarService, 
        ParentescoService 
    ]
})

export class CadastroEstruturaFamiliarComponent extends BaseComponent {

    nome = new FormControl('', [Validators.required]);
    profissao_id = new FormControl();
    sexo  = new FormControl('');
    parentesco_id  = new FormControl('', [Validators.required]);   
    estado_civil = new FormControl('', [Validators.required]);
    data_nascimento = new FormControl('', [Validators.required]);
    renda = new FormControl(0.00);

    model:any = {
        profissao_id:"",
        parentesco_id:"",
        sexo:"",
        estado_civil: "",
        cliente_id: "",
        idade: 0
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerParentesco: boolean = false;
    loadingSpinnerEstadoCivil: boolean = false;
    listaParentesco: any[] = [];
    listEstadoCivil: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: EstruturaFamiliarService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroEstruturaFamiliarComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _profissaoService: ProfissaoService,
                public _parentescoService: ParentescoService,
                private _estadoCivilService: EstadoCivilService)
    {
        super(dialog, snackBar);
        
        this.getParentesco();
        this.getEstadoCivil();

        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }

        this.profissao_id.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._profissaoService.search(this.params);
                }
            }
        );
        
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getProfissao(item){
        this.loadingSpinner  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){

        if (!this.nome.valid){
            return true;
        }
        
        if (this.model.sexo == "") {
            return true;
        }

        if (!this.parentesco_id.valid) {
            return true;
        }

        if (this.model.estado_civil_id == "") {
            return true;
        }

        return false;
    }


    salvar(){ 
        this.openLoading();

        const profissao = this.profissao_id.value;

        if(profissao != undefined){
            this.model.profissao_id = profissao.id;
        }        
        
        this.model.nome = this.nome.value;       
        this.model.sexo = this.sexo.value;
        this.model.parentesco_id = this.parentesco_id.value;
        this.model.estado_civil_id = this.estado_civil.value;
        this.model.data_nascimento = this.data_nascimento.value;
        this.model.renda = this.renda.value;
        
        if(this.data.id == null){
        
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.nome.setValue(item.data.nome);
                this.profissao_id.setValue(item.data.profissao);
                this.sexo.setValue(item.data.sexo);
                this.parentesco_id.setValue(item.data.parentesco_id);
                this.data_nascimento.setValue(item.data.data_nascimento);
                this.estado_civil.setValue(item.data.estado_civil_id);
                this.renda.setValue(item.data.renda);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );

    }

    getParentesco() {

        this.loadingSpinnerParentesco = true;
        this._parentescoService.getAll(this.params).subscribe(
            resquest => {
                this.listaParentesco = resquest.data;

            },
            error => {
                this.loadingSpinnerParentesco = false;
            },
            () => {
                this.loadingSpinnerParentesco = false;
            }
        );
    }

    getEstadoCivil() {
        this.loadingSpinnerEstadoCivil = true;
        this._estadoCivilService.getLista()
            .subscribe(
                resquest => {
                    this.listEstadoCivil = resquest.data;
                },
                error => {
                    this.loadingSpinnerEstadoCivil = false;
                },
                () => {
                    this.loadingSpinnerEstadoCivil = false;
                }
            );
    }
}

