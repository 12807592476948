import {
    Component, 
    ViewChild, 
    Input
}  from '@angular/core';
import {
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator
} from '@angular/material';

import { Router } from "@angular/router";
import {FormControl} from "@angular/forms";
import {SelectionType} from '@swimlane/ngx-datatable';

import {BaseComponent, ActionDialog} from "app/base-module/base-component/base.component";
import { CadastroEstruturaFamiliarComponent } from '../cadastro-estrutura-familiar/cadastro-estrutura-familiar.component';

import { AuthService } from 'app/base-module/base-service/auth.service';
import { EstruturaFamiliarService } from '../../../services/estrutura-familiar.service';

@Component({
    selector: 'app-lista-estrutura-familiar',
    templateUrl: './lista-estrutura-familiar.component.html',
    styleUrls: ['./lista-estrutura-familiar.component.scss'],
    providers: [ EstruturaFamiliarService ]
})

export class ListaEstruturaFamiliarComponent extends BaseComponent{
    
    params: any = {};
    user:any;
    loadingIndicator: boolean;
    public search = new FormControl();
    SelectionType = SelectionType;

    selected = [];
    data:any = {};
    titulo:string;

    model: any = {
        status_id: "",
        boletas:[]
    };

    _cliente_id:any = null;
    @ViewChild('paginator',{ static: false }) paginator: MatPaginator;

    constructor(public service: EstruturaFamiliarService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private route: Router,
                private _authService: AuthService) {
        super(dialog, snackBar);

    }

    @Input() cliente_id: number;

    ngOnInit() {
        this.user = JSON.parse(this._authService.getUser());
        this.search.setValue('');
        this.getAll();      
    }

    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        
        if(event.column.name == "Responsavel Operação"){
            this.params.sortColumn = "operador_id";
        }else if(event.column.name == "Escritório"){
            this.params.sortColumn = "escritorio_id";
        }else if(event.column.name == "Conta Cliente"){
            this.params.sortColumn = "numero_xp";
        }else if(event.column.name == "Cliente"){
            this.params.sortColumn = "nome_cliente";
        }else if(event.column.name == "Status"){
            this.params.sortColumn = "status_id";
        }        

        this.params.sortOrder = event.newValue;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){        

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de Boletas
     */
    getAll() {
        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        if(this.cliente_id != null && this.cliente_id != undefined){
            this.params.cliente_id = this.cliente_id;
        }

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
                
                if(error.status == 401){        
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                }
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de boleta
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Estrutura Familiar",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    openCadastro(){        
        let dialogRef = this.dialog.open(CadastroEstruturaFamiliarComponent, {
            width: '500px',
            height: '600px',
            data: {
                acao_comercial_id: null,
                cliente_id: this.cliente_id,
                id: null
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Estrutura Familiar cadastrada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar uma nova Estrutura Familiar.");
            }
        });        
    }

    openAlterar(id){
        let dialogRef = this.dialog.open(CadastroEstruturaFamiliarComponent, {
            width: '500px',
            height: '600px',
            data: {
                acao_comercial_id: null,
                cliente_id: this.cliente_id,
                id: id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Estrutura Familiar atualizada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar alterar uma nova Estrutura Familiar.");
            }
        });     
    }

    /**
     * Consulta lista
     */
    getAllExport() {

        this.openLoading();
        this.service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    getNomeEstaoCivil(row){
        if(row.estado_civil != undefined && row.estado_civil != '' && row.estado_civil != null){
            return row.estado_civil.nome;
        }

        return '';
    }
}
