import { 
    Component, 
    ViewChild, 
    TemplateRef 
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent 
} from '@angular/material';

import { FormControl} from "@angular/forms";
import { Subscription } from 'rxjs';

import { BaseComponent, ActionDialog } from '../../base-module/base-component/base.component';
import { ListaAtividadeAssuntoService } from '../services/lista-atividade-assunto.service';
import { CadastroAssuntoComponent } from '../cadastro-assunto/cadastro-assunto.component';

@Component({
    selector: 'lista-assunto',
    templateUrl: './lista-assunto.component.html',
    styleUrls: ['./lista-assunto.component.scss']
})
export class ListaAssuntoComponent extends BaseComponent {
    params: any = {};
    loadingIndicator: boolean;
    inputSubscription: Subscription; 

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;

    @ViewChild(ListaAssuntoComponent, { static: false })
    table: ListaAssuntoComponent;

    public search = new FormControl();

    constructor(
        public service: ListaAtividadeAssuntoService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar
    ) {
        super(dialog, snackBar);
        this.search.setValue("");

        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent) {
        this.params.page = event.pageIndex + 1;
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * 
     */
    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    onEnter(value: string) {    
        this.stopNow();     
        this.params.search = value;
        this.search.setValue(value);
        this.paginator.firstPage();
        this.getAll();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {
        if (this.search.value != null) {
            this.params.nome = this.search.value;
        }

        this.loadingIndicator = true;
        this.inputSubscription = this.service.getAll(this.params).subscribe(
            (data) => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            (error) => {                
                this.loadingIndicator = false;
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroAssuntoComponent, {
            width: "500px",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess(
                    "Registro cadastrado com sucesso!"
                );
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar cadastrar um novo registro."
                );
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastroAssuntoComponent, {
            width: "500px",
            data: { id: id },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Registro atualizado com sucesso!");
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

    
    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: "300px",
            disableClose: true,
            panelClass: "modal-delete",
            data: {
                title: "Excluir Assunto da lista de atividades",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        (data) => {                            
                            lista.getAll();
                            lista.showSuccess(data.message);
                        },
                        (error) => {
                            lista.closeLoading();
                            lista.showError(error.message);
                            lista.loadingIndicator = false;
                        },
                        () => { }
                    );
                },
            },
        });
    }

    getDescricaoVisivel(is_visible:boolean){
        return is_visible? 'Sim' : 'Não';
    }
}