import { 
    Component, 
    ChangeDetectorRef, 
    Inject
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    MatDialogRef, 
    MAT_DIALOG_DATA
} from '@angular/material';

import { 
    FileUploader, 
    FileItem
} from 'ng2-file-upload/ng2-file-upload';

import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { BoletaRendaFixaService } from 'app/renda-fixa/services/boleta-rendafixa.service';
import { FormControl, Validators } from '@angular/forms';

interface UploadFile {
    url: string;
    title: string;
    urlModelDownload: string;
}

@Component({
    selector: 'app-upload-renda-fixa',
    templateUrl: './upload-renda-fixa.component.html',
    styleUrls: ['./upload-renda-fixa.component.scss']
})

export class UploadRendaFixaComponent extends BaseComponent {

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;
    hasFile:any;

    data_referencia = new FormControl(new Date(), [Validators.required]);
    
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UploadRendaFixaComponent>,
        public service: BoletaRendaFixaService,
        public utiliesService: UtilitesService,
        public authService: AuthService,
        private changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public paramUpload: UploadFile,
    ) {

    super(dialog, snackBar);
    
    this.nomeFile = null;    

    this.uploader = new FileUploader({
      url: this.utiliesService.urlPrefix + this.paramUpload.url,
      isHTML5: true,
      authToken: "Bearer " + this.authService.getToken()
    });

    this.uploader.onAfterAddingFile = (file) => { 
        file.withCredentials = false; 
    };
    
    this.uploader.onProgressItem = ( fileItem: FileItem, progress: any ) => { 
        this.changeDetector.detectChanges();
    };
    
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      
      this.closeLoading();
      
      if (status == 200){
        var info = JSON.parse(response);
        
        this.showSuccess(info.message);
        
        this.dialogRef.close(info);
      }else{
        var info = JSON.parse(response);
        this.showError(info.message);        
      }
      
    };
    
  }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    uploadFile(event) {
        let elem = event.target;        
        if (elem.files.length > 0) {
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }

    isNomeFile(){
        return (this.nomeFile != null);
    }

    startUpload() {
        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('data_refencia' , this.utiliesService.formatDateToString(new Date(this.data_referencia.value)));
        };
        this.openLoading();
        this.uploader.queue[0].upload(); 
    }

    isInvalidForm(){
        if (!this.data_referencia.valid){
            return true;
        }

        if(this.hasFile == undefined){
            return true;
        }

        return false;
    }

}
