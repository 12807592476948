import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,    
    MatPaginator,    
    MatDatepickerInputEvent
} from '@angular/material';

import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router} from "@angular/router";

import { BaseComponent } from "../../../base-module/base-component/base.component";

/** Components */
import { CadastroAporteRetiradaComponent } from '../../cadastro-aporte-retirada/cadastro-aporte-retirada.component';

/** Services */
import { ClienteService } from '../../services/cliente.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ImportacaoArquivoService } from 'app/movimentacao/services/importacao-arquivo.service';
import { ClienteProdutoService } from '../../services/cliente-produto.service';
import { ClientEquityService } from 'app/clientes/services/client-equity.service';
import { ClienteModel } from 'app/clientes/models/cliente.model';
export interface DialogData {
    id: number;
}

@Component({
    selector: "dialog-cliente-cadastro-painel",
    templateUrl: "./cadastro-completo-painel.component.html",
    styleUrls: ["./cadastro-completo-painel.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ClienteProdutoService, ClientEquityService],
})
export class CadastroCompletoPainelComponent extends BaseComponent {
    gradient: boolean = true;
    showLegend: boolean = true;
    showLabels: boolean = true;
    isDoughnut: boolean = false;
    equity: any[] = [];

    cliente: ClienteModel;

    view: any[] = [1200, 200];
    vlr_boleta_fo = new FormControl("", []);
    vlr_boleta_st = new FormControl("", []);
    vlr_boleta_fii = new FormControl("", []);
    desconto_corretagem = new FormControl("", []);
    patrimonio_geral = new FormControl("", []);
    despesas_mensais = new FormControl("", []);
    receita_mensal = new FormControl("", []);
    receita_familiar = new FormControl("", []);
    situacao_tributaria = new FormControl("", []);
    codigo_xp = new FormControl("", []);
    today_date = new Date();
    yesterday = new Date(
        this.today_date.getFullYear(),
        this.today_date.getMonth(),
        this.today_date.getDate()
    );
    data_agenda = new FormControl();

    total_prosperidade = new FormControl(0, []);
    valor_gobal = new FormControl(0, []);
    sow = new FormControl(0, []);
    saldo_conta = new FormControl(0, []);

    operacao_stop = new FormControl();
    estado_civil_id = new FormControl();
    sexo = new FormControl();
    fundos = new FormControl();
    renda_fixa = new FormControl();
    renda_variavel = new FormControl();
    suitability = new FormControl();
    valor_internacional = new FormControl();

    isHabilityRendaVariavelClient: boolean = false;
    isHabilityExtractClient: boolean = false;
    datas_disponiveis: any[] = [];
    isDisabledDate: boolean = true;

    colorScheme = {
        domain: [
            "#00D680",
            "#FCCD00",
            "#A18857",
            "#FD8E00",
            "#00AAE5",
            "#BA6BC6",
        ],
    };

    params: any = {
        per_page: "",
    };

    data: any = {};
    data_atualizacao: any;

    exibeValorInicial: boolean = true;
    exibeValorGlobal: boolean = true;
    isConditionIsTrue: boolean = true;

    totalCustodiaFop: any = 0;
    totalCustodiaSt: any = 0;
    totalCustodiaFii: any = 0;

    isDisabledAssessor: boolean = true;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroCompletoPainelComponent, { static: false })
    table: CadastroCompletoPainelComponent;
    @ViewChild("paginatorRelacionamento", { static: false })
    paginatorRelacionamento: MatPaginator;

    user: any;

    constructor(
        public service: ClienteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _Activatedroute: ActivatedRoute,
        private route: Router,
        private _authService: AuthService,
        public _arquivoService: ImportacaoArquivoService,
        private _custodiaCliente: ClienteProdutoService,
        private _equityClientService: ClientEquityService
    ) {
        super(dialog, snackBar);
        this.data = this._Activatedroute.snapshot.params["id"];
        this.user = JSON.parse(this._authService.getUser());
        this.initAplication();
    }

    initAplication() {
        this.getLastDateUpdate();
        this.getCliente();
        this.getEquityClient({ cliente_id: this.data });
        this.addDateYesterdayDate();
    }

    addDateYesterdayDate() {
        this.yesterday.setDate(this.yesterday.getDate() - 1);
        this.data_agenda.setValue(this.yesterday);
    }

    onSelect(data): void {
        const selectedItem = JSON.parse(JSON.stringify(data));

        if (selectedItem.name == "RENDA VARIÁVEL") {
            this.isHabilityRendaVariavelClient = true;
            this.isHabilityExtractClient = false;
        } else {
            this.isHabilityRendaVariavelClient = false;
            this.isHabilityExtractClient = false;
        }
    }

    changeExtractClient(event): void {
        this.isHabilityExtractClient = event;
    }

    changeInfoClient() {
        this.isHabilityRendaVariavelClient = false;
        this.isHabilityExtractClient = false;
    }

    realValueFormat(c): any {
        return parseFloat(c).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    }

    percentValueFormat(c): any {
        return parseFloat(c).toFixed(2);
    }

    getEquityClient(parametros) {
        this._custodiaCliente.getAll(parametros).subscribe(
            (list) => {
                this.equity = list.data;
            },
            (error) => {},
            () => {}
        );
    }

    getValorFinanceiro(row) {
        if (row.preco_executado > 0) {
            return parseFloat(row.qtd) * parseFloat(row.preco_executado);
        } else {
            return row.vlr_financeiro;
        }
    }

    getCliente() {
        this.openLoading();
        this.service.getById(this.data).subscribe(
            (cliente) => {
                this.cliente = cliente.data;
                this.getDateAvailable({ cliente_id: this.data });
            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    retornarLista() {
        this.route.navigate(["/clientes/consulta"]);
    }

    boleta(tipo) {
        let dialogRef = this.dialog.open(CadastroAporteRetiradaComponent, {
            width: "400px",
            data: {
                tipo: tipo,
                cliente_id: this.data,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Boleta cadastrada com Sucesso!");
                window.location.reload();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar cadastrar o aprote, tente novamente"
                );
            }
        });
    }
    /**
     * Modal de edição
     */
    openAporte(tipo): void {
        let dialogRef = this.dialog.open(CadastroAporteRetiradaComponent, {
            width: "400px",
            data: {
                tipo: tipo,
                cliente_id: this.data,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                if (tipo == "A") {
                    this.showSuccess("Aporte cadastrado com Sucesso!");
                } else if (tipo == "R") {
                    this.showSuccess("Retirada cadastrada com Sucesso!");
                }
                window.location.reload();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar cadastrar o aprote, tente novamente"
                );
            }
        });
    }

    getValueZero(value) {
        if (value == undefined || value == null || value == "") {
            return 0.0;
        }

        return parseFloat(value);
    }

    diasDisponiveis = (d: Date): boolean => {
        const date = new Date(d);
        if (this.datas_disponiveis.length > 0) {
            return (
                this.datas_disponiveis.indexOf(date.toLocaleDateString()) != -1
            );
        }
    };

    changeDateAvailable(e: MatDatepickerInputEvent<Date>) {
        const date = new Date(e.value);
        const parametros = {
            data_refencia_mes: date.toISOString().slice(0, 10),
            cliente_id: this.data,
        };

        this.getEquityClient(parametros);
    }

    getDateAvailable(parametros: Object) {
        this.isDisabledDate = true;
        this._equityClientService
            .getAllDateHistoryInvestiments(parametros)
            .subscribe(
                (list) => {
                    this.datas_disponiveis = list.data;
                    if (
                        this.datas_disponiveis != undefined &&
                        this.datas_disponiveis.length > 0
                    ) {
                        this.isDisabledDate = false;
                    }
                },
                (error) => {
                    this.showError(error.error.message);
                    this.isDisabledDate = true;
                },
                () => {}
            );
    }

    getLastDateUpdate() {
        this._equityClientService.getLastUpdate().subscribe(
            (list) => {
                this.data_agenda.setValue(list.data);
            },
            (error) => {
                this.showError(error.error.message);
            },
            () => {}
        );
    }

    editFichaCadastral(){
        this.route.navigate(['/clientes/ficha-cadastral/'+this.data]);
    }
}
