import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-secured-download',
  templateUrl: './secured-download.component.html'
})
export class SecuredDownloadComponent implements OnInit {

    @Input() public src: string;
    @Input() public fileName: string;

    

    ngOnChanges(): void {

    }



    // we need HttpClient to load the image
    constructor(private httpClient: HttpClient) { }

    ngOnInit(): void { }

    download(): void {
        this.httpClient
            .get(this.src, { responseType: 'blob' })
            .subscribe(blob => {
                const a = document.createElement('a')
                const objectUrl = URL.createObjectURL(blob)
                a.href = objectUrl
                //a.download = 'archive.zip';
                a.target = "_blank";
                a.click();
                URL.revokeObjectURL(objectUrl);
            });
    }

}
