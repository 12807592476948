import {Component, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent} from '@angular/material';
import {ActionDialog, BaseComponent} from "../../base-module/base-component/base.component";
import {FormControl, Validators} from "@angular/forms";
import {RolesService} from "../services/roles.service";
import {RoleCadastrarComponent} from "../role-cadastrar/role-cadastrar.component";
import {PermissaoComponent} from "../permissoes/permissao.component";

@Component({
    selector: 'app-data-tabble-role',
    templateUrl: './role-lista.component.html',
    styleUrls: ['./role-lista.component.scss']
})
export class RoleListaComponent extends BaseComponent{

    params: any = {
        name:"",
        empresa_id:null
    };

    name = new FormControl('', [Validators.required]);
    empresa:any;
    loadingIndicator: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(RoleListaComponent, { static: false }) table: RoleListaComponent;

    constructor(public service: RolesService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar) {
        super(dialog,snackBar);
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    getAll() {
        this.params.name = this.name.value;
        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }

    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir",
                text: "Deseja realmente excluir este intem?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.message);

                        },
                        () => {
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(RoleCadastrarComponent, {
            width: '700px',            
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Perfil cadastrado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo perfil.");
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(RoleCadastrarComponent, {
            width: '1200px',
            maxHeight: '99vh',
            data: { id: id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Perfil alterado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo perfil.");
            }
        });
    }

    permissoes(id): void {

        var model = null;
        for (var i=0; i<this.rows.length;i++){
            if(id == this.rows[i].id){
                model = this.rows[i];
            }
        }

        let dialogRef = this.dialog.open(PermissaoComponent, {
            width: '1200px',
            maxHeight: '99vh',
            data: model
        });

        dialogRef.afterClosed().subscribe(result => { });
    }

    isPossuiEmpresa(id){

        var possui = false;
        for(var i=0; i < this.rows.length;i++){
            if(this.rows[i].id == id && this.rows[i].empresa_id != null){
                possui=true;
            }
        }

        return possui;
    }

}