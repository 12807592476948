import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    PageEvent
} from '@angular/material';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { ActionDialog, BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { LanceCartaConsorcioService } from '../services/lance-carta-consorcio.service';
import { TipoLanceCartaConsorcioService } from '../services/tipo-lance-carta-consorcio.service';
import { ICarta } from '../cadastro-boleta-consorcio/cadastro-boleta-consorcio.component';

interface IModel {
    carta_id:number;
    tipo_lance_id:number;
    valor:number;
};

@Component({
    selector: 'app-cadastro-lances-boleta-consorcio',
    templateUrl: './cadastro-lances-boleta-consorcio.component.html',
    styleUrls: ['./cadastro-lances-boleta-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class CadastroLancesBoletaConsorcioComponent extends BaseComponent implements OnInit {
    loadingSpinner: boolean;
    filteredOptions: Observable<string[]>;
    formGroup:FormGroup = new FormGroup({
        tipo_lance: new FormControl('', [Validators.required]),
        valor_lance: new FormControl(0),
    });

    model:IModel = {
        carta_id: null,
        tipo_lance_id: null,
        valor: 0
    };

    loadingLocal:any;
    listTipoLance: any[] = [];
    loadingSpinnerTipoLance: boolean = false;
    listLances: any[] = [];
    data:any = {
        id:null
    };

    @Input() carta: ICarta;

    constructor(
        public service: LanceCartaConsorcioService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public authService: AuthService,
        private _tipoLanceCartaService: TipoLanceCartaConsorcioService,
    ){
        super(dialog, snackBar);
    }

    ngOnInit(): void {
        this.loadingIndicator=false;
        this.getAll();
        this.getAllTipoLance();
    }


    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    salvar(){
        this.model = this.buildModel();
        this.model.carta_id = this.carta.id;
        console.log(this.carta);
        if(this.formGroup.valid){
            this.openLoading();
            if(!this.data.id){
                this.service.create(this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.getAll();
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }else{
                this.service.update(this.data.id, this.model).subscribe(
                    data => {
                        this.closeLoading();
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }
        }else{
            if(this.formGroup.invalid){
                this.validateAllFormFields(this.formGroup);
            }
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
            }
        });
    }

    getById(id:number){
        this.openLoading();
        this.service.getById(id).subscribe(
         item => {
            this.formGroup.get('tipo_lance').setValue(item.tipo_lance);
            this.formGroup.get('valor_lance').setValue(item.valor_lance);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    getAll() {
        this.loadingSpinner = true;
        this.service.getAll({ carta_id: this.carta.id }).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
            },
            () => {
                this.loadingSpinner = false;
            }
        );
    }

    getAllTipoLance() {
        this.loadingSpinnerTipoLance = true;
        this._tipoLanceCartaService.getAll({}).subscribe(
            list => {
                this.listTipoLance = list.data;
                this.loadingSpinnerTipoLance = false;
            },
            error => {
                this.loadingSpinnerTipoLance = false;
            },
            () => {
                this.loadingSpinnerTipoLance = false;
                console.log("complete");
            }
        );
    }

    buildModel(): IModel{
        const buildModel:IModel = {
            ...this.formGroup.value,
            tipo_lance_id: this.formGroup.get('tipo_lance').value.id,
            carta_id: this.carta.id
        }
        return buildModel;
    }

    selectedLance(event) {
        console.log(event);
    }

    getSimboloTipoValor(): string{
        return this.formGroup.get('tipo_lance').value.tipo_valor == 'percent'? '% ' : 'R$ ';
    }

    getOptionsTypeValue() {
        return { prefix: this.getSimboloTipoValor(), thousands: '.', decimal: ',', align: 'center' }
    }

    getCalcValor(){
        let valor:number = 0;
        if(this.formGroup.get('tipo_lance').value.tipo_valor == 'percent'){
            valor = (Number(this.formGroup.get('valor_lance').value)/100)*Number(this.carta.valor_carta);
        }else{
            valor = Number(this.formGroup.get('valor_lance').value);
        }

        return valor;
    }

    getCalcValorByParams(row){
        let valor:number = 0;
        if(row){
            if(row.tipo_lance.tipo_valor == 'percent'){
                valor = (Number(row.valor_lance)/100)*Number(this.carta.valor_carta);
            }else{
                valor = Number(row.valor_lance);
            }    
        }
        
        return valor;
    }

    //Implementando listagem

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Excluir registro
     * @param id
     */
    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Lance",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();
                    
                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.closeLoading();
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {

                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

}
