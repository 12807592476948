import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ListaNoticiasComponent } from './lista-noticias/lista-noticias.component';
import { ListaNoticiaComponent } from './lista-noticia/lista-noticia.component';
import {CadastroNoticiaComponent} from './cadastro-noticia/cadastro-noticia.component';
import { AuthGuardService } from 'app/base-module/base-service/auth-guard.service';




const routes: Routes = [{
    path: '',
    children: [
        {
            path: '',
            component: ListaNoticiasComponent
        },
        {
            path: 'noticia/:id',
            component: ListaNoticiaComponent
        },
        {
            path: 'cadastrar',
            component: CadastroNoticiaComponent
        },
        {
            path: 'cadastrar/:id',
            component: CadastroNoticiaComponent
        }
    ],
    canActivate: [AuthGuardService]
}];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CentralNoticiasRoutesModule { }