import { 
    Component, 
    OnInit, 
    Input, 
    Output,
    EventEmitter
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar 
} from '@angular/material';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { EarningClientService } from 'app/renda-variavel/services/earning-client.service';


@Component({
  selector: 'lista-custodia-proventos',
  templateUrl: './lista-custodia-proventos.component.html',
  styleUrls: ['./lista-custodia-proventos.component.scss'],
  providers: [ EarningClientService ]
})
export class ListaCustodiaProventosComponent extends BaseComponent implements OnInit {

    @Input() cliente_id: number;    
    @Output() totalProventosEvent = new EventEmitter<number>();


    user:any;
    loadingListaAtivo:boolean = false;
    total_financeiro_atual: number = 0;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _serviceProventos: EarningClientService,
        private _authService: AuthService
    ) { 
        super(dialog,snackBar);
        this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getAll();
    }

    getAll() {
        this.loadingListaAtivo = true;
        this._serviceProventos.getAll({account_id :this.cliente_id}).subscribe(
            list => {
                this.length = list.data.length;
                this.rows  = list.data;
                this.temp  = [...this.rows];     
            },
            error => { 
                this.loadingListaAtivo = false;
            },
            () => {
                this.loadingListaAtivo = false;
                this.getTotalFinanceiroCustodiaCliente() 
            }
        );
    }

    getTotalFinanceiroCustodiaCliente(){
        this.total_financeiro_atual = this.rows
            .map(item => item.total_value)
            .reduce((acumulador:number, valorAtual:number) => Number(acumulador) + Number(valorAtual), 0);

        this.totalProventosEvent.emit(this.total_financeiro_atual);
    }

    getType(type){
        if(type == 'DIVIDENDS'){
            return 'Dividendos';
        }

        if(type == 'INTEREST ON CAPITAL'){
            return 'Juros sobre capital';
        }
    }

    getAtivo(row){
        if(row.sigla != '' && row.sigla != null && row.sigla != undefined){
            return row.sigla;
        }else{
            return row.company;
        }

    }

}
