//Services
import {UtilitesService} from './utilites.service';

//Models
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";

@Injectable()
export class UsuarioService {

    constructor(private _http: HttpClient, public utilitesService: UtilitesService, public authService:AuthService) {
    }

    /**
     * Cadastra usuário e empresa
     * @param data
     * @returns {Observable<Object>}
     */
    public create(data){
        return this._http.post(this.utilitesService.urlPrefix+"usuarios/create",data);
    }

    /**
     * Consulta por ID
     * @param id
     * @returns {Observable<any>}
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "usuarios/" + id, {headers: this.authService.getAuthHeaders()} );
    }

    /**
     * Lista
     * @param params
     * @returns {Observable<any>}
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"usuarios"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Lista
     * @param params
     * @returns {Observable<any>}
     */
    getLista(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/lista"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Lista Todos os usuários
     * @param params
     * @returns {Observable<any>}
     */
    getAllUsuarios(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/listaTodos"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Lista Operadores
     * @param params
     * @returns {Observable<any>}
     */
    getListaOperadores(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/listaOperadores"+env,{headers: this.authService.getAuthHeaders()});
    }

    search(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "usuarios/search"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Consulta Tipo de Usuario
     * @param {string} query
     * @returns {Observable<any>}
     */
    tipoUsuario(): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix+"usuarios/tipo-usuario",{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Realiza Switch Role
     * @param data Object
     * @returns Observable<any>
     */
    switchRole(data): Observable<any> {
        return this._http.post(this.utilitesService.urlPrefix+"usuarios/switch-role",data);
    }

}
