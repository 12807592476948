import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { BalanceAvailableService } from '../services/balance-available.service';

@Component({
  selector: 'saldo-disponivel-component',
  templateUrl: './saldo-disponivel-component.component.html',
  styleUrls: ['./saldo-disponivel-component.component.scss'],
  providers: [ BalanceAvailableService ]
})

export class SaldoDisponivelComponentComponent 
    extends BaseComponent 
    implements OnInit 
{

    balanceAvailable:number = 0;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,        
        public _route: Router,
        private _balanceAvailableService: BalanceAvailableService
    ) 
    { 
        super(dialog, snackBar);
    }

    ngOnInit() {
        this.getBalance();
    }

    handleBalanceClient(){
        this._route.navigate(['/saldo-clientes']);
    }

    getBalance() {

        this.loadingIndicator = true;
        this._balanceAvailableService.getBalance({}).subscribe(
            balance => {               
               this.balanceAvailable = parseFloat(balance.data); 
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

}
