import { 
    Component, 
    Input, 
    OnInit 
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar 
} from '@angular/material';

import { BaseComponent } from 'app/base-module/base-component/base.component';

import { ClienteModel } from 'app/clientes/models/cliente.model';

@Component({
  selector: 'cliente-renda-variavel-perfil',
  templateUrl: './cliente-renda-variavel-perfil.component.html',
  styleUrls: ['./cliente-renda-variavel-perfil.component.scss']
})

export class ClienteRendaVariavelPerfilComponent 
    	extends BaseComponent 
        implements OnInit 
{

    @Input() client: ClienteModel;

    constructor( 
        public dialog: MatDialog, 
        public snackBar: MatSnackBar) 
    { 
        super(dialog, snackBar);
    }

    ngOnInit() {
    }

}
