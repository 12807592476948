import {
    Component, 
    ViewChild,     
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA,
    PageEvent
} from '@angular/material';
import {BaseComponent} from "../../../base-module/base-component/base.component";
import {AuthService} from "../../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { NetNewMoneyService } from 'app/relatorios/services/net-new-money.service';

export interface DialogData {
    cliente_id: number;
    dataMes: Date;
}

@Component({
    selector: 'app-modal-detail-nnm',
    templateUrl: './modal-detail-nnm.component.html',
    styleUrls: ['./modal-detail-nnm.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ NetNewMoneyService ]
})

export class ModalDetailNnmComponent extends BaseComponent {
    
    model:any = {
        nome:"",
    };

    loadingLocal:boolean = false;

    @ViewChild(ModalDetailNnmComponent, { static: false }) table: ModalDetailNnmComponent;

    constructor(public service: NetNewMoneyService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                public authService: AuthService,
                public dialogRef: MatDialogRef<ModalDetailNnmComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData)
    {
        super(dialog, snackBar);        
        this.getClienteNetNewMoneyForMonth();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    getClienteNetNewMoneyForMonth(){
        this.loadingLocal = true;
        this.params.cliente_id = this.data.cliente_id;
        this.params.mesAno = this.data.dataMes;

        this.service.getAll(this.params).subscribe(
            item => {           
                this.length = item.meta.total;
                this.rows = item.data;
                this.temp = [...this.rows];
            },        
            error => {
                this.loadingLocal = false;    
            },
            () => {
                this.loadingLocal = false;      
            }
        );
    }

     /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any): void{

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getClienteNetNewMoneyForMonth();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getClienteNetNewMoneyForMonth();
    }

}
