import {EventEmitter, Injectable}   from "@angular/core";
import { UtilitesService }          from "../../base-module/base-service/utilites.service";
import { AuthService }              from "../../base-module/base-service/auth.service";
import { Observable }               from "rxjs";
import {HttpClient}                 from "@angular/common/http";

@Injectable()
export class TipoOportunidadeService {
    public empresaPadrao = new EventEmitter<any>();

    constructor(
        private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService
    ) {}

    /**
     * Tipo de Oportunidade por id
     * @param id ID da Tipo de Oportunidade
     */
    getById(id): Observable<any> {
        return this._http.get(
            this.utilitesService.urlPrefix + "tipo-oportunidade/" + id,
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Lista de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    getAll(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }

        return this._http.get(
            this.utilitesService.urlPrefix + "tipo-oportunidade" + env,
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Lista de Tipo de Oportunidade
     */
    getLista(): Observable<any> {
        return this._http.get(
            this.utilitesService.urlPrefix + "tipo-oportunidade/lista",
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Delete de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    delete(id): Observable<any> {
        return this._http.delete(
            this.utilitesService.urlPrefix + "tipo-oportunidade/" + id,
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Cadastra Tipo de Oportunidade
     * @param params Json Tipo de Oportunidade
     */
    create(params): Observable<any> {
        return this._http.post(
            this.utilitesService.urlPrefix + "tipo-oportunidade",
            JSON.stringify(params),
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Atualiza Tipo de Oportunidade
     * @param params Json Tipo de Oportunidade
     */
    update(id, params): Observable<any> {
        return this._http.put(
            this.utilitesService.urlPrefix + "tipo-oportunidade/" + id,
            JSON.stringify(params),
            { headers: this.authService.getAuthHeaders() }
        );
    }
}
