import { 
    Component, 
    Input, 
    OnInit, 
    Output, 
    ViewChild,
    EventEmitter
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent
} from '@angular/material';

import { differenceInBusinessDays } from 'date-fns';

import { 
    ActionDialog, 
    BaseComponent 
} from 'app/base-module/base-component/base.component';

import { AuthService } from 'app/base-module/base-service/auth.service';
import { PainelService } from 'app/renda-variavel/services/painel.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { CadastraItemPainelComponent } from '../cadastra-item-painel/cadastra-item-painel.component';

@Component({
  selector: 'itens-painel',
  templateUrl: './itens-painel.component.html',
  styleUrls: ['./itens-painel.component.scss'],
  providers: [ PainelService ]
})

export class ItensPainelComponent extends BaseComponent implements OnInit {

    ColumnMode = ColumnMode;
    
    @Input() estrategia_id: number;        
    @Input() data_inicio: Date;        
    @Input() data_fim: Date;        
    @Output() refreshEvent = new EventEmitter<boolean>();

    loadingListaAtivo:boolean = false;
    paramsCarteira: any = {};    
    
    user:any;
    pageSize = 100;    

    @ViewChild('tableAtivo', { static: false }) table: ItensPainelComponent;    

    constructor( 
        public dialog: MatDialog,
        public snackBar: MatSnackBar,        
        private _authService: AuthService,
        private _painelService: PainelService,
        private _utilitesService: UtilitesService) { 
            super(dialog,snackBar);              
            this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getAll();
    }

     /**
     * Consulta lista de Boletas
     */
    getAll() {
        this.loadingListaAtivo = true;        
        this.paramsCarteira.estrategia_id = this.estrategia_id;
        this.paramsCarteira.data_inicio = this._utilitesService.formatDateToString(new Date(this.data_inicio));
        this.paramsCarteira.data_fim = this._utilitesService.formatDateToString(new Date(this.data_fim));
        this.paramsCarteira.no_paginate = true;

        this._painelService.getAll(this.paramsCarteira).subscribe(
            data => {
                this.length = data.data.length;
                this.rows  = data.data;
                this.temp  = [...this.rows];                
            },
            error => {                
                this.loadingListaAtivo = false;
            },
            () => {
                this.loadingListaAtivo = false;
            }
        );
    }  


    /**
    * Evento de ordenação da lista de produtos
    * @param event
    */
    onSort(event:any): void{

        this.paramsCarteira.sortColumn = event.column.prop;
        this.paramsCarteira.sortOrder = event.newValue;

        this.getAll();
    }

    /**
    * Evento da paginação
    * @param {PageEvent} event
    */
    pageEvent(event:PageEvent){

        this.paramsCarteira.page = (event.pageIndex+1);
        this.paramsCarteira.per_page = event.pageSize;

        this.getAll();
    }

    /**
    * Exclui registro de empresa
    * @param id
    */
    delete(id): void {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir item do Painel",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();
                    
                    lista.loadingListaAtivo = true;
                    lista._painelService.delete(id).subscribe(
                        data => {
                            lista.loadingListaAtivo = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                            lista.refreshEvent.emit(true);
                        },
                        error => {
                            lista.closeLoading();                            
                            lista.showError(error.message);

                        },
                        () => {
                            lista.loadingListaAtivo = false;
                        }
                    );
                }
            }
        });
    }

    /**
    * Modal de edição
    */
     openChange(id): void {     
        let dialogRef = this.dialog.open(CadastraItemPainelComponent, {
            width: '600px',
            maxHeight: '99vh',
            data: { id }
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Painel atualizada com Sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registro");
            }
        });   
    } 
    
    /**
     * Cor das linhas
     */
     getRowClass = (row) => {
        
        if(row.valor_perfomance < 0){
            return {'row-color-painel-rv-failed': true};
        }else if(row.valor_perfomance > 0){
            return {'row-color-painel-rv-sucess': true};
        }else if(row.data_fim == null || row.data_fim == undefined){
            return {'row-color-painel-rv-parcial': true};
        }
    }

}
