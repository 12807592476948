import {Component}  from '@angular/core';
import {MatDialog, MatSnackBar}  from '@angular/material';
import {ActionDialog} from '../../../base-module/base-component/base.component';
import {BaseComponent} from "../../../base-module/base-component/base.component";
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';
import { GeradorOrdemService } from 'app/renda-variavel/services/gerador-ordem.service';
import { fuseAnimations } from '@fuse/animations';
import { FormControl } from '@angular/forms';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { CadastroGeradorOrdemComponent } from '../cadastro-gerador-ordem/cadastro-gerador-ordem.component';

export interface Ordens {
    id:number;
    data_operacao: Date;
    user:any;
    estrategia:any;
    rotulo: string;
    tipo: string;
    qtd_boletas: number;
}

@Component({
    selector: 'gerador-ordem',
    templateUrl: './gerador-ordem-lista.component.html',
    styleUrls: ['./gerador-ordem-lista.component.scss'],
    animations : fuseAnimations
})

export class GeradorOrdemListaComponent extends BaseComponent {

    params: any = {};
    user: any;
    loadingIndicator: boolean;       

    listEstrategia:any[]=[];
    loadingSpinnerEstrategia:boolean;
    filteredGerador: Array<Ordens> = [];
    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

    estrategia_id = new FormControl('');

    data_operacao_inicio = new FormControl(this.primeiroDia);
    data_operacao_fim = new FormControl(this.ultimoDia);    

    constructor(public _estrategiaService: EstrategiaService, 
                private _utilitesService: UtilitesService, 
                private _service: GeradorOrdemService,                
                public dialog: MatDialog, 
                public snackBar: MatSnackBar) 
    {        
        super(dialog, snackBar);
        this.getEstrategia();
        this.getAll();
    } 
    
    getEstrategia(){
        
        this.loadingSpinnerEstrategia = true;
        
        this._estrategiaService.getLista().subscribe(
            list => {
                this.loadingSpinnerEstrategia = false;
                this.listEstrategia = list.data;
            },
            error => {
                this.loadingSpinnerEstrategia = false;
            },
            () => {
                this.loadingSpinnerEstrategia = false;                
            }
        );
    }
    /**
     * Consulta lista de Boletas
     */
    getAll() {        

        this.loadingIndicator = true;

        if (this.data_operacao_inicio.value != null){
            this.params.data_inicio = this._utilitesService.formatDateToString(new Date(this.data_operacao_inicio.value));
        }

        if (this.data_operacao_fim.value != null) {
            this.params.data_fim = this._utilitesService.formatDateToString(new Date(this.data_operacao_fim.value));
        }

        this.params.estrategia_id = this.estrategia_id.value;

        this._service.getAll(this.params).subscribe(
            data => {
                this.filteredGerador = data.data;                
            },
            error => {               
                this.loadingIndicator = false;
            },
            () => {               
                this.loadingIndicator = false;
            }
        );
    }
    /**
     * Exclui registro de boleta
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Gerador de Ordem",
                text: "Deseja realmente excluir este item ?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    openCadastro(){
        let dialogRef = this.dialog.open(CadastroGeradorOrdemComponent, {
            width: '400px',
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Gerador de Ordem cadastrado com sucesso!");
                this.getAll();
            }
        });
    }

    finalizaGerador(id){

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            data: {
                title: "Finalizar Gerador de Ordem",
                text: "Deseja realmente finalizar este gerador ?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.finalizaGerador(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });        
    }

}
