import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';

import {
    MatDialog,
    MatPaginator,
    MatSnackBar,
    PageEvent
} from '@angular/material';

import { BaseComponent } from "app/base-module/base-component/base.component";

import { ClientEquityService } from 'app/clientes/services/client-equity.service';

@Component({
  selector: 'historico-movimentacao',
  templateUrl: './historico-movimentacao.component.html',
  styleUrls: ['./historico-movimentacao.component.scss']
})
export class HistoricoMovimentacaoComponent extends BaseComponent implements OnInit {

    @Input() client_id: number;
    @Input() isComplete: boolean = false;
    @Input() range: String;
    @Output() habilityExtractFullClient = new EventEmitter<boolean>();

    @ViewChild('paginator', { static: false }) paginator: MatPaginator;
    @ViewChild(HistoricoMovimentacaoComponent, { static: false }) table: HistoricoMovimentacaoComponent;

    loadingIndicator:boolean;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _equityClientService: ClientEquityService
    )
    {
        super(dialog,snackBar);
    }

    ngOnInit(): void {
        this.loadingIndicator = false;
        this.getMovement();
    }

    getMovement(){
        this.loadingIndicator = true;
        this.params.cliente_id = this.client_id;
        this.params.periodo = this.range;

        this._equityClientService.getAllMovimentEquity(this.params).subscribe(
        equity =>{
            this.length = equity.meta.total;
            this.rows = equity.data;
            this.temp = [...this.rows];
        },
        error => {
            this.loadingIndicator = false;
        },
        () => {
            this.loadingIndicator = false;
        });
    }

    handleExtractFullClient(){
        this.habilityExtractFullClient.emit(true);
    }

     /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getMovement();
    }

     /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
      onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getMovement();
    }
}
