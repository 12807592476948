import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { FormControl, Validators } from '@angular/forms';
import { BasketService } from '../services/basket.service';
import { TipoBasketService } from '../services/tipo-basket.service';

export interface DialogData {
    id: number;
} 

@Component({
    selector: 'app-upload-basket',
    templateUrl: './upload-basket.component.html',
    styleUrls: ['./upload-basket.component.scss']
})

export class UploadBasketComponent extends BaseComponent implements OnInit {

    basket:any = {
        id:''
    };

    file:any;
    nomeFile:String;
    uploader: FileUploader;
    loadingLocal: any;

    tipo_basket_id  = new FormControl('', [Validators.required]);
    rotulo          = new FormControl('', [Validators.required]);

    model:any = {
        tipo_basket_id:"",
    }
    
    params:any ={}
    listTipo:any[]=[];

    loadingSpinnerTipo:boolean;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UploadBasketComponent>,
        public service: BasketService,
        public utiliesService: UtilitesService,
        public authService: AuthService,
        private changeDetector: ChangeDetectorRef,
        private _service: BasketService,
        private _tipoService: TipoBasketService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {

        super(dialog, snackBar);
        
        this.nomeFile = null;
        this.getAllTipos();

        this.uploader = new FileUploader({
            url: this.utiliesService.urlPrefix + 'renda-variavel/basket/uploads',
            isHTML5: true,
            authToken: "Bearer " + this.authService.getToken(),           
        });

        this.uploader.onAfterAddingFile = (file) => { 
            file.withCredentials = false; 
        };
        
        this.uploader.onProgressItem = ( fileItem: FileItem, progress: any ) => { 
            this.changeDetector.detectChanges();
        };
        
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        
            this.closeLoading();
            
            if (status == 200){
                var info = JSON.parse(response);
                
                this.showSuccess(info.message);
                
                this.dialogRef.close(info);
            }else{
                var info = JSON.parse(response);               
                this.showError(info.message);      
                this.excluirBasket();
                this.dialogRef.close("cancel");
            }
        
        };
    
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    uploadFile(event) {    //line 1

        //console.log(event);
        let elem = event.target;  //line 2
        //console.log(elem);
        if (elem.files.length > 0) {     //line 3
        this.file = elem.files[0];
        this.nomeFile = this.file.name;
        }
    }

    getAllTipos(){
        
        this.loadingSpinnerTipo = true;
        
        this._tipoService.getAll(this.params).subscribe(
            list => {
                this.loadingSpinnerTipo = false;
                this.listTipo = list.data;
            },
            error => {
                this.loadingSpinnerTipo = false;
            },
            () => {
                this.loadingSpinnerTipo = false;                
            }
        );
    }

    isNomeFile(){
        return (this.nomeFile != null);
    } 

    salvar(){
        this.openLoading();
        this.model.tipo_basket_id   = this.tipo_basket_id.value;
        this.model.nome_arquivo     = this.rotulo.value;
        
        this._service.create(this.model).subscribe(
            data => {                    
                this.basket = data.data;
            },
            error => {                    
                this.showError(error.error.message);                
            },
            () => {                    
                this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
                    form.append('basket_id' , this.basket.id);                    
                   };
                this.uploader.uploadAll();                                               
            }
        );

    }

    excluirBasket(){
        this.service.delete(this.basket.id).subscribe(
            data => {
            },
            error => {
            },
            () => { }
        );
    }

    startUpload() {        
        this.salvar();
    }

}

