import { 
    Component, 
    OnInit, 
    Input 
} from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar 
} from '@angular/material';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ClienteCarteiraDerivativosService } from 'app/clientes/services/cliente-derivativos.service';

@Component({
  selector: 'lista-custodia-derivativos',
  templateUrl: './lista-custodia-derivativos.component.html',
  styleUrls: ['./lista-custodia-derivativos.component.scss'],
  providers: [ ClienteCarteiraDerivativosService ]
})

export class ListaCustodiaDerivativosComponent extends BaseComponent implements OnInit {

    @Input() cliente_id: number;    

    loadingListaAtivo:boolean = false;
    listDerivativos: any[]= [];
    total_financeiro_atual: number = 0;
    user:any;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _serviceDerivativos: ClienteCarteiraDerivativosService,
        private _authService: AuthService
    ) { 
        super(dialog,snackBar);
        this.user = JSON.parse(this._authService.getUser());
    }

    ngOnInit() {
        this.getAll();
    }

    getAll() {
        this.loadingListaAtivo = true;
        this._serviceDerivativos.getAll({cliente_id :this.cliente_id}).subscribe(
            list => {
                this.length = list.data.length;
                this.rows  = list.data;
                this.temp  = [...this.rows];     
            },
            error => { 
                this.loadingListaAtivo = false;
            },
            () => {
                this.loadingListaAtivo = false;
                this.getTotalFinanceiroCustodiaCliente() 
            }
        );
    }

    getTotalFinanceiroCustodiaCliente(){
        this.total_financeiro_atual = this.rows
            .map(item => item.volume_financeiro)
            .reduce((acumulador:number, valorAtual:number) => Number(acumulador) + Number(valorAtual), 0);
    }

    openAlterarAtivo(){

    }

    delete(){
        
    }

}
