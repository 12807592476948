import { Component, ViewEncapsulation,Inject } from '@angular/core';
import { MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { FundoInvestimentoService } from '../services/fundo-investimento.service';
import { ClienteFundoInvestimentoService } from 'app/clientes/services/cliente-fundo-investimento.service';

export interface DialogData {
    acao_comercial_id: any;
    cliente_id: any;
    id: any;
}

@Component({
    selector: 'app-cadastro-boleta-fundo',
    templateUrl: './cadastro-boleta-fundo.component.html',
    styleUrls: ['./cadastro-boleta-fundo.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [FundoInvestimentoService]
})

export class CadastroBoletaFundoComponent extends BaseComponent {

    valor = new FormControl('', [Validators.required]);    
    fundo = new FormControl('', [Validators.required]);
    cliente = new FormControl('', [Validators.required]); 
    cliente_id = new FormControl('', [Validators.required]);
    status_id = new FormControl();
    qtd = new FormControl();

    model:any = {
        caracteristica_id:"",
        acao_comercial_id:"",
    };

    loadingLocal: any;
    titulo: string;
    texto: string;
    listFundoInvestimento: any[] = [];
    listStatus: any[] = [];    
    loadingSpinnerFundoInvestimento: boolean = false;    
    loadingSpinnerStatus: boolean = false;
    loadingSpinner: boolean;
    filteredOptions: Observable<string[]>;    
    filteredOptionsFundo: Observable<string[]>;    

    constructor(public _boletaService: ClienteFundoInvestimentoService,
                private _serviceFundoInvestimento: FundoInvestimentoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroBoletaFundoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _clienteService: ClienteService,
                private _statusService: StatusMovimentacaoService)
    {
        super(dialog, snackBar);        
        this.getAllStatus();

        if( this.data.id != null){
            this.getBoleta();
        }

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        );
        
        this.fundo.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinnerFundoInvestimento = true;
                    this.params.search = query;
                    this.filteredOptionsFundo = this._serviceFundoInvestimento.search(this.params);
                }
            }
        );
        
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    displayFnFundo(fundo) {
        if (fundo) {
            return fundo.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }

    getFundo(cliente){
        this.loadingSpinnerFundoInvestimento  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        
        if (!this.valor.valid){
            return true;
        }  
        
        if (!this.status_id.valid){
            return true;
        }

        if (!this.fundo.valid){
            return true;
        }

        return false;
    }


    salvar(){ 
        this.openLoading();
        if(this.data.cliente_id != null){
            this.model.cliente_id = this.data.cliente_id;
        }else{
            const novaBoletaCliente = this.cliente.value;
            this.model.cliente_id = novaBoletaCliente.id;
        }

        const fundo = this.fundo.value;
        this.model.fundo_investimento_id = fundo.id;
        this.model.valor = this.valor.value;        
        this.model.status_id = this.status_id.value;
        this.model.qtd = this.qtd.value;

        if(this.data.id == null){
        
            this._boletaService.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                           
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    console.log("complete");
                    this.closeLoading();
                }
            );
        }else{
            
            this._boletaService.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    console.log("complete");
                    this.closeLoading();
                }
            );
        }
    }

    getAllStatus(){

        this.loadingSpinnerStatus = true;
        
        this._statusService.getLista({ tipo_produto: 'FU' }).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
                console.log("complete");
            }
        );
    }

    getErrorValorMessage() {
        return this.valor.hasError('required') ? 'Valor é um campo obrigatório' : '';
    }

    getBoleta(){
        this.openLoading();
        this._boletaService.getById(this.data.id).subscribe(
            boleta => {
                this.valor.setValue(boleta.data.valor);                
                this.fundo.setValue(boleta.data.fundo);
                this.cliente.setValue(boleta.data.cliente);
                this.cliente_id.setValue(boleta.data.cliente.id);
                this.status_id.setValue(boleta.data.status_id);
                this.qtd.setValue(boleta.data.qtd);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
                console.log("complete");
            }
        );

    }

}

