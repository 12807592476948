import {Component, ViewChild, TemplateRef, ViewEncapsulation,Inject} from '@angular/core';
import { MatDialog,MatSnackBar,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import {AuthService} from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { Observable } from 'rxjs';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { AcaoComercialService } from 'app/acao-comercial/services/acao-comercial.service';
import { CaracteristicaPrevidenciaService } from '../services/caracteristica-previdencia.service';
import { BoletaPrevidenciaService } from '../services/boleta-previdencia.service';

export interface DialogData {
    acao_comercial_id: any;
    cliente_id: any;
    id: any;
}


@Component({
    selector: 'app-cadastro-boleta-previdencia',
    templateUrl: './cadastro-boleta-previdencia.component.html',
    styleUrls: ['./cadastro-boleta-previdencia.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class CadastroBoletaPrevidenciaComponent extends BaseComponent {

    valor = new FormControl('', [Validators.required]);    
    caracteristica = new FormControl('', [Validators.required]);
    data_futura  = new FormControl();
    observacao  = new FormControl();
    dinheiro_novo  = new FormControl('', [Validators.required]);   
    cliente = new FormControl('', [Validators.required]); 
    cliente_id = new FormControl('', [Validators.required]);
    acao_comercial_id = new FormControl();
    qtd = new FormControl();
    status_id = new FormControl('',[Validators.required]);

    model:any = {
        caracteristica_id:"",
        acao_comercial_id:"",
    };

    loadingLocal: any;
    titulo: string;
    texto: string;
    listCaracteristica: any[] = [];
    listStatus: any[] = [];
    listAcaoComercial: any[] = [];
    loadingSpinnerCaracteristica: boolean = false;
    loadingSpinnerAcaoComercial: boolean = false;
    loadingSpinnerStatus: boolean = false;
    loadingSpinner: boolean;
    filteredOptions: Observable<string[]>;
    filteredOptionsCaracteristica: Observable<string[]>;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _boletaService: BoletaPrevidenciaService,
                private _serviceCaracteristica: CaracteristicaPrevidenciaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,                
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroBoletaPrevidenciaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _clienteService: ClienteService,
                private _statusService: StatusMovimentacaoService,
                private _serviceAcaoComercial: AcaoComercialService)
    {
        super(dialog, snackBar);        
        this.getAllStatus();
        this.getAllAcaoComercial();        

        if( this.data.id != null){
            this.getBoleta();
        }

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        );

        this.caracteristica.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinnerCaracteristica = true;
                    this.params.search = query;
                    this.filteredOptionsCaracteristica = this._serviceCaracteristica.search(this.params);
                }
            }
        );
        
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    displayFnCaracteristica(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }

    getCaracteristicaNome(cliente){
        this.loadingSpinnerCaracteristica  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        
        if (!this.valor.valid){
            return true;
        }  

        if (!this.status_id.valid){
            return true;
        }        

        return false;
    }


    salvar(){ 
        this.openLoading();
        if(this.data.cliente_id != null){
            this.model.cliente_id = this.data.cliente_id;
        }else{
            const novaBoletaCliente = this.cliente.value;
            this.model.cliente_id = novaBoletaCliente.id;
        }
        const caracteristica = this.caracteristica.value;

        this.model.valor = this.valor.value;
        this.model.acao_comercial_id = this.acao_comercial_id.value;
        this.model.caracteristica_id = caracteristica.id;
        this.model.status_id = this.status_id.value;
        this.model.observacao = this.observacao.value;
        this.model.qtd = this.qtd.value;

        if(this.data.id == null){
        
            this._boletaService.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);                           
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    console.log("complete");
                    this.closeLoading();
                }
            );
        }else{
            
            this._boletaService.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    console.log("complete");
                    this.closeLoading();
                }
            );
        }
    }

    getCaracteristica(){
        
        this.loadingSpinnerCaracteristica = true;
        this.params.no_paginate = true;
        this._serviceCaracteristica.getAll(this.params).subscribe(
            resquest => {
                this.listCaracteristica = resquest.data;

            },
            error => {
                console.log("error: " + error);
                this.loadingSpinnerCaracteristica = false;
            },
            () => {

                console.log("complete");
                this.loadingSpinnerCaracteristica = false;
            }
        );
    }

    getAllStatus(){

        this.loadingSpinnerStatus = true;
        
        this._statusService.getLista({ tipo_produto: 'PE' }).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
                console.log("complete");
            }
        );
    }

    getErrorValorMessage() {
        return this.valor.hasError('required') ? 'Valor é um campo obrigatório' : '';
    }

    getBoleta(){
        this.openLoading();
        this._boletaService.getById(this.data.id).subscribe(
            boleta => {
                this.valor.setValue(boleta.data.valor);                
                this.caracteristica.setValue(boleta.data.caracteristica);
                this.cliente.setValue(boleta.data.cliente);
                this.cliente_id.setValue(boleta.data.cliente.id);
                this.acao_comercial_id.setValue(boleta.data.acao_comercial_id);
                this.status_id.setValue(boleta.data.status_id);                
                this.observacao.setValue(boleta.data.observacao);                
                this.qtd.setValue(boleta.data.qtd);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
                console.log("complete");
            }
        );

    }

    getAllAcaoComercial(){

        this.loadingSpinnerAcaoComercial = true;
        
        this._serviceAcaoComercial.getAll( {no_paginate: true }).subscribe(
            list => {
                this.listAcaoComercial = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerAcaoComercial = false;
                if (this.data.acao_comercial_id != null && (this.acao_comercial_id.value == null || this.acao_comercial_id.value == '')){                    
                    this.acao_comercial_id.setValue(Number(this.data.acao_comercial_id));
                    this.model.acao_comercial_id = Number(this.data.acao_comercial_id);
                }
            }
        );
    }

}

