import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";
import {BaseComponent, ActionDialog} from '../../../../base-module/base-component/base.component';

import { fuseAnimations } from '@fuse/animations';

import { FileManagerService } from 'app/apps/file-manager/file-manager.service';
import { CriarPastaComponent } from '../../criar-pasta/criar-pasta.component';
import { ArquivoService } from '../../services/arquivo.service';
import { PastaService } from '../../services/pasta.service';
import { AuthService } from 'app/base-module/base-service/auth.service';

@Component({
    selector   : 'file-manager-details-sidebar',
    templateUrl: './details.component.html',
    styleUrls  : ['./details.component.scss'],
    animations : fuseAnimations
})

export class FileManagerDetailsSidebarComponent extends BaseComponent
{
    selected: any;
    params: any = {
        pasta_id:''
    };
    loadingTest:any;
    usuario:any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FileManagerService} _fileManagerService
     */
    constructor(
        private _fileManagerService: FileManagerService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogLoading: MatDialog,
        private _arquivoService: ArquivoService,
        private _pastaService: PastaService,
        private _authService: AuthService,
    )
    {
        super(dialog,snackBar);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.usuario = JSON.parse(this._authService.getUser());        
    }

    
    /**
     * Exibe mensagem de sucesso
     * @param {string} message
     */
    showSuccess(message: string): void {
        this.snackBar.open(message, 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    /**
     * Exibe mensagem de erro
     * @param {string} message
     */

    showError(message: string): void {
        this.snackBar.open(message, 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._fileManagerService.onFileSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selected => {
                this.selected = selected;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onEdit(selected): void 
    {
        if(selected.type == 'folder'){
            this.alterarPasta(selected.id);
        }

        console.log(selected)
    }

    onDelete(selected): void 
    {
        if(selected.type == 'folder')
        {
            var lista = this;
            this.dialog.open(ActionDialog, {
                width: '250px',
                disableClose: true,
                panelClass: 'modal-delete',
                data: {
                    title: "Exclução de Pasta",
                    text: "Deseja realmente excluir esta pasta?",
                    callback: function () {
                        lista.dialog.closeAll();
                        
                        lista.loadingIndicator = true;
                        lista._pastaService.delete(selected.id).subscribe(
                            data => {
                                lista.loadingIndicator = false;
                                lista.showSuccess(data.message);
                                var params: any = {};
                                params.pasta_id = selected.pasta_id;

                                if(selected.pasta_id != null){
                                    lista._fileManagerService.getFiles(params);
                                }else{
                                    lista._fileManagerService.getFiles({});
                                }
                            },
                            error => {
                                lista.closeLoading();
                                console.log("error: " + error);
                                lista.showError(error.message);
    
                            },
                            () => {
    
                                console.log("complete");
                                lista.loadingIndicator = false;
                            }
                        );
                    }
                }
            });
        }else{
            var lista = this;
            this.dialog.open(ActionDialog, {
                width: '250px',
                disableClose: true,
                data: {
                    title: "Exclução de Arquivo",
                    text: "Deseja realmente excluir este arquivo?",
                    callback: function () {
                        lista.dialog.closeAll();
                        
                        lista.loadingIndicator = true;
                        lista._arquivoService.delete(selected.id).subscribe(
                            data => {
                                lista.loadingIndicator = false;
                                lista.showSuccess(data.message);     
                                lista._fileManagerService.getFiles(this.params);                           
                            },
                            error => {
                                lista.closeLoading();
                                console.log("error: " + error);
                                lista.showError(error.message);
    
                            },
                            () => {
    
                                console.log("complete");
                                lista.loadingIndicator = false;
                            }
                        );
                    }
                }
            });
        }
    }

    /**
     * Modal de cadastro
     */
    alterarPasta(id): void {

        let dialogRef = this.dialog.open(CriarPastaComponent, {
            width: '600px',
            data: {
                pasta_id: this.selected.id,
                id: id
            }      
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Pasta salva com Sucesso!");                
                this._fileManagerService.getFiles(this.params);

            }else if(result != undefined && result != "cancel"){
                this.showError("Não foi possivel criar a pasta. Tente novamente!");
            }
        });
    }

    openLoading(): void {

        this.loadingTest =  this.dialogLoading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoading(): void {
        this.loadingTest.close();
    }

    /**
     * Consulta lista
     */
    onDownload(selected) {

        //this.openLoading();
        this._arquivoService.download(selected.id).subscribe(
            data => {

                const blob = new Blob([data], { type: selected.mineType });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {
                console.log(error);
                //this.closeLoading();
            },
            () => {

                console.log("complete");
                //this.closeLoading();
            }
        );
    }
}

export class LoadingTeste {

    constructor(
        public dialogRef: MatDialogRef<LoadingTeste>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}