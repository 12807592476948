import {Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';
import { FormControl } from '@angular/forms';
import {BaseComponent} from "../../base-module/base-component/base.component";
import { Observable } from 'rxjs';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';
import { AtivoService } from 'app/auxiliares/services/ativo-service';
import { EstrategiaService } from 'app/renda-variavel/services/estrategia.service';

export interface DialogData {
    id: number;
}

@Component({
    selector: 'app-filtro-historico-cliente',
    templateUrl: './filtro-historico-cliente.component.html',
    styleUrls: ['./filtro-historico-cliente.component.scss'],
    providers:[ AtivoService ]
})

export class FiltroHistoricoClienteComponent extends BaseComponent {
    
    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
    
    ativo = new FormControl('');
    operacao = new FormControl('');
    estrategia_id = new FormControl('');
    data_operacao_inicio = new FormControl(this.primeiroDia);
    data_operacao_fim = new FormControl(this.ultimoDia);    

    paramsStatus: any = {
        finalizada: "",
        tipo: ""
    }

    loadingSpinner: boolean;
    loadingSpinnerStatus: boolean;
    loadingSpinnerAssessor: boolean;
    loadingSpinnerAtivos: boolean;
    loadingSpinnerArquivos: boolean;
    filteredOptions: Observable<string[]>;
    listStatus: Object[] = [];
    listAssessor: Object[] = [];
    listAtivos: Object[] = [];
    listArquivos: Object[] = [];
    filteredOptionsAtivo: Observable<string[]>;

    listEstrategia: any[] = [];
    loadingSpinnerEstrategia: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltroHistoricoClienteComponent, { static: false }) table: FiltroHistoricoClienteComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltroHistoricoClienteComponent>,        
        private _utilitesService: UtilitesService,
        private _ativoService: AtivoService,       
        private _serviceEstrategia: EstrategiaService, 
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        super(dialog, snackBar);
       
        this.getAllEstrategias();
        this.ativo.valueChanges.subscribe(
            query => {
                if (typeof query === "string") {
                    this.loadingSpinnerAtivos = true;
                    this.params.search = query;
                    this.params.tipo = 'NORMAL';
                    this.filteredOptionsAtivo = this._ativoService.search(this.params);
                }
            }
        );
        
    }
    

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(): void {

        var inic = null;
        if (this.data_operacao_inicio.value != null){
            inic = this._utilitesService.formatDateToString(new Date(this.data_operacao_inicio.value));
        }

        var fim = null;
        if (this.data_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(new Date(this.data_operacao_fim.value));
        }

        const ativoSelecionado = this.ativo.value;

        this.dialogRef.close({           
            'data_operacao_inicio': inic,
            'data_operacao_fim': fim,
            'ativo_id': ativoSelecionado.id,               
            'estrategia_id': this.estrategia_id.value,
            'operacao': this.operacao.value,
        });
    }

    displayFnAtivo(ativo): void {
        if (ativo) {
            return ativo.sigla; 
        }
    }

    getAtivo(ativo): void{
        this.loadingSpinnerAtivos  = false;
    }

    getAtivos(): void {
        this.loadingSpinnerAtivos = true;
        this._ativoService.getAll({ no_paginate:true })
            .subscribe(
                resquest => {
                    this.listAtivos = resquest.data;
                },
                error => {
                    this.loadingSpinnerAtivos = false;
                },
                () => {
                    this.loadingSpinnerAtivos = false;                    
                }
            );
    } 
    
    getAllEstrategias() {
        this.loadingSpinnerEstrategia = true;
        this._serviceEstrategia.getLista().subscribe(
            list => {
                this.listEstrategia = list.data;                
            },
            error => { },
            () => {
                this.loadingSpinnerEstrategia = false;                
            }
        );
    }

}