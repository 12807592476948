import { Injectable} from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class EscritorioService {    

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Escritório por id
     * @param id ID da escritorio
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "escritorio/" + id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Escritório
     * @param id ID da Escritório
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "escritorio"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Lista de Escritórios
     * @param id ID
     */
    getAllList(params): Observable<any> {
        
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix+"escritorio/lista"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Lista de Escritórios
     * @param id ID
     */
    getTodos(params): Observable<any> {
        
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix+"escritorio/todos"+env,{headers: this.authService.getAuthHeaders()});
    }

    /**
     * Delete de Escritório
     * @param id ID da Escritório
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "escritorio/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Cadastra Escritório
     * @param params Json Escritório
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "escritorio", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Atualiza Escritório
     * @param params Json Escritório
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "escritorio/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }    

}
