import { 
    Component, 
    OnInit,
    Input
} from '@angular/core';

import { MatDialog, MatSnackBar } from '@angular/material';

import { BaseComponent } from 'app/base-module/base-component/base.component';

import { ClienteModel } from 'app/clientes/models/cliente.model';
import { ClientEquityModel } from 'app/clientes/models/client-equity.model';

import { ClientEquityService } from 'app/clientes/services/client-equity.service';

@Component({
  selector: 'cliente-investimentos',
  templateUrl: './investimentos.component.html',
  styleUrls: ['./investimentos.component.scss'],
  providers: [ ClientEquityService ]
})
export class InvestimentosComponent extends BaseComponent implements OnInit {

    @Input() client: ClienteModel;
    @Input() clientEquity: ClientEquityModel;
    @Input() dataOperacao: Date | null;

    loadingList:boolean = false;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _equityClientService: ClientEquityService){ 
            super(dialog, snackBar);           
    }

    ngOnInit() {
    }

    getProductClient(serviceId){
        let searchParams = {       
            servico_id: serviceId,
            cliente_id: this.client.id,
            data_operacao: null
        };
        
        if(typeof this.dataOperacao != 'string'){     
            const data = new Date(this.dataOperacao);              
            searchParams.data_operacao = data.toISOString().split('T')[0];
        }else{
            searchParams.data_operacao = this.dataOperacao;                      
        }

        this.rows = [];
        this.temp = [];
        
        this.loadingList = true;
        this._equityClientService.getAll(searchParams).subscribe(
        equity =>{
            this.length = equity.data.length;
            this.rows = equity.data;
            this.temp = [...this.rows];
        },
        error => {
            this.loadingList = false;
        },
        () => {
            this.loadingList = false;
        });
    }
}
