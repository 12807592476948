import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/base-module/base-service/auth-guard.service';
import { ListaNotificacaoComponent } from './lista-notificacao/lista-notificacao.component';
import { NgModule } from '@angular/core';

const routes: Routes = [{
    path: '',
    children: [
        {
            path: '',
            component: ListaNotificacaoComponent,
        },    
      
    ],
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class NotificacaoRoutesModule {}