import {Injectable}         from "@angular/core";
import { UtilitesService }  from "../../../base-module/base-service/utilites.service";
import { AuthService }      from  "../../../base-module/base-service/auth.service";
import { Observable }       from "rxjs";
import {HttpClient}         from "@angular/common/http";

@Injectable()
export class PastaService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }

    /**
     * Busca a Pasta pelo ID
     * @param id ID da Pasta
     */
    getById(id): Observable<any> {
        return this._http.get(this.utilitesService.urlPrefix + "file-manager/pasta/" + id, { headers: this.authService.getAuthHeaders() });
    }
    
    /**
     * Lista todas as pasta do GED
     * @param id ID da Pasta
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "file-manager/pasta"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Delete pasta do Usuário
     * @param id ID da Pasta
     */
    delete(id): Observable<any> {
        return this._http.delete(this.utilitesService.urlPrefix + "file-manager/pasta/"+id, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Criar Pasta do Usuário
     * @param params Json Pasta
     */
    create(params):Observable<any>{
        return this._http.post(this.utilitesService.urlPrefix + "file-manager/pasta", JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }


    /**
     * Atualiza Pasta do Usuário
     * @param params Json Pasta
     */
    update(id, params):Observable<any>{
        return this._http.put(this.utilitesService.urlPrefix + "file-manager/pasta/"+id, JSON.stringify(params), { headers: this.authService.getAuthHeaders() });
    }    
	
}
