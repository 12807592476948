import { Component, ViewChild, TemplateRef, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent, LoadingTeste} from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { BoletaCoeService } from '../services/boleta-coe.service';
import { BoletaRendaFixaService } from '../services/boleta-rendafixa.service';

export interface DialogData {
    boletas: any;
    tipo: string;
}

@Component({
    selector: 'app-atualiza-status-boleta-renda-fixa',
    templateUrl: './atualiza-status-boleta-renda-fixa.component.html',
    styleUrls: ['./atualiza-status-boleta-renda-fixa.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ BoletaCoeService ]
})

export class AtualizaStatusBoletaRendaFixaComponent extends BaseComponent {

    status_id   = new FormControl('', [Validators.required]);
    listStatus: any[] = [];

    model: any = {
        status_id: "",
    };

    paramsStatus: any ={
        finalizada:""
    }

    loadingLocal: any;
    loadingSpinnerStatus: boolean;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(AtualizaStatusBoletaRendaFixaComponent, { static: false }) table: AtualizaStatusBoletaRendaFixaComponent;

    constructor(public service: BoletaRendaFixaService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading:MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<AtualizaStatusBoletaRendaFixaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _statusService:StatusMovimentacaoService )
    {
        super(dialog, snackBar);
        this.listStatus = [];

       
        this.getAllStatus();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    getAllStatus(){
        
        this.loadingSpinnerStatus = true;
        this.paramsStatus.tipo_produto = 'RF';
        
        this._statusService.getAll(this.paramsStatus).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
            }
        );
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){
        if (!this.status_id.valid){
            return true;
        }

        return false;
    }

    salvar(){
        this.openLoading();
        this.model.status_id    = this.status_id.value;
        this.model.boletas      = this.data.boletas;
        this.atualizaStatus();

    }

    private atualizaStatus(){
        console.log(this.model)
        this.service.updateStatus(this.model).subscribe(
            data => {
                this.closeLoading();
                this.dialogRef.close(data);
                this.showSuccess(data.message);
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getErrorStatusMessage() {
        return this.status_id.hasError('required') ? 'Status é um campo obrigatório' : '';
    }

}
