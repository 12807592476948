import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuardService } from 'app/base-module/base-service/auth-guard.service';
import { ListaBasketComponent } from './lista-basket/lista-basket.component';
import { ListaBasketAtivoComponent } from './lista-basket-ativo/lista-basket-ativo.component';
import { ListaOperacaoComponent } from './swing-trade/lista-operacao/lista-operacao.component';
import { ListaOperacaoClientesComponent } from './swing-trade/lista-operacao-clientes/lista-operacao-clientes.component';
import { GeradorOrdemListaComponent } from './gerador-ordem/gerador-ordem-lista/gerador-ordem-lista.component';
import { GeradorOrdemStepsComponent } from './gerador-ordem/gerador-ordem-steps/gerador-ordem-steps.component';
import { DashboardOperacionalComponent } from './dashboard-operacional/dashboard-operacional.component';
import { ListaOperacaoEstruturadaComponent } from './lista-operacao-estruturada/lista-operacao-estruturada.component';
import { DashRvComercialComponent } from './dash-rv-comercial/dash-rv-comercial.component';
import { ListaBoletaPendenteComponent } from './boletas/lista-boleta-pendente/lista-boleta-pendente.component';
import { ListaIncosistenciasComponent } from './lista-incosistencias/lista-incosistencias.component';
import { ListaComponent } from './painel-rv/lista/lista.component';

const routes: Routes = [{
    path: '',
    children: [
        {
            path: 'dashboard-operacional',
            component: DashboardOperacionalComponent,
        },
        {
            path: 'dashboard-comercial',
            component: DashRvComercialComponent,
        },
        {
            path: 'basket',
            component: ListaBasketComponent,
        },
        {
            path: 'gerador-ordens',
            component: GeradorOrdemListaComponent,
        },
        {
            path: 'gerador-ordens/:id',
            component: GeradorOrdemStepsComponent,
        },
        {
            path: 'basket-ativos/:id',
            component: ListaBasketAtivoComponent,
        },  
        {
            path: 'swing-trade',
            component: ListaOperacaoComponent,
        },     
        {
            path: 'swing-trade/clientes/:id',
            component: ListaOperacaoClientesComponent,
        },
        {
            path: 'historico-operacao-estruturada',
            component: ListaOperacaoEstruturadaComponent,
        },   
        {
            path: 'boleta-pendente',
            component: ListaBoletaPendenteComponent,
        },   
        {
            path: 'lista-incosistencias',
            component: ListaIncosistenciasComponent,
            canActivate: [AuthGuardService]
        }, 
        {
            path: 'painel-renda-variavel',
            component: ListaComponent,
            canActivate: [AuthGuardService]
        },               
      
    ],
    canActivate: [AuthGuardService]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class RendaVariavelRoutesModule {}