import {Component, ViewChild, TemplateRef}  from '@angular/core';
import {MatDialog, MatSnackBar, PageEvent, MatPaginator}  from '@angular/material';
import {BaseComponent} from "../../base-module/base-component/base.component";
import {Router, ActivatedRoute} from "@angular/router";
import { AuthService } from 'app/base-module/base-service/auth.service';
import {SelectionType} from '@swimlane/ngx-datatable';
import { BasketService } from '../services/basket.service';
import { FiltraBasketAtivoComponent } from '../filtra-basket-ativo/filtra-basket-ativo.component';

@Component({
    selector: 'app-lista-basket-ativo',
    templateUrl: './lista-basket-ativo.component.html',
    styleUrls: ['./lista-basket-ativo.component.scss']
})

export class ListaBasketAtivoComponent extends BaseComponent{

    params: any = {};
    user:any;
    loadingIndicator: boolean;
    SelectionType = SelectionType;

    selected = [];
    data:any = {};
    titulo:string;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaBasketAtivoComponent, { static: false }) table: ListaBasketAtivoComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;

    constructor(public service: BasketService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                private _authService: AuthService,
                private _Activatedroute: ActivatedRoute) 
    {
        
        super(dialog, snackBar);
        this.data = this._Activatedroute.snapshot.params['id'];          
        
        this.user = JSON.parse(this._authService.getUser());                
        this.getAllAtivos();
        this.titulo = 'Basket';
        this.getById();

    }
    /**
     * Evento de ordenação da lista de produtos
     * @param event
     */
    onSort(event:any){

        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;
        this.getAllAtivos();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        console.log(event);

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAllAtivos();
        
    }

    retornarLista(){
        this.route.navigate(['/renda-variavel/basket']);
    }
    /**
     * Consulta lista de Boletas
     */
    getById() {     
        this.loadingIndicator = true;
        this.service.getById(this.data).subscribe(
            data => {                
                this.titulo = ('Basket - ' + data.data.nome_arquivo + ' (' + data.data.tipo_basket.nome + ')');                
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Consulta lista de Boletas
     */
    getAllAtivos() {     
        this.loadingIndicator = true;
        this.params.basket_id = this.data;
        this.service.getAllAtivos(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];                
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

     /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltraBasketAtivoComponent, {
            width: '700px',            
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined) {
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.ativo != undefined && result.ativo != null) {
                    this.params.ativo_id = result.ativo.id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                this.params.basket_id = this.data;

                this.paginator.firstPage();
                this.getAllAtivos();
            }
        });
    } 

    getOperacao(row){
        if(row.operacao.trim() == 'C'){
            return 'Compra';          
        }else if(row.operacao.trim() == 'V'){
            return 'Venda'
        }else{
            return row.operacao.trim();
        }
    }

}
