import { Component, ViewChild, OnInit} from '@angular/core';
import { MatDialog, MatSnackBar} from '@angular/material';
import { CadastroProdutoRendafixaComponent } from 'app/renda-fixa/cadastro-produto-rendafixa/cadastro-produto-rendafixa.component';
import { ProdutoRendaFixaService } from 'app/renda-fixa/services/produto-rendafixa.service';
import { BaseComponent } from '../../../base-module/base-component/base.component';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})

export class ProdutosComponent extends BaseComponent implements OnInit {

    @ViewChild(ProdutosComponent, { static: false }) table: ProdutosComponent;

    constructor(
            public dialog: MatDialog, 
            public snackBar: MatSnackBar,
            public service: ProdutoRendaFixaService) {
        super(dialog, snackBar);
    }

    ngOnInit() {
    }

      /**
     * Modal de cadastro
     */
       openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroProdutoRendafixaComponent, {
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Produto cadastrado com sucesso!");               
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo registro.");
            }
        });
    }

}
