import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { subDays, startOfDay, addDays } from 'date-fns';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';

@Injectable()
export class CalendarService implements Resolve<any>
{
    events: any;
    onEventsUpdated: Subject<any>;

    public data = [
        {
            start : subDays(startOfDay(new Date()), 1),
            end : addDays(new Date(), 1),
            title : 'Reunião Prosperidade',
            allDay : false,
            color : {
                primary : '#F44336',
                secondary: '#FFCDD2'
            },
            resizable: {
                beforeStart: true,
                afterEnd : true
            },
            draggable: true,
            meta : {
                location: 'Recife',
                notes : 'Reunião para tratar das melhorias do prosper'
            }
        },
    ]

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService,
        private utilitesService: UtilitesService
    )
    {
        // Set the defaults
        this.onEventsUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
               // this.getEvents()
            ]).then(
                ([events]: [any]) => {
                    resolve();
                },
                reject
            );
        });
    }
/**
     * 
     * @@param id
     */
    getById(id): Observable<any> {
        return this._httpClient.get(this.utilitesService.urlPrefix + "calendario/eventos/" + id, { headers: this._authService.getAuthHeaders() });
    }


    /**
     * Get events
     *
     * @returns {Promise<any>}
     */
    getEvents(): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.get(this.utilitesService.urlPrefix + 'calendario/eventos')
                .subscribe((response: any) => {
                    this.events = this.data;
                    this.onEventsUpdated.next(this.events);
                    resolve(this.events);
                }, reject);
        });
    }

    /**
     * Lista os Eventos
     * @param id ID do Evento
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._httpClient.get(this.utilitesService.urlPrefix + "calendario/eventos"+env, { headers: this._authService.getAuthHeaders() });
    }

      /**
     * Cadastra de Eventos
     * @param params Json do Eventos
     */
    create(params):Observable<any>{
        return this._httpClient.post(this.utilitesService.urlPrefix + "calendario/eventos", JSON.stringify(params), { headers: this._authService.getAuthHeaders() });
        
    }



        /**
     * Atualiza o Evento
     * @param params Json do Evento
     */
    update(id, params):Observable<any>{
        return this._httpClient.put(this.utilitesService.urlPrefix + "calendario/eventos/"+id, JSON.stringify(params), { headers: this._authService.getAuthHeaders() });
    }

    /**
     * Update events
     *
     * @param events
     * @returns {Promise<any>}
     */
    updateEvents(events): Promise<any>
    {
        return new Promise((resolve, reject) => {
            
            this.data.push(events);
            
            this._httpClient.post(this.utilitesService.urlPrefix  + 'calendario/eventos', {
                id  : 'events',
                data: [...events]
            })
                .subscribe((response: any) => {
                    //this.getEvents();
                }, reject);
        });
    }

      /**
     * Deleta um Evento
     * @param id ID do Evento
     */
    delete(id): Observable<any> {
        return this._httpClient.delete(this.utilitesService.urlPrefix + "calendario/eventos/"+id, { headers: this._authService.getAuthHeaders() });
    }


}
