import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable } from "rxjs";
import {AuthService} from "../base-service/auth.service";
import {Router} from "@angular/router";
import {of} from "rxjs/internal/observable/of";
import {catchError} from "rxjs/operators";
import {MatSnackBar} from "@angular/material";

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
    
    constructor(
        private router: Router,
        public authService:AuthService, 
        public snackBar: MatSnackBar
    ) {}

    /**
     * @param HttpRequest<any> request - The intercepted request
     * @param HttpHandler next - The next interceptor in the pipeline
     * @return Observable<HttpEvent<any>>
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        request = this.addToken(request);
        return next.handle(request)
        // add error handling
            .pipe(
                catchError(
                    (error: any, caught: Observable<HttpEvent<any>>) => {
                        console.log(error);
                        if (error.status === 401) {

                            this.handleAuthError();                            
                    
                            // if you've caught / handled the error, you don't
                            // want to rethrow it unless you also want
                            // downstream consumers to have to handle it as
                            // well.

                            this.showError('Sessão expirada, ou o usuário não está autenticado, efetue login novamente.');
                            return of(error);

                        }else if (error.status === 403) {
                            this.handlePerfilError();
                            // if you've caught / handled the error, you don't
                            // want to rethrow it unless you also want
                            // downstream consumers to have to handle it as
                            // well.
                            this.showError(error.error.message);
                            return of(error);
                        }

                        throw error;
                    }
                ),
            );
    }

    /**
     * Handle API authentication errors.
     */
    private handlePerfilError() {
        // clear stored credentials; they're invalid
        //this.authService.logout();

        // navigate back to the login page
        this.router.navigate(['/home']);
    }

    showError(message: string) {
        this.snackBar.open(message, 'X', {
            duration: 3000
            //extraClasses: ['error-message', 'mdl-shadow--6dp', 'bold']
        });
    }

    /**
     * Handle API authentication errors.
     */
    private handleAuthError() {
        // clear stored credentials; they're invalid
        this.authService.logout();

        // navigate back to the login page
        this.router.navigate(['/auth/login']);
    }

    /**
     * Add stored auth token to request headers.
     * @param HttpRequest<any> request - the intercepted request
     * @return HttpRequest<any> - the modified request
     */
    private addToken(request: HttpRequest<any>): HttpRequest<any> {
        const token: string = this.authService.getToken();
        if (token) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
        return request;
    }
}