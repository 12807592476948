import {
    Component, 
    ViewChild, 
    TemplateRef, 
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import {BaseComponent} from "../../base-module/base-component/base.component";

import {RolesService} from "../services/roles.service";
import {AuthService} from "../../base-module/base-service/auth.service";
import { ModuloService } from 'app/modulos/services/modulo.service';

export interface DialogData {
    id: number;
    empresa_id:any;
}

@Component({
    selector: 'dialog-permissao',
    templateUrl: './permissao.component.html',
    styleUrls: ['./permissao.component.scss'],
    providers: [ModuloService]    
})

export class PermissaoComponent extends BaseComponent {
    
    model:any = {
        empresa_id:"",
        role_id:"",
        permissoes:[]
    };

    listRoles:any[]=[];
    messageModulos:any = "Carregando lista de permissões.";
    modulos:any[] = [];
    
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(PermissaoComponent, { static: false }) table: PermissaoComponent;

    constructor(public service: RolesService, public dialog: MatDialog,
                public snackBar: MatSnackBar, public dialogRef: MatDialogRef<PermissaoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,                
                public authService: AuthService,
                public moduloService:ModuloService) {
        super(dialog, snackBar);

        this.listRoles=[];

        if(this.data != null && this.data != undefined){
            this.model.role_id = this.data.id;
            this.getAllPermissoes();
            this.getAll();
        }

    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    salvar(){
        this.openLoading();
        this.service.savePermissoes(this.data.id,this.model).subscribe(
            data => {
                this.closeLoading();
                this.dialogRef.close(data);
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
            }
        );

    }

    getAll(){
        this.openLoading();
        this.moduloService.getAllPermissoes(this.model).subscribe(
            list => {
                this.closeLoading();                
                this.modulos = list.data;
                if(this.modulos.length<=0){
                    this.messageModulos = "Esta empresa não posssui módulos associados."
                }
            },
            error => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getAllPermissoes(){       
        this.service.getAllPermissoes(this.data.id,this.model).subscribe(
            list => {               
                for(var i=0;i<list.data.length;i++){
                    this.model.permissoes.push(list.data[i].id);
                }
            },
            error => {              
                this.showError(error.error.message);
            },
            () => {  }
        );
    }

    onChangePermissao(event,id){
        if(event.checked){
            this.addPermissao(id);
        }else{
            this.removePermissao(id);
        }
    }

    removePermissao(id){
        var permissoes = this.model.permissoes;
        var outerModulo = [];
        for(var i=0;i<permissoes.length;i++){
            if(id != permissoes[i]){
                outerModulo.push(permissoes[i]);
            }
        }

        this.model.permissoes = outerModulo;
    }

    addPermissao(id){
        this.model.permissoes.push(id);
    }

    isCheckedModulo(permissao){
        var check = false;

        for(var i=0; i < this.model.permissoes.length;i++){

            if(permissao.id == this.model.permissoes[i]){
                check = true;
            }
        }

        return check;
    }

    isDisabled(){
        var disabled = false;

        if(this.data.empresa_id == null && !this.authService.isSuperAdmin()){
            disabled = true;
        }

        return disabled;
    }
}
