import {
    Component,
    Inject,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
    MatSnackBar
} from '@angular/material';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { ClienteService } from 'app/clientes/services/cliente.service';
import { BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { ListaAtividadeAssuntoService } from '../services/lista-atividade-assunto.service';
import { ListaAtividadeClienteService } from '../services/lista-atividade-clientes.service';
import { ListaAtividadeService } from '../services/lista-atividades.service';

export interface DialogData {
    id: number;
    is_alterar_data: boolean;
}

@Component({
    selector: 'cadastra-atividade-clientes',
    templateUrl: './cadastra-atividade-clientes.component.html',
    styleUrls: ['./cadastra-atividade-clientes.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CadastraAtividadeClientesComponent extends BaseComponent {
    form = new FormGroup ({
        nome: new FormControl("", [Validators.required]),
        cliente_id: new FormControl("", [Validators.required]),
        assunto_id: new FormControl("", [Validators.required]),
        data_hora_atividade: new FormControl("", [Validators.required]),
        cliente: new FormControl("", [Validators.required]),
        atividade_id: new FormControl(""),
        observacao: new FormControl(""),
        valor: new FormControl(0),
    });

    model: any = {
        cliente_id: "",
        observacao: "",
        assunto_id: "",
    };

    loadingLocal: any;
    loadingSpinnerAssunto: boolean = false;
    loadingSpinner: boolean = false;
    filteredOptions: Observable<string[]>;
    listAssunto: Object[] = [];

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;

    constructor(
        public _service: ListaAtividadeService,
        public _assuntoService: ListaAtividadeAssuntoService,
        public _atividadeClienteService: ListaAtividadeClienteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public _authService: AuthService,
        private _clienteService: ClienteService,
        public dialogRef: MatDialogRef<CadastraAtividadeClientesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);
        if (this.data != null && this.data != undefined && this.data.id != null) {
            this.getById();
        }

        this.getAllAssuntos();
        
    }

    ngOnInit(): void {
       
        if(this.data != undefined && this.data.is_alterar_data != undefined && this.data.is_alterar_data){
            this.form.get('nome').disable();
            this.form.get('cliente_id').disable();
            this.form.get('assunto_id').disable();
            this.form.get('cliente').disable();
            this.form.get('observacao').disable();
            this.form.get('valor').disable();
        }

        this.form.get('cliente').valueChanges.subscribe((query) => {
            if (typeof query === "string" || typeof query === "number") {
                this.loadingSpinner = true;
                this.params.search = query;
                this.filteredOptions = this._clienteService.search(this.params);
            }
        });
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }   

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (this.form.get('data_hora_atividade').value == null || this.form.get('data_hora_atividade').value == undefined) {
            return true;
        }

        if (this.form.get('assunto_id').value == null || this.form.get('assunto_id').value == undefined) {
            return true;
        }

        if (this.form.get('cliente').value == null || this.form.get('assunto_id').value == undefined) {
            return true;
        }

        if (this.form.get('valor').value == null || this.form.get('assunto_id').value == undefined) {
            return true;
        }

        return false;
    }

    salvar() {   

        this.openLoading();
        const clienteDados  = this.form.get('cliente').value;

        this.model.cliente_id  = [ clienteDados.id ];        
        this.model.assunto_id = this.form.get('assunto_id').value;
        this.model.observacao = this.form.get('observacao').value;
        this.model.data_hora_atividade = this.form.get('data_hora_atividade').value;
        this.model.valor = this.form.get('valor').value;
        

        if (this.data == null) {
            this._service.create(this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        } else {
            this._service.update(this.form.get('atividade_id').value, this.model).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById() {
        this.openLoading();
        this._atividadeClienteService.getById(this.data.id).subscribe(
            (item) => {                
                            
                this.form.get('data_hora_atividade').setValue( new Date(item.data.data_hora_atividade) );
                this.form.get('assunto_id').setValue(item.data.atividade.assunto_id);
                this.form.get('cliente').setValue(item.data.cliente);
                this.form.get('observacao').setValue(item.data.atividade.observacao);
                this.form.get('atividade_id').setValue(item.data.atividade_id);
                this.form.get('valor').setValue(item.data.atividade.valor);

            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getAllAssuntos() {
        this.loadingSpinnerAssunto = true;
        this._assuntoService.getLista({is_subject_prosperidade: false}).subscribe(
            list => {
                this.listAssunto = list.data;
            },
            error => {
                this.loadingSpinnerAssunto = false;
            },
            () => {
                this.loadingSpinnerAssunto = false;
            });
    }

    getErrorAssuntoMessage() {
        return this.form.get('assunto_id').hasError('required') ? 'Assunto é um campo obrigatório' : '';
    }

    disabledItemByUpdateData(){
        if(this.data.is_alterar_data) return true;

        return false;
    }
}
