import { 
    Component, 
    ViewChild, 
    TemplateRef 
}  from '@angular/core';

import { 
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator 
}  from '@angular/material';

import { ActionDialog } from '../../base-module/base-component/base.component';
import { BaseComponent } from "../../base-module/base-component/base.component";
import { Router } from "@angular/router";
import { ClienteService } from "../services/cliente.service";
import { FormControl } from "@angular/forms";
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FiltrarClienteComponent } from '../filtrar-cliente/filtrar-cliente.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import {Subscription} from "rxjs";
import { UploadHistoricoClienteComponent } from '../upload-historico-cliente/upload-historico-cliente.component';
import { ClientEquityService } from '../services/client-equity.service';

interface ItemsPermissionActions {
    enabled: boolean;
    permission: string;
}

interface Actions {
    buttonNew: ItemsPermissionActions;
    buttonView: ItemsPermissionActions;
    buttonEdit: ItemsPermissionActions;
    buttonDelete: ItemsPermissionActions;
    buttonExport: ItemsPermissionActions;
    buttoUploadPosition: ItemsPermissionActions;
}


@Component({
    selector: 'app-data-table-lista',
    templateUrl: './lista.component.html',
    styleUrls: ['./lista.component.scss'],
    providers: [ ClientEquityService ]
})

export class ListaComponent extends BaseComponent{

    params: any = { };

    usuario:any;
    loadingIndicator: boolean;
    habilitaEscritorios: boolean = false;
    public search = new FormControl('');
    inputSubscription: Subscription; 
    lastDateUpdate: Date;

    _actions: Actions = {
        buttonNew: {
            permission: 'Cadastrar clientes',
            enabled: false
        },
        buttonView: {
            permission: 'Visualizar clientes',
            enabled: false
        },
        buttonEdit: {
            permission: 'Editar clientes',
            enabled: false
        },
        buttonDelete:{
            permission: 'Excluir clientes',
            enabled: false
        },
        buttonExport:{
            permission: 'Exportar Clientes',
            enabled: false
        },
        buttoUploadPosition:{
            permission: 'Importar Clientes',
            enabled: false
        }
    }; 

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaComponent, { static: false }) table: ListaComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;
    @ViewChild('table', { static: false }) table2: DatatableComponent;

    constructor(public service: ClienteService, 
                public dialog: MatDialog, 
                public snackBar: MatSnackBar, 
                private route: Router,
                public _authService:AuthService,
                private _equityClientService: ClientEquityService) {
        super(dialog,snackBar);
        
        this.verifyPermissionForActions();
        this.search.setValue('');
        
        this.usuario = JSON.parse(this._authService.getUser());   
        this.getLastDateUpdate();     
        this.getAll();
        
        if ( this.usuario.escritorios.length > 1){
            this.habilitaEscritorios = true;
        }

    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;

        if(event.column.prop == "assessor"){
            this.params.sortColumn = "assessor_id";
        }else if(event.column.prop == "escritorio"){
            this.params.sortColumn = "escritorio_id";
        }

        this.params.sortOrder = event.newValue;

        this.getAll();
    }

    /**
     * 
     */
    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    /**
     * Consulta lista de empresas
     */
    getAll() {

        if(this.search.value != null && this.search.value != ''){
            this.params.search = this.search.value;
        }

        this.loadingIndicator = true;
        this.inputSubscription = this.service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
                if(error.status == 401){                    
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                } 
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro de empresa
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir cliente",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

     /**
     * 
     */
    cadastroCliente(){
        this.route.navigate(['/clientes/cadastro']);
    }

    /**
     * 
     */
    cadastroCompleto(){
        this.route.navigate(['/clientes/ficha-cadastral']);
    }

    /**
     * 
     * @param id 
     */
    editFichaCadastral(id){
        this.route.navigate(['/clientes/ficha-cadastral/'+id]);
    }

     /**
     * 
     * @param id 
     */
     editClient(id){
        this.route.navigate(['/clientes/cadastro/'+id]);
    }

    /**
     * 
     * @param id 
     */
    viewClient(id){
        this.route.navigate([`/clientes/cadastro/${id}/painel`]);
    }

    /**
     * Modal de cadastro
    */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarClienteComponent, {
            width: '700px',
            data: { 
                escritorio_id: this.params.escritorio_id,
                assessor_id: this.params.assessor_id,
                nome: this.params.nome,
                numero_xp:this.params.numero_xp,
                tipo_cliente:this.params.tipo_cliente,
                homebroker:this.params.homebroker
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            
            if (result != "cancel" && result != undefined) {                

                if (result.escritorio_id != undefined && result.escritorio_id != null){
                    this.params.escritorio_id = result.escritorio_id
                }

                if (result.assessor_id != undefined && result.assessor_id != null){
                    this.params.assessor_id = result.assessor_id
                }
                
                if (result.razao_social != undefined && result.razao_social != null){
                    this.params.razao_social = result.razao_social
                }

                if (result.homebroker != undefined && result.homebroker != null){
                    this.params.homebroker = result.homebroker
                }
                
                if (result.prospect != undefined && result.prospect != null){
                    this.params.prospect = result.prospect
                }

                if (result.numero_xp != undefined && result.numero_xp != null){
                    this.params.numero_xp = result.numero_xp
                }

                if (result.nome != undefined && result.nome != null){
                    this.params.nome = result.nome
                }

                if (result.documento != undefined && result.documento != null){
                    this.params.documento = result.documento
                }

                if (result.profissao_id != undefined && result.profissao_id != null){
                    this.params.profissao_id = result.profissao_id
                }

                if (result.tipo_cliente != undefined && result.tipo_cliente != null){
                    this.params.tipo_cliente = result.tipo_cliente
                }

                if (result.primeiro_contato_id != undefined && result.primeiro_contato_id != null){
                    this.params.primeiro_contato_id = result.primeiro_contato_id
                }

                this.paginator.firstPage();               
                
                this.getAll();
            }
        });
    }

    /**
     * 
     * @param value string
     */
    onEnter(value: string) {    
        this.stopNow();     
        this.params.search = value;
        this.search.setValue(value);
        this.paginator.firstPage();
        this.getAll();
    }

    /**
     * 
     * @param row 
     * @returns 
     */
    getRowClass = (row) => {
        
        if(row.inativo){
            return {'row-color-inativo': true};
        }
    }

    /**
     * Consulta lista
     */
    getAllExport() {

        this.openLoading();
        this.service.getAllExportClientes(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    /**
     * 
     * @param row 
     * @returns 
     */
    getGanhoPerda(row){
        if(row.valor_patrimonio_atual > 0 && row.volume_geral_anterior > 0){
            return (row.valor_patrimonio_atual - row.volume_geral_anterior) - row.whithdrawal_value;
        }

        return 0;
    }

    /**
     * 
     * @param row 
     * @returns 
     */
    getRentabilidade(row){        
        const valor_anterior = this.getGanhoPerda(row);
        if(row.valor_patrimonio_atual > 0 && valor_anterior != 0){
            const percent = (valor_anterior / row.valor_patrimonio_atual) * 100;
            return percent >= 100 || percent <= -100 ? 0 : percent;
        }
        return 0;
    }

    /**
     * Upload da posição do cliente extraido da corretora
     */
    openUpload() :void {
        let dialogRef = this.dialog.open(UploadHistoricoClienteComponent, {
            width: '400px',     
            data:{
                url: 'clientes/import',
                title: 'Importação de Base de Clientes Corretora',
                urlArchiveModule: 'downloads/modelo_importacao_historico.xlsx'
            }      
        });
    }

     /**
     * Verifica se usuário logado tem permissão dos buttons
     * de Delete e Edit
     */
    verifyPermissionForActions(){

        this._actions.buttonDelete.enabled = this._authService
        .isPermissao([this._actions.buttonDelete.permission]);

        this._actions.buttonEdit.enabled = this._authService
        .isPermissao([this._actions.buttonEdit.permission]);

        this._actions.buttonView.enabled = this._authService
        .isPermissao([this._actions.buttonEdit.permission]);

        this._actions.buttonNew.enabled = this._authService
        .isPermissao([this._actions.buttonNew.permission]);

        this._actions.buttonExport.enabled = this._authService
        .isPermissao([this._actions.buttonExport.permission]);

        this._actions.buttoUploadPosition.enabled = this._authService
        .isPermissao([this._actions.buttoUploadPosition.permission]);
    }

    getLastDateUpdate(){                  
        this._equityClientService.getLastUpdate().subscribe(
            list => {
                this.lastDateUpdate = list.data;               
            },
            error => {
                this.showError(error.error.message);                
            },
            () => { }
        );
    }

}
