import {Injectable}   from "@angular/core";
import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class NetNewMoneyService {

    constructor(private authService: AuthService,
                private _http: HttpClient,
                public utilitesService: UtilitesService) {
    }
    /**
     * Lista de Basket
     * @param id ID da Basket
     */
    getAll(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if(paramHttp != null && paramHttp != ""){
            env = "?"+paramHttp;
        }

        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/net-new-money"+env, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Upload de arquivo
     * @param file 
     */
    upload(file):Observable<any>{
        const formData = new FormData();
        formData.append('arquivo', file);
        return this._http.post(this.utilitesService.urlPrefix + 'movimentacao/net-new-money/upload', formData, { headers: this.authService.getAuthHeaders() });
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "movimentacao/net-new-money/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }

}
